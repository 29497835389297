import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

type GridStatesStore = {
  gridStates: {
    [key: string]: GridInitialStatePremium;
  };
  setGridState: (gridName: string, gridState: GridInitialStatePremium) => void;
};

export const useGridStatesStore = create<GridStatesStore>()(
  persist(
    (set) => ({
      gridStates: {},
      setGridState: (gridName, gridState) => {
        set((state) => ({
          gridStates: {
            ...state.gridStates,
            [gridName]: gridState,
          },
        }));
      },
    }),
    {
      name: 'grid-states-store',
      storage: zustandStorage(),
      version: 0,
    },
  ),
);
