import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
  styled,
} from '@mui/material';

export type ListItemTextProps = MuiListItemTextProps;

const ListItemText = ({ ...args }: ListItemTextProps) => (
  <MuiListItemText {...args} />
);

const StyledListItemText = styled(ListItemText)(
  () => `
`,
);
export default StyledListItemText;
