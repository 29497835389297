import { colors } from '@bas/theme';
import { faCircle, faTruck } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid2, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@bas/ui/web/base';
import { ContentSection } from '@bas/ui/web/molecules';

export type PlannableMaterialItemProps = {
  className?: string;
  active?: boolean;
  onClick: () => Promise<void> | void;
};

const PlannableMaterialItem = ({
  className,
  active,
  onClick,
}: PlannableMaterialItemProps): ReactElement => (
  <ContentSection
    className={clsx(className, { 'Bas-PlannableMaterialItem-Active': active })}
    actions={[]}
    onClick={onClick}
  >
    <Box className="Bas-PlannableMaterialItem-root">
      <Box className="Bas-PlannableMaterialItem-Avatar">
        <span className="Bas-Checkbox-Icon fa-stack">
          <Icon icon={faCircle} className="fa-stack-2x" />
          <Icon icon={faTruck} className="fa-stack-1x" />
        </span>
      </Box>
      <Grid2
        container
        className="Bas-PlannableMaterialItem-NameBlock"
        alignItems="center"
      >
        <Grid2 className="Bas-PlannableMaterialItem-Role" size={12}>
          <Typography>
            <FormattedMessage id="projects.movingJobVehicles.addVehicle" />
          </Typography>
        </Grid2>
      </Grid2>
    </Box>
  </ContentSection>
);

const StyledPlannableMaterialItem = styled(PlannableMaterialItem)(
  ({ theme }) => `
  cursor: pointer;
  &:hover, &.Bas-PlannableMaterialItem-Active {
    .Bas-PlannableMaterialItem-root {
      .Bas-PlannableMaterialItem-Role .MuiTypography-root, .Bas-PlannableMaterialItem-Name .MuiTypography-root {
        color: ${colors.blue[500]};
      }
    }
  }

  .Bas-ContentSection-Actions {
    top: -${theme.spacing(0)};
    right: ${theme.spacing(0)};
  }

  &.Bas-ContentSection-HasActions {
    &:hover, &.Bas-ContentSection-Editing {
      &:before {
        left: -13px;
        right: -13px;
        top: -${theme.spacing(1)};
        bottom: -${theme.spacing(1)};
      }
    }
  }

  .Bas-PlannableMaterialItem-root {
    display: flex;
    flex-direction: row;

    .Bas-PlannableMaterialItem-Avatar {
      color: ${colors.lila[400]};
      padding-right: 12px;
      font-size: 22px;
      .fa-stack, .fa-stack-1x, .fa-stack-2x {
        width: 2em;
      }
    }

    .Bas-PlannableMaterialItem-Role .MuiTypography-root {
      color: ${theme.palette.common.black};
    }

    .Bas-PlannableMaterialItem-Name .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
    }
  }
`,
);
export default StyledPlannableMaterialItem;
