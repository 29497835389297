import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Download, isDownload } from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DownloadsRequestProps = Pagination;

type Response = AxiosResponse<Collection<Download>>;
type QueryKeyType = QueryKey & {
  [0]: 'downloads';
  [1]: 'list';
};

export const DownloadsRequest = async ({
  ...params
}: DownloadsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/downloads`, { params });

export const useDownloadsRequest = (
  request: DownloadsRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => DownloadsRequest({ ...request }),
    queryKey: ['downloads', 'list'],
  });

export const DownloadsByDownloadIdsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isDownload(data)) {
    queryClient.invalidateQueries({ queryKey: ['downloads', 'list'] });
  }
};
