import { colors, fontSizesWeb } from '@bas/theme';
import {
  styled,
  TableBody as MuiTableBody,
  TableBodyProps as MuiTableBodyProps,
} from '@mui/material';

export type TableBodyProps = MuiTableBodyProps;

const TableBody = ({ ...args }: TableBodyProps) => <MuiTableBody {...args} />;

const StyledTableBody = styled(TableBody)(
  ({ theme }) => `
.MuiTableCell-root {
  padding: 17.75px 0;
  font-size: ${fontSizesWeb.sm};
  line-height: 18px;
  max-height: 72px;
  height: 60px;
  color: ${colors.lila[800]};
  border-bottom: 0.5px solid ${colors.lila[400]};
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
`,
);
export default StyledTableBody;
