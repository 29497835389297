import { isObjectResponse } from '@bas/value-objects';
import { SchoutenZekerheidCertificate } from '../types';

export function isSchoutenZekerheidCertificate(
  object: unknown,
): object is SchoutenZekerheidCertificate {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'SchoutenZekerheidCertificate'
  );
}
