import {
  NmbrsSettingsInputType,
  NmbrsSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { Integration } from '@bas/integration-domain/models';
import { NmbrsSettingsContentSection } from '@bas/integration-domain/web/molecules';
import { ReactHookForm } from '@bas/ui/web/molecules';
import { ReactElement, useState } from 'react';
import { EditingNmbrsSettingsContentSection } from '../EditingNmbrsSettingsContentSection';

export type EditableNmbrsSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: NmbrsSettingsInputType) => Promise<void>;
};

const EditableNmbrsSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableNmbrsSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<NmbrsSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          companyId: '',
          ...integration.providerSettings,
        }}
        validationSchema={NmbrsSettingsInputTypeValidationBuilder}
      >
        <EditingNmbrsSettingsContentSection
          integrationId={integration.integrationId}
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  return (
    <NmbrsSettingsContentSection
      onEdit={() => setIsEditing(true)}
      integration={integration}
    />
  );
};

export default EditableNmbrsSettingsBlock;
