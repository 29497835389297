import {
  isTimeOffRegistration,
  TimeOffStatistics,
} from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type TimeOffStatisticsByEmployeeIdRequestProps = {
  employeeId: Uuid;
  year: number;
};

type Response = AxiosResponse<TimeOffStatistics>;

export const TimeOffStatisticsByEmployeeIdRequest = async ({
  employeeId,
  year,
  ...params
}: TimeOffStatisticsByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/${employeeId}/time-off-statistics/${year}`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useTimeOffStatisticsByEmployeeIdRequest = (
  { employeeId, ...request }: TimeOffStatisticsByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      TimeOffStatisticsByEmployeeIdRequest({ ...request, employeeId }),
    queryKey: ['time-off-statistics', 'list', employeeId, request],
  });

export const usePrefetchTimeOffStatisticsByEmployeeIdRequest = ({
  employeeId,
  ...request
}: TimeOffStatisticsByEmployeeIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'time-off-statistics',
        'list',
        employeeId,
        ...Object.values({ employeeId, ...request }),
      ],
      queryFn: async () =>
        TimeOffStatisticsByEmployeeIdRequest({ employeeId, ...request }),
    });
  }, [employeeId, queryClient, request]);
};

export const TimeOffStatisticsByEmployeeIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isTimeOffRegistration(data)) {
      queryClient.invalidateQueries({
        queryKey: ['time-off-statistics', 'list', data.employeeId],
      });
    }
  };
