import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { InventoryEvent, isInventoryEvent } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type InventoryEventsByProjectIdRequestProps = Pagination &
  Filterable & {
    projectId: Uuid;
  };

type Response = AxiosResponse<Collection<InventoryEvent>>;

export const InventoryEventsByProjectIdRequest = async ({
  perPage,
  page,
  ...params
}: InventoryEventsByProjectIdRequestProps): Promise<
  AxiosResponse<Collection<InventoryEvent>>
> =>
  axios.get('api/{tenantId}/inventory/inventory-events', {
    params: { ...params, perPage: perPage || 100, page: page || 1 },
  });

export const useInventoryEventsByProjectIdRequest = (
  request: InventoryEventsByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => InventoryEventsByProjectIdRequest(request),
    queryKey: [
      'inventory-items',
      'inventory-events-per-project',
      request.projectId,
      ...Object.values(request),
    ],
  });

export const InventoryEventsByProjectIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isInventoryEvent(data)) {
    queryClient.invalidateQueries({
      queryKey: [
        'inventory-items',
        'inventory-events-per-project',
        data.projectId,
      ],
    });
  }
};
