import { Communication, isCommunication } from '@bas/crm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type CommunicationsRequestProps = Pagination & {
  projectId?: Uuid;
  damageId?: Uuid;
  relationId?: Uuid;
  globalFilter?: string;
};

type Response = AxiosResponse<Collection<Communication>>;

export const CommunicationsRequest = async ({
  projectId,
  relationId,
  damageId,
  ...params
}: CommunicationsRequestProps): Promise<Response> => {
  let url = `api/{tenantId}/relations/${relationId}/communications`;

  if (projectId) {
    url = `api/{tenantId}/projects/${projectId}/communications`;
  }
  return axios.get(url, {
    params: { damage: damageId, ...params },
  });
};

export const useCommunicationsRequest = (
  request: CommunicationsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    placeholderData: keepPreviousData,
    queryFn: async () => CommunicationsRequest(request),
    queryKey: [
      'communications',
      'list',
      request.projectId || request.relationId,
      JSON.stringify(request),
    ],
  });

export const usePrefetchCommunicationsRequest = (
  request: CommunicationsRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'communications',
        'list',
        request.projectId || request.relationId,
        JSON.stringify(request),
      ],
      queryFn: async () => CommunicationsRequest(request),
    });
  }, [queryClient, request]);
};

export const CommunicationsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isCommunication(data)) {
    queryClient.invalidateQueries({
      queryKey: ['communications', 'list', data.relationId],
    });
    if (data.projectId) {
      queryClient.invalidateQueries({
        queryKey: ['communications', 'list', data.projectId],
      });
    }
  }
};
