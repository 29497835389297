import { colors, fontSizesWeb } from '@bas/theme';
import {
  Avatar,
  AvatarProps,
  AvatarSizeType,
  Draggable,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
  ListItemText,
} from '@bas/ui/web/atoms';
import { UseDraggableArguments } from '@dnd-kit/core';
import { ListItemAvatar, styled } from '@mui/material';
import clsx from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';
import { MaterialListItemVariant } from './enum';

export type MaterialListItemProps = Omit<ListItemProps, 'draggable'> & {
  primary: string | ReactNode;
  secondary?: string | ReactNode;
  selected?: boolean;

  button?: ListItemButtonProps;
  avatar: AvatarProps;
  onClick?: MouseEventHandler<Element>;
  variant?: MaterialListItemVariant;
  draggable?: UseDraggableArguments;
};

const MaterialListItem = ({
  className,
  primary,
  secondary,
  avatar,
  onClick,
  button,
  selected,
  draggable,
  disableGutters = true,
  ...args
}: MaterialListItemProps) => {
  const content = (
    <>
      <ListItemAvatar>
        <Avatar size={AvatarSizeType.SMALL} {...avatar} />
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
    </>
  );

  let result: JSX.Element | null = null;
  if (onClick) {
    result = (
      <ListItemButton onClick={onClick} selected={selected} {...button}>
        {content}
      </ListItemButton>
    );
  } else {
    result = content;
  }

  if (draggable) {
    return (
      <Draggable draggableProps={draggable}>
        {({ setNodeRef, listeners, attributes }) => (
          <ListItem
            {...args}
            disableGutters={disableGutters}
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            className={clsx(className, 'Bas-MaterialListItem')}
          >
            {result}
          </ListItem>
        )}
      </Draggable>
    );
  }

  return (
    <ListItem
      className={clsx(className, 'Bas-MaterialListItem')}
      disableGutters={disableGutters}
      {...args}
    >
      {result}
    </ListItem>
  );
};

const StyledMaterialListItem = styled(MaterialListItem)(({
  theme,
  variant = MaterialListItemVariant.DEFAULT,
}) => {
  let variantStyle = ``;

  if (variant === MaterialListItemVariant.DEFAULT) {
    variantStyle = `
      box-sizing: border-box;
      border-bottom: 1px solid ${colors.lila[300]};
      padding: ${theme.spacing(2, 3)};

      &:first-of-type {
        border-top: 1px solid ${colors.lila[300]};
      }

      .MuiListItemAvatar-root {
         padding-right: 4px;
      }

      .MuiTypography-root {
        color: ${colors.lila[800]};
        font-size: ${fontSizesWeb.base};
      }

      .MuiListItemText-primary {
        font-weight: bold;
        line-height: 21px;
      }

      .MuiListItemText-secondary {
        line-height: 22px;
      }
    `;
  } else if (variant === MaterialListItemVariant.MOBILE) {
    variantStyle = `
      box-sizing: border-box;
      border-bottom: 1px solid ${colors.lila[300]};
      padding: 14px 0;

      &:first-of-type {
        border-top: 1px solid ${colors.lila[300]};
      }

      .MuiListItemText-multiline {
        display: flex;
        flex-direction: row;
      }

      .MuiTypography-root {
        color: ${colors.lila[800]};
        font-size: ${fontSizesWeb.lg};
        line-height: 26px;
      }

      .MuiListItemText-primary {
        margin-left: ${theme.spacing(1)};
      }

      .MuiListItemText-secondary {
        margin-left: auto;
        margin-right: 0
      }
    `;
  }

  return `

    ${variantStyle}
    `;
});

const RealMaterialListItem = (props: MaterialListItemProps) => (
  <StyledMaterialListItem {...props} />
);

export default RealMaterialListItem;
