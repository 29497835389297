import { colors, fontSizesWeb } from '@bas/theme';
import { CalendarDay } from '@bas/ui/web/molecules';
import {
  CalendarOptions,
  DatesSetArg,
  DayCellContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { styled } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { forwardRef, RefObject, useCallback } from 'react';
import { DailyCalendarStatisticsTooltip } from '../DailyCalendarStatisticsTooltip';
import { SizeType } from './enum';

export type MonthlyCalendarProps = CalendarOptions & {
  className?: string;
  onChangeCurrentDate?: (date: Date) => void;
  onChangeViewDates?: (viewStartDate: Date, viewEndDate: Date) => void;
  size: SizeType | 'small' | 'medium' | 'large';
};

const MonthlyCalendar = forwardRef<
  FullCalendar | undefined,
  MonthlyCalendarProps
>(
  (
    {
      className,
      onChangeCurrentDate,
      onChangeViewDates,
      size = SizeType.MEDIUM,
      events,
      ...args
    },
    ref,
  ) => {
    const handleNewCurrentDate = useCallback(
      (date: Date) => {
        if (typeof onChangeCurrentDate === 'function') {
          onChangeCurrentDate(date);
        }
      },
      [onChangeCurrentDate],
    );

    const handleDatesChange = useCallback(
      ({ view }: DatesSetArg) => {
        setTimeout(() => {
          if (onChangeViewDates) {
            onChangeViewDates(view.activeStart, view.activeEnd);
          }

          const existingView = args.initialDate;
          if (
            existingView &&
            (existingView instanceof Date ||
              (typeof existingView === 'string' &&
                dayjs(existingView).isValid())) &&
            dayjs(view.currentStart).isSame(existingView, 'month')
          ) {
            return;
          }

          handleNewCurrentDate(view.currentStart);
        }, 50);
      },
      [args.initialDate, handleNewCurrentDate, onChangeViewDates],
    );

    const renderDayCellContent = useCallback(
      (dayInfo: DayCellContentArg) => {
        let availableHoursEvent;
        if (Array.isArray(events)) {
          availableHoursEvent = events.find(
            ({ id }) => id === dayjs(dayInfo.date).format('YYYY-MM-DD'),
          );
        }

        const calendarDate = dayInfo.view.calendar.getDate();
        const isToday = dayjs(dayInfo.date).isSame(dayjs(), 'day');
        const isSelected = dayjs(dayInfo.date).isSame(calendarDate, 'day');
        const isPast = dayjs(dayInfo.date).isBefore(dayjs(), 'day');

        const extendedProps = availableHoursEvent?.extendedProps;

        return (
          <DailyCalendarStatisticsTooltip
            arrow
            allowWider
            currentDate={dayInfo.date}
          >
            <CalendarDay
              size={size}
              isToday={isToday}
              isPast={isPast}
              other={dayInfo.isOther}
              selected={isSelected}
              label={dayInfo.dayNumberText}
              availablePercentage={
                extendedProps ? extendedProps.availablePercentage : 100
              }
              reservedPercentage={extendedProps?.reservedPercentage}
              confirmedPercentage={extendedProps?.confirmedPercentage}
            />
          </DailyCalendarStatisticsTooltip>
        );
      },
      [events, size],
    );

    const handleDateClick = useCallback(
      ({ date }: DateClickArg) => {
        handleNewCurrentDate(date);
      },
      [handleNewCurrentDate],
    );

    return (
      <div
        className={clsx(
          className,
          { 'Bas-MonthlyCalendar-Small': size === SizeType.SMALL },
          { 'Bas-MonthlyCalendar-Medium': size === SizeType.MEDIUM },
          { 'Bas-MonthlyCalendar-Large': size === SizeType.LARGE },
        )}
      >
        <FullCalendar
          schedulerLicenseKey="0026761475-fcs-1692370365"
          titleFormat={{ month: 'long', year: 'numeric' }}
          height="auto"
          headerToolbar={{
            start: '',
            center: 'prev title next',
            end: '',
          }}
          datesSet={handleDatesChange}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          firstDay={1}
          dayCellContent={renderDayCellContent}
          dateClick={handleDateClick}
          ref={ref as RefObject<FullCalendar>}
          {...args}
        />
      </div>
    );
  },
);

const StyledMonthlyCalendar = styled(MonthlyCalendar)`
  .fc .fc-header-toolbar.fc-toolbar {
    min-height: 43px;
  }

  .fc-theme-standard td,
  .fc-theme-standard th,
  .fc-theme-standard .fc-scrollGrid2 {
    border: none;
  }

  .fc-direction-ltr .fc-daygrid-week-number {
    position: absolute;
    left: -36px;
    top: 0;
    bottom: 0;
    background: unset;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    font-family:
      DM Sans,
      sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: ${colors.lila[600]};
  }

  .fc .fc-button.fc-button-primary,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    color: ${colors.lila[800]};
    background: transparent;
    border: 1px solid transparent;
  }

  .fc-toolbar-chunk {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .fc .fc-day-other .fc-daygrid-day-top {
    opacity: 1;
  }

  .fc .fc-scrollgrid {
    border: 0;
  }

  .fc .fc-scrollgrid-section-sticky > * {
    background: transparent;
  }

  .fc .fc-button.fc-button-primary,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    border: none;
    box-shadow: none;
  }

  .fc .fc-toolbar-title {
    text-align: center;
    text-transform: capitalize;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${fontSizesWeb.xl};
    font-weight: normal;
    color: ${colors.lila[800]};
    min-width: 200px;
  }

  .fc th {
    text-align: center;
    text-transform: capitalize;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${fontSizesWeb.base};
    font-weight: normal;
    color: ${colors.lila[600]};
  }

  .fc .fc-daygrid-day.fc-day-today {
    background: none;
  }

  .fc .fc-daygrid-day-frame {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fc-col-header-cell-cushion {
    max-width: 90%;
  }

  .fc .fc-daygrid-day-events {
    display: none;
  }

  &.Bas-MonthlyCalendar-Small {
    .fc .fc-daygrid-day {
      padding-bottom: ${({ theme }) => theme.spacing(1)};
    }

    .fc .fc-daygrid-day-frame {
      padding-bottom: ${({ theme }) => theme.spacing(0)};
      padding-top: ${({ theme }) => theme.spacing(0)};
    }
  }

  &.Bas-MonthlyCalendar-Medium,
  &.Bas-MonthlyCalendar-Large {
    .fc .fc-daygrid-day {
      padding-bottom: ${({ theme }) => theme.spacing(2)};
    }

    .fc .fc-daygrid-day-frame {
      padding-bottom: ${({ theme }) => theme.spacing(1)};
      padding-top: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
export default StyledMonthlyCalendar;
