import {
  BatchData,
  Integration,
  isNewLeadData,
  SyncStatus,
} from '@bas/integration-domain/models';
import { useMarkBatchDataAsCompletedMutation } from '@bas/integration-domain/mutations';
import { useBatchDataByIntegrationIdRequest } from '@bas/integration-domain/requests';
import { NewLeadTableRow } from '@bas/integration-domain/web/organisms';
import { useDatatable } from '@bas/shared/hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bas/ui/web/atoms';
import { LoadingTableRow } from '@bas/ui/web/molecules';
import { DataTableTemplate } from '@bas/ui/web/templates';
import { TableContainer } from '@mui/material';
import { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type NewLeadDataTabProps = {
  integration: Integration;
  failed?: boolean;
};

const NewLeadDataTab = ({
  integration,
  failed,
}: NewLeadDataTabProps): ReactElement => {
  const { mutateAsync: markBatchDataAsCompletedMutation } =
    useMarkBatchDataAsCompletedMutation();

  const filters = useMemo(
    () =>
      failed
        ? {
            'syncStatus.value': [
              SyncStatus.PENDING,
              SyncStatus.AWAITING_APPROVAL,
              SyncStatus.FAILED,
            ],
          }
        : {},
    [failed],
  );

  const {
    basedUponFilters,
    searchGlobally: handleSearch,
    currentPage,
    rowsPerPage,
    resetFilters,
    changePage,
    changeRowsPerPage,
    requestFilters,
  } = useDatatable();

  const {
    data: batchDataItemsData,
    isLoading,
    isFetching,
    isFetched,
  } = useBatchDataByIntegrationIdRequest({
    integrationId: integration.integrationId,
    perPage: rowsPerPage,
    page: currentPage,
    ...requestFilters,
    ...filters,
  });

  const batchDataItems = useMemo(
    () => batchDataItemsData?.data.member || [],
    [batchDataItemsData?.data],
  );

  const numberOfBatchDataItems = batchDataItemsData?.data.totalItems || 0;

  const handleMarkAsCompleted = useCallback(
    async (data: BatchData) => {
      await markBatchDataAsCompletedMutation({
        integrationId: data.integrationId,
        batchId: data.batchId,
        datas: [
          {
            dataId: data.dataId,
          },
        ],
      });
    },
    [markBatchDataAsCompletedMutation],
  );

  const loadingRows = useMemo(
    () =>
      new Array(10)
        .fill(null)
        // eslint-disable-next-line react/no-array-index-key
        .map((v, index) => <LoadingTableRow numberOfColumns={5} key={index} />),
    [],
  );

  return (
    <DataTableTemplate
      isFetching={isFetching && !isFetched}
      onSearch={handleSearch}
      appliedFilters={basedUponFilters}
      onResetFilters={resetFilters}
      pagination={{
        page: currentPage,
        rowsPerPage,
        itemCount: numberOfBatchDataItems,
        onPageChange: (e, page) => changePage(page),
        onRowsPerPageChange: (e) =>
          changeRowsPerPage(Number.parseInt(e.target.value, 10)),
      }}
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell paddingRight>
                <FormattedMessage id="label.name" />
              </TableCell>
              <TableCell paddingRight>
                <FormattedMessage id="label.preferredMovingDate" />
              </TableCell>
              <TableCell paddingRight>
                <FormattedMessage id="label.status" />
              </TableCell>
              <TableCell paddingRight>
                <FormattedMessage id="label.processedAt" />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && loadingRows}
            {!isLoading &&
              batchDataItems.map((data) => {
                if (isNewLeadData(data)) {
                  return (
                    <NewLeadTableRow
                      key={data.dataId}
                      data={data}
                      onMarkAsCompleted={async () =>
                        handleMarkAsCompleted(data)
                      }
                    />
                  );
                }

                return <Fragment key={data.dataId} />;
              })}
            {!isLoading && batchDataItems.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <FormattedMessage id="label.noLeadsFound" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DataTableTemplate>
  );
};

export default NewLeadDataTab;
