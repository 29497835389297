import { isEvent } from '@bas/planning-domain/models';
import { useTenantClosureDatesByDateRequest } from '@bas/planning-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { colors, fontSizesWeb } from '@bas/theme';
import { DailyCalendarNowIndicator } from '@bas/ui/web/atoms';
import {
  halfHourHeightPerMaxScreenHeight,
  isLessImportantEvent,
} from '@bas/value-objects';
import { CalendarOptions, DatesSetArg } from '@fullcalendar/core';
import { VerboseFormattingArg } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box, styled } from '@mui/material';
import { forwardRef, RefObject, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export type DailyCalendarProps = CalendarOptions & {
  className?: string;
  onChangeCurrentDate?: (date: Date) => void;
  currentDate: Date;
  disableEventOrder?: boolean;
};

const weekDayMap: {
  [key: number]:
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';
} = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  0: 'sunday',
};

const DailyCalendar = forwardRef<FullCalendar | undefined, DailyCalendarProps>(
  (
    {
      className,
      onChangeCurrentDate,
      currentDate,
      disableEventOrder,
      events,
      ...args
    },
    ref,
  ) => {
    const { formatDateToParts, formatMessage } = useIntl();
    const tenant = useTenantStore((state) => state.internalTenant);

    const { data: closureDatesData, isPending: isLoadingClosure } =
      useTenantClosureDatesByDateRequest({
        start: currentDate,
        end: currentDate,
      });

    const closureDates = useMemo(
      () => (isLoadingClosure ? [] : (closureDatesData?.data?.member ?? [])),
      [closureDatesData?.data, isLoadingClosure],
    );

    const businessHours = useMemo(() => {
      const result: {
        daysOfWeek: number[];
        startTime?: string | null;
        endTime?: string | null;
      }[] = [];

      if (!tenant || !tenant.workingSchedule) {
        return result;
      }

      const closedWeekDays: number[] = [];
      if (closureDates.length > 0) {
        closureDates.forEach((closureDate) => {
          const dayOfWeek = new Date(closureDate.date).getDay();
          if (!closedWeekDays.includes(dayOfWeek)) {
            closedWeekDays.push(dayOfWeek);
          }
        });
      }

      [1, 2, 3, 4, 5, 6, 0].forEach((dayOfWeek) => {
        const weekDay = tenant.workingSchedule[weekDayMap[dayOfWeek]];
        if (weekDay && !closedWeekDays.includes(dayOfWeek)) {
          result.push({
            daysOfWeek: [dayOfWeek],
            startTime: weekDay.startTime || '00:00:00',
            endTime: weekDay.endTime || '00:00:01',
          });

          return;
        }

        result.push({
          daysOfWeek: [dayOfWeek],
          startTime: '00:00:00',
          endTime: '00:00:01',
        });
      });

      return result;
    }, [tenant, closureDates]);

    const handleNewCurrentDate = useCallback(
      (date: Date) => {
        if (typeof onChangeCurrentDate === 'function') {
          onChangeCurrentDate(date);
        }
      },
      [onChangeCurrentDate],
    );

    const handleDatesChange = useCallback(
      ({ view }: DatesSetArg) => {
        setTimeout(() => {
          handleNewCurrentDate(view.currentStart);
        }, 50);
      },
      [handleNewCurrentDate],
    );

    const formatTitleFormat = useCallback(
      ({ date }: VerboseFormattingArg) => {
        const { day } = date;
        let weekday = '';

        formatDateToParts(date.marker, {
          weekday: 'long',
        }).forEach(({ type, value }) => {
          if (type === 'weekday') {
            weekday = value;
          }
        });

        return formatMessage(
          { id: 'label.dayTitleFormat' },
          {
            day,
            weekday,
          },
        );
      },
      [formatMessage, formatDateToParts],
    );

    const eventsWithClosure = useMemo(
      () => [
        ...(Array.isArray(events) ? events : []),
        ...closureDates.map((closureDate) => ({
          id: closureDate.closureDateId,
          title: closureDate.reason,
          start: closureDate.date,
          allDay: true,
          closureDate: true,
          extendedProps: {
            closureDate: true,
            isHoliday: true,
          },
          holiday: {
            date: closureDate.date,
            name: closureDate.reason,
            country: tenant?.address?.country ?? 'NL',
            holidayType: 'closure',
            extraData: {},
          },
        })),
      ],
      [events, closureDates, tenant?.address?.country],
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleEventOrder = useCallback((a: any, b: any) => {
      const aEvent = a.extendedProps.event;
      const bEvent = b.extendedProps.event;
      if (aEvent?.isPlanning) {
        return -1;
      }

      if (aEvent && isEvent(aEvent) && bEvent && isEvent(bEvent)) {
        const aLessImportant = isLessImportantEvent(aEvent.eventType);
        const bLessImportant = isLessImportantEvent(bEvent.eventType);

        if (!aLessImportant && bLessImportant) {
          return -1;
        }

        if (aLessImportant && !bLessImportant) {
          return 1;
        }

        return 0;
      }
      return 0;
    }, []);

    return (
      <Box className={className}>
        <FullCalendar
          schedulerLicenseKey="0026761475-fcs-1692370365"
          titleFormat={formatTitleFormat}
          slotLabelFormat={{ hour: '2-digit', minute: '2-digit' }}
          height="100%"
          dayHeaders={false}
          headerToolbar={{
            start: '',
            center: 'prev title next',
            end: '',
          }}
          datesSet={handleDatesChange}
          plugins={[
            interactionPlugin,
            dayGridPlugin,
            timeGridPlugin,
            scrollGridPlugin,
            rrulePlugin,
          ]}
          initialView="timeGridDay"
          firstDay={1}
          nowIndicator
          nowIndicatorContent={<DailyCalendarNowIndicator />}
          eventContent={
            <Box sx={{ background: 'red', height: '100%' }}>
              Override event content please!
            </Box>
          }
          slotLabelInterval={{ hours: 1 }}
          slotEventOverlap={false}
          ref={ref as RefObject<FullCalendar>}
          eventOrderStrict={!disableEventOrder}
          eventOrder={disableEventOrder ? undefined : handleEventOrder}
          businessHours={businessHours}
          events={eventsWithClosure}
          {...args}
        />
      </Box>
    );
  },
);

const StyledDailyCalendar = styled(DailyCalendar)`
  height: 100%;

  .fc .fc-button.fc-button-primary,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    color: ${colors.lila[800]};
    background: transparent;
    border: 1px solid transparent;
  }

  .fc-toolbar-chunk {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .fc .fc-toolbar-title,
  .fc .fc-titleWithClick-button {
    text-align: center;
    text-transform: capitalize;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${fontSizesWeb.xl};
    font-weight: normal;
    color: ${colors.lila[800]};
  }

  .fc .fc-button.fc-button-primary,
  .fc .fc-button-primary:not(:disabled):active,
  .fc .fc-button-primary:not(:disabled).fc-button-active,
  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    border: none;
    box-shadow: none;
  }

  .fc .fc-button.fc-today-button:hover {
    color: #fff;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc-button:not(.fc-today-button):hover {
    color: ${colors.blue[500]};
  }

  .fc-daygrid-body .fc-timegrid-axis,
  .fc-timeGrid2 thead .fc-scrollgrid-section-header .fc-timegrid-axis {
    display: none;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border-color: ${colors.lila[400]};
    border: 0;
  }

  .fc .fc-daygrid-day.fc-day-today,
  .fc .fc-timegrid-col.fc-day-today {
    background: ${({ theme }) => theme.palette.common.white};
    padding-left: 52px;
  }

  .fc-timegrid-now-indicator-container {
    margin-left: -52px;
  }

  .fc .fc-timegrid-slot-minor,
  .fc .fc-view > table {
    border: none;
  }

  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    color: ${colors.lila[800]};
    font-size: ${fontSizesWeb.base};
    line-height: 21px;
  }

  .fc .fc-timegrid-slot:not(.fc-timegrid-slot-minor) {
    border-top: 1px solid ${colors.lila[400]};
  }

  .fc .fc-timegrid-slot {
    height: 61px;
  }

  ${Object.keys(halfHourHeightPerMaxScreenHeight).map(
    (maxScreenHeight, index) => {
      const previousKey = Object.keys(halfHourHeightPerMaxScreenHeight)[
        index - 1
      ];
      if (previousKey && !Number.isNaN(previousKey)) {
        return `
        @media (min-height: ${previousKey}px) and (max-height: ${maxScreenHeight}px) {
          .fc .fc-timegrid-slot {
            height: ${halfHourHeightPerMaxScreenHeight[maxScreenHeight]}px;
            }
          }`;
      }

      return `
      @media (max-height: ${maxScreenHeight}px) {
        .fc .fc-timegrid-slot {
          height: ${halfHourHeightPerMaxScreenHeight[maxScreenHeight]}px;
        }
      }`;
    },
  )}
  .fc .fc-timegrid-slot-label {
    vertical-align: top;
  }

  .fc .fc-timegrid-now-indicator-line {
    border-color: ${colors.orange['700']};
    border-width: 2px 0 0;
  }

  .fc .fc-timegrid-now-indicator-arrow {
    right: 0;
    height: 10px;
    border-top: 2px solid ${colors.orange['700']};
    border-left: none;
    border-bottom: none;
    border-right: none;
    margin-top: 0;
  }

  .fc-timegrid-now-indicator-line .Bas-DailyCalendarNowIndicator-root {
    display: none;
  }

  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    padding: 10px 28px 0;
  }

  .fc .fc-daygrid-day-frame,
  .fc .fc-daygrid-day-events {
    min-height: ${({ theme }) => theme.spacing(8)};
    box-sizing: border-box;
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .fc .fc-daygrid-day-events {
    margin-top: 0;
  }

  .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    margin-bottom: 0;
  }

  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: unset;
  }

  .fc-h-event {
    border: 0;
    background: none;
  }

  .fc-event {
    min-width: 226px;
    width: 226px;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0;
    padding-left: 1px;
    margin-right: 24px;
  }

  .fc-daygrid-day {
    padding: ${({ theme }) => theme.spacing(0, 7)};
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 3px;
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-start,
  .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 3px;
  }

  .fc .fc-daygrid-event {
    margin-top: 6px;

    &,
    .fc-event-main {
      width: fit-content;
    }
  }

  .fc-v-event,
  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    background: none;
    border: none;
    box-shadow: none;
  }

  .fc-popover-body {
    display: flex;
    margin-left: -${({ theme }) => theme.spacing(2)};
    margin-right: -${({ theme }) => theme.spacing(2)};

    > div {
      padding: ${({ theme }) => theme.spacing(0, 2)};
    }

    .fc-timegrid-event .fc-event-main {
      margin: 0;
    }

    & .Bas-VerticalEventCalendarItem-Time {
      display: block;
    }
  }

  .fc .fc-timegrid-event-harness:hover {
    z-index: 100 !important;
  }

  .fc-timegrid-col-events {
    padding-left: 52px;
  }
`;
export default StyledDailyCalendar;
