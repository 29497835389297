import { colors } from '@bas/theme';
import {
  Button,
  ReactHookWizard,
  ReactHookWizardRenderProps,
  Tab,
} from '@bas/ui/web/atoms';
import { Dialog, DialogProps, Tabs } from '@bas/ui/web/molecules';
import { ReactHookWizardStep } from '@bas/value-objects';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Portal,
  styled,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { DefaultValues, FieldValues, SubmitHandler } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { FormattedMessage, useIntl } from 'react-intl';

export type FormWizardDialogProps<
  TFieldValues extends FieldValues = FieldValues,
> = Omit<DialogProps, 'title' | 'onSubmit'> & {
  initialValues: DefaultValues<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
  steps: (ReactHookWizardStep<TFieldValues> & {
    disabledContentPadding?: boolean;
  })[];
  persist?: string;
  name: string;
  title: ReactNode;
  onCancel: () => void;
  submitButtonText: ReactNode;
  extraFinishButtons?: ReactNode | ReactNode[];
  disableFormTag?: boolean;
  disableSubmitOnEnter?: boolean;
};

type RenderDialogProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  DialogProps,
  'title' | 'onSubmit'
> & {
  title: ReactNode;
  hideCloseButton?: boolean;
  onCancel: () => void;
  submitButtonText: ReactNode;
  disableSubmitOnEnter?: boolean;
  extraFinishButtons?: ReactNode | ReactNode[];
  steps: (ReactHookWizardStep<TFieldValues> & {
    disabledContentPadding?: boolean;
  })[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & ReactHookWizardRenderProps<TFieldValues, any>;

const RenderDialog = <TFieldValues extends FieldValues = FieldValues>({
  title,
  children,
  submitButtonText,
  disableSubmitOnEnter,
  extraFinishButtons,
  onClose,
  onCancel,
  className,
  form: {
    getValues,
    formState: {
      isSubmitting,
      isValid,
      isValidating,
      isDirty,
      errors,
      dirtyFields: formDirtyFields,
    },
  },
  currentStepIndex,
  currentStep: { disabledContentPadding },
  setCurrentStepIndex,
  finishedSteps,
  isFirstStep,
  isLastStep,
  handleNext,
  handlePrev,
  steps,
  hideCloseButton = false,
  ...args
}: RenderDialogProps<TFieldValues>): ReactElement => {
  const { formatMessage } = useIntl();
  const backendErrors = useMemo(
    () => errors?.backendErrors,
    [errors?.backendErrors],
  );

  const handleClose = useCallback(
    (
      event: Record<string, unknown>,
      reason: 'backdropClick' | 'escapeKeyDown',
      isCancel = false,
    ) => {
      const closingFunction = isCancel ? onCancel : onClose;

      if (!closingFunction) {
        return;
      }

      if (isDirty) {
        const dirtyFields = Object.keys(formDirtyFields).filter(
          (key) => !!getValues(key as FieldPath<TFieldValues>),
        );

        if (
          dirtyFields.length > 0 &&
          // eslint-disable-next-line no-alert
          !window.confirm(formatMessage({ id: 'label.closeWithDirtyFields' }))
        ) {
          return;
        }
      }

      closingFunction(event, reason);
    },
    [formDirtyFields, isDirty, formatMessage, getValues, onClose, onCancel],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      {...args}
      className={clsx(className, {
        'Bas-FormWizardDialog-DisableContentPadding': disabledContentPadding,
      })}
      onClose={handleClose}
      disablePortal
    >
      <DialogTitle>
        <Box className="Bas-FormWizardDialog-Title">
          <Grid2 container columnSpacing={6} alignItems="baseline">
            <Grid2>{title}</Grid2>
            <Grid2>
              <Tabs
                value={currentStepIndex}
                onChange={(e, newTab) => setCurrentStepIndex(newTab)}
              >
                {steps.map((step, index) => (
                  <Tab
                    key={`${step.category}-${step.key}`}
                    label={step.title}
                    disabled={
                      index !== currentStepIndex &&
                      (isSubmitting ||
                      isValidating ||
                      index === currentStepIndex + 1
                        ? !isValid
                        : index > 0 && !finishedSteps.includes(index))
                    }
                  />
                ))}
              </Tabs>
            </Grid2>
          </Grid2>
        </Box>
      </DialogTitle>
      <DialogContent>
        {backendErrors && backendErrors.message && (
          <Typography pb={1} color="error">
            {backendErrors.message as string}
          </Typography>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {isFirstStep && (
          <Button
            onClick={() => handleClose({}, 'escapeKeyDown', true)}
            variant="outlined"
          >
            <FormattedMessage id="button.cancel" />
          </Button>
        )}
        {!isFirstStep && (
          <Button onClick={handlePrev} variant="outlined">
            <FormattedMessage id="button.previous" />
          </Button>
        )}
        {isLastStep && extraFinishButtons}
        <Button
          type={disableSubmitOnEnter ? 'button' : 'submit'}
          onClick={handleNext}
          disabled={isSubmitting}
        >
          {isLastStep ? (
            submitButtonText
          ) : (
            <FormattedMessage id="button.next" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormWizardDialog = <TFieldValues extends FieldValues = FieldValues>({
  onSubmit,
  initialValues,
  steps,
  name,
  persist,
  disableFormTag,
  ...args
}: FormWizardDialogProps<TFieldValues>): ReactElement => (
  <Portal>
    <ReactHookWizard
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialStepIndex={0}
      wizardSteps={steps}
      useProvider
      name={name}
      persist={persist}
      disableFormTag={disableFormTag}
      hideBackendErrors
    >
      {({ ...wizardArgs }) => (
        <RenderDialog {...args} {...wizardArgs} steps={steps} />
      )}
    </ReactHookWizard>
  </Portal>
);

const Styled = styled(FormWizardDialog)`
  &.Bas-FormWizardDialog-DisableContentPadding {
    .MuiDialog-paper .MuiDialogContent-root {
      padding: 0;
    }
  }

  .MuiDialog-paper {
    // height: 80%;
    @media (min-height: 1024px) {
      min-height: 986px;
    }

    .MuiDialogContent-root {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      > .step {
        display: flex;
        justify-content: center;
        width: 100%;
        .MuiGrid2-container {
          align-content: flex-start;
        }
        > .MuiGrid2-root {
          width: 100%;
        }
        > .MuiBox-root > .MuiGrid2-root {
          width: 100%;
        }
      }
    }

    .MuiDialogTitle-root {
      padding-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .MuiDialogTitle-root + .MuiDialogContent-root {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiDialogContent-root + .MuiDialogActions-root {
      padding-top: 38px;
    }

    .MuiDialogActions-root {
      padding: ${({ theme }) => theme.spacing(5)};
      border-top: 1px solid ${colors.lila[400]};
    }

    .MuiDialogContent-root {
      padding: ${({ theme }) => theme.spacing(5, 5, 4, 5)};
    }
  }

  .Bas-FormWizardDialog-Title {
    width: 100%;
    position: relative;
    box-shadow: inset 0px -1px 0px ${colors.lila[400]};
    .MuiTabs-root {
      min-height: ${({ theme }) => theme.spacing(6)};
      max-height: ${({ theme }) => theme.spacing(6)};
      height: ${({ theme }) => theme.spacing(6)};
    }

    .MuiTab-root {
      padding: 0;
      min-height: ${({ theme }) => theme.spacing(6)};
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
` as typeof FormWizardDialog;

const RealFormWizardDialog = <TFieldValues extends FieldValues = FieldValues>(
  props: FormWizardDialogProps<TFieldValues>,
) => <Styled<TFieldValues> {...props} />;

export default RealFormWizardDialog;
