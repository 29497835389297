import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  InventoryOutstandingAndUsedStock,
  isInventoryEvent,
} from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type OutstandingAndUsedStockByProjectIdRequestProps = Pagination &
  Filterable & {
    projectId: Uuid;
  };

type Response = AxiosResponse<Collection<InventoryOutstandingAndUsedStock>>;

export const OutstandingAndUsedStockByProjectIdRequest = async ({
  projectId,
  perPage,
  page,
  ...params
}: OutstandingAndUsedStockByProjectIdRequestProps): Promise<
  AxiosResponse<Collection<InventoryOutstandingAndUsedStock>>
> =>
  axios.get(
    `api/{tenantId}/inventory/item/outstanding-and-used-by-project/${projectId}`,
    {
      params: { ...params, perPage: perPage || 100, page: page || 1 },
    },
  );

export const useOutstandingAndUsedStockByProjectIdRequest = (
  request: OutstandingAndUsedStockByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => OutstandingAndUsedStockByProjectIdRequest(request),
    queryKey: [
      'inventory-items',
      'outstanding-and-used',
      request.projectId,
      ...Object.values(request),
    ],
  });

export const OutstandingAndUsedStockByProjectIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isInventoryEvent(data) && data.projectId) {
      queryClient.invalidateQueries({
        queryKey: ['inventory-items', 'outstanding-and-used', data.projectId],
      });
    }
  };
