import { WhatsAppSettings } from '@bas/integration-domain/models';
import { Button } from '@bas/ui/web/atoms';
import { ContentSection } from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { WhatsAppPhoneNumberNameStatusChip } from '../WhatsAppPhoneNumberStatusChip';

export type ConnectedWhatsAppNumbersBlockProps = {
  providerSettings: WhatsAppSettings;
  onConnectExtraNumber: () => void;
};

const ConnectedWhatsAppNumbersBlock = ({
  providerSettings,
  onConnectExtraNumber,
}: ConnectedWhatsAppNumbersBlockProps): ReactElement => (
  <ContentSection actions={[]}>
    <Grid2 container columnSpacing={3}>
      <Grid2 size={12}>
        <Button onClick={() => onConnectExtraNumber()} size="small">
          <FormattedMessage id="button.connectAExtraNumber" />
        </Button>
      </Grid2>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.connectedWhatsAppNumbers" />
        </Typography>
      </Grid2>
      <Grid2 container spacing={1} size={12}>
        {providerSettings.numbers.map((number) => (
          <Grid2 container key={number.id} size={12}>
            <Grid2 size={6}>{number.verified_name}</Grid2>
            <Grid2 size={3}>{number.display_phone_number}</Grid2>
            <Grid2 size={3}>
              <WhatsAppPhoneNumberNameStatusChip
                phoneNumberStatus={number.name_status}
              />
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default ConnectedWhatsAppNumbersBlock;
