import { ErrorFallbackTemplate } from '@bas/ui/web/templates';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntercom } from 'react-use-intercom';

type AppErrorBoundaryProps = {
  error: unknown;
  eventId: string | null;
  resetError(): void;
};

const AppErrorBoundary = ({
  error,
  eventId,
  resetError,
}: AppErrorBoundaryProps) => {
  const { showNewMessage, trackEvent } = useIntercom();
  const handleRequestHelp = useCallback(
    () => showNewMessage(),
    [showNewMessage],
  );

  useEffect(() => {
    if (error instanceof Error) {
      trackEvent('User got an exception', {
        name: error.name,
        message: error.message,
        eventId,
      });
    } else if (typeof error === 'string') {
      trackEvent('User got an exception', {
        message: error,
        name: 'Unknown error',
        eventId,
      });
    } else {
      trackEvent('User got an exception', {
        name: 'Unknown error',
        message: 'Unknown error',
        eventId,
      });
    }
  }, [trackEvent, error, eventId]);

  return (
    <ErrorFallbackTemplate
      title={<FormattedMessage id="errorFallback.oopsSomethingWentWrong" />}
      description={<FormattedMessage id="errorFallback.errorDescription" />}
      resetError={resetError}
      requestHelp={handleRequestHelp}
    />
  );
};

export default AppErrorBoundary;
