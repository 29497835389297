import { isProjectEstimate, ProjectEstimate } from '@bas/project-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  QueryKey,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type ProjectEstimatesProps = Pagination & {
  projectId: Uuid;
};

type Response = AxiosResponse<Collection<ProjectEstimate>>;
type QueryKeyType = QueryKey & {
  [0]: 'project-estimates';
  [1]: 'list';
  [2]: Uuid;
};

export const ProjectEstimatesByProjectIdsRequest = async ({
  projectId,
  ...params
}: ProjectEstimatesProps): Promise<Response> =>
  axios.get(`api/{tenantId}/projects/${projectId}/project-estimates`, {
    params: { projectId, ...params },
  });

export const useProjectEstimates = (
  request: ProjectEstimatesProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => ProjectEstimatesByProjectIdsRequest({ ...request }),
    queryKey: ['project-estimates', 'list', request.projectId],
  });

export const usePrefetchProjectEstimatesRequest = (
  request: ProjectEstimatesProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['project-estimates', 'list', request.projectId],
      queryFn: async () => ProjectEstimatesByProjectIdsRequest({ ...request }),
    });
  }, [queryClient, request]);
};

export const ProjectEstimatesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isProjectEstimate(data)) {
    queryClient.invalidateQueries([
      'project-estimates',
      'list',
      data.projectId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ] as any);
  }
};
