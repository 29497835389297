import {
  HoursEntry,
  isHoursEntry,
  isProjectHoursEntry,
} from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type HourEntriesByEntryIdsRequestProps = Pagination & {
  entryId: Uuid[];
};

type Response = AxiosResponse<Collection<HoursEntry>>;

export const HourEntriesByEntryIdsRequest = async ({
  ...params
}: HourEntriesByEntryIdsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hours-entries`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useHourEntriesByEntryIdsRequest = (
  { entryId, ...request }: HourEntriesByEntryIdsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => HourEntriesByEntryIdsRequest({ ...request, entryId }),
    queryKey: ['hour-entries', 'list-by-entry-ids', entryId, request],
  });

export const usePrefetchHourEntriesByEntryIdsRequest = ({
  entryId,
  ...request
}: HourEntriesByEntryIdsRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'hour-entries',
        'list-by-entry-ids',
        ...Object.values({ entryId, ...request }),
      ],
      queryFn: async () =>
        HourEntriesByEntryIdsRequest({ entryId, ...request }),
    });
  }, [entryId, queryClient, request]);
};

export const HourEntriesByEntryIdsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isHoursEntry(data) || isProjectHoursEntry(data)) {
    queryClient.invalidateQueries({
      queryKey: ['hour-entries', 'list-by-entry-ids'],
    });
  }
};
