import { Declaration, isDeclaration } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DeclarationByDeclarationIdRequestQueryProps = {
  declarationId: Uuid;
};

export const DeclarationByDeclarationIdRequestQuery = async ({
  declarationId,
  ...params
}: DeclarationByDeclarationIdRequestQueryProps): Promise<
  AxiosResponse<Declaration>
> =>
  axios.get(`api/{tenantId}/hrm/declarations/${declarationId}`, {
    params,
  });

export const useDeclarationByDeclarationIdRequestQuery = (
  request: DeclarationByDeclarationIdRequestQueryProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Declaration>,
    AxiosError,
    AxiosResponse<Declaration>
  > = {},
): UseQueryResult<AxiosResponse<Declaration>, AxiosError> =>
  useQuery<AxiosResponse<Declaration>, AxiosError, AxiosResponse<Declaration>>({
    ...options,
    queryFn: async () => DeclarationByDeclarationIdRequestQuery(request),
    queryKey: ['declarations', 'detail', request.declarationId],
  });

export const DeclarationByDeclarationIdRequestQueryInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isDeclaration(data)) {
      queryClient.invalidateQueries({
        queryKey: ['declarations', 'detail', data.declarationId],
      });
    }
  };
