import {
  styled,
  Table as MuiTable,
  TableProps as MuiTableProps,
} from '@mui/material';

export type TableProps = MuiTableProps;

const Table = ({ ...args }: TableProps) => <MuiTable {...args} />;

const StyledTable = styled(Table)(() => ``);
export default StyledTable;
