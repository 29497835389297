import { Chip } from '@bas/ui/web/atoms';
import { ReactHookFormAutocomplete } from '@bas/ui/web/molecules';
import { AutocompleteRenderGetTagProps, Grid2 } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type ResendEmailFormProps = {
  children?: ReactNode;
};

const ResendEmailForm = ({ children }: ResendEmailFormProps): ReactElement => (
  <Grid2 container rowSpacing={3} columnSpacing={2}>
    <Grid2 size={12}>
      <Controller
        name="receivers"
        render={(registered) => (
          <ReactHookFormAutocomplete
            {...registered}
            fullWidth
            multiple
            options={[]}
            freeSolo
            textField={{
              label: <FormattedMessage id="label.to" />,
              select: false,
            }}
            openOnFocus={false}
            open={false}
            renderTags={(
              value: string[],
              getTagProps: AutocompleteRenderGetTagProps,
            ) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
          />
        )}
      />
    </Grid2>
    {children}
  </Grid2>
);

export default ResendEmailForm;
