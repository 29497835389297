import {
  EstimationSettings,
  isEstimationSettings,
} from '@bas/project-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AllEstimationSettingsRequestProps = Pagination & {
  globalFilter?: string;
};

type Response = AxiosResponse<Collection<EstimationSettings>>;
type QueryKeyType = QueryKey & {
  [0]: 'estimation-settings';
  [1]: 'list';
};

export const AllEstimationSettingsRequest = async ({
  ...params
}: AllEstimationSettingsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/estimation-settings`, {
    params: { ...params },
  });

export const useAllEstimationSettingsRequest = (
  request: AllEstimationSettingsRequestProps = { perPage: 999 },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => AllEstimationSettingsRequest({ ...request }),
    queryKey: ['estimation-settings', 'list', JSON.stringify(request)],
    placeholderData: keepPreviousData,
  });

export const EstimationSettingsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEstimationSettings(data)) {
    queryClient.invalidateQueries({
      queryKey: ['estimation-settings', 'list'],
    });
  }
};
