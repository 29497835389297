import { isEvent, TravelTimeToEvent } from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TravelTimeToEventByEventIdRequestProps = {
  eventId: string;
};

type Response = AxiosResponse<TravelTimeToEvent>;
type QueryKeyType = QueryKey & {
  [0]: 'events';
  [1]: 'travel-time';
  [2]: Uuid;
};

export const TravelTimeToEventByEventIdRequest = async ({
  eventId,
}: TravelTimeToEventByEventIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events/${eventId}/travel-time`);

export const useTravelTimeToEventByEventIdRequest = (
  request: TravelTimeToEventByEventIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    queryFn: async () => TravelTimeToEventByEventIdRequest(request),
    queryKey: ['events', 'travel-time', request.eventId],
    ...options,
  });

export const TravelTimeToEventByEventIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEvent(data)) {
    queryClient.invalidateQueries({
      queryKey: ['events', 'travel-time', data.eventId],
    });
  }
};
