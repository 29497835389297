import { Integration } from '@bas/integration-domain/models';
import { useVerifyAllExternalInvoicesMutation } from '@bas/integration-domain/mutations';
import { useExternalInvoicesByIntegrationIdRequest } from '@bas/integration-domain/requests';
import { useExternalInvoicesTable } from '@bas/integration-domain/web/tables';
import { useUserStore } from '@bas/shared/state';
import { Button, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { StatsBlock } from '@bas/ui/web/molecules';
import { DataGrid } from '@bas/ui/web/organisms';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { ButtonBase, Grid2, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

type VerifyExternalInvoiceDataTabProps = {
  integration: Integration;
};

const VerifyExternalInvoiceDataTab = ({
  integration,
}: VerifyExternalInvoiceDataTabProps): ReactElement => {
  const { data: allInvoicesData, isLoading: isLoadingInvoices } =
    useExternalInvoicesByIntegrationIdRequest({
      integrationId: integration.integrationId,
      perPage: 1,
      order: {
        lastDataSync: 'desc',
      },
    });

  const { data: verifiedInvoicesData, isLoading: isLoadingVerifiedInvoices } =
    useExternalInvoicesByIntegrationIdRequest({
      integrationId: integration.integrationId,
      perPage: 1,
      verified: true,
    });

  const {
    data: unverifiedInvoicesData,
    isLoading: isLoadingUnverifiedInvoices,
  } = useExternalInvoicesByIntegrationIdRequest({
    integrationId: integration.integrationId,
    perPage: 1,
    verified: false,
  });

  const { data: notFoundInvoicesData, isLoading: isLoadingNotFoundInvoices } =
    useExternalInvoicesByIntegrationIdRequest({
      integrationId: integration.integrationId,
      perPage: 1,
      foundInExternalPackage: false,
    });

  const numberOfSyncedInvoices = useMemo(
    () => allInvoicesData?.data?.totalItems || 0,
    [allInvoicesData],
  );

  const numberOfNotFoundInvoices = useMemo(
    () => notFoundInvoicesData?.data?.totalItems || 0,
    [notFoundInvoicesData],
  );

  const numberOfVerifiedInvoices = useMemo(
    () => verifiedInvoicesData?.data?.totalItems || 0,
    [verifiedInvoicesData],
  );

  const numberOfUnverifiedInvoices = useMemo(
    () => unverifiedInvoicesData?.data?.totalItems || 0,
    [unverifiedInvoicesData],
  );

  const isSuperAdmin = useUserStore(
    (state) => state.user?.roles.includes('ROLE_SUPER_ADMIN') || null,
  );

  const [filterType, setFilterType] = useState<
    'verified' | 'notVerified' | 'notFound' | undefined
  >(undefined);

  const dataGridProps = useExternalInvoicesTable({
    query: useExternalInvoicesByIntegrationIdRequest,
    defaultQueryParams: {
      integrationId: integration.integrationId,
    },
    onlyVerified: filterType === 'verified',
    onlyNotVerified: filterType === 'notVerified',
    onlyNotFound: filterType === 'notFound',
  });

  const { mutateAsync: verifyAllExternalInvoicesMutation } =
    useVerifyAllExternalInvoicesMutation();

  const handleSync = useCallback(async () => {
    await verifyAllExternalInvoicesMutation({
      integrationId: integration.integrationId,
    });
  }, [integration.integrationId, verifyAllExternalInvoicesMutation]);

  const lastSyncDate = useMemo(
    () => allInvoicesData?.data?.member?.[0]?.lastDataSync || null,
    [allInvoicesData],
  );

  const canSync = useMemo(() => {
    if (isSuperAdmin) {
      return true;
    }

    return !isLoadingInvoices && dayjs(lastSyncDate).isSame(dayjs(), 'week');
  }, [isLoadingInvoices, lastSyncDate, isSuperAdmin]);

  return (
    <Grid2 container spacing={4}>
      <Grid2 container spacing={2} size={12}>
        <Grid2 size={12}>
          <Tooltip
            disabled={canSync}
            title={
              <>
                {isLoadingInvoices && (
                  <FormattedMessage id="label.invoicesAreStillLoading" />
                )}
                {!isLoadingInvoices &&
                  dayjs(lastSyncDate).isSame(dayjs(), 'week') && (
                    <FormattedMessage id="label.youCanOnlySyncOnceAWeek" />
                  )}
                {!isLoadingInvoices &&
                  !dayjs(lastSyncDate).isSame(dayjs(), 'week') && (
                    <FormattedMessage id="label.verifyExternalInvoices" />
                  )}
              </>
            }
          >
            <span>
              <Button
                onClick={handleSync}
                disabled={!canSync}
                startIcon={<Icon icon={faSync} />}
              >
                <FormattedMessage id="button.verifyData" />
              </Button>
            </span>
          </Tooltip>
        </Grid2>
        <Grid2 size={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.lastVerifySyncDate" />
          </Typography>
          <Typography>
            {lastSyncDate ? (
              <FormattedDate
                value={dayjs(lastSyncDate).toDate()}
                dateStyle="medium"
              />
            ) : (
              <FormattedMessage id="label.never" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={3}>
          <ButtonBase onClick={() => setFilterType(undefined)}>
            <StatsBlock
              variant="secondary"
              title={<FormattedMessage id="label.syncedInvoices" />}
              value={numberOfSyncedInvoices}
              valueSuffix={<FormattedMessage id="label.invoices" />}
              isLoading={isLoadingInvoices}
              sx={{ cursor: 'pointer' }}
            />
          </ButtonBase>
        </Grid2>
        <Grid2 size={3}>
          <ButtonBase onClick={() => setFilterType('verified')}>
            <StatsBlock
              variant="secondary"
              title={<FormattedMessage id="label.verifiedInvoices" />}
              value={numberOfVerifiedInvoices}
              valueSuffix={<FormattedMessage id="label.invoices" />}
              isLoading={isLoadingVerifiedInvoices}
              sx={{ cursor: 'pointer' }}
            />
          </ButtonBase>
        </Grid2>
        <Grid2 size={3}>
          <ButtonBase onClick={() => setFilterType('notVerified')}>
            <StatsBlock
              variant="secondary"
              title={<FormattedMessage id="label.unverifiedInvoices" />}
              value={numberOfUnverifiedInvoices}
              valueSuffix={<FormattedMessage id="label.invoices" />}
              isLoading={isLoadingUnverifiedInvoices}
              sx={{ cursor: 'pointer' }}
            />
          </ButtonBase>
        </Grid2>
        <Grid2 size={3}>
          <ButtonBase onClick={() => setFilterType('notFound')}>
            <StatsBlock
              variant="secondary"
              title={<FormattedMessage id="label.notFoundInvoices" />}
              value={numberOfNotFoundInvoices}
              valueSuffix={<FormattedMessage id="label.invoices" />}
              isLoading={isLoadingNotFoundInvoices}
            />
          </ButtonBase>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <DataGrid {...dataGridProps} gridName="external-invoices" />
      </Grid2>
    </Grid2>
  );
};

export default VerifyExternalInvoiceDataTab;
