import { useFormBackendHandler } from '@bas/shared/hooks';
import { Form } from '@bas/ui/web/atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { AnyObjectSchema } from 'yup';

export type ReactHookFormProps<TFieldValues extends FieldValues> = {
  defaultValues?: DefaultValues<TFieldValues>;
  validationSchema?: AnyObjectSchema | (() => AnyObjectSchema);
  useProvider?: boolean;
  name: string;
  onSubmit: SubmitHandler<TFieldValues>;
  children: ReactNode;
  hideBackendErrors?: boolean;
  disableSubmitOnEnter?: boolean;
};

const ReactHookForm = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  validationSchema,
  children,
  useProvider,
  name,
  onSubmit,
  hideBackendErrors,
  disableSubmitOnEnter,
}: ReactHookFormProps<TFieldValues>): ReactElement => {
  let resolver;
  if (validationSchema) {
    if (typeof validationSchema === 'function') {
      resolver = yupResolver(validationSchema());
    } else {
      resolver = yupResolver(validationSchema);
    }
  }

  const form = useForm<TFieldValues>({
    resolver,
    mode: 'all',
    defaultValues,
  });

  let content = children;
  if (useProvider) {
    content = <FormProvider {...form}>{content}</FormProvider>;
  }

  const handleSubmitWithError = useFormBackendHandler(onSubmit, form);

  const backendErrors = useMemo(
    () => form.formState.errors?.backendErrors,
    [form.formState.errors?.backendErrors],
  );

  // eslint-disable-next-line no-console
  console.log(form.formState.errors);

  const handleDisableSubmit = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (event.target instanceof Element) {
          if (event.target.closest('form')?.name === name) {
            event.preventDefault();
          }
        }
      }
    },
    [name],
  );

  useEffect(() => {
    if (disableSubmitOnEnter) {
      document.addEventListener('keydown', handleDisableSubmit);
    }

    return () => document.removeEventListener('keydown', handleDisableSubmit);
  }, [disableSubmitOnEnter, handleDisableSubmit]);

  return (
    <Form
      style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
      name={name}
      onSubmit={
        form.formState.isSubmitting
          ? undefined
          : form.handleSubmit(handleSubmitWithError)
      }
      noValidate
      isValid={form.formState.isValid && !form.formState.isValidating}
    >
      {backendErrors &&
        backendErrors.message &&
        Array.isArray(backendErrors.message) &&
        !hideBackendErrors &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        backendErrors.message.map((message: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography color="error" key={index}>
            {message}
          </Typography>
        ))}
      {content}
    </Form>
  );
};

export default ReactHookForm;
