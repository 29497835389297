import { colors } from '@bas/theme';
import { Box, BoxProps, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, useEffect, useState } from 'react';
import { FormattedTime } from 'react-intl';

export type DailyCalendarNowIndicatorProps = BoxProps & {
  orientation?: 'vertical' | 'horizontal';
};

const DailyCalendarNowIndicator = ({
  className,
  orientation = 'horizontal',
  ...args
}: DailyCalendarNowIndicatorProps): ReactElement => {
  const [date, setDate] = useState<Date>(new Date());
  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      className={clsx(
        className,
        'Bas-DailyCalendarNowIndicator-root',
        {
          'Bas-DailyCalendarNowIndicator-Horizontal':
            orientation === 'horizontal',
        },
        {
          'Bas-DailyCalendarNowIndicator-Vertical': orientation === 'vertical',
        },
      )}
      {...args}
    >
      <FormattedTime value={date} />
    </Box>
  );
};

const StyledDailyCalendarNowIndicator = styled(DailyCalendarNowIndicator)(
  ({ theme }) => `
  background: ${colors.orange['700']};
  color: ${theme.palette.common.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &.Bas-DailyCalendarNowIndicator-Vertical {
   position: absolute;
   top: -1px;
   left: -26px;
   height: 31px;
   width: 52px;
  }

  &.Bas-DailyCalendarNowIndicator-Horizontal {
   position: absolute;
   top: -16px;
   left: 50%;
   height: 32px;
   width: 50px;
 }
`,
);
export default StyledDailyCalendarNowIndicator;
