import dayjs, { Dayjs } from 'dayjs';
import { ReactElement, ReactNode } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { DatePickerField, DatePickerFieldProps } from '../DatePickerField';

export interface ReactHookFormDatePickerFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<DatePickerFieldProps, 'name' | 'value' | 'error' | 'onChange'> {
  onBlur?: () => void;
  helperText?: ReactNode;
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
  onChange?: (value: Dayjs | null) => void;
}

const fieldToDatePickerField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  disabled,
  onChange: onChangeProp,
  field: { onChange, onBlur, value },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  helperText,
  textFieldProps,
  ...props
}: ReactHookFormDatePickerFieldProps<
  TFieldValues,
  TName
>): DatePickerFieldProps => {
  const fieldError = error?.message;
  const showError = invalid;

  return {
    textFieldProps: {
      ...textFieldProps,
      error: showError,
      helperText: showError ? fieldError : helperText,
      onBlur,
    },
    disabled: disabled ?? isSubmitting,
    onChange: onChangeProp ?? onChange,
    ...props,
    value: typeof value === 'string' ? dayjs(value) : value,
  };
};

const ReactHookFormDatePickerField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...args
}: ReactHookFormDatePickerFieldProps<TFieldValues, TName>): ReactElement => (
  <DatePickerField {...fieldToDatePickerField(args)} />
);

export default ReactHookFormDatePickerField;
