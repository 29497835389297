import { NationalHoliday } from '@bas/hrm-domain/models';
import { NationalHolidayWorkflow } from '@bas/settings-domain/web/organisms';
import { Grid2 } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { ReactElement } from 'react';

dayjs.extend(timezone);

export type NationalHolidayInformationTabProps = {
  nationalHoliday: NationalHoliday;
};

const NationalHolidayInformationTab = ({
  nationalHoliday,
}: NationalHolidayInformationTabProps): ReactElement => (
  <Grid2 container spacing={4}>
    <Grid2 size={12}>
      <NationalHolidayWorkflow
        nationalHolidayId={nationalHoliday.nationalHolidayId}
      />
    </Grid2>
    <Grid2 size={6}>{dayjs.tz.guess()}</Grid2>
  </Grid2>
);

export default NationalHolidayInformationTab;
