import { YukiDomain } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type YukiDomainsByIntegrationIdRequestProps = Pagination & {
  integrationId: Uuid;
};

type Response = AxiosResponse<Collection<YukiDomain>>;

export const YukiDomainsByIntegrationIdRequest = async ({
  integrationId,
  ...params
}: YukiDomainsByIntegrationIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/integrations/${integrationId}/yuki/yuki-domains`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useYukiDomainsByIntegrationIdRequest = (
  request: YukiDomainsByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => YukiDomainsByIntegrationIdRequest(request),
    queryKey: ['integrations', 'yuki-domains', request.integrationId, request],
  });
