import { EmployeeAttribute, isEmployeeAttribute } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeAttributeByEmployeeAttributeIdRequestQueryProps = {
  employeeAttributeId: Uuid;
};

export const EmployeeAttributeByEmployeeAttributeIdRequestQuery = async ({
  employeeAttributeId,
  ...params
}: EmployeeAttributeByEmployeeAttributeIdRequestQueryProps): Promise<
  AxiosResponse<EmployeeAttribute>
> =>
  axios.get(`api/{tenantId}/hrm/employee-attributes/${employeeAttributeId}`, {
    params,
  });

export const useEmployeeAttributeByEmployeeAttributeIdRequestQuery = (
  request: EmployeeAttributeByEmployeeAttributeIdRequestQueryProps,
  options: QueryOptionsWithKey<
    AxiosResponse<EmployeeAttribute>,
    AxiosError,
    AxiosResponse<EmployeeAttribute>
  > = {},
): UseQueryResult<AxiosResponse<EmployeeAttribute>, AxiosError> =>
  useQuery<
    AxiosResponse<EmployeeAttribute>,
    AxiosError,
    AxiosResponse<EmployeeAttribute>
  >({
    ...options,
    queryFn: async () =>
      EmployeeAttributeByEmployeeAttributeIdRequestQuery(request),
    queryKey: ['employee-attributes', 'detail', request.employeeAttributeId],
  });

export const EmployeeAttributeByEmployeeAttributeIdRequestQueryInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEmployeeAttribute(data)) {
      queryClient.invalidateQueries({
        queryKey: ['employee-attributes', 'detail', data.employeeAttributeId],
      });
    }
  };
