import {
  ContentSection,
  ContentSectionCancelAction,
  ContentSectionSaveAction,
} from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { EmployeeAttributeForm } from '../EmployeeAttributeForm';

export type EditingEmployeeAttributeInformationContentSectionProps = {
  onCancel: () => void;
};

const EditingEmployeeAttributeInformationContentSection = ({
  onCancel,
}: EditingEmployeeAttributeInformationContentSectionProps): ReactElement => (
  <ContentSection
    editing
    actions={[
      <ContentSectionCancelAction onClick={onCancel} />,
      <ContentSectionSaveAction />,
    ]}
  >
    <Grid2 container rowSpacing={2} size={12}>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.details" />
        </Typography>
      </Grid2>
      <Grid2 container rowSpacing={3} size={12}>
        <Grid2 size={12}>
          <EmployeeAttributeForm />
        </Grid2>
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default EditingEmployeeAttributeInformationContentSection;
