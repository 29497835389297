import {
  Feature,
  InternalTenant,
  PublicTenant,
} from '@bas/tenant-domain/models';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

type TenantStoreType = {
  tenant?: PublicTenant;
  internalTenant?: InternalTenant;
  resetTenant: () => void;
  setTenant: (tenant: PublicTenant) => void;
  setInternalTenant: (tenant: InternalTenant) => void;
  updateInternalTenantValues: (values: Partial<InternalTenant>) => void;
  updateTenantFeature: (value: Feature) => void;
  getTenantId: (forcePublic?: boolean) => string | undefined;
  getTimezone: () => string | undefined;
  logout: () => void;
};

export const useTenantStore = create<TenantStoreType>()(
  persist(
    (set, get) => ({
      tenant: undefined,
      internalTenant: undefined,
      setTenant: (tenant: PublicTenant) => {
        let { internalTenant } = get();
        if (internalTenant && tenant.tenantId !== internalTenant.tenantId) {
          internalTenant = undefined;
        }

        set({ tenant, internalTenant });
      },

      setInternalTenant: (tenant: InternalTenant) =>
        set({ internalTenant: tenant }),
      resetTenant: () => set({ tenant: undefined, internalTenant: undefined }),
      logout: () => set({ tenant: undefined, internalTenant: undefined }),
      getTenantId: (forcePublic = false) =>
        forcePublic
          ? get().tenant?.tenantId
          : get().tenant?.tenantId || get().internalTenant?.tenantId,

      getTimezone: () =>
        get().tenant?.timezone || get().internalTenant?.timezone,
      updateInternalTenantValues: (values: Partial<InternalTenant>) => {
        set((state) => ({
          internalTenant: state.internalTenant
            ? {
                ...state.internalTenant,
                ...values,
              }
            : undefined,
        }));
      },
      updateTenantFeature: (value: Feature) => {
        set((state) => ({
          internalTenant: state.internalTenant
            ? {
                ...state.internalTenant,
                features: [
                  ...state.internalTenant.features.filter(
                    (feature) => feature.featureType !== value.featureType,
                  ),
                  value,
                ],
              }
            : undefined,
        }));
      },
    }),
    {
      name: 'tenant-store',
      storage: zustandStorage(),
    },
  ),
);
