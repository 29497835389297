import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { isStorage, isStoredItem, StoredItem } from '@bas/wms-domain/models';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type StoredItemsRequestProps = Pagination &
  Filterable & {
    storageId?: Uuid | null;
    storageLocationId?: Uuid | null;
  };
type Response = AxiosResponse<Collection<StoredItem>>;

export const StoredItemsRequest = (
  { storageId, storageLocationId, ...params }: StoredItemsRequestProps,
  signal?: AbortSignal,
): Promise<Response> =>
  axios.get(
    `api/{tenantId}/${storageId ? `storages/${storageId}/` : ''}stored-items`,
    {
      params: {
        perPage: 9999999,
        ...params,
        warehouse: storageId || undefined,
        storageLocation: storageLocationId || undefined,
      },
      signal,
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

type QueryKey = ['stored-items', 'list', StoredItemsRequestProps];

export const useStoredItemsRequest = (
  request: StoredItemsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKey
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKey>({
    ...options,
    queryKey: ['stored-items', 'list', request],
    queryFn: async ({ signal }) => StoredItemsRequest(request, signal),
  });

export const usePrefetchStoredItemsRequest = (
  request: StoredItemsRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['stored-items', 'list', request],
      queryFn: async ({ signal }) => StoredItemsRequest(request, signal),
    });
  }, [queryClient, request]);
};

export const StoredItemsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isStoredItem(data) || isStorage(data)) {
    queryClient.invalidateQueries({ queryKey: ['stored-items', 'list'] });
  }
};
