import { colors } from '@bas/theme';
import { Box, BoxProps, Grid2, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';

export type ContentSectionProps = BoxProps & {
  actions: Array<ReactElement>;
  editing?: boolean;
};

const ContentSection = ({
  children,
  actions = [],
  editing = false,
  className,
  ...args
}: ContentSectionProps) => (
  <Box
    {...args}
    className={clsx(
      className,
      { 'Bas-ContentSection-Editing': editing },
      { 'Bas-ContentSection-HasActions': actions.length > 0 },
    )}
  >
    <Box className="Bas-ContentSection-Actions">
      <Grid2
        container
        spacing={1}
        alignItems="baseline"
        justifyContent="flex-end"
      >
        {actions.map((action, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid2 key={index}>{action}</Grid2>
        ))}
      </Grid2>
    </Box>
    <Box width="100%">{children}</Box>
  </Box>
);

const StyledContentSection = styled(ContentSection)(
  ({ theme }) => `
  color: ${colors.lila[800]};
  position: relative;
  box-sizing: border-box;
  margin-bottom: ${theme.spacing(2)};
  display: flex;
  // padding: ${theme.spacing(2, 3)};
  // margin: -${theme.spacing(2)} 0 -${theme.spacing(2)} -${theme.spacing(3)};


  &:before {
    transition: background 0.4s ease;
    background: transparent;
    content: '';
    border-radius: 5px;
    position: absolute;
    display: block;
    left: -${theme.spacing(3)};
    right: -${theme.spacing(3)};
    top: -${theme.spacing(2)};
    bottom: -${theme.spacing(2)};
    z-index: -1;
  }

  .Bas-ContentSection-Actions {
    position: absolute;
    top: ${theme.spacing(0)};
    right: ${theme.spacing(0)};
    display: none;

    ${theme.breakpoints.down('xl')} {
      max-width: 150px;
    }

    .MuiIconButton-root {
      padding: 0;
      background: transparent !important;
    }

    &, .MuiIconButton-root {
      color: ${colors.blue[500]};
      font-size: 20px;
    }

    .MuiGrid2-root:hover {
      &, .MuiIconButton-root {
        color: ${colors.blue[700]};
      }
    }
  }

  &.Bas-ContentSection-HasActions {
    &.Bas-ContentSection-Editing {
      .Bas-ContentSection-Actions {
        top: ${theme.spacing(1)};
      }
    }
    &:hover, &.Bas-ContentSection-Editing {
      &:before {
        background: ${colors.lila[100]};
      }

      .MuiTypography-subtitle1:not(.Bas-ContentSection-DontChangeColor) {
        color: ${colors.blue[500]};
      }

      .Bas-ContentSection-Actions {
        display: block;
      }
    }
  }
`,
);
export default StyledContentSection;
