import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RejectExternalEmployeeMutationProps = {
  integrationId: Uuid;
  externalId?: Uuid | null;
  reason?: string | null;
};

export const RejectExternalEmployeeMutation = async ({
  integrationId,
  externalId,
  ...values
}: RejectExternalEmployeeMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/integrations/${integrationId}/external-employees/${externalId}/reject`,
    {
      integrationId,
      externalId,
      ...values,
    },
  );

export const useRejectExternalEmployeeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RejectExternalEmployeeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RejectExternalEmployeeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RejectExternalEmployeeMutationProps
  >({
    mutationFn: RejectExternalEmployeeMutation,
    throwOnError: false,
    ...options,
  });
