import { isEmployee, ListedEmployee } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type EmployeesRequestProps = Pagination &
  Filterable & {
    backofficeOnly?: boolean;
    activeEmploymentOnly?: boolean;
  };

type Response = AxiosResponse<Collection<ListedEmployee>>;

export const EmployeesRequest = async ({
  activeEmploymentOnly,
  ...params
}: EmployeesRequestProps): Promise<Response> => {
  const extraParams: { [key: string]: string } = {};

  if (activeEmploymentOnly) {
    extraParams['employmentEndDate[after]'] = formatDate(new Date());
  }

  return axios.get('api/{tenantId}/hrm/employees', {
    params: { ...extraParams, ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });
};

export const useEmployeesRequest = (
  request: EmployeesRequestProps = {
    perPage: 9999,
    activeEmploymentOnly: true,
    backofficeOnly: false,
  },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => EmployeesRequest(request),
    queryKey: ['employees', 'list', ...Object.values(request)],
  });

export const usePrefetchEmployeesRequest = (
  request: EmployeesRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['employees', 'list', ...Object.values(request)],
      queryFn: async () => EmployeesRequest(request),
    });
  }, [queryClient, request]);
};

export const EmployeesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEmployee(data)) {
    queryClient.invalidateQueries({ queryKey: ['employees', 'list'] });
  }
};
