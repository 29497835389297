import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  InventoryOutstandingStock,
  isInventoryEvent,
} from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type OutstandingStockRequestProps = Pagination &
  Filterable & {
    inventoryItemId: Uuid;
  };

type Response = AxiosResponse<Collection<InventoryOutstandingStock>>;

export const OutstandingStockRequest = async ({
  inventoryItemId,
  perPage,
  page,
  ...params
}: OutstandingStockRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/inventory/item/${inventoryItemId}/outstanding`, {
    params: { ...params, perPage: perPage || 100, page: page || 1 },
  });

export const useOutstandingStockRequest = (
  request: OutstandingStockRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => OutstandingStockRequest(request),
    queryKey: ['moving-boxes', 'outstanding', request.inventoryItemId, request],
  });

export const OutstandingStockRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isInventoryEvent(data) && data.inventoryItemId) {
    queryClient.invalidateQueries({
      queryKey: ['moving-boxes', 'outstanding', data.inventoryItemId],
    });
  }
};
