import { fontSizesWeb } from '@bas/theme';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid2 } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import {
  SidebarListItem,
  SidebarListItemProps,
  SidebarSizeType,
} from '../SidebarListItem';

export type MenuListItemProps = SidebarListItemProps & {
  icon: IconProp;
  label: ReactElement | string;
};

const MenuListItem = ({
  icon,
  label,
  size = SidebarSizeType.MEDIUM,
  ...args
}: MenuListItemProps) => (
  <SidebarListItem {...args} size={size}>
    <Grid2
      container
      alignContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid2 className="MenuListItem-Icon">
        <FontAwesomeIcon icon={icon} />
      </Grid2>
      <Grid2 className="MenuListItem-Label">{label}</Grid2>
    </Grid2>
  </SidebarListItem>
);

const StyledMenuListItem = styled(MenuListItem)(
  ({ theme }) => `
  .MenuListItem-Icon {
    font-size: 1.65rem;
    margin-bottom: 4px;
  }

  .MenuListItem-Label {
    font-size: ${fontSizesWeb.xs};
    font-family: ${theme.typography.fontFamily};
  }
`,
);
export default StyledMenuListItem;
