import { Button, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import {
  ClickAwayListener,
  Grid2,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import {
  Dispatch,
  Fragment,
  MouseEvent,
  ReactElement,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

export type WorkflowItem =
  | {
      label: ReactNode | string;
      variant?: 'text' | 'outlined' | 'contained';
      color?: 'inherit' | 'primary' | 'success' | 'error' | 'info' | 'warning';
      dialog: ReactNode;
      disabled?: boolean;
      allowInDropdown?: boolean;
      forceOutlined?: boolean;
      tooltip?: string | ReactNode;
      hidden?: boolean;
    }
  | {
      label: ReactNode | string;
      variant?: 'text' | 'outlined' | 'contained';
      color?: 'inherit' | 'primary' | 'success' | 'error' | 'info' | 'warning';
      onClick: () => void;
      disabled?: boolean;
      allowInDropdown?: boolean;
      forceOutlined?: boolean;
      tooltip?: string | ReactNode;
      hidden?: boolean;
    }
  | {
      buttonComponent?: ReactNode;
      allowInDropdown?: boolean;
      hidden?: boolean;
    };

const hasButtonComponent = (
  object: WorkflowItem,
): object is { buttonComponent?: ReactNode } =>
  Object.prototype.hasOwnProperty.call(object, 'buttonComponent');

export type WorkflowButtonsProps = {
  workflow: WorkflowItem[];
  activeButtonIndex: number | undefined;
  setActiveButtonIndex: Dispatch<SetStateAction<undefined | number>>;
};

const WorkflowButtons = ({
  workflow,
  activeButtonIndex,
  setActiveButtonIndex,
}: WorkflowButtonsProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggle = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const dialog = useMemo(() => {
    if (typeof activeButtonIndex === 'undefined') {
      return undefined;
    }

    const activeButton = workflow[activeButtonIndex];

    if (!activeButton || hasButtonComponent(activeButton)) {
      return undefined;
    }

    if ('onClick' in activeButton) {
      return undefined;
    }

    return activeButton.dialog;
  }, [activeButtonIndex, workflow]);

  const [dropdownButtons, mainButtons] = useMemo(() => {
    const mappedButtons = [...workflow]
      .map((button, index) => ({
        ...button,
        index,
      }))
      .filter(({ hidden }) => !hidden);

    if (mappedButtons.length <= 3) {
      return [[], mappedButtons];
    }

    const filteredButtons = mappedButtons.filter(
      (button) => !hasButtonComponent(button) && button.allowInDropdown,
    );

    return [
      filteredButtons,
      mappedButtons.filter((button) => !filteredButtons.includes(button)),
    ];
  }, [workflow]);

  return (
    <Grid2 container spacing={1}>
      {mainButtons.map(({ index, ...button }) => {
        if (hasButtonComponent(button)) {
          return <Grid2 key={index}>{button.buttonComponent}</Grid2>;
        }
        let variant = button.variant || 'contained';
        if (button.forceOutlined) {
          variant = 'outlined';
        }

        if (button.tooltip) {
          return (
            <Grid2 key={index}>
              <Tooltip title={button.tooltip}>
                <span>
                  <Button
                    disabled={button.disabled}
                    variant={variant}
                    color={button.color}
                    onClick={
                      'onClick' in button
                        ? button.onClick
                        : () => setActiveButtonIndex(index)
                    }
                  >
                    {button.label}
                  </Button>
                </span>
              </Tooltip>
            </Grid2>
          );
        }

        return (
          <Grid2 key={index}>
            <Button
              disabled={button.disabled}
              variant={variant}
              color={button.color}
              onClick={
                'onClick' in button
                  ? button.onClick
                  : () => setActiveButtonIndex(index)
              }
            >
              {button.label}
            </Button>
          </Grid2>
        );
      })}
      {dropdownButtons.length > 0 && (
        <Grid2>
          <Button variant="outlined" onClick={handleToggle}>
            <FormattedMessage id="button.extraActions" /> &nbsp;
            <Icon icon={faChevronDown} />
          </Button>
        </Grid2>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        style={{ zIndex: 2 }}
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: false,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {dropdownButtons.map(({ index, ...button }) => {
                    if (hasButtonComponent(button)) {
                      return <Fragment key={index} />;
                    }

                    return (
                      <MenuItem
                        onClick={() =>
                          'onClick' in button
                            ? button.onClick()
                            : setActiveButtonIndex(index)
                        }
                        key={index}
                        sx={{
                          color: button.color === 'error' ? 'error.main' : '',
                        }}
                      >
                        {button.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {dialog}
    </Grid2>
  );
};

export default WorkflowButtons;
