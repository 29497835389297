import * as Yup from 'yup';

export type EmployeeAttributeInputType = {
  name: string;
  description: string;
};

export const employeeAttributeInputTypeDefaultValues =
  (): EmployeeAttributeInputType => ({
    name: '',
    description: '',
  });

export const employeeAttributeInputTypeValidationBuilder =
  (): Yup.ObjectSchema<EmployeeAttributeInputType> =>
    Yup.object({
      name: Yup.string().label('label.attribute').ensure().required().max(25),
      description: Yup.string()
        .label('label.description')
        .ensure()
        .required()
        .max(150),
    });
