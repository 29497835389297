import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import {
  DriversLicenseInputType,
  DriversLicenseInputTypeDefaultValues,
  DriversLicenseInputTypeValidatorBuilder,
} from '@bas/shared/input-types';
import * as Yup from 'yup';

export interface ImportExternalEmployeeInputType
  extends DriversLicenseInputType {
  externalId: string;
  employeeSkills: (OfficeSkill | MovingJobSkill)[];
}

export const ImportExternalEmployeeInputTypeDefaultValues =
  (): ImportExternalEmployeeInputType => ({
    externalId: '',
    employeeSkills: [],
    ...DriversLicenseInputTypeDefaultValues(),
  });

export const ImportExternalEmployeeInputTypeValidationBuilder =
  (): Yup.ObjectSchema<ImportExternalEmployeeInputType> =>
    Yup.object({
      externalId: Yup.string().required().label('label.externalId'),
      employeeSkills: Yup.array()
        .of(
          Yup.string()
            .required()
            .oneOf([
              ...Object.values(OfficeSkill),
              ...Object.values(MovingJobSkill),
            ])
            .label('label.skill'),
        )
        .required()
        .min(1)
        .label('label.skills'),
    }).concat(DriversLicenseInputTypeValidatorBuilder());
