import { Event, isEvent } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';

export type RecurringEventsByEmployeeIdRequestProps = Pagination & {
  employeeId: Uuid;
  fromDate: Date | Dayjs;
  untilDate: Date | Dayjs;
  intake?: 'true' | 'false';
};

type Response = AxiosResponse<Collection<Event>>;
export const RecurringEventsByEmployeeIdRequest = async ({
  employeeId,
  fromDate,
  untilDate,
  ...params
}: RecurringEventsByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/events/recurring/by-employee/${employeeId}/${formatDate(
      fromDate,
    )}/${formatDate(untilDate)}`,
    {
      params: {
        ...params,
      },
    },
  );

export const useRecurringEventsByEmployeeIdRequest = (
  request: RecurringEventsByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      RecurringEventsByEmployeeIdRequest({ perPage: 100, ...request }),
    queryKey: [
      'recurring-events',
      'list',
      request.employeeId,
      formatDate(request.fromDate),
      formatDate(request.untilDate),
      request.intake,
    ],
  });

export const RecurringEventsByEmployeeIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (
    isEvent(data) &&
    !!data.employees &&
    typeof data.employees === 'object' &&
    Array.isArray(data.employees)
  ) {
    data.employees.forEach(({ employee: { employeeId } }) =>
      queryClient.invalidateQueries({
        queryKey: ['recurring-events', 'list', employeeId],
      }),
    );
  }
};
