import { colors, fontSizesWeb } from '@bas/theme';
import {
  styled,
  TableFooter as MuiTableFooter,
  TableFooterProps as MuiTableFooterProps,
} from '@mui/material';
import clsx from 'clsx';

export type TableFooterProps = MuiTableFooterProps & {
  noBorder?: boolean;
  dense?: boolean;
};

const TableFooter = ({
  className,
  noBorder,
  dense,
  ...args
}: TableFooterProps) => (
  <MuiTableFooter
    className={clsx(
      className,
      { 'Bas-TableFooter-NoBorder': noBorder },
      { 'Bas-TableFooter-Dense': dense },
    )}
    {...args}
  />
);

const StyledTableFooter = styled(TableFooter)(
  ({ theme }) => `
.MuiTableCell-root {
  padding: 17.75px 0;
  font-size: ${fontSizesWeb.sm};
  line-height: 18px;
  color: ${colors.lila[800]};
  border-bottom: 0.5px solid ${colors.lila[400]};
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
&.Bas-TableFooter-NoBorder {
  .MuiTableCell-root {
    border: none;
  }
}
&.Bas-TableFooter-Dense {
  .MuiTableCell-root {
    padding: 11px 0 0 0;
  }
}
`,
);
export default StyledTableFooter;
