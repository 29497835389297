import { colors } from '@bas/theme';
import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
  styled,
} from '@mui/material';
import { forwardRef, ReactElement } from 'react';

export type ListItemProps = MuiListItemProps;

const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  ({ ...args }: ListItemProps, ref): ReactElement => (
    <MuiListItem {...args} ref={ref} />
  ),
);

const StyledListItem = styled(ListItem)(
  ({ theme }) => `
  color: ${colors.lila[800]};
`,
);
export default StyledListItem;
