import { colors, fontSizesWeb } from '@bas/theme';
import { Box, styled } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type OptionalLabelProps = {
  className?: string;
};

const OptionalLabel = ({ className }: OptionalLabelProps): ReactElement => (
  <Box className={className}>
    <FormattedMessage id="label.optional" />
  </Box>
);

const StyledOptionalLabel = styled(OptionalLabel)(
  ({ theme }) => `
  background: ${colors.lila[400]};
  border-radius: 3px;
  min-width: 65px;
  height: 23px;
  color: ${colors.lila[800]};
  font-weight: bold;
  font-size: ${fontSizesWeb.xs};
  line-height: 13px;
  width: fit-content;
  margin-left: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

`,
);
export default StyledOptionalLabel;
