import { useEmployeeStore, useUserStore } from '@bas/shared/state';
import { colors, fontSizesWeb } from '@bas/theme';
import {
  Avatar,
  AvatarSizeType,
  Badge,
  SidebarIconListItem,
  SidebarListItem,
  SidebarSizeType,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { MenuList, MenuListProps } from '@bas/ui/web/molecules';
import { faBell } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid2, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  INotificationCenterStyles,
  NovuProvider,
  PopoverNotificationCenter,
  useSocket,
} from '@novu/notification-center';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export type SidebarProps = {
  mainMenuList: MenuListProps;
  bottomMenuList: MenuListProps;
  logo: ReactNode | string;
  forceSmall?: boolean;
};

const NovuComponent = () => {
  const { socket } = useSocket();
  const { enqueueSnackbar } = useSnackbar();

  const handleEvent = useCallback(
    (event: Record<string, Record<string, unknown>>) => {
      // eslint-disable-next-line no-console
      console.log('received event', event);
      if (!event.message || typeof event.message.content !== 'string') {
        return;
      }

      enqueueSnackbar(event.message.content, {
        variant: 'info',
        persist: false,
      });
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    const eventName = 'notification_received';
    socket?.on(eventName, handleEvent);

    return () => socket?.off(eventName);
  }, [handleEvent, socket]);

  const navigate = useNavigate();

  return (
    <PopoverNotificationCenter
      position="right"
      colorScheme="light"
      showUserPreferences
      onActionClick={(templateIdentifier, type, message) => {
        if (message.cta?.type === 'redirect') {
          if (message.cta?.data.url?.includes('http')) {
            window.open(message.cta?.data.url, '_blank');
          } else if (message.cta?.data.url) {
            navigate(message.cta?.data.url);
          }
        }
      }}
    >
      {({ unseenCount }) => (
        <SidebarIconListItem>
          <Grid2
            container
            alignContent="center"
            alignItems="center"
            direction="column"
          >
            <Grid2 className="MenuListItem-Icon">
              <Badge badgeContent={unseenCount} color="primary">
                <Icon icon={faBell} />
              </Badge>
            </Grid2>
            <Grid2 className="MenuListItem-Label">
              <FormattedMessage id="label.notifications" />
            </Grid2>
          </Grid2>
        </SidebarIconListItem>
      )}
    </PopoverNotificationCenter>
  );
};

const Sidebar = ({
  mainMenuList,
  bottomMenuList,
  logo,
  forceSmall = false,
  ...args
}: SidebarProps) => {
  const employee = useEmployeeStore((state) => state.employee);
  const user = useUserStore((state) => state.user);
  let isSmallerMenu = useMediaQuery('(max-height:852px)');
  if (forceSmall) {
    isSmallerMenu = true;
  }

  const userId = useUserStore((state) => state.user?.userId);
  const theme = useTheme();
  const styles = useMemo(
    (): INotificationCenterStyles => ({
      popover: {
        dropdown: {
          marginLeft: '-13px',
        },
      },
      layout: {
        root: {
          width: 500,
          minHeight: 600,
          background: colors.lila[100],
          ' > div:nth-child(2)': {
            minHeight: 580,
            '.infinite-scroll-component': {
              minHeight: '580px !important',
            },
          },
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
          '.nc-unseen-badge': {
            background: theme.palette.primary.main,
          },
        },
      },
      header: {
        title: {
          color: colors.lila[800],
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: fontSizesWeb.xl,
          lineHeight: '31px',
        },
        markAsRead: {
          padding: '9.5px 11px',
          color: colors.lila[800],
          background: colors.white,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.lila[400],
          fontSize: '16px',
          lineHeight: '21px',
          fontWeight: 700,
          borderRadius: '5px',
        },
      },
      notifications: {
        root: {},
        listItem: {
          contentLayout: {},
          timestamp: {
            color: colors.lila[600],
          },
          unread: {
            background: colors.white,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors.lila[300],
            color: colors.lila[800],
            fontSize: fontSizesWeb.base,
            lineHeight: '24px',
            '&:before': {
              background: theme.palette.primary.main,
            },
            '.nc-bell-button-dot > rect': {
              fill: theme.palette.primary.main,
            },
          },
          read: {
            background: colors.white,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors.lila[300],
            color: colors.lila[800],
            fontSize: fontSizesWeb.base,
            lineHeight: '24px',
          },
          buttons: {
            root: {
              padding: '11px 18px !important',
              color: colors.white,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'transparent',
              fontSize: '16px',
              lineHeight: '21px',
              fontWeight: 700,
              borderRadius: '5px',
            },
            primary: {
              padding: '11px 18px !important',
              height: 'unset',
              width: 'auto',
              background: theme.palette.primary.main,
              '&:hover': {
                background: theme.palette.primary.dark,
                boxShadow: 'none',
              },
              '&:active': {
                background: theme.palette.primary.main,
                boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.3)',
              },
            },
            secondary: {
              padding: '11px 18px !important',
              height: 'unset',
              width: 'auto',
              background: theme.palette.secondary.main,
              '&:hover': {
                background: theme.palette.secondary.dark,
                boxShadow: 'none',
              },
              '&:active': {
                background: theme.palette.secondary.main,
                boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.3)',
              },
            },
          },
        },
      },
    }),
    [theme],
  );

  return (
    <Box {...args}>
      <Grid2
        container
        justifyItems="center"
        flexDirection="column"
        spacing={0}
        className="Sidebar-Grid"
        wrap="nowrap"
      >
        <Grid2
          container
          justifyContent="center"
          className="Sidebar-Logo"
          spacing={0}
        >
          {logo}
        </Grid2>
        <Grid2>
          <MenuList
            {...mainMenuList}
            size={
              isSmallerMenu ? SidebarSizeType.SMALL : SidebarSizeType.MEDIUM
            }
          />
        </Grid2>
        <Grid2 className="Sidebar-LastItem">
          <MenuList
            {...bottomMenuList}
            size={
              isSmallerMenu ? SidebarSizeType.SMALL : SidebarSizeType.MEDIUM
            }
          >
            <NovuProvider
              subscriberId={userId}
              backendUrl="https://novu-api.bas.software"
              socketUrl="https://novu-ws.bas.software"
              applicationIdentifier={
                import.meta.env.VITE_NOVU_APP_ID || 'wW2C2BHD9dld'
              }
              styles={styles}
              i18n="nl"
            >
              <NovuComponent />
            </NovuProvider>
            <SidebarListItem to="/profile">
              {employee && (
                <Avatar
                  size={AvatarSizeType.SMALL}
                  src={employee.profilePictureUrl}
                  name={`${employee.personName.firstName} ${employee.personName.lastName}`}
                  shortName={employee.personName.lastName}
                />
              )}
              {user && !employee && (
                <Avatar
                  size={AvatarSizeType.SMALL}
                  src={user.profilePictureUrl}
                  name={`${user.personName.firstName} ${user.personName.lastName}`}
                  shortName={user.personName.lastName}
                />
              )}
            </SidebarListItem>
          </MenuList>
        </Grid2>
      </Grid2>
    </Box>
  );
};

const StyledSidebar = styled(Sidebar)(
  ({ theme, forceSmall = false }) => `
  background: ${colors.blue[900]};
  height: 100vh;
  width: 88px;
  overflow: auto;

  .MuiList-root {
    position: unset;
  }

  @media (max-height:768px) {
    padding-top: ${theme.spacing(1)};
    .Sidebar-Logo {
      height: auto;
    }
  }

  padding-top: ${theme.spacing(forceSmall ? 1 : 2)};
  .Sidebar-Logo {
    height: ${!forceSmall ? '60px' : 'auto'};
  }

  .Sidebar-Grid {
    min-height: 100%;
  }
  .Sidebar-LastItem {
    margin-top: auto;
  }
`,
);
export default StyledSidebar;
