import { colors } from '@bas/theme';
import {
  styled,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
} from '@mui/material';

export type TabsProps = MuiTabsProps;

const Tabs = ({
  children,
  scrollButtons = 'auto',
  variant = 'scrollable',
  ...args
}: TabsProps) => (
  <MuiTabs {...args} scrollButtons={scrollButtons} variant={variant}>
    {children}
  </MuiTabs>
);

const StyledTabs = styled(Tabs)(
  () => `
  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 1;
    color: ${colors.lila[400]};
  }
`,
);
export default StyledTabs;
