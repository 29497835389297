import {
  ExternalInvoice,
  isExternalInvoice,
} from '@bas/integration-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ExternalInvoicesByIntegrationIdRequestProps = Pagination &
  Filterable & {
    integrationId: Uuid;
  };

type Response = AxiosResponse<Collection<ExternalInvoice>>;

export const ExternalInvoicesByIntegrationIdRequest = async ({
  integrationId,
  ...params
}: ExternalInvoicesByIntegrationIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/integrations/${integrationId}/external-invoices`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useExternalInvoicesByIntegrationIdRequest = (
  request: ExternalInvoicesByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ExternalInvoicesByIntegrationIdRequest(request),
    queryKey: [
      'integrations',
      'external-invoices',
      request.integrationId,
      request,
    ],
  });

export const ExternalInvoicesByIntegrationIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isExternalInvoice(data)) {
      queryClient.invalidateQueries({
        queryKey: ['integrations', 'external-invoices', data.integrationId],
      });
    }
  };
