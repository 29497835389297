import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import { RecurringInformationType } from '../types';

dayjs.extend(weekday);
dayjs.extend(isoWeek);

const weekDayMap: {
  [key: number]:
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday';
} = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  0: 'sunday',
};

const getWeekDayString = (
  date: Date | Dayjs,
):
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday' => weekDayMap[dayjs(date).isoWeekday()];

export const emptyRecurringInformation = (
  date?: Date | Dayjs,
): RecurringInformationType => ({
  rrule: '',
  repeats: 'weekly',
  repeatFrom: dayjs(date).toDate(),
  repeatUntil: dayjs(date).add(1, 'months').toDate(),
  repeatCount: 10,
  repeatInterval: 1,
  untilType: 'date',
  weekDays: [getWeekDayString(dayjs(date))],
  repeatOnType: 'dayOfMonth',
  repeatOnDayOfMonth: dayjs(date).date(),
  repeatOn: {
    day: getWeekDayString(dayjs(date)),
    which: 'first',
  },
});
