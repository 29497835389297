import { colors } from '@bas/theme';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '../Tooltip';

export type DriversLicenceBlockProps = BoxProps & {
  disabled?: boolean;
  onClick?: () => void;
};

const DriversLicenceBlock = ({
  className,
  children,
  disabled,
  onClick,
  ...args
}: DriversLicenceBlockProps): ReactElement => {
  const content = (
    <Box
      onClick={onClick}
      className={clsx(
        className,
        { 'Bas-DriversLicenceBlock-Disabled': disabled },
        { 'Bas-DriversLicenceBlock-WithClick': !!onClick },
      )}
      {...args}
    >
      <Box className="Bas-DriversLicenceBlock-Block" />
      <Typography className="Bas-DriversLicenceBlock-LicenseName">
        {children}
      </Typography>
    </Box>
  );

  if (!onClick) {
    return content;
  }

  return (
    <Tooltip
      title={<FormattedMessage id={`label.${disabled ? 'add' : 'remove'}`} />}
    >
      {content}
    </Tooltip>
  );
};

const StyledDriversLicenceBlock = styled(DriversLicenceBlock)(
  ({ theme }) => `
    height: 49px;
    width: 85px;
    background: ${colors.pink[200]};
    border-radius: 5px;
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    box-sizing: border-box;
    transition: opacity 0.3s ease;

    .Bas-DriversLicenceBlock-Block {
      height: 5px;
      width: 9px;
      background: ${colors.blue[700]};
      margin-bottom: 4px;
      transition: opacity 0.3s ease;
    }

    .Bas-DriversLicenceBlock-LicenseName {
      color: ${colors.blue[700]};
      font-size: 20px;
      line-height: 26px;
      font-weight: bold;
      transition: opacity 0.3s ease;
    }

    &.Bas-DriversLicenceBlock-WithClick {
      cursor: pointer;
      &:hover {
        border: 2px solid ${colors.blue[500]};

        &:not(.Bas-DriversLicenceBlock-Disabled) {
          .Bas-DriversLicenceBlock-Block, .Bas-DriversLicenceBlock-LicenseName {
            opacity: 0.4;
          }
        }

        &.Bas-DriversLicenceBlock-Disabled {
          opacity: 1;
        }
      }
    }

    &.Bas-DriversLicenceBlock-Disabled {
      opacity: 0.4;
    }
`,
);
export default StyledDriversLicenceBlock;
