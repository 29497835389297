import { colors } from '@bas/theme';
import {
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { Storage } from '@bas/wms-domain/models';
import { StorageStatusWithIcon } from '@bas/wms-domain/storage/web/atoms';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid2, styled } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import { MouseEventHandler, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type ProjectStorageListItemProps = ListItemProps & {
  button?: ListItemButtonProps;
  onClick: MouseEventHandler;
  storage: Storage;
  filter?: string;
};

const ProjectStorageListItem = ({
  onClick,
  button,
  storage,
  filter,
  disableGutters = true,
  ...args
}: ProjectStorageListItemProps): ReactElement => {
  const matches = match(storage.storageCode, filter || '', {
    insideWords: true,
  });
  const parts = parse(storage.storageCode, matches);

  return (
    <ListItem {...args} disableGutters={disableGutters}>
      <ListItemButton onClick={onClick} {...button}>
        <Grid2 container alignItems="center" width="100%">
          <Grid2 pr={2} size={1}>
            <Icon icon={faCheck} />
          </Grid2>
          <Grid2 pr={3} size={3}>
            <FormattedMessage id={`storageTypes.${storage.storageType}`} />
          </Grid2>
          <Grid2 pr={4} size={3}>
            {parts.map(({ highlight, text }, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={clsx({
                  'Bas-ProjectStorageListItem-Match': highlight,
                })}
              >
                {text}
              </span>
            ))}
          </Grid2>
          <Grid2 pr={2} size={3}>
            <StorageStatusWithIcon storageStatus={storage.storageStatus} />
          </Grid2>
          <Grid2 size={2}>
            {storage.cubicMeter}
            <span>&#13221;</span>
          </Grid2>
        </Grid2>
      </ListItemButton>
    </ListItem>
  );
};

const StyledProjectStorageListItem = styled(ProjectStorageListItem)(
  ({ theme }) => `
  background: transparent;
  padding: 4px 0;

  .Bas-ProjectStorageListItem-Match {
    color: ${theme.palette.common.black};
    font-weight: bold;
  }

  .MuiListItemButton-root {
    background: ${colors.lila[300]};
    border: 0;
    border-radius: 5px;
    color: ${colors.gray[600]};
    height: 52px;
    padding-left: ${theme.spacing(3)};
    padding-right: ${theme.spacing(3)};

    &:hover {
      background: ${colors.blue[500]};
      color: ${theme.palette.common.white};
    }

    &.Mui-selected {
      background: ${colors.blue[500]};
      color: ${theme.palette.common.white};
    }
  }
`,
);
export default StyledProjectStorageListItem;
