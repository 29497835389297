import {
  ContentSection,
  ContentSectionCancelAction,
  ContentSectionSaveAction,
} from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { VerhuisOffertesSettingsForm } from '../VerhuisOffertesSettingsForm';

export type EditingVerhuisOffertesSettingsContentSectionProps = {
  onCancel: () => void;
};

const EditingVerhuisOffertesSettingsContentSection = ({
  onCancel,
}: EditingVerhuisOffertesSettingsContentSectionProps): ReactElement => (
  <ContentSection
    editing
    actions={[
      <ContentSectionCancelAction onClick={onCancel} />,
      <ContentSectionSaveAction />,
    ]}
  >
    <Grid2 container spacing={3} alignItems="flex-start">
      <Grid2 pt={4} size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.settings" />
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <VerhuisOffertesSettingsForm />
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default EditingVerhuisOffertesSettingsContentSection;
