import { colors } from '@bas/theme';
import { TextField, TextFieldProps } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';
import { styled, TextField as MuiTextField } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

export type DatePickerFieldProps = MuiDatePickerProps<Dayjs> & {
  textFieldProps?: TextFieldProps;
};

const OpenPickerIcon = () => (
  <Icon icon={faCalendarDay} sx={{ marginRight: '4px' }} fontSize={16} />
);

const DatePickerField = ({
  textFieldProps,
  className,
  value,
  onChange,
  format = 'DD-MM-YYYY',
  slots = {
    openPickerIcon: OpenPickerIcon,
    textField: TextField as typeof MuiTextField,
  },
  showDaysOutsideCurrentMonth = true,
  displayWeekNumber = true,
  slotProps = {
    openPickerButton: {
      disableRipple: true,
    },
  },
  ...props
}: DatePickerFieldProps) => (
  <MuiDatePicker
    value={value ? dayjs(value) : null}
    onChange={(date, context) => {
      onChange?.(date || null, context);
    }}
    format={format}
    slots={slots}
    showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
    displayWeekNumber={displayWeekNumber}
    slotProps={{
      ...slotProps,
      popper: {
        className,
        ...slotProps?.popper,
      },
      textField: {
        ...slotProps?.textField,
        ...textFieldProps,
      },
    }}
    {...props}
  />
);

const StyledDatePickerField = styled(DatePickerField)(
  ({ theme }) => `
  .MuiPaper-root {
    box-shadow: unset;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${colors.lila[400]};

    > div > div, .MuiCalendarPicker-root {
      width: 326px;
    }

    .MuiCalendarPicker-root {
      padding: ${theme.spacing(0, 2)};
      box-sizing: border-box;
    }

    .MuiCalendarPicker-viewTransitionContainer > div > div:not(.PrivatePickersSlideTransition-root) {
      display:none;
    }
  }
`,
);
export default StyledDatePickerField;
