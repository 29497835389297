import { isQuote, Quote } from '@bas/financial-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type QuoteByQuoteIdRequestProps = {
  quoteId: Uuid;
};

export const QuoteByQuoteIdRequest = async ({
  quoteId,
  ...params
}: QuoteByQuoteIdRequestProps): Promise<AxiosResponse<Quote>> =>
  axios.get(`api/{tenantId}/quotes/${quoteId}`, {
    params,
  });

export const useQuoteByQuoteIdRequestQuery = (
  request: QuoteByQuoteIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Quote>,
    AxiosError,
    AxiosResponse<Quote>
  > = {},
): UseQueryResult<AxiosResponse<Quote>, AxiosError> =>
  useQuery<AxiosResponse<Quote>, AxiosError, AxiosResponse<Quote>>({
    ...options,
    queryFn: async () => QuoteByQuoteIdRequest(request),
    queryKey: ['quotes', 'detail', request.quoteId],
  });

export const QuoteByQuoteIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isQuote(data)) {
    queryClient.invalidateQueries({
      queryKey: ['quotes', 'detail', data.quoteId],
    });
  }
};
