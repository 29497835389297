import { ReactHookFormTextField } from '@bas/ui/web/molecules';
import { Grid2 } from '@mui/material';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const ConnectWhatsAppForm = (): ReactElement => (
  <Grid2 container rowSpacing={3} columnSpacing={2}>
    <Grid2 size={12}>
      <Controller
        name="clientId"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.clientId" />}
          />
        )}
      />
    </Grid2>
    <Grid2 size={12}>
      <Controller
        name="clientSecret"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.clientSecret" />}
          />
        )}
      />
    </Grid2>
    <Grid2 size={12}>
      <Controller
        name="token"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.token" />}
          />
        )}
      />
    </Grid2>
  </Grid2>
);

export default ConnectWhatsAppForm;
