import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { InternalTenant, isTenant } from '@bas/tenant-domain/models';
import { ErrorResponse, isObjectResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type InternalTenantByHostnameRequestProps = {
  hostname: string;
  token: string;
};

type Response = AxiosResponse<InternalTenant>;
export const InternalTenantByHostnameRequest = async ({
  hostname,
  token,
}: InternalTenantByHostnameRequestProps): Promise<Response> =>
  axios.get(`/api/internal/tenants/${hostname}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const useInternalTenantByHostnameRequest = (
  request: InternalTenantByHostnameRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => InternalTenantByHostnameRequest({ ...request }),
    queryKey: ['internal-tenant', request.hostname],
  });

export const InternalTenantByHostnameRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isObjectResponse(data) && data['@type'] === 'TenantBilling') {
    queryClient.invalidateQueries({ queryKey: ['internal-tenant'] });
  }
  if (isTenant(data)) {
    queryClient.invalidateQueries({ queryKey: ['internal-tenant'] });
  }
};
