import { isObjectResponse } from '@bas/value-objects';
import { InventoryEvent, InventoryEventType } from '../../types';

export function isInventoryEvent(object: unknown): object is InventoryEvent {
  return (
    isObjectResponse(object) &&
    Object.prototype.hasOwnProperty.call(object, 'eventType') &&
    Object.prototype.hasOwnProperty.call(object, 'inventoryEventId') &&
    Object.prototype.hasOwnProperty.call(object, 'inventoryItemId') &&
    typeof object.eventType === 'string' &&
    Object.values(InventoryEventType).includes(
      object.eventType as InventoryEventType,
    )
  );
}
