import {
  timeOffTypeGroupsOrder,
  timeOffTypeGroupsWithBuildUp,
} from '@bas/hrm-domain/models';
import { useTimeOffStatisticsByEmployeeIdRequest } from '@bas/hrm-domain/requests';
import { useGetStackValuesForTimeOff } from '@bas/hrm-domain/utils';
import { formatHours } from '@bas/shared/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bas/ui/web/atoms';
import { ContentSection, StackedLinearProgress } from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Box, Grid2, styled, Typography } from '@mui/material';
import * as React from 'react';
import { Fragment, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type TimeOffBalanceContentSectionProps = {
  employeeId: Uuid;
  year: number;
  className?: string;
  collapse?: boolean;
};

const TimeOffBalanceContentSection = ({
  employeeId,
  year,
  className,
  collapse,
}: TimeOffBalanceContentSectionProps): ReactElement => {
  const { data: timeOffStatisticsData } =
    useTimeOffStatisticsByEmployeeIdRequest({
      employeeId,
      year,
    });

  const stackValues = useGetStackValuesForTimeOff(timeOffStatisticsData?.data);

  return (
    <ContentSection actions={[]} className={className}>
      <Accordion
        sx={{
          width: '100%',
          borderBottom: 'none',
        }}
        disableGutters
        key="time-off-balance"
        defaultExpanded={!collapse}
      >
        <AccordionSummary>
          <Grid2 container>
            <Grid2 size={12}>
              <Typography variant="subtitle1">
                <FormattedMessage id="label.timeOffBalance" />
              </Typography>
            </Grid2>
            <Grid2 pb={2} pt={1} size={12}>
              <Box sx={{ height: 25 }}>
                <StackedLinearProgress
                  sx={{
                    height: 25,
                  }}
                  values={stackValues}
                />
              </Box>
            </Grid2>
          </Grid2>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="label.description" />
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <FormattedMessage id="label.hours" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timeOffStatisticsData?.data?.groupedHours
                .sort((a, b) =>
                  timeOffTypeGroupsOrder.indexOf(a.group) >
                  timeOffTypeGroupsOrder.indexOf(b.group)
                    ? 1
                    : -1,
                )
                .map((item) => {
                  const balance =
                    item.buildUpHours +
                    item.requestedBuildUpHours -
                    item.usedHours -
                    item.reservedHours;
                  return (
                    <Fragment key={item.group}>
                      <TableRow>
                        <TableCell className="Ignore-Border">
                          <Typography>
                            <FormattedMessage
                              id={`timeOffGroup.${item.group}`}
                            />
                          </Typography>
                        </TableCell>
                        <TableCell
                          className="Ignore-Border"
                          sx={{ textAlign: 'right' }}
                        >
                          <Typography>
                            {formatHours(
                              timeOffTypeGroupsWithBuildUp.includes(item.group)
                                ? item.buildUpHours
                                : item.usedHours,
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {timeOffTypeGroupsWithBuildUp.includes(item.group) && (
                        <TableRow>
                          <TableCell className="Ignore-Border">
                            <Typography>
                              <FormattedMessage id="label.requestedBuildUpHours" />
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="Ignore-Border"
                            sx={{ textAlign: 'right' }}
                          >
                            <Typography>
                              {formatHours(item.requestedBuildUpHours)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell className="Ignore-Border">
                          <Typography>
                            <FormattedMessage id="label.requested" />
                          </Typography>
                        </TableCell>
                        <TableCell
                          className="Ignore-Border"
                          sx={{ textAlign: 'right' }}
                        >
                          <Typography>
                            {formatHours(item.reservedHours)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {timeOffTypeGroupsWithBuildUp.includes(item.group) && (
                        <TableRow>
                          <TableCell className="Ignore-Border">
                            <Typography>
                              <FormattedMessage id="label.usedLeaveHours" />
                            </Typography>
                          </TableCell>
                          <TableCell
                            className="Ignore-Border"
                            sx={{ textAlign: 'right' }}
                          >
                            <Typography>
                              {formatHours(item.usedHours)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>
                          <Typography fontWeight="bold">
                            <FormattedMessage id="label.balance" />
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'right' }}>
                          <Typography fontWeight="bold">
                            {formatHours(
                              timeOffTypeGroupsWithBuildUp.includes(item.group)
                                ? balance
                                : Math.abs(balance),
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </ContentSection>
  );
};

const StyledTimeOffBalanceContentSection = styled(TimeOffBalanceContentSection)`
  .MuiTableCell-root.Bas-TableCell {
    padding: 4px 0 !important;
    height: fit-content;

    &.Ignore-Border {
      border-bottom: none;
    }
  }
`;
export default StyledTimeOffBalanceContentSection;
