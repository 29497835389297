import { InvoiceData } from '@bas/integration-domain/models';
import { Button } from '@bas/ui/web/atoms';
import {
  DialogProps,
  IdentityIndicator,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { FormDialog } from '@bas/ui/web/organisms';
import { Uuid } from '@bas/value-objects';
import { Grid2, styled } from '@mui/material';
import { ReactElement } from 'react';
import { Controller, SubmitHandler, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export type AskExternalInvoicesExternalIdsFormDialogProps = Omit<
  DialogProps,
  'onSubmit'
> & {
  open: boolean;
  data: InvoiceData[];
  onSubmit: SubmitHandler<{
    datas: { dataId: Uuid; externalId?: string }[];
  }>;
};

const FormContent = ({ data }: { data: InvoiceData[] }): ReactElement => {
  const values = useWatch<{
    datas: { dataId: Uuid; externalId?: string }[];
  }>({
    name: 'datas',
  });

  console.log(values);

  return (
    <Grid2 container alignItems="stretch" spacing={4}>
      <Grid2 size={12}>
        <FormattedMessage id="settings.integrations.enterTheExternalIdsOfTheInvoicesIfYouLeaveThemEmptyTheyWillNotBeAutomaticallyReconciled" />
      </Grid2>
      <Grid2 container size={12} spacing={0}>
        {data.map(({ dataId, invoiceNumber, identityId }, index) => (
          <Grid2
            key={dataId}
            container
            spacing={2}
            alignItems="flex-end"
            pb={2}
            size={12}
          >
            <Grid2 size={6}>
              <IdentityIndicator identityId={identityId}>
                {invoiceNumber}
              </IdentityIndicator>
            </Grid2>
            <Grid2 size={6}>
              <Controller
                name={`datas.${index}.externalId`}
                render={(registered) => (
                  <ReactHookFormTextField
                    {...registered}
                    autoComplete="off"
                    fullWidth
                    label={<FormattedMessage id="label.externalId" />}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    </Grid2>
  );
};

const AskExternalInvoicesExternalIdsFormDialog = ({
  onClose,
  data,
  ...args
}: AskExternalInvoicesExternalIdsFormDialogProps): ReactElement | null => {
  console.log(
    data.map(({ dataId }) => ({
      dataId,
      externalId: '',
    })),
  );

  if (!data.length) {
    return null;
  }

  return (
    <FormDialog<{
      datas: { dataId: Uuid; externalId?: string }[];
    }>
      name="editing-template-translation"
      onClose={onClose}
      title={
        <FormattedMessage id="settings.integrations.whatAreTheExternalIdsOfTheInvoices" />
      }
      validationSchema={Yup.object({
        datas: Yup.array().of(
          Yup.object({
            dataId: Yup.string().required().label('label.invoiceId'),
            externalId: Yup.string().required().label('label.externalId'),
          }),
        ),
      })}
      dialogActions={[
        <Button type="submit" key="save">
          <FormattedMessage id="button.save" />
        </Button>,
      ]}
      defaultValues={{
        datas: data.map(({ dataId }) => ({
          dataId,
          externalId: '',
        })),
      }}
      maxWidth="md"
      fullWidth
      useProvider
      {...args}
    >
      <FormContent data={data} />
    </FormDialog>
  );
};

const StyledAskExternalInvoicesExternalIdsFormDialog = styled(
  AskExternalInvoicesExternalIdsFormDialog,
)``;

export default StyledAskExternalInvoicesExternalIdsFormDialog;
