import {
  MenuListItem,
  MenuListItemProps,
  SidebarSizeType,
} from '@bas/ui/web/atoms';
import { List, ListProps, styled } from '@mui/material';

export type MenuListProps = ListProps & {
  menuItems: Array<MenuListItemProps>;
  size?: SidebarSizeType;
};

const MenuList = ({
  menuItems,
  size = undefined,
  children,
  ...args
}: MenuListProps) => (
  <List {...args}>
    {menuItems.map((menuItem, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <MenuListItem size={size} {...menuItem} key={index} />
    ))}
    {children}
  </List>
);

const StyledMenuList = styled(MenuList)(
  () => `
  padding: 0;
`,
);
export default StyledMenuList;
