import { useTenantStore } from '@bas/shared/state';
import { getCountryName } from '@bas/shared/utils';
import { CalendarSyncFeatureInputType } from '@bas/tenant-domain/input-types';
import { CalendarSyncFeature } from '@bas/tenant-domain/models';
import { useTenantByTenantIdRequestQuery } from '@bas/tenant-domain/requests';
import { TextField, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { FeatureHelpTooltip, Switch } from '@bas/ui/web/molecules';
import { Country, isCountry } from '@bas/value-objects';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  Grid2,
  MenuItem,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type CalendarSyncFeatureContentSectionProps = {
  feature: CalendarSyncFeature;
  disabled?: boolean;
  onUpdateCalendarSyncFeature: (
    values: CalendarSyncFeatureInputType,
  ) => Promise<void>;
};

const CalendarSyncFeatureContentSection = ({
  feature,
  disabled,
  onUpdateCalendarSyncFeature,
  ...args
}: CalendarSyncFeatureContentSectionProps): ReactElement => {
  const [schoolHolidaysCountry, setSchoolHolidaysCountry] = useState<
    'NL' | 'BE' | ''
  >(feature.schoolHolidaysCountry);

  const [schoolHolidaysRegion, setSchoolHolidaysRegion] = useState<
    string | null
  >(feature.schoolHolidaysRegion);

  const tenantState = useTenantStore((state) => state.internalTenant);
  const { data: tenantData } = useTenantByTenantIdRequestQuery(
    {
      tenantId: tenantState?.tenantId || '',
    },
    { enabled: !!tenantState },
  );

  const syncLink = useMemo(
    () =>
      tenantData?.data
        ? `${import.meta.env.VITE_API_URL}tenant/${
            tenantData.data.tenantId
          }/planning/calendar/${tenantData.data.secret}.ics`
        : undefined,
    [tenantData?.data],
  );

  useDebounce(
    () => {
      if (
        schoolHolidaysCountry !== feature.schoolHolidaysCountry ||
        schoolHolidaysRegion !== feature.schoolHolidaysRegion
      ) {
        onUpdateCalendarSyncFeature({
          ...feature,
          schoolHolidaysCountry,
          schoolHolidaysRegion,
        });
      }
    },
    300,
    [
      schoolHolidaysCountry,
      feature.schoolHolidaysCountry,
      schoolHolidaysRegion,
      feature.schoolHolidaysRegion,
    ],
  );

  return (
    <Grid2 container spacing="12px" {...args}>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="featureTypes.calendar-sync" />
        </Typography>
      </Grid2>
      <Grid2 spacing={1} container alignItems="center" size={12}>
        <Grid2>
          <Switch
            label={
              <FormattedMessage id="features.calendar-sync.downloadSchoolHolidays" />
            }
            checked={feature.downloadSchoolHolidays}
            disabled={disabled}
            onChange={(e, val) =>
              onUpdateCalendarSyncFeature({
                ...feature,
                downloadSchoolHolidays: val,
              })
            }
          />
        </Grid2>
        <Grid2>
          <FeatureHelpTooltip
            title={
              <FormattedMessage id="features.calendar-sync.downloadSchoolHolidays.help" />
            }
          />
        </Grid2>
      </Grid2>
      {feature.downloadSchoolHolidays && (
        <>
          <Grid2 spacing={1} container alignItems="center" size={12}>
            <Grid2>
              <TextField
                value={schoolHolidaysCountry}
                disabled={disabled}
                onChange={({ target: { value } }) => {
                  if (isCountry(value)) {
                    setSchoolHolidaysCountry(value);
                  }
                }}
                select
              >
                {(['NL', 'BE'] as Country[]).map((option) => (
                  <MenuItem key={option} value={option}>
                    {getCountryName(option)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid2>
            <Grid2>
              <Typography color="textPrimary">
                <FormattedMessage id="features.calendar-sync.schoolHolidaysCountry" />
              </Typography>
            </Grid2>
          </Grid2>
          {schoolHolidaysCountry === 'NL' && (
            <Grid2 spacing={1} container alignItems="center" size={12}>
              <Grid2>
                <TextField
                  value={schoolHolidaysRegion}
                  disabled={disabled}
                  onChange={({ target: { value } }) => {
                    setSchoolHolidaysRegion(value);
                  }}
                  select
                >
                  {['noord', 'midden', 'zuid'].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid2>
              <Grid2>
                <Typography color="textPrimary">
                  <FormattedMessage id="features.calendar-sync.schoolHolidaysRegion" />
                </Typography>
              </Grid2>
            </Grid2>
          )}
        </>
      )}
      <Grid2 spacing={1} container alignItems="center" pt={1} size={12}>
        <Grid2>
          <Typography>
            {!syncLink && <Skeleton variant="text" />}
            {syncLink && (
              <TextField
                id="syncLink"
                value={syncLink}
                variant="outlined"
                sx={{ padding: 0 }}
              />
            )}
          </Typography>
        </Grid2>
        <Grid2>
          <Tooltip title={<FormattedMessage id="label.copy" />}>
            <Box>
              <Icon
                sx={{ cursor: 'pointer' }}
                icon={faCopy}
                onClick={() => {
                  const copyText = document.getElementById('syncLink');
                  if (copyText instanceof HTMLInputElement) {
                    copyText.select();
                    copyText.setSelectionRange(0, 99999);
                    navigator.clipboard.writeText(copyText.value);
                  }
                }}
              />
            </Box>
          </Tooltip>
        </Grid2>
        <Grid2>
          <FeatureHelpTooltip
            onClick={() => {
              window.open('https://help.bas.software/', '_blank', 'noopener');
            }}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const StyledCalendarSyncFeatureContentSection = styled(
  CalendarSyncFeatureContentSection,
)(
  () => `
  .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root {
    width: 260px;
    padding: 0;
  }
`,
);
export default StyledCalendarSyncFeatureContentSection;
