import {
  BatchData,
  Integration,
  isCertificateData,
  SyncStatus,
} from '@bas/integration-domain/models';
import {
  useApproveBatchDataMutation,
  useDisapproveBatchDataMutation,
  useRetryBatchDataMutation,
  useSchoutenZekerheidFinalizedBatchDataMutation,
} from '@bas/integration-domain/mutations';
import { useBatchDataByIntegrationIdRequest } from '@bas/integration-domain/requests';
import { CertificateTableRow } from '@bas/integration-domain/web/organisms';
import { useDatatable } from '@bas/shared/hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bas/ui/web/atoms';
import { LoadingTableRow } from '@bas/ui/web/molecules';
import { DataTableTemplate } from '@bas/ui/web/templates';
import { TableContainer } from '@mui/material';
import { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type SchoutenZekerheidDataTabProps = {
  integration: Integration;
  pending?: boolean;
};

const SchoutenZekerheidDataTab = ({
  integration,
  pending,
}: SchoutenZekerheidDataTabProps): ReactElement => {
  const { mutateAsync: approveBatchDataMutation } =
    useApproveBatchDataMutation();
  const { mutateAsync: disapproveBatchDataMutation } =
    useDisapproveBatchDataMutation();
  const { mutateAsync: retryBatchDataMutation } = useRetryBatchDataMutation();
  const { mutateAsync: finalizedBatchDataMutation } =
    useSchoutenZekerheidFinalizedBatchDataMutation();

  const filters = useMemo(
    () =>
      pending
        ? {
            'syncStatus.value': [
              SyncStatus.PENDING,
              SyncStatus.AWAITING_APPROVAL,
              SyncStatus.FAILED,
              SyncStatus.SUCCESS,
            ],
            finished: false,
          }
        : {},
    [pending],
  );

  const {
    basedUponFilters,
    searchGlobally: handleSearch,
    currentPage,
    rowsPerPage,
    resetFilters,
    changePage,
    changeRowsPerPage,
    requestFilters,
  } = useDatatable();

  const {
    data: batchDataItemsData,
    isLoading,
    isFetching,
    isFetched,
  } = useBatchDataByIntegrationIdRequest({
    integrationId: integration.integrationId,
    perPage: rowsPerPage,
    page: currentPage,
    ...requestFilters,
    ...filters,
  });

  const batchDataItems = batchDataItemsData?.data.member || [];
  const numberOfBatchDataItems = batchDataItemsData?.data.totalItems || 0;

  const handleApprove = useCallback(
    async (data: BatchData) => {
      await approveBatchDataMutation({
        integrationId: data.integrationId,
        batchId: data.batchId,
        datas: [
          {
            dataId: data.dataId,
          },
        ],
      });
    },
    [approveBatchDataMutation],
  );

  const handleDisapprove = useCallback(
    async (data: BatchData) => {
      await disapproveBatchDataMutation({
        integrationId: data.integrationId,
        batchId: data.batchId,
        datas: [
          {
            dataId: data.dataId,
          },
        ],
      });
    },
    [disapproveBatchDataMutation],
  );

  const handleRetry = useCallback(
    async (data: BatchData) => {
      await retryBatchDataMutation({
        integrationId: data.integrationId,
        batchId: data.batchId,
        datas: [
          {
            dataId: data.dataId,
          },
        ],
      });
    },
    [retryBatchDataMutation],
  );

  const handleFinalized = useCallback(
    async (data: BatchData) => {
      await finalizedBatchDataMutation({
        integrationId: data.integrationId,
        batchId: data.batchId,
        dataId: data.dataId,
      });
    },
    [finalizedBatchDataMutation],
  );

  const loadingRows = useMemo(
    () =>
      new Array(10)
        .fill(null)
        // eslint-disable-next-line react/no-array-index-key
        .map((v, index) => <LoadingTableRow numberOfColumns={5} key={index} />),
    [],
  );

  return (
    <DataTableTemplate
      isFetching={isFetching && !isFetched}
      onSearch={handleSearch}
      appliedFilters={basedUponFilters}
      onResetFilters={resetFilters}
      pagination={{
        page: currentPage,
        rowsPerPage,
        itemCount: numberOfBatchDataItems,
        onPageChange: (e, page) => changePage(page),
        onRowsPerPageChange: (e) =>
          changeRowsPerPage(Number.parseInt(e.target.value, 10)),
      }}
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell paddingRight>
                <FormattedMessage id="label.customer" />
              </TableCell>
              <TableCell paddingRight>
                <FormattedMessage id="label.status" />
              </TableCell>
              <TableCell paddingRight>
                <FormattedMessage id="label.approvedOn" />
              </TableCell>
              <TableCell paddingRight>
                <FormattedMessage id="label.processedAt" />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && loadingRows}
            {!isLoading &&
              batchDataItems.map((data) => {
                if (isCertificateData(data)) {
                  return (
                    <CertificateTableRow
                      key={data.dataId}
                      data={data}
                      onApprove={async () => handleApprove(data)}
                      onDisapprove={async () => handleDisapprove(data)}
                      onRetry={async () => handleRetry(data)}
                      onFinalized={async () => handleFinalized(data)}
                    />
                  );
                }

                return <Fragment key={data.dataId} />;
              })}
            {!isLoading && batchDataItems.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <FormattedMessage id="label.noCertificatesFound" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DataTableTemplate>
  );
};

export default SchoutenZekerheidDataTab;
