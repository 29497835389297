import { isEvent, TravelTimeForEvent } from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TravelTimeForEventsByDateRequestProps = {
  date: Date;
};

type Response = AxiosResponse<Collection<TravelTimeForEvent>>;
type QueryKeyType = QueryKey & {
  [0]: 'events';
  [1]: 'travel-time';
  [2]: string;
};

export const TravelTimeForEventsByDateRequest = async ({
  date,
}: TravelTimeForEventsByDateRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events/travel-time/${formatDate(date)}`);

export const useTravelTimeForEventsByDateRequest = (
  request: TravelTimeForEventsByDateRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    queryFn: async () => TravelTimeForEventsByDateRequest(request),
    queryKey: ['events', 'travel-time', formatDate(request.date)],
    ...options,
  });

export const TravelTimeForEventsByDateRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEvent(data)) {
    queryClient.invalidateQueries({
      queryKey: ['events', 'travel-time', formatDate(data.start)],
    });
  }
};
