import { Invoice, isInvoice } from '@bas/financial-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type InvoicesRequestProps = Pagination &
  Filterable & {
    projectId?: Uuid;
    relation?: Uuid;
    invoiceId?: Uuid | Uuid[];
  };
type Response = AxiosResponse<Collection<Invoice>>;

export const InvoicesRequest = async ({
  ...params
}: InvoicesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/invoices`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useInvoicesRequest = (
  request: InvoicesRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => InvoicesRequest(request),
    queryKey: ['invoices', 'list', request],
  });

export const usePrefetchInvoicesRequest = (
  request: InvoicesRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['invoices', 'list', request],
      queryFn: async () => InvoicesRequest(request),
    });
  }, [queryClient, request]);
};

export const InvoicesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isInvoice(data)) {
    queryClient.invalidateQueries({ queryKey: ['invoices', 'list'] });
  }
};
