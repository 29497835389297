import { isProject, ProjectStatistics } from '@bas/project-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';

export type ProjectStatisticsRequestProps = {
  startPeriod: Dayjs;
  endPeriod: Dayjs;
};
type Response = AxiosResponse<ProjectStatistics>;

export const ProjectStatisticsRequest = async ({
  startPeriod,
  endPeriod,
}: ProjectStatisticsRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/projects/project-statistics/${formatDate(
      startPeriod,
    )}/${formatDate(endPeriod)}`,
    {},
  );

export const useProjectStatisticsRequest = (
  request: ProjectStatisticsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ProjectStatisticsRequest(request),
    queryKey: [
      'projects',
      'statistics',
      formatDate(request.startPeriod),
      formatDate(request.endPeriod),
    ],
  });

export const ProjectStatisticsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isProject(data)) {
    queryClient.invalidateQueries({ queryKey: ['projects', 'statistics'] });
  }
};
