import {
  styled,
  TableCell as MuiTableCell,
  TableCellProps as MuiTableCellProps,
} from '@mui/material';
import clsx from 'clsx';

export type TableCellProps = MuiTableCellProps & {
  limitWidth?: boolean;
  limitHeight?: boolean;
  noWrap?: boolean;
  paddingRight?: boolean;
  paddingLeft?: boolean;
};

const TableCell = ({
  limitWidth = true,
  limitHeight,
  noWrap,
  paddingRight,
  paddingLeft,
  className,
  ...args
}: TableCellProps) => (
  <MuiTableCell
    className={clsx(
      className,
      'Bas-TableCell',
      { 'Bas-TableCell-LimitHeight': limitHeight },
      { 'Bas-TableCell-LimitWidth': limitWidth },
      { 'Bas-TableCell-NoWrap': noWrap },
      { 'Bas-TableCell-PaddingRight': paddingRight },
      { 'Bas-TableCell-PaddingLeft': paddingLeft },
    )}
    {...args}
  />
);

const StyledTableCell = styled(TableCell)(
  ({ theme }) => `
  padding: 0;
  &.Bas-TableCell-LimitWidth {
    max-width: 150px;
  }
  &.Bas-TableCell-LimitHeight {
    padding-top:0;
    padding-bottom:0;
    max-height: 60px;
  }

  &.Bas-TableCell-NoWrap {
    white-space: nowrap;
  }

  &.Bas-TableCell-PaddingRight {
    padding-right: ${theme.spacing(2)};
  }

  &.Bas-TableCell-PaddingLeft {
    padding-left: ${theme.spacing(1)};
  }
`,
);
export default StyledTableCell;
