import { HoursEntry, OtherPlannedEmployee } from '@bas/hrm-domain/models';
import { OtherPlannedEmployeesTooltipContent } from '@bas/planning-domain/web/molecules';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { TableCell, TableRow, TableRowProps, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { Uuid, WorkedHoursSpecification } from '@bas/value-objects';
import {
  faExclamationCircle,
  faPencil,
  faTable,
  faThumbsUp,
  faTrashCan,
  faXmarkToSlot,
} from '@fortawesome/pro-light-svg-icons';
import { IconButton, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import { WorkedHoursSpecificationSummary } from '../WorkedHoursSpecificationSummary';

export type EmployeeHourEntryTableRowProps = TableRowProps & {
  entryId: Uuid;
  startTime?: Date;
  endTime?: Date;
  plannedStart?: Date;
  plannedEnd?: Date;
  title: string | ReactNode;
  reason?: string;
  totalHours: WorkedHoursSpecification;
  projectHours: WorkedHoursSpecification;
  plannedHours: WorkedHoursSpecification | null;
  internalHours: WorkedHoursSpecification;
  approvedHours: WorkedHoursSpecification;
  nonWorkingHours: WorkedHoursSpecification;
  waitingHours: WorkedHoursSpecification;
  accordingToForeman: boolean;
  approvalReasons?: string[];
  otherPlannedEmployees: OtherPlannedEmployee[];
  approved: boolean;
  approvedEntryId?: Uuid;
  approvedEntries: HoursEntry[];
  onStartShowingHourEntries: (entryId: Uuid) => void;
  onStartEditingHours: (entryId: Uuid) => void;
  onStartApprovingHours: (entryId: Uuid) => void;
  onStartRemovingHours: (entryId: Uuid) => void;
};

const EmployeeHourEntryTableRow = ({
  entryId,
  startTime,
  endTime,
  plannedStart,
  plannedEnd,
  title,
  reason,
  totalHours,
  projectHours,
  internalHours,
  approvedHours,
  nonWorkingHours,
  plannedHours,
  waitingHours,
  approvalReasons,
  otherPlannedEmployees,
  accordingToForeman,
  approved,
  approvedEntryId,
  approvedEntries,
  onStartEditingHours,
  onStartApprovingHours,
  onStartRemovingHours,
  onStartShowingHourEntries,
  ...args
}: EmployeeHourEntryTableRowProps): ReactElement => {
  const mismatchingWithColleagues = useMemo(() => {
    if (!plannedHours || plannedHours?.workedHours === 0) {
      return false;
    }

    return (
      otherPlannedEmployees.findIndex(
        ({ workedHours: otherWorkedHours, plannedHours: otherPlannedHours }) =>
          (otherWorkedHours === null ? otherPlannedHours : otherWorkedHours) !==
          totalHours.workedHours,
      ) !== -1
    );
  }, [otherPlannedEmployees, plannedHours, totalHours.workedHours]);

  return (
    <TableRow {...args}>
      <TableCell paddingRight colSpan={2}>
        <Tooltip title={reason || ''} disabled={!reason}>
          <span>
            {title}
            {reason && (
              <>
                &nbsp;
                <Icon icon={faExclamationCircle} />
              </>
            )}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {startTime && <FormattedTime value={startTime} timeStyle="short" />}
      </TableCell>
      <TableCell align="center">
        {endTime && <FormattedTime value={endTime} timeStyle="short" />}
      </TableCell>
      <TableCell
        align="center"
        sx={{ fontWeight: 'bold' }}
        className={clsx(
          {
            'Bas-EmployeeHourEntryTableRow-Overtime':
              !approved &&
              plannedHours !== undefined &&
              totalHours.workedHours + totalHours.travelTime >
                (plannedHours?.workedHours || 0) +
                  (plannedHours?.travelTime || 0),
          },
          {
            'Bas-EmployeeHourEntryTableRow-MismatchingWithColleagues':
              !approved && mismatchingWithColleagues,
          },
        )}
      >
        {plannedStart && plannedEnd ? (
          <Tooltip
            disabled={otherPlannedEmployees.length === 0}
            title={
              <OtherPlannedEmployeesTooltipContent
                plannedStart={plannedStart}
                plannedEnd={plannedEnd}
                otherPlannedEmployees={otherPlannedEmployees}
              >
                <WorkedHoursSpecificationSummary
                  workedHoursSpecification={totalHours}
                />
              </OtherPlannedEmployeesTooltipContent>
            }
          >
            <span
              className={clsx({
                'Bas-EmployeeHourEntryTableRow-Underline':
                  otherPlannedEmployees.length > 0,
              })}
            >
              {formatHours(totalHours.workedHours + totalHours.travelTime)}
            </span>
          </Tooltip>
        ) : (
          formatHours(totalHours.workedHours + totalHours.travelTime)
        )}
      </TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            <WorkedHoursSpecificationSummary
              workedHoursSpecification={approvedHours}
            />
          }
          disabled={approvedHours.workedHoursIncludingBreakTime === 0}
        >
          <span>
            {formatHours(approvedHours.workedHours + approvedHours.travelTime)}
          </span>
        </Tooltip>
        {(approvalReasons?.length || 0) > 0 && (
          <Tooltip title={approvalReasons || ''}>
            <span>
              &nbsp;
              <Icon icon={faExclamationCircle} />
            </span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            <WorkedHoursSpecificationSummary
              workedHoursSpecification={projectHours}
            />
          }
          disabled={projectHours.workedHoursIncludingBreakTime === 0}
        >
          <span>
            {formatHours(projectHours.workedHours + projectHours.travelTime)}
          </span>
        </Tooltip>
        {accordingToForeman && (
          <Tooltip
            title={
              <FormattedMessage id="label.workedHoursAccordingToForeman" />
            }
          >
            <span>
              &nbsp;
              <Icon icon={faExclamationCircle} />
            </span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            <WorkedHoursSpecificationSummary
              workedHoursSpecification={internalHours}
            />
          }
          disabled={internalHours.workedHoursIncludingBreakTime === 0}
        >
          <span>
            {formatHours(internalHours.workedHours + internalHours.travelTime)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            <WorkedHoursSpecificationSummary
              workedHoursSpecification={nonWorkingHours}
            />
          }
          disabled={nonWorkingHours.workedHoursIncludingBreakTime === 0}
        >
          <span>
            {formatHours(
              nonWorkingHours.workedHours + nonWorkingHours.travelTime,
            )}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip
          title={
            <WorkedHoursSpecificationSummary
              workedHoursSpecification={waitingHours}
            />
          }
          disabled={waitingHours.workedHoursIncludingBreakTime === 0}
        >
          <span>
            {formatHours(waitingHours.workedHours + waitingHours.travelTime)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell className="Bas-EmployeeHourEntryTableRow-OnHover">
        {approvedEntries.length > 1 && (
          <Tooltip title={<FormattedMessage id="button.showHourEntries" />}>
            <IconButton
              onClick={() => onStartShowingHourEntries(entryId)}
              size="small"
            >
              <Icon icon={faTable} />
            </IconButton>
          </Tooltip>
        )}
        {approvedEntries.length <= 1 && (
          <>
            <Tooltip
              title={
                <FormattedMessage
                  id={entryId ? 'button.edit' : 'button.enterHours'}
                />
              }
            >
              <IconButton
                onClick={() => onStartEditingHours(approvedEntryId || entryId)}
                size="small"
              >
                <Icon icon={entryId ? faPencil : faXmarkToSlot} />
              </IconButton>
            </Tooltip>
            {!approved && entryId && (
              <Tooltip title={<FormattedMessage id="button.approveHours" />}>
                <IconButton
                  onClick={() => onStartApprovingHours(entryId)}
                  size="small"
                >
                  <Icon icon={faThumbsUp} />
                </IconButton>
              </Tooltip>
            )}
            {!approved && entryId && (
              <Tooltip title={<FormattedMessage id="button.removeHours" />}>
                <IconButton
                  onClick={() => onStartRemovingHours(entryId)}
                  size="small"
                >
                  <Icon icon={faTrashCan} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const StyledEmployeeHourEntryTableRow = styled(EmployeeHourEntryTableRow)(
  ({ theme }) => `
  .Bas-EmployeeHourEntryTableRow-Overtime {
    color: ${colors.yellow[700]};
    font-weight: bold;
  }

  .Bas-EmployeeHourEntryTableRow-MismatchingWithColleagues {
    color: ${colors.red[500]};
    font-weight: bold;
  }

  .Bas-EmployeeHourEntryTableRow-Underline {
    text-decoration: underline;
  }

  .Bas-EmployeeHourEntryTableRow-OnHover * {
    opacity: 0;
  }

  &:hover {
    .Bas-EmployeeHourEntryTableRow-OnHover * {
      opacity: 1;
    }
  }
`,
);
export default StyledEmployeeHourEntryTableRow;
