import { formatDate } from '@bas/shared/utils';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DisableEmployeeAttributeMutationProps = {
  employeeAttributeId: Uuid;
  disableAfter: Date;
};

export const DisableEmployeeAttributeMutation = async ({
  employeeAttributeId,
  disableAfter,
  ...values
}: DisableEmployeeAttributeMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/employee-attributes/${employeeAttributeId}/disable`,
    {
      employeeAttributeId,
      disableAfter: formatDate(disableAfter),
      ...values,
    },
  );

export const useDisableEmployeeAttributeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableEmployeeAttributeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DisableEmployeeAttributeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisableEmployeeAttributeMutationProps
  >({
    mutationFn: DisableEmployeeAttributeMutation,
    throwOnError: false,
    ...options,
  });
