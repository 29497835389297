import { isObjectResponse } from '@bas/value-objects';
import type { EmployeeAttribute } from '../types';

export function isEmployeeAttribute(
  object: unknown,
): object is EmployeeAttribute {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'EmployeeAttribute'
  );
}
