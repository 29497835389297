import { colors, fontSizesWeb } from '@bas/theme';
import { Button, TextField } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faCog, faSearch } from '@fortawesome/pro-light-svg-icons';
import { Box, Collapse, Grid2, styled } from '@mui/material';
import { ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type TableToolbarProps = {
  className?: string;
  onSearch?: (newValue: string | undefined) => void;
  children?: ReactNode | ReactNode[];
  rightSideActions?: ReactNode | ReactNode[];
  filters?: ReactNode;
};

const TableToolbar = ({
  className,
  children,
  filters,
  onSearch,
  rightSideActions,
}: TableToolbarProps): ReactElement => {
  const [searching, setSearching] = useState<boolean>(false);
  const [filtering, setFiltering] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const searchRef = useRef<HTMLDivElement>(null);

  const handleClickSearch = useCallback(async () => {
    setSearching((value) => !value);
    await new Promise((r) => {
      setTimeout(r, 100);
    });

    searchRef.current?.querySelector('input')?.focus();
  }, []);

  return (
    <Grid2 container className={className} alignItems="center">
      <Grid2 className="Bas-TableToolbar-Buttons">{children}</Grid2>
      <Grid2 className="Bas-TableToolbar-Actions">
        <Grid2 container alignItems="center">
          <Grid2>{rightSideActions}</Grid2>
          {onSearch && (
            <>
              <Grid2>
                <Collapse in={searching} orientation="horizontal">
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <Icon
                          onClick={() => setSearching((value) => !value)}
                          icon={faSearch}
                        />
                      ),
                    }}
                    ref={searchRef}
                    onChange={(e) => onSearch(e.target.value)}
                    placeholder={formatMessage({ id: 'label.startTyping' })}
                  />
                </Collapse>
              </Grid2>
              <Grid2>
                <Collapse in={!searching} orientation="horizontal">
                  <Button
                    variant="text"
                    color={searching ? 'primary' : 'secondary'}
                    size="small"
                    onClick={handleClickSearch}
                  >
                    <Icon icon={faSearch} />
                    <FormattedMessage id="button.search" />
                  </Button>
                </Collapse>
              </Grid2>
            </>
          )}
          <Grid2 hidden={!filters}>
            <Button
              variant="text"
              color={filtering ? 'primary' : 'secondary'}
              size="small"
              onClick={() => setFiltering((value) => !value)}
            >
              <Icon icon={faCog} />
              <FormattedMessage id="button.filters" />
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <Collapse in={filtering}>
          <Box className="Bas-TableToolbar-Filters">{filters}</Box>
        </Collapse>
      </Grid2>
    </Grid2>
  );
};

const StyledTableToolbar = styled(TableToolbar)(
  ({ theme }) => `
  .Bas-TableToolbar-Actions {
    margin-left: auto;
    .MuiGrid2-root:not(:first-of-type) {
      margin-left: 4px;
    }

    .MuiInputBase-root svg {
      padding-bottom: 5px;
      cursor: pointer;
      &:hover {
        color: ${colors.blue[500]};
      }
    }

    .MuiFormControl-root {
      padding-top: 5px;
    }

    .MuiInputBase-inputAdornedStart {
      margin-left: 9px;
      padding-bottom: 11px;
    }

    .MuiInput-input {
      font-size: ${fontSizesWeb.sm};
      line-height: 18px;
    }
  }

  .Bas-TableToolbar-Filters {
    margin-top: 18px;
    background: ${colors.lila[200]};
    border-radius: 5px;
    padding: ${theme.spacing(2, 4)};
  }
`,
);
export default StyledTableToolbar;
