import { isTimeOffRegistration, TimeOffBalance } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeOffBalancesRequestProps = Pagination &
  Filterable & {
    year: number;
  };

type Response = AxiosResponse<Collection<TimeOffBalance>>;

export const TimeOffBalancesRequest = async ({
  year,
  perPage,
  ...params
}: TimeOffBalancesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/employees/time-off-hours-balance/${year}`, {
    params: {
      perPage: perPage ?? 99999,
      ...params,
    },
  });

export const useTimeOffBalancesRequest = (
  request: TimeOffBalancesRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TimeOffBalancesRequest(request),
    queryKey: ['time-off-balances', 'list', request],
  });

export const TimeOffBalancesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTimeOffRegistration(data)) {
    queryClient.invalidateQueries({ queryKey: ['time-off-balances', 'list'] });
  }
};
