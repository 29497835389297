import { Employee, TeamMemberRole } from '@bas/hrm-domain/models';
import { colors } from '@bas/theme';
import { Avatar, AvatarSizeType } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ContentSection } from '@bas/ui/web/molecules';
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid2, IconButton, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type TeamMemberItemProps = {
  employee: Employee;
  teamMemberRole: TeamMemberRole;
  className?: string;
  active?: boolean;
  onChange: () => void | Promise<void>;
  onRemove: () => void | Promise<void>;
};

const TeamMemberItem = ({
  teamMemberRole,
  employee: {
    profilePictureUrl,
    personName: { firstName, lastName, fullName },
  },
  className,
  onChange,
  onRemove,
  active,
}: TeamMemberItemProps): ReactElement => (
  <ContentSection
    className={clsx(className, { 'Bas-TeamMemberItem-Active': active })}
    actions={[
      <IconButton onClick={onChange}>
        <Icon icon={faPencil} />
      </IconButton>,
      <IconButton onClick={onRemove}>
        <Icon icon={faTrashCan} />
      </IconButton>,
    ]}
  >
    <Box className="Bas-TeamMemberItem-root">
      <Box className="Bas-TeamMemberItem-Avatar">
        <Avatar
          size={AvatarSizeType.SMALL}
          src={profilePictureUrl}
          name={`${firstName} ${lastName}`}
          shortName={lastName}
        />
      </Box>
      <Grid2 container className="Bas-TeamMemberItem-NameBlock">
        <Grid2 className="Bas-TeamMemberItem-Role" size={12}>
          <Typography>
            <FormattedMessage id={`teamMemberRoles.${teamMemberRole}`} />
          </Typography>
        </Grid2>
        <Grid2 className="Bas-TeamMemberItem-Name" size={12}>
          <Typography>{firstName || fullName}</Typography>
        </Grid2>
      </Grid2>
    </Box>
  </ContentSection>
);

const StyledTeamMemberItem = styled(TeamMemberItem)(
  ({ theme }) => `
  .Bas-ContentSection-Actions {
    top: -${theme.spacing(0)};
    right: ${theme.spacing(0)};
  }

  &:hover, &.Bas-TeamMemberItem-Active {
    .Bas-TeamMemberItem-root {
      .Bas-TeamMemberItem-Role .MuiTypography-root, .Bas-TeamMemberItem-Name .MuiTypography-root {
        color: ${colors.blue[500]};
      }
    }
  }

  &.Bas-ContentSection-HasActions {
    &:hover, &.Bas-ContentSection-Editing {
      &:before {
        left: -13px;
        right: -13px;
        top: -${theme.spacing(1)};
        bottom: -${theme.spacing(1)};
      }
    }
  }
  .Bas-TeamMemberItem-root {
    display: flex;
    flex-direction: row;
    .Bas-TeamMemberItem-Avatar {
      padding-right: 12px;
    }

    .Bas-TeamMemberItem-Name .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
    }
  }
`,
);
export default StyledTeamMemberItem;
