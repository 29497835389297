import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { isTask, Task } from '@bas/task-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type TasksRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<Task>>;

export const TasksRequest = async ({
  ...params
}: TasksRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/tasks`, {
    params: { perPage: 1000, ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useTasksRequest = (
  request: TasksRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TasksRequest(request),
    queryKey: ['tasks', 'list', request],
  });

export const usePrefetchTasksRequest = (request: TasksRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['tasks', 'list', request],
      queryFn: async () => TasksRequest(request),
    });
  }, [queryClient, request]);
};

export const TasksRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTask(data)) {
    queryClient.invalidateQueries({ queryKey: ['tasks', 'list'] });
  }
};
