import {
  TimeOffRegistration,
  TimeOffRegistrationStatus,
} from '@bas/hrm-domain/models';
import { useTimeOffRegistrationsRequest } from '@bas/hrm-domain/requests';
import { colors } from '@bas/theme';
import { Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ContentSection } from '@bas/ui/web/molecules';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Badge, Box, Grid2, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl';

export type OverlappingTimeOffRequestsContentSectionProps = {
  timeOffRegistration: TimeOffRegistration;
};

const timeOffRegistrationStatusColors: {
  [key: string]:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
} = {
  [TimeOffRegistrationStatus.PENDING]: 'warning',
  [TimeOffRegistrationStatus.APPROVED]: 'success',
  [TimeOffRegistrationStatus.REJECTED]: 'error',
  [TimeOffRegistrationStatus.CANCELLED]: 'error',
};

const OverlappingTimeOffRequestsContentSection = ({
  timeOffRegistration,
}: OverlappingTimeOffRequestsContentSectionProps): ReactElement | null => {
  const { data: overlappingTimeOffRequestsData } =
    useTimeOffRegistrationsRequest({
      'start[before]': timeOffRegistration.end,
      'end[after]': timeOffRegistration.start,
      buildUp: false,
    });

  const overlappingTimeOffRequests = useMemo(
    () =>
      (overlappingTimeOffRequestsData?.data?.member || []).filter(
        ({ timeOffId }) => timeOffId !== timeOffRegistration.timeOffId,
      ),
    [overlappingTimeOffRequestsData?.data, timeOffRegistration.timeOffId],
  );

  if (overlappingTimeOffRequests.length === 0) {
    return null;
  }

  return (
    <ContentSection actions={[]}>
      <Grid2 container columnSpacing={3} rowSpacing={1}>
        <Grid2 size={12}>
          <Typography variant="subtitle1" color={colors.yellow['800']}>
            <FormattedMessage id="label.overlappingTimeOffRequests" />
            <Icon icon={faExclamationTriangle} color={colors.yellow['800']} />
          </Typography>
        </Grid2>
        <Grid2 container spacing={3} size={12}>
          {overlappingTimeOffRequests.map((overlappingTimeOffRequest) => (
            <Grid2 key={overlappingTimeOffRequest.timeOffId} size={6}>
              <Typography variant="subtitle2">
                <Box flexDirection="row" alignItems="center">
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={`timeOffRegistrationStatus.${overlappingTimeOffRequest.status}`}
                      />
                    }
                  >
                    <span>
                      <Badge
                        color={
                          timeOffRegistrationStatusColors[
                            overlappingTimeOffRequest.status
                          ] || 'warning'
                        }
                        badgeContent=" "
                        variant="dot"
                      />
                      &nbsp;&nbsp;
                      {overlappingTimeOffRequest.employeeName}
                    </span>
                  </Tooltip>
                </Box>
              </Typography>
              <Typography>{overlappingTimeOffRequest.reason}</Typography>
              <Typography>
                {overlappingTimeOffRequest.timeOffTypeName}
              </Typography>
              <Typography>
                <FormattedDateTimeRange
                  from={dayjs(overlappingTimeOffRequest.start).toDate()}
                  to={dayjs(overlappingTimeOffRequest.end).toDate()}
                  dateStyle="short"
                  timeStyle={
                    overlappingTimeOffRequest.fullDay ? undefined : 'short'
                  }
                />
              </Typography>
            </Grid2>
          ))}
        </Grid2>
      </Grid2>
    </ContentSection>
  );
};

export default OverlappingTimeOffRequestsContentSection;
