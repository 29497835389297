import { NmbrsCompany } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type NmbrsCompaniesByIntegrationIdRequestProps = Pagination & {
  integrationId: Uuid;
};

type Response = AxiosResponse<Collection<NmbrsCompany>>;

export const NmbrsCompaniesByIntegrationIdRequest = async ({
  integrationId,
  ...params
}: NmbrsCompaniesByIntegrationIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/integrations/${integrationId}/nmbrs/nmbrs-companies`,
    {
      params: { ...params },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useNmbrsCompaniesByIntegrationIdRequest = (
  request: NmbrsCompaniesByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => NmbrsCompaniesByIntegrationIdRequest(request),
    queryKey: [
      'integrations',
      'nmbrs-companies',
      request.integrationId,
      request,
    ],
  });
