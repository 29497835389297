import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { isStorage, Storage } from '@bas/wms-domain/models';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type StoragesRequestProps = Pagination &
  Filterable & {
    storageLocationId?: Uuid | null;
    warehouseId?: Uuid | null;
  };
type Response = AxiosResponse<Collection<Storage>>;

export const StoragesRequest = async ({
  warehouseId,
  storageLocationId,
  ...params
}: StoragesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/storages`, {
    params: {
      ...params,
      warehouse: warehouseId || undefined,
      storageLocation: storageLocationId || undefined,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useStoragesRequest = (
  request: StoragesRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => StoragesRequest(request),
    queryKey: ['storages', 'list', request],
  });

export const usePrefetchStoragesRequest = (
  request: StoragesRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['storages', 'list', request],
      queryFn: async () => StoragesRequest(request),
    });
  }, [queryClient, request]);
};

export const StoragesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isStorage(data)) {
    queryClient.invalidateQueries({ queryKey: ['storages', 'list'] });
  }
};
