import { DisableSettingInputType } from '@bas/shared/input-types';
import { ReactHookFormDatePickerField } from '@bas/ui/web/molecules';
import { Grid2 } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type DisableSettingFormProps = {
  children?: ReactNode;
};

const DisableSettingForm = ({
  children,
}: DisableSettingFormProps): ReactElement => (
  <Grid2 container rowSpacing={3} columnSpacing={2}>
    <Grid2 size={12}>
      <Controller<DisableSettingInputType>
        name="disableAfter"
        render={(registered) => (
          <ReactHookFormDatePickerField
            {...registered}
            textFieldProps={{
              fullWidth: true,
            }}
            label={<FormattedMessage id="label.disableAfter" />}
          />
        )}
      />
    </Grid2>
    {children}
  </Grid2>
);

export default DisableSettingForm;
