import { Communication, CommunicationType } from '@bas/crm-domain/models';
import { colors } from '@bas/theme';
import { Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faSms } from '@fortawesome/pro-light-svg-icons';
import { Grid2, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedRelativeTime,
} from 'react-intl';

export type CommunicationBlockProps = {
  className?: string;
  communication: Communication;
};

const icons = {
  [CommunicationType.CALL]: faPhone,
  [CommunicationType.WHATSAPP]: faWhatsapp,
  [CommunicationType.EMAIL]: faEnvelope,
  [CommunicationType.SMS]: faSms,
};

const showDateInsteadOfRelativeBefore = dayjs().subtract(2, 'weeks');

const CommunicationBlock = ({
  className,
  communication,
}: CommunicationBlockProps): ReactElement => (
  <Grid2
    className={className}
    container
    alignContent="space-between"
    alignItems="center"
  >
    <Grid2 className="Bas-CommunicationBlock-Date">
      {dayjs(communication.communicationDate).isBefore(
        showDateInsteadOfRelativeBefore,
      ) ? (
        <Typography variant="subtitle1">
          <FormattedDate
            value={dayjs(communication.communicationDate).toDate()}
            dateStyle="long"
            timeStyle="short"
          />
        </Typography>
      ) : (
        <Tooltip
          title={
            <FormattedDate
              value={dayjs(communication.communicationDate).toDate()}
              dateStyle="long"
              timeStyle="short"
            />
          }
        >
          <Typography variant="subtitle1">
            <FormattedRelativeTime
              value={
                dayjs(communication.communicationDate).unix() - dayjs().unix()
              }
              numeric="auto"
              // eslint-disable-next-line react/style-prop-object
              style="long"
              updateIntervalInSeconds={1}
            />
          </Typography>
        </Tooltip>
      )}
    </Grid2>
    <Grid2 className="Bas-CommunicationBlock-Icon">
      <Icon icon={icons[communication.communicationType]} />
    </Grid2>
    <Grid2 className="Bas-CommunicationBlock-Message" size={12}>
      {communication.message}
    </Grid2>
    <Grid2 className="Bas-CommunicationBlock-Sender" size={12}>
      <Typography variant="subtitle2" fontWeight="bold">
        {communication.sender}
      </Typography>
      {communication.projectCode && (
        <Typography variant="subtitle2">
          <FormattedMessage id="label.projectCode" />
          {`: ${communication.projectCode}`}
        </Typography>
      )}
    </Grid2>
  </Grid2>
);

const StyledCommunicationBlock = styled(CommunicationBlock)(
  ({ theme }) => `
    border-radius: 5px;
    position: relative;
    min-height: 200px;
    box-sizing: border-box;
    // background: ${colors.lila[100]};
    padding: ${theme.spacing(2, 0)};


  &:before {
    background: ${colors.lila[100]};
    content: '';
    border-radius: 5px;
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: -${theme.spacing(3)};
    right: -${theme.spacing(3)};
    z-index: -1;
  }

  &, .MuiTypography-root {
    color: ${colors.lila[800]};
  }

  .Bas-CommunicationBlock-Icon {
    margin-left: auto;
    font-size: 24px;
  }

  .Bas-CommunicationBlock-Message {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
`,
);
export default StyledCommunicationBlock;
