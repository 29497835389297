import { colors, fontSizesWeb } from '@bas/theme';
import helpTooltipBg from '@bas/ui/assets/Resources/helpTooltip.svg';
import {
  styled,
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';

export type TooltipProps = MuiTooltipProps & {
  allowWider?: boolean;
  allowFullHeight?: boolean;
  light?: boolean;
  helpTooltip?: boolean;
  errorTooltip?: boolean;
  notClickable?: boolean;
  disabled?: boolean;
};

const Tooltip = ({
  children,
  className,
  allowWider = false,
  allowFullHeight,
  light,
  helpTooltip,
  errorTooltip,
  notClickable,
  disabled,
  enterTouchDelay,
  ...args
}: TooltipProps): ReactElement => (
  <MuiTooltip
    classes={{
      tooltip: clsx(
        className,
        { 'Bas-Tooltip-AllowWider': allowWider },
        { 'Bas-Tooltip-AllowFullHeight': allowFullHeight },
        { 'Bas-Tooltip-HelpTooltip': helpTooltip },
        { 'Bas-Tooltip-ErrorTooltip': errorTooltip },
        { 'Bas-Tooltip-Light': light },
      ),
    }}
    enterTouchDelay={notClickable ? 50 : enterTouchDelay}
    disableFocusListener={disabled}
    disableHoverListener={disabled}
    disableTouchListener={disabled}
    {...args}
  >
    {children}
  </MuiTooltip>
);

const StyledTooltip = styled(Tooltip)`
  background-color: ${colors.blue[700]} !important;
  color: ${colors.white};
  font-size: ${fontSizesWeb.sm};
  border-radius: 2px;
  font-weight: normal;
  line-height: 18px;
  padding-left: 11px;
  padding-right: 11px;

  .MuiTypography-root {
    color: ${colors.white};
  }

  &.Bas-Tooltip-HelpTooltip {
    // eslint-disable-next-line
    // prettier-ignore
    background: url("${helpTooltipBg}") !important;
    color: ${colors.lila[800]};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 25px;
    padding-left: 40px;
    background-size: contain !important;
    height: 320px;
    width: 381px;
    max-width: none;
  }

  &.Bas-Tooltip-ErrorTooltip {
    background-color: ${colors.red[700]} !important;
    font-size: ${fontSizesWeb.xs};
    padding: 4px 8px;
    color: ${colors.white};
    box-sizing: border-box;
    border-radius: 5px;
    max-width: none;
  }

  &.Bas-Tooltip-Light {
    background-color: ${colors.lila[100]} !important;
    color: ${colors.lila[800]};
    box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.1);

    .MuiTooltip-arrow {
      color: ${colors.lila[100]};
    }

    .MuiTypography-root {
      color: ${colors.lila[800]};
    }
  }

  &.Bas-Tooltip-AllowWider {
    max-width: none;
  }

  &.Bas-Tooltip-AllowFullHeight {
    height: fit-content;
  }

  .MuiTooltip-arrow {
    color: ${colors.blue[700]};
  }
`;
export default StyledTooltip;
