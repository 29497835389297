import {
  List as MuiList,
  ListProps as MuiListProps,
  styled,
} from '@mui/material';

export type ListProps = MuiListProps;

const List = ({ disablePadding = true, ...args }: ListProps) => (
  <MuiList {...args} disablePadding={disablePadding} />
);

const StyledList = styled(List)(() => ``);
export default StyledList;
