import {
  WebsiteSettingsInputType,
  WebsiteSettingsInputTypeDefaultValues,
  WebsiteSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { Integration, isWebsiteSettings } from '@bas/integration-domain/models';
import { WebsiteSettingsContentSection } from '@bas/integration-domain/web/molecules';
import { ReactHookForm } from '@bas/ui/web/molecules';
import { PricingType } from '@bas/value-objects';
import { ReactElement, useState } from 'react';
import { ConnectedWebsiteForm } from '../ConnectedWebsiteForm';
import { EditingWebsiteSettingsContentSection } from '../EditingWebsiteSettingsContentSection';

export type EditableWebsiteSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: WebsiteSettingsInputType) => Promise<void>;
};

const EditableWebsiteSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableWebsiteSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<WebsiteSettingsInputType>
        name="edit-websites"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          ...WebsiteSettingsInputTypeDefaultValues(),
          ...integration.providerSettings,
        }}
        validationSchema={WebsiteSettingsInputTypeValidationBuilder}
      >
        <EditingWebsiteSettingsContentSection
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  if (isAdding) {
    return (
      <ReactHookForm<WebsiteSettingsInputType>
        name="add-website"
        onSubmit={async (values) => {
          await onUpdateSettings({
            ...integration.providerSettings,
            websites: [
              ...(isWebsiteSettings(integration.providerSettings)
                ? integration.providerSettings.websites
                : []),
              ...values.websites,
            ],
          });
          setIsAdding(false);
        }}
        useProvider
        defaultValues={{
          websites: [
            {
              name: '',
              websiteCode: '',
              packageId: '',
              identityId: '',
              pricingType: PricingType.UNKNOWN,
            },
          ],
        }}
        validationSchema={WebsiteSettingsInputTypeValidationBuilder}
      >
        <WebsiteSettingsContentSection
          onEdit={() => setIsEditing(true)}
          onAdd={() => setIsAdding(true)}
          integration={integration}
          onCancel={() => setIsAdding(false)}
        >
          <ConnectedWebsiteForm prefix="websites.0" />
        </WebsiteSettingsContentSection>
      </ReactHookForm>
    );
  }

  return (
    <WebsiteSettingsContentSection
      onEdit={() => setIsEditing(true)}
      onAdd={() => setIsAdding(true)}
      integration={integration}
    />
  );
};

export default EditableWebsiteSettingsBlock;
