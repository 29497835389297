import { ServiceType } from '@bas/shared/models';
import {
  CurrencyTextFieldNumberFormat,
  RenderTranslatedName,
  TextField,
} from '@bas/ui/web/atoms';
import { InternalServiceType, Money } from '@bas/value-objects';
import { Grid2, styled, Typography } from '@mui/material';
import hash from 'object-hash';
import { ReactElement, useState } from 'react';
import isEqual from 'react-fast-compare';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type EditableServicePricesFeatureBlockProps = {
  serviceTypes: ServiceType[];
  disabled?: boolean;
  onUpdateServiceType: (values: ServiceType) => Promise<void> | void;
};

const mapServiceType = (
  serviceType: ServiceType,
): {
  serviceTypeId: string;
  price: Money;
} => ({
  serviceTypeId: serviceType.serviceTypeId,
  price: serviceType.price,
});

const EditableServicePricesFeatureBlock = ({
  serviceTypes: defaultValues,
  disabled,
  onUpdateServiceType,
  ...args
}: EditableServicePricesFeatureBlockProps): ReactElement | null => {
  const [savedHashes, setSavedHashes] = useState<{ [key: string]: string }>({});
  const [serviceTypes, setServiceTypes] =
    useState<ServiceType[]>(defaultValues);

  useDebounce(
    () => {
      serviceTypes.forEach((serviceType) => {
        const defaultValue = defaultValues.find(
          ({ serviceTypeId }) => serviceTypeId === serviceType.serviceTypeId,
        );

        if (
          !!defaultValue &&
          !!serviceTypes &&
          !isEqual(mapServiceType(serviceType), mapServiceType(defaultValue)) &&
          savedHashes[serviceType.internalType] !==
            hash(mapServiceType(serviceType))
        ) {
          onUpdateServiceType(serviceType);
          setSavedHashes((currentValue) => ({
            ...currentValue,
            [serviceType.internalType]: hash(mapServiceType(serviceType)),
          }));
        }
      });
    },
    300,
    [defaultValues, serviceTypes, onUpdateServiceType],
  );

  return (
    <Grid2 container spacing="12px" {...args}>
      <Grid2 pt={2} size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="featureTypes.service" />
        </Typography>
      </Grid2>
      <Grid2 container spacing={1} alignItems="center" size={12}>
        {serviceTypes.map((serviceType, index) => {
          const isHourly = [
            InternalServiceType.PACKING,
            InternalServiceType.UNPACK,
            InternalServiceType.ASSEMBLE,
            InternalServiceType.DISASSEMBLE,
          ].includes(serviceType.internalType);

          return (
            <Grid2
              container
              spacing={1}
              alignItems="center"
              key={serviceType.serviceTypeId}
              size={12}
            >
              <Grid2>
                <TextField
                  value={serviceType.price.amount}
                  disabled={disabled}
                  sx={{ width: '65px' }}
                  onChange={(e) =>
                    setServiceTypes((curr) => {
                      const newTypes = [...curr];
                      newTypes[index] = {
                        ...curr[index],
                        price: {
                          ...curr[index].price,
                          amount: parseFloat(e.target.value) || 0,
                        },
                      };

                      return newTypes;
                    })
                  }
                  InputProps={{
                    inputComponent: CurrencyTextFieldNumberFormat,
                    inputProps: {
                      pattern: '[0-9]*',
                      inputMode: 'numeric',
                      decimalScale: 2,
                    },
                  }}
                />
              </Grid2>
              <Grid2>
                <Typography color="textPrimary">
                  <RenderTranslatedName
                    translations={serviceType.translatedNames || []}
                    fallback={serviceType.serviceTypeName}
                  />
                  {isHourly && (
                    <>
                      &nbsp;
                      <FormattedMessage id="label.perHour" />
                    </>
                  )}
                </Typography>
              </Grid2>
            </Grid2>
          );
        })}
      </Grid2>
    </Grid2>
  );
};
const StyledEditableServicePricesFeatureBlock = styled(
  EditableServicePricesFeatureBlock,
)(
  () => `
  .MuiFormControl-root {
    width: 65px;
  }
  .MuiInput-input {
    text-align: center;
  }
`,
);
export default StyledEditableServicePricesFeatureBlock;
