import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DisapproveBatchDataMutationProps = {
  integrationId: Uuid;
  batchId: Uuid;
  datas: { dataId: Uuid }[];
};

export const DisapproveBatchDataMutation = async ({
  integrationId,
  batchId,
  ...values
}: DisapproveBatchDataMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/integrations/${integrationId}/batches/${batchId}/batch-datas/disapprove`,
    {
      integrationId,
      batchId,
      ...values,
    },
  );

export const useDisapproveBatchDataMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisapproveBatchDataMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  DisapproveBatchDataMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    DisapproveBatchDataMutationProps
  >({
    mutationFn: DisapproveBatchDataMutation,
    throwOnError: false,
    ...options,
  });
