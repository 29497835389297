import {
  HrmHoursOverview,
  isDeclaration,
  isTimeEntry,
  isTimeOffRegistration,
} from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import Qs from 'qs';

export type HoursOverviewRequestProps = {
  startDate: Date | Dayjs;
  endDate: Date | Dayjs;
};

type Response = AxiosResponse<Collection<HrmHoursOverview>>;

export const HoursOverviewRequest = async ({
  startDate,
  endDate,
  ...params
}: HoursOverviewRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/hours-overview/${formatDate(
      startDate,
    )}/${formatDate(endDate)}`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useHoursOverviewRequest = (
  { startDate, endDate, ...request }: HoursOverviewRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      HoursOverviewRequest({ ...request, startDate, endDate }),
    queryKey: [
      'hours-overview',
      'list',
      formatDate(startDate),
      formatDate(endDate),
      request,
    ],
  });

export const HoursOverviewRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTimeEntry(data) || isTimeOffRegistration(data) || isDeclaration(data)) {
    queryClient.invalidateQueries({ queryKey: ['hours-overview', 'list'] });
  }
};
