import { colors } from '@bas/theme';
import { Tab, TabProps } from '@bas/ui/web/atoms';
import { Tabs } from '@bas/ui/web/molecules';
import { Box, styled } from '@mui/material';
import { ReactElement } from 'react';

export type TabsWithinRightSideProps = {
  className?: string;
  tabs: (Omit<TabProps, 'content'> & { tabKey: string })[];
  activeTab: string;
  onChange?: (newTab: string) => void;
};

const TabsWithinRightSide = ({
  className,
  activeTab,
  tabs,
  onChange,
}: TabsWithinRightSideProps): ReactElement => (
  <Box className={className}>
    <Box className="Bas-TabsWithinRightSide-Tabs">
      <Tabs
        value={activeTab}
        onChange={onChange ? (e, newTab) => onChange(newTab) : undefined}
      >
        {tabs.map(({ tabKey, ...tab }) => (
          <Tab key={tabKey} {...tab} />
        ))}
      </Tabs>
    </Box>
  </Box>
);

const StyledTabsWithinRightSide = styled(TabsWithinRightSide)(
  ({ theme }) => `
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .Bas-TabsWithinRightSide-Tabs {
    width: 100%;
    position: relative;
    box-shadow: inset 0px -1px 0px ${colors.lila[400]};
    .MuiTab-root:first-of-type {
      margin-left: 0;
    }
  }
`,
);
export default StyledTabsWithinRightSide;
