import { isObjectResponse } from '@bas/value-objects';
import { ReeleezeeSettings } from '../../types';

export function isReeleezeeSettings(
  object: unknown,
): object is ReeleezeeSettings {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'ReeleezeeSettings'
  );
}
