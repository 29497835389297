import { Event, isEvent } from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EventByPeriodRequestProps = {
  fromDate: Date;
  untilDate: Date;
};

type Response = AxiosResponse<Collection<Event>>;
type QueryKeyType = QueryKey & {
  [0]: 'events';
  [1]: 'list';
  [2]: string;
  [3]: string;
};

export const EventByPeriodRequest = async ({
  fromDate,
  untilDate,
  ...params
}: EventByPeriodRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events`, {
    params: {
      fromDate: formatDate(fromDate),
      untilDate: formatDate(untilDate),
      ...params,
      perPage: 5000,
    },
  });

export const useEventByPeriodRequest = (
  request: EventByPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => EventByPeriodRequest({ ...request }),
    queryKey: [
      'events',
      'list',
      formatDate(request.fromDate),
      formatDate(request.untilDate),
    ],
  });

export const EventByPeriodRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEvent(data)) {
    queryClient.invalidateQueries({ queryKey: ['events', 'list'] });
  }
};
