import {
  getProviderLogo,
  getProviderName,
} from '@bas/settings-domain/web/hooks';
import { colors, fontSizesWeb } from '@bas/theme';
import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ProviderType } from '@bas/value-objects';
import { faArrowRight, faCog } from '@fortawesome/pro-light-svg-icons';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type IntegrationBlockProps = {
  provider: ProviderType;
  connected?: boolean;
  comingSoon?: boolean;
  beta?: boolean;
  onOpen?: () => void;
  onStartConnection?: () => void;
};

const IntegrationBlock = ({
  provider,
  connected,
  comingSoon,
  beta,
  onOpen,
  onStartConnection,
  ...args
}: IntegrationBlockProps): ReactElement => (
  <Box {...args}>
    <Typography className="Bas-IntegrationBlock-ProviderName">
      {getProviderName(provider)}
    </Typography>
    {onOpen && (
      <Box className="Bas-IntegrationBlock-SettingsButton">
        <IconButton onClick={onOpen}>
          <Icon icon={faCog} />
        </IconButton>
      </Box>
    )}
    {connected && (
      <Box className="Bas-IntegrationBlock-Active">
        <FormattedMessage id="label.activeIntegration" />
      </Box>
    )}
    {comingSoon && (
      <Box className="Bas-IntegrationBlock-ComingSoon">
        <FormattedMessage id="label.comingSoon" />
      </Box>
    )}
    {beta && (
      <Box className="Bas-IntegrationBlock-Beta">
        <FormattedMessage id="label.inBeta" />
      </Box>
    )}
    {onStartConnection && !connected && (
      <Button
        className="Bas-IntegrationBlock-Connect"
        variant="text"
        color="secondary"
        size="small"
        onClick={onStartConnection}
      >
        <FormattedMessage id="button.activateIntegration" />
        &nbsp;
        <span className="Bas-AvailableIntegrationBlock-Icon">
          <Icon icon={faArrowRight} />
        </span>
      </Button>
    )}
    <Box className="Bas-IntegrationBlock-ProviderLogo">
      <img src={getProviderLogo(provider)} alt={getProviderName(provider)} />
    </Box>
  </Box>
);

const StyledIntegrationBlock = styled(IntegrationBlock)(
  ({ theme }) => `
  position: relative;
  background: ${colors.lila[200]};
  border-radius: 5px;
  box-sizing: border-box;
  width: 294px;
  height: 217px;
  padding: ${theme.spacing(2, 3, 3)};
  color: ${colors.lila[800]};

  .Bas-IntegrationBlock-ProviderName {
    font-weight: bold;
  }

  .Bas-IntegrationBlock-Active {
    background: ${colors.green[300]};
    color: ${colors.green[800]};
    border-radius: 5px;
    padding: 6px 10px;
    font-size: ${fontSizesWeb.xs};
    line-height: 13px;
    text-align: center;
    width: 94px;
    box-sizing: border-box;
    margin-top: 7px;
  }

  .Bas-IntegrationBlock-ComingSoon {
    background: ${colors.orange[600]};
    color: ${colors.orange[900]};
    border-radius: 5px;
    padding: 6px 10px;
    font-size: ${fontSizesWeb.xs};
    line-height: 13px;
    text-align: center;
    width: 136px;
    box-sizing: border-box;
    margin-top: 7px;
  }

  .Bas-IntegrationBlock-SettingsButton {
    position: absolute;
    top: ${theme.spacing(1)};
    right: 27px;
    .MuiIconButton-root {
      font-size: 21px;
    }
  }

  .Bas-IntegrationBlock-Connect {
    text-transform: lowercase;
    padding: 0 !important;
    .Bas-IntegrationBlock-Icon {
      font-size: 12px;
    }
  }

  .Bas-IntegrationBlock-ProviderLogo {
    position: absolute;
    bottom: ${theme.spacing(2)};
    left: ${theme.spacing(2)};
    width: 150px;
    height: 50px;
    display: flex;
    align-items: baseline;
    img {
      max-width: 150px;
      max-height: 50px;
    }
  }
`,
);
export default StyledIntegrationBlock;
