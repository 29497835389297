import { isTimeOffType, TimeOffType } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeOffTypeByTimeOffTypeIdProps = {
  timeOffTypeId: Uuid;
};

export const TimeOffTypeByTimeOffTypeId = async ({
  timeOffTypeId,
  ...params
}: TimeOffTypeByTimeOffTypeIdProps): Promise<AxiosResponse<TimeOffType>> =>
  axios.get(`api/{tenantId}/hrm/time-off-types/${timeOffTypeId}`, {
    params,
  });

export const useTimeOffTypeByTimeOffTypeId = (
  request: TimeOffTypeByTimeOffTypeIdProps,
  options: QueryOptionsWithKey<
    AxiosResponse<TimeOffType>,
    AxiosError,
    AxiosResponse<TimeOffType>
  > = {},
): UseQueryResult<AxiosResponse<TimeOffType>, AxiosError> =>
  useQuery<AxiosResponse<TimeOffType>, AxiosError, AxiosResponse<TimeOffType>>({
    ...options,
    queryFn: async () => TimeOffTypeByTimeOffTypeId(request),
    queryKey: ['time-off-types', 'detail', request.timeOffTypeId],
  });

export const TimeOffTypeByTimeOffTypeIdInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTimeOffType(data)) {
    queryClient.invalidateQueries({
      queryKey: ['time-off-types', 'detail', data.timeOffTypeId],
    });
  }
};
