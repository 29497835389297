import { getCountryName } from '@bas/shared/utils';
import { Address } from '@bas/value-objects';
import { Box, BoxProps, Link, Typography } from '@mui/material';
import { ElementType, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type AddressBlockProps = Omit<BoxProps, 'component'> & {
  component?: ElementType;
  address: Address;
  showLink?: boolean;
  showCountry?: boolean;
  variant?: 'single_line' | 'multiline';
  addressLabel?: string | ReactNode;
  children?: ReactNode | ReactNode[];
};

const AddressBlock = ({
  address,
  showLink = false,
  addressLabel,
  showCountry = false,
  variant = 'multiline',
  children,
  component = 'span',
  ...args
}: AddressBlockProps) => {
  if (variant === 'single_line') {
    return (
      <Box {...args} component={component}>
        {addressLabel && <b>{addressLabel} -&nbsp;</b>}
        {`${address.streetName} ${address.houseNumber}`}
        {address.houseNumberAddition && ` ${address.houseNumberAddition}`}
        {variant !== 'single_line' && <br />}
        {variant === 'single_line' && ', '}
        {`${address.zipCode} ${address.city}`}
        {variant !== 'single_line' && showCountry && <br />}
        {showCountry && getCountryName(address.country)}
      </Box>
    );
  }

  if (!address) {
    return <span />;
  }

  return (
    <Box {...args} component={component}>
      <Typography component="div" variant="subtitle2">
        {addressLabel}
      </Typography>
      <Typography component="div">
        {showLink && address.streetName && (
          <Link
            href={`http://maps.google.com/maps?q=${address.streetName.replace(
              '&',
              ' ',
            )} ${address.houseNumber} ${address.houseNumberAddition || ''} ${
              address.zipCode
            } ${address.city} ${getCountryName(address.country)}&navigate=yes`}
            target="_blank"
            underline="always"
            color="textPrimary"
          >
            <FormattedMessage id="button.goToGoogleMaps" />
          </Link>
        )}
        {showLink && <br />}
        {`${address.streetName} ${address.houseNumber}`}
        {address.houseNumberAddition && ` ${address.houseNumberAddition}`}
        <br />
        {`${address.zipCode} ${address.city}`}
        {showCountry && <br />}
        {showCountry && getCountryName(address.country)}
        {children}
      </Typography>
    </Box>
  );
};

export default AddressBlock;
