import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  HandlingCosts,
  isHandlingCosts,
  isStorage,
} from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type HandlingCostsByStorageIdProps = Pagination &
  Filterable & {
    storageId: Uuid;
  };

type Response = AxiosResponse<Collection<HandlingCosts>>;

export const HandlingCostsByStorageId = async ({
  storageId,
  ...params
}: HandlingCostsByStorageIdProps): Promise<Response> =>
  axios.get(`api/{tenantId}/storages/${storageId}/handling-costs`, {
    params: { ...params },
  });

export const useHandlingCostsByStorageId = (
  request: HandlingCostsByStorageIdProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => HandlingCostsByStorageId({ perPage: 100, ...request }),
    queryKey: ['handling-costs', 'list', request.storageId],
  });

export const HandlingCostsByStorageIdInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isHandlingCosts(data) || isStorage(data)) {
    queryClient.invalidateQueries({
      queryKey: ['handling-costs', 'list', data.storageId],
    });
  }
};
