import { EmployeeAttribute, isEmployeeAttribute } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeAttributesRequestProps = Pagination &
  Filterable & {
    withDisabled?: boolean;
  };

export const EmployeeAttributesRequest = async ({
  ...params
}: EmployeeAttributesRequestProps): Promise<
  AxiosResponse<Collection<EmployeeAttribute>>
> =>
  axios.get(`api/{tenantId}/hrm/employee-attributes`, {
    params: { ...params },
  });

export const useEmployeeAttributesRequest = (
  request: EmployeeAttributesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<EmployeeAttribute>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<EmployeeAttribute>>
  > = {},
): UseQueryResult<
  AxiosResponse<Collection<EmployeeAttribute>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<EmployeeAttribute>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<EmployeeAttribute>>
  >({
    ...options,
    queryFn: async () => EmployeeAttributesRequest(request),
    queryKey: ['employee-attributes', 'list', request],
  });

export const EmployeeAttributesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEmployeeAttribute(data)) {
    queryClient.invalidateQueries({
      queryKey: ['employee-attributes', 'list'],
    });
  }
};
