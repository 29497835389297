import {
  CalculatedTotalsForAcceptanceQuote,
  QuoteLine,
} from '@bas/financial-domain/models';
import { colors } from '@bas/theme';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormattedCurrency,
  OptionalLabel,
} from '@bas/ui/web/atoms';
import { Checkbox } from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Box, Grid2, Skeleton, styled, Typography } from '@mui/material';
import { Fragment, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type MobileQuoteTableForAcceptanceProps = {
  className?: string;
  acceptedLines: Uuid[];
  setAcceptedLines: (newValue: Uuid[]) => void;
  reloadTotals: (newValue: Uuid[]) => Promise<void> | void;
  loadingTotals?: boolean;
  totals: CalculatedTotalsForAcceptanceQuote;
  hasOptionalLines: boolean;
  showTotals?: boolean;
  showQuantity?: boolean;
  showTotalPerLine?: boolean;
  lines: QuoteLine[];
};

const MobileQuoteTableForAcceptance = ({
  className,
  setAcceptedLines,
  reloadTotals,
  loadingTotals,
  totals,
  hasOptionalLines,
  acceptedLines,
  showTotals = true,
  showQuantity = true,
  showTotalPerLine = true,
  lines,
}: MobileQuoteTableForAcceptanceProps): ReactElement => (
  <Grid2 container className={className} rowSpacing={3}>
    <Grid2 className="Bas-MobileQuoteTableForAcceptance-Lines" size={12}>
      <Box className="Bas-MobileQuoteTableForAcceptance-LinesHolder">
        <Box sx={{ position: 'relative' }}>
          {lines.map((line) => (
            <Accordion disableGutters key={line.lineId}>
              <AccordionSummary>
                <Grid2 container alignItems="center">
                  {hasOptionalLines && (
                    <Grid2 sx={{ marginRight: '13px' }}>
                      <Checkbox
                        removePadding
                        disabled={!line.optional}
                        checked={
                          !!acceptedLines && acceptedLines.includes(line.lineId)
                        }
                        onChange={() => {
                          const newValue = [...acceptedLines];
                          const index = newValue.indexOf(line.lineId);

                          if (index === -1) {
                            newValue.push(line.lineId);
                          } else {
                            newValue.splice(index, 1);
                          }

                          reloadTotals(newValue);
                          setAcceptedLines(newValue);
                        }}
                      />
                    </Grid2>
                  )}
                  <Grid2 size={hasOptionalLines ? 7 : 9}>
                    {line.description}
                  </Grid2>
                  <Grid2 size={3}>
                    <Typography textAlign="right">
                      <FormattedCurrency
                        {...line[
                          showTotalPerLine ? 'totalPrice' : 'pricePerUnit'
                        ]}
                      />
                    </Typography>
                  </Grid2>
                </Grid2>
              </AccordionSummary>
              <AccordionDetails>
                <Grid2 container rowSpacing={2} alignItems="center">
                  <Grid2 size={12}>{line.optional && <OptionalLabel />}</Grid2>
                  {showQuantity && (
                    <>
                      <Grid2 size={4}>
                        <Typography variant="subtitle1">
                          <FormattedMessage id="label.quantity" />
                        </Typography>
                      </Grid2>
                      <Grid2 sx={{ textAlign: 'right' }} size={8}>
                        {`${line.quantity}x`}
                      </Grid2>
                    </>
                  )}
                  <Grid2 size={4}>
                    <Typography variant="subtitle1">
                      <FormattedMessage id="label.pricePerUnit" />
                    </Typography>
                  </Grid2>
                  <Grid2 sx={{ textAlign: 'right' }} size={8}>
                    <FormattedCurrency {...line.pricePerUnit} />
                  </Grid2>
                  {showTotalPerLine && (
                    <>
                      <Grid2 size={4}>
                        <Typography variant="subtitle1">
                          <FormattedMessage id="label.total" />
                        </Typography>
                      </Grid2>
                      <Grid2 sx={{ textAlign: 'right' }} size={8}>
                        <FormattedCurrency {...line.totalPrice} />
                      </Grid2>
                    </>
                  )}
                  <Grid2 size={4}>
                    <Typography variant="subtitle1">
                      <FormattedMessage id="label.vat" />
                    </Typography>
                  </Grid2>
                  <Grid2 sx={{ textAlign: 'right' }} size={8}>
                    {`${line.vatPercentage.percentage}%`}
                  </Grid2>
                </Grid2>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Grid2>
    {showTotals && (
      <Grid2 container spacing={2} size={12}>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.subTotal" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography textAlign="right">
            {loadingTotals ? (
              <Skeleton variant="text" />
            ) : (
              <FormattedCurrency {...totals.totalExcludingVat} />
            )}
          </Typography>
        </Grid2>
        {totals.vatAmounts
          .sort(
            ({ vatPercentage: vatA }, { vatPercentage: vatB }) =>
              parseInt(vatA, 10) - parseInt(vatB, 10),
          )
          .map(({ vatPercentage, vatAmount }) => (
            <Fragment key={vatPercentage}>
              <Grid2 size={6}>
                <Typography>
                  {`${vatPercentage}% `}
                  <FormattedMessage id="label.vat" />
                </Typography>
              </Grid2>
              <Grid2 size={6}>
                <Typography textAlign="right">
                  {loadingTotals ? (
                    <Skeleton variant="text" />
                  ) : (
                    <FormattedCurrency {...vatAmount} />
                  )}
                </Typography>
              </Grid2>
            </Fragment>
          ))}
        <Grid2 size={6}>
          <Typography fontWeight="bold">
            <FormattedMessage id="label.total" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography textAlign="right" fontWeight="bold">
            {loadingTotals ? (
              <Skeleton variant="text" />
            ) : (
              <FormattedCurrency {...totals.totalIncludingVat} />
            )}
          </Typography>
        </Grid2>
      </Grid2>
    )}
  </Grid2>
);

const StyledMobileQuoteTableForAcceptance = styled(
  MobileQuoteTableForAcceptance,
)(
  ({ theme }) => `
.Bas-MobileQuoteTableForAcceptance-Lines {
    .Bas-MobileQuoteTableForAcceptance-LinesHolder {
      margin-left: -13px;
      margin-right: -13px;
    }
}

.MuiAccordion-root {
  background: ${theme.palette.common.white};
  border: 1px solid ${colors.lila[300]};
  padding: ${theme.spacing(2)} 11px;
  box-sizing: border-box;

  margin-top: 0;
  margin-bottom: 0;

  &:not(:last-of-type) {
    margin-bottom: 6px !important;
  }

  &, &:first-of-type, &:last-of-type {
    border-radius: 5px;
  }

  .MuiAccordionSummary-root {
    min-height: unset;
    justify-content: flex-start;
  }

  .MuiTypography-subtitle1 {
    color: ${colors.lila[700]};
  }

  &:not(.Mui-expanded) {
    .MuiAccordionSummary-content {
      .MuiGrid2-root {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;
  }

  .MuiAccordionDetails-root {
    padding-top: ${theme.spacing(3)};
    padding-bottom: ${theme.spacing(1)};
  }
}
`,
);
export default StyledMobileQuoteTableForAcceptance;
