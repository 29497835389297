import { OtherPlannedEmployee } from '@bas/hrm-domain/models';
import { Grid2 } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { OtherPlannedEmployeeAvatar } from '../OtherPlannedEmployeeAvatar';

export type OtherPlannedEmployeesTooltipContentProps = {
  plannedStart: Date;
  plannedEnd: Date;
  otherPlannedEmployees: OtherPlannedEmployee[];
  children?: ReactNode;
};

const OtherPlannedEmployeesTooltipContent = ({
  plannedStart,
  plannedEnd,
  otherPlannedEmployees,
  children,
}: OtherPlannedEmployeesTooltipContentProps): ReactElement => (
  <Grid2 container spacing={2} p={1}>
    <Grid2 size={12}>{children}</Grid2>
    <Grid2 size={12}>
      <FormattedMessage
        id="projects.projectHours.plannedMessage"
        values={{
          plannedStart: (
            <FormattedDate
              value={plannedStart}
              minute="2-digit"
              hour="2-digit"
            />
          ),
          plannedEnd: (
            <FormattedDate value={plannedEnd} minute="2-digit" hour="2-digit" />
          ),
        }}
      />
    </Grid2>
    {otherPlannedEmployees &&
      otherPlannedEmployees.map(
        ({
          employeeId,
          personName,
          profilePicture,
          plannedHours,
          workedHours,
          workedHoursAccordingToForeman,
          approvedWorkedHours,
          plannedTravelTime,
          travelTime,
          travelTimeAccordingToForeman,
          approvedTravelTime,
        }) => (
          <Grid2 key={employeeId}>
            <OtherPlannedEmployeeAvatar
              personName={personName}
              profilePictureUrl={profilePicture?.contentUrl}
              plannedHours={plannedHours + (plannedTravelTime || 0)}
              workedHours={
                !!workedHours && !!travelTime ? workedHours + travelTime : null
              }
              workedHoursAccordingToForeman={
                !!workedHoursAccordingToForeman &&
                !!travelTimeAccordingToForeman
                  ? workedHoursAccordingToForeman + travelTimeAccordingToForeman
                  : null
              }
              approvedWorkedHours={
                !!approvedWorkedHours && !!approvedTravelTime
                  ? approvedWorkedHours + approvedTravelTime
                  : null
              }
            />
          </Grid2>
        ),
      )}
  </Grid2>
);

export default OtherPlannedEmployeesTooltipContent;
