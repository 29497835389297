import { colors, fontSizesWeb } from '@bas/theme';
import { styled } from '@mui/material';

export type LogoProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  color?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  dotColor?: string;
  label?: string;
  className?: string;
};

const Logo = ({ label = 'Bas', className }: LogoProps) => (
  <div className={className}>
    {label}
    <div className="Bas-logo-dot" />
  </div>
);

const StyledLogo = styled(Logo)(
  ({ theme, color, dotColor }) => `
  color: ${color || colors.white};
  font-family: ${theme.typography.fontFamily};
  font-weight: bold;
  font-size: ${fontSizesWeb.xl};
  line-height: 31px;
  display: flex;
  align-items: baseline;
  & .Bas-logo-dot {
    margin-left: 2px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${dotColor || theme.palette.primary.main};
  }
  `,
);
export default StyledLogo;
