import { PlanProjectEventInputType } from '@bas/planning-domain/input-types';
import { convertRRuleToText } from '@bas/planning-domain/web/hooks';
import { formatDate } from '@bas/shared/utils';
import { Button, Tooltip } from '@bas/ui/web/atoms';
import { Uuid } from '@bas/value-objects';
import { Box, Grid2, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import {
  FormattedDate,
  FormattedDateTimeRange,
  FormattedMessage,
} from 'react-intl';

export type PlanningEventType = {
  eventId: Uuid;
  name: string;
} & PlanProjectEventInputType;

export type PlanningProjectEventsToolbarProps = {
  planningEvents: (PlanningEventType & {
    id: string;
    overridesDay?: boolean;
  })[];
  currentPlanningDate: Date;
  setCurrentPlanningDate: (value: Date) => void;
  onEditEvent: (eventId: Uuid) => void;
};

const PlanningProjectEventsToolbar = ({
  planningEvents,
  currentPlanningDate,
  setCurrentPlanningDate,
  onEditEvent,
  ...args
}: PlanningProjectEventsToolbarProps): ReactElement => {
  const plannedDates = useMemo(() => {
    const result: { [key: string]: (PlanningEventType & { id: string })[] } =
      {};
    planningEvents
      .filter(({ isRecurring }) => !isRecurring)
      .forEach((planningEvent) => {
        if (!result[formatDate(planningEvent.start as Date)]) {
          result[formatDate(planningEvent.start as Date)] = [];
        }

        result[formatDate(planningEvent.start as Date)].push(planningEvent);
      });
    return result;
  }, [planningEvents]);

  const recurringEvents = useMemo(
    () =>
      planningEvents
        .filter(({ isRecurring }) => isRecurring)
        .map((event) => ({
          ...event,
          rruleText: convertRRuleToText(
            event.recurringInformation?.rrule || '',
          ),
        })),
    [planningEvents],
  );

  return (
    <Box {...args}>
      <Grid2 container rowSpacing={1} flexWrap="nowrap">
        {planningEvents.length === 0 && recurringEvents.length === 0 && (
          <Grid2 size={12}>
            <FormattedMessage id="label.nothingHasBeenPlannedYet" />
          </Grid2>
        )}
        {recurringEvents.length > 0 &&
          recurringEvents.map((e) => (
            <Grid2 key={e.eventId}>
              <Button
                variant="text"
                color="secondary"
                sx={{
                  fontWeight: 'normal',
                }}
                onClick={() => onEditEvent(e.eventId)}
              >
                {e.rruleText}
              </Button>
            </Grid2>
          ))}
        {planningEvents.length > 0 &&
          Object.keys(plannedDates).map((date) => (
            <Grid2 key={date}>
              <Tooltip
                title={
                  <Grid2 container>
                    {plannedDates[date].map((event) => (
                      <Grid2
                        container
                        columnSpacing={1}
                        key={event.id}
                        size={12}
                      >
                        <Grid2>
                          <Typography>
                            <FormattedMessage
                              id={`planning.events.eventType.${event.eventType}`}
                            />
                          </Typography>
                        </Grid2>
                        <Grid2>
                          <Typography>
                            <FormattedDateTimeRange
                              from={dayjs(event.start).toDate()}
                              to={dayjs(event.end).toDate()}
                              timeStyle="short"
                            />
                          </Typography>
                        </Grid2>
                      </Grid2>
                    ))}
                  </Grid2>
                }
              >
                <Button
                  variant="text"
                  color="secondary"
                  sx={{
                    fontWeight: 'normal',
                    color: (theme) =>
                      dayjs(date).isSame(currentPlanningDate, 'd')
                        ? `${theme.palette.primary.main} !important`
                        : undefined,
                  }}
                  onClick={() => setCurrentPlanningDate(dayjs(date).toDate())}
                >
                  <FormattedDate
                    value={date}
                    weekday="long"
                    day="numeric"
                    month="long"
                    year={dayjs().isSame(date, 'year') ? undefined : 'numeric'}
                  />
                </Button>
              </Tooltip>
            </Grid2>
          ))}
      </Grid2>
    </Box>
  );
};

const StyledPlanningProjectEventsToolbar = styled(PlanningProjectEventsToolbar)`
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  box-sizing: border-box;
  overflow: auto;
  height: 60px;

  .MuiButton-root {
    white-space: nowrap;
  }
`;

export default StyledPlanningProjectEventsToolbar;
