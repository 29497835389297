import { TextField, TextFieldProps } from '@bas/ui/web/atoms';
import {
  ChangeEvent,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseFormStateReturn,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type ReactHookFormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<TextFieldProps, 'name' | 'value' | 'error'> & {
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
  updateOnBlur?: boolean;
  debounce?: number | boolean;
  disableError?: boolean;
  loading?: boolean;
};

const ReactHookFormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  children,
  disabled,
  helperText,
  updateOnBlur = false,
  disableError,
  loading,
  debounce = false,
  select,
  field: { value, onBlur, onChange },
  fieldState: { error, invalid },
  formState: { isSubmitting },
  ...props
}: ReactHookFormTextFieldProps<TFieldValues, TName>): ReactElement | null => {
  const { formatMessage } = useIntl();
  const fieldError = error?.message;
  const showError = !disableError && invalid;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newValue, setNewValue] = useState<any>(value);
  const [firstValue] = useState(value);
  const handleBlur = useCallback(() => {
    onChange(newValue);
    onBlur();
  }, [newValue, onBlur, onChange]);

  const textFieldArgs = useMemo(
    () => ({
      variant: props.variant,
      error: showError,
      helperText: showError ? fieldError : helperText,
      disabled: disabled ?? isSubmitting,
    }),
    [disabled, fieldError, helperText, isSubmitting, props, showError],
  );

  const [currentValueProp, setCurrentValueProp] = useState<{
    value?: unknown;
    defaultValue?: unknown;
  }>();

  const calculatedValueProp = useMemo(() => {
    const result: { value?: unknown; defaultValue?: unknown } = {};
    if (loading) {
      const loadingMessage = formatMessage({ id: 'label.loading' });
      if (updateOnBlur || debounce) {
        result.defaultValue = loadingMessage;
      } else {
        result.value = loadingMessage;
      }
    } else if (updateOnBlur || debounce) {
      result.defaultValue = firstValue || '';
    } else {
      result.value = value || '';
    }

    return result;
  }, [debounce, firstValue, formatMessage, loading, updateOnBlur, value]);

  useEffect(() => {
    if (!isEqual(calculatedValueProp, currentValueProp)) {
      setCurrentValueProp(calculatedValueProp);
    }
  }, [
    calculatedValueProp,
    currentValueProp,
    debounce,
    firstValue,
    formatMessage,
    loading,
    updateOnBlur,
    value,
  ]);

  useDebounce(
    () => {
      if (debounce && newValue !== value) {
        console.log('trigger debounce', newValue);
        onChange(newValue);
      }
    },
    75,
    [debounce, newValue, value],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (updateOnBlur || debounce) {
        setNewValue(event.target.value);
      } else {
        onChange(event.target.value);
      }
    },
    [debounce, onChange, updateOnBlur],
  );

  if (!currentValueProp) {
    return null;
  }

  return (
    <TextField
      onChange={handleChange}
      {...textFieldArgs}
      {...props}
      {...(debounce || updateOnBlur ? currentValueProp : calculatedValueProp)}
      select={loading ? false : select}
      onBlur={updateOnBlur ? handleBlur : onBlur}
    >
      {children}
    </TextField>
  );
};

const MemoizedReactHookFormTextField = memo(
  ReactHookFormTextField,
  isEqual,
) as unknown as typeof ReactHookFormTextField;

export default MemoizedReactHookFormTextField;
