import { isEstimationSettings } from './EstimationSettings';
import type { MovingJobEstimationSettings } from '../types';

export function isMovingJobEstimationSettings(
  object: unknown,
): object is MovingJobEstimationSettings {
  return (
    !!object &&
    isEstimationSettings(object) &&
    object.estimationSettingsType === 'moving-job-estimation-settings'
  );
}
