import { isTimeTypeCategory, TimeTypeCategory } from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeTypeCategoriesRequestProps = Pagination;

export const TimeTypeCategoriesRequest = async ({
  ...params
}: TimeTypeCategoriesRequestProps): Promise<
  AxiosResponse<Collection<TimeTypeCategory>>
> =>
  axios.get(`api/{tenantId}/hrm/time-type-categories`, {
    params: { ...params },
  });

export const useTimeTypeCategoriesRequest = (
  request: TimeTypeCategoriesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<TimeTypeCategory>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeTypeCategory>>
  > = {},
): UseQueryResult<
  AxiosResponse<Collection<TimeTypeCategory>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<TimeTypeCategory>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<TimeTypeCategory>>
  >({
    ...options,
    queryFn: async () => TimeTypeCategoriesRequest(request),
    queryKey: ['time-type-categories', 'list'],
  });

export const TimeTypeCategoriesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTimeTypeCategory(data)) {
    queryClient.invalidateQueries({
      queryKey: ['time-type-categories', 'list'],
    });
  }
};
