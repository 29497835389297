import { WhatsAppAccountReviewStatus } from '@bas/integration-domain/models';
import { Chip } from '@bas/ui/web/atoms';
import { Badge } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type WhatsAppAccountReviewStatusChipProps = {
  businessStatus: WhatsAppAccountReviewStatus;
};

const WhatsAppAccountReviewStatusChip = ({
  businessStatus,
}: WhatsAppAccountReviewStatusChipProps): ReactElement => {
  const categories: {
    [key: string]: {
      color:
        | 'primary'
        | 'secondary'
        | 'default'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    };
  } = useMemo(
    () => ({
      [WhatsAppAccountReviewStatus.APPROVED]: {
        color: 'success',
      },
      [WhatsAppAccountReviewStatus.PENDING]: {
        color: 'warning',
      },
      [WhatsAppAccountReviewStatus.REJECTED]: {
        color: 'error',
      },
    }),
    [],
  );

  const category = categories[businessStatus];
  if (typeof category !== 'undefined') {
    return (
      <Chip
        sx={{ width: '100%' }}
        label={
          <>
            <Badge color={category.color} badgeContent=" " variant="dot" />
            <span style={{ paddingLeft: '8px' }}>
              <FormattedMessage
                id={`whatsAppAccountReviewStatus.${businessStatus}`}
              />
            </span>
          </>
        }
      />
    );
  }

  return (
    <Chip
      sx={{ width: '100%' }}
      label={
        <FormattedMessage
          id={`whatsAppAccountReviewStatus.${businessStatus}`}
        />
      }
    />
  );
};

export default WhatsAppAccountReviewStatusChip;
