import { isObjectResponse } from '@bas/value-objects';
import { TenantClosureDate } from '../types';

export function isTenantClosureDate(
  closureDate: unknown,
): closureDate is TenantClosureDate {
  return (
    typeof closureDate === 'object' &&
    closureDate !== null &&
    isObjectResponse(closureDate) &&
    closureDate['@type'] === 'TenantClosureDate' &&
    Object.prototype.hasOwnProperty.call(closureDate, 'closureDateId') &&
    Object.prototype.hasOwnProperty.call(closureDate, 'date') &&
    Object.prototype.hasOwnProperty.call(closureDate, 'reason')
  );
}
