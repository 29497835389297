import { GeneralLedgerMappingType } from '@bas/integration-domain/models';
import { useGeneralLedgerBasOptions } from '@bas/integration-domain/web/hooks';
import { MappingTableExternalOption } from '@bas/value-objects';
import { Grid2 } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { MappingTableForm } from '../MappingTableForm';

export type GeneralLedgerMappingFormProps = {
  generalLedgerMappingType: GeneralLedgerMappingType;
  externalOptions: MappingTableExternalOption[];
  noExternalSelect?: boolean;
};

const GeneralLedgerMappingForm = ({
  generalLedgerMappingType,
  externalOptions,
  noExternalSelect,
}: GeneralLedgerMappingFormProps): ReactElement => {
  const basIdLabel = useMemo(() => {
    if (generalLedgerMappingType === GeneralLedgerMappingType.PER_IDENTITY) {
      return <FormattedMessage id="label.identity" />;
    }

    if (
      generalLedgerMappingType === GeneralLedgerMappingType.PER_TURNOVER_GROUP
    ) {
      return <FormattedMessage id="label.turnoverGroup" />;
    }

    return <FormattedMessage id="label.vatCode" />;
  }, [generalLedgerMappingType]);

  const basOptions = useGeneralLedgerBasOptions(generalLedgerMappingType);

  return (
    <Grid2 container>
      <Grid2 size={12}>
        <MappingTableForm
          vatMapping={
            generalLedgerMappingType === GeneralLedgerMappingType.PER_VAT_CODE
          }
          basOptions={basOptions}
          externalOptions={externalOptions}
          prefix="generalLedgerMapping"
          basIdLabel={basIdLabel}
          externalIdLabel={<FormattedMessage id="label.generalLedger" />}
          noExternalSelect={noExternalSelect}
        />
      </Grid2>
    </Grid2>
  );
};

export default GeneralLedgerMappingForm;
