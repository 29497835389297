import { colors } from '@bas/theme';
import { PickersDay, TextField, TextFieldProps } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';
import { styled, TextField as MuiTextField } from '@mui/material';
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

export type DateTimePickerFieldProps = MuiDateTimePickerProps<Dayjs> & {
  textFieldProps?: TextFieldProps;
};

const OpenPickerIcon = () => (
  <Icon icon={faCalendarDay} sx={{ marginRight: '4px' }} fontSize={16} />
);

const DateTimePickerField = ({
  textFieldProps,
  className,
  value,
  format = 'DD-MM-YYYY HH:mm',
  slots = {
    openPickerIcon: OpenPickerIcon,
    day: PickersDay,
    textField: TextField as typeof MuiTextField,
  },
  showDaysOutsideCurrentMonth = true,
  displayWeekNumber = true,
  ampm = false,
  ampmInClock = false,
  slotProps = {
    openPickerButton: {
      disableRipple: true,
    },
  },
  ...props
}: DateTimePickerFieldProps) => (
  <MuiDateTimePicker<Dayjs>
    value={value ? dayjs(value) : null}
    format={format}
    slots={slots}
    showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
    displayWeekNumber={displayWeekNumber}
    ampm={ampm}
    ampmInClock={ampmInClock}
    slotProps={{
      ...slotProps,
      popper: {
        className,
        ...slotProps?.popper,
      },
      textField: {
        ...slotProps?.textField,
        ...textFieldProps,
      },
    }}
    {...props}
  />
);

const StyledDateTimePickerField = styled(DateTimePickerField)(
  ({ theme }) => `
  .MuiPaper-root {
    box-shadow: unset;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${colors.lila[400]};

    > div > div, .MuiCalendarPicker-root {
      min-width: 326px;
    }

    .MuiCalendarPicker-root {
      padding: ${theme.spacing(0, 2)};
      box-sizing: border-box;
    }

    .MuiCalendarPicker-viewTransitionContainer > div > div:not(.PrivatePickersSlideTransition-root) {
      display:none;
    }
  }
`,
);

export default StyledDateTimePickerField;
