import { KingFinanceGeneralLedger } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type KingFinanceGeneralLedgersByIntegrationIdRequestProps =
  Pagination & {
    integrationId: Uuid;
  };

type Response = AxiosResponse<Collection<KingFinanceGeneralLedger>>;

export const KingFinanceGeneralLedgersByIntegrationIdRequest = async ({
  integrationId,
  ...params
}: KingFinanceGeneralLedgersByIntegrationIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/integrations/${integrationId}/imuis/i-muis-general-ledgers`,
    {
      params: { ...params },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useKingFinanceGeneralLedgersByIntegrationIdRequest = (
  request: KingFinanceGeneralLedgersByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      KingFinanceGeneralLedgersByIntegrationIdRequest(request),
    queryKey: [
      'integrations',
      'king-finance-general-ledgers',
      request.integrationId,
      request,
    ],
  });
