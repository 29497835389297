import {
  EmployeeOccasionalAvailability,
  isEmployeeOccasionalAvailability,
} from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type EmployeeOccasionalAvailabilitiesByEmployeeIdRequestProps =
  Pagination & {
    employeeId: Uuid;
  };

type Response = AxiosResponse<Collection<EmployeeOccasionalAvailability>>;

export const EmployeeOccasionalAvailabilitiesByEmployeeIdRequest = async ({
  employeeId,
  ...params
}: EmployeeOccasionalAvailabilitiesByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/${employeeId}/occasional-availabilities`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useEmployeeOccasionalAvailabilitiesByEmployeeIdRequest = (
  {
    employeeId,
    ...request
  }: EmployeeOccasionalAvailabilitiesByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      EmployeeOccasionalAvailabilitiesByEmployeeIdRequest({
        ...request,
        employeeId,
      }),
    queryKey: ['employee-occasional-availability', 'list', employeeId, request],
  });

export const EmployeeOccasionalAvailabilitiesByEmployeeIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEmployeeOccasionalAvailability(data)) {
      queryClient.invalidateQueries({
        queryKey: ['employee-occasional-availability', 'list', data.employeeId],
      });
    }
  };
