import { formatDate } from '@bas/shared/utils';
import { Uuid } from '@bas/value-objects';
import dayjs from 'dayjs';
import { v7 } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

export type TimeTrackingItem = {
  timeEntryId: Uuid;
  employeeId: Uuid;
  timeTypeId?: Uuid;
  wasUnknown?: boolean;
  projectId?: Uuid | null | undefined;
  relationId?: Uuid | null | undefined;
  eventId?: Uuid | null | undefined;
  start: Date;
  end?: Date;
};

type TimeTrackingStore = {
  activeEmployeeId: Uuid | undefined;
  currentProjectId?: Uuid | undefined;
  currentEventId?: Uuid | undefined;
  hasActiveItem: boolean;
  today: {
    date: Date;
    workedHours: number;
    workedHoursByTimeTypeId: {
      [key: string]: number;
    };
  };
  updateTrackedTime: (
    employeeId: Uuid,
    timeEntryId: Uuid,
    item: Partial<TimeTrackingItem>,
  ) => void;
  lastSync:
    | {
        date: Date;
        success: boolean;
        errors?: string[];
      }
    | undefined;
  hasUnsyncedItems: boolean;
  checkAndUpdateUnsyncedItems: () => void;
  setActiveEmployeeId: (employeeId: Uuid) => void;
  changeLastSync: (
    date: Date,
    success: boolean,
    employeeId: Uuid,
    errors?: string[],
  ) => void;
  createTrackedTime: (employeeId: Uuid, item: TimeTrackingItem) => void;
  removeTrackedTime: (employeeId: Uuid, timeEntryId: Uuid) => void;
  trackedTimePerDate: { [key: string]: TimeTrackingItem[] };
  startTrackingTime: (
    employeeId: Uuid,
    timeTypeId: Uuid | undefined,
    start: Date,
    extraProps?: Partial<TimeTrackingItem>,
    force?: boolean,
  ) => void;
  finishActiveItem: (employeeId: Uuid, end: Date) => void;
  getActiveItem: (employeeId: Uuid) => TimeTrackingItem | undefined;
  getUnfinishedItems: (employeeId: Uuid) => TimeTrackingItem[];
  getWorkedHoursToday: (employeeId: Uuid) => number;
  resetDay: (day: Date) => void;
  getWorkedHoursTodayByTimeTypeId: (
    employeeId: Uuid,
    timeTypeId: Uuid,
  ) => number;
  calculateToday: (employeeId: Uuid) => void;
  reset: () => void;
  resetEmployee: (employeeId: Uuid) => void;
  changeCurrentProjectIdAndOrEventId: (
    projectId: Uuid | undefined,
    eventId: Uuid | undefined,
  ) => void;
  isSyncing: boolean;
  startSyncing: () => void;
  finishSyncing: () => void;
  getIsSyncing: () => boolean;
  isTracking: boolean;
  startedAt?: Date;
  getStartedAt: () => Date | undefined;
  getIsTracking: () => boolean;
  setIsTracking: (isTracking: boolean) => void;
  __dev_setTodaysItems: (items: TimeTrackingItem[]) => void;
};

export const useTimeTrackingStore = create<TimeTrackingStore>()(
  persist(
    (set, get) => ({
      activeEmployeeId: undefined,
      isSyncing: false,
      isTracking: false,
      getIsTracking: () => get().isTracking,
      setIsTracking: (isTracking: boolean) => {
        set({ isTracking, startedAt: isTracking ? new Date() : undefined });
      },
      getStartedAt: () => get().startedAt,
      startSyncing: () => {
        set({ isSyncing: true });
      },
      finishSyncing: () => {
        set({ isSyncing: false });
      },
      getIsSyncing: () => get().isSyncing,
      hasActiveItem: false,
      today: {
        date: new Date(),
        workedHours: 0,
        workedHoursByTimeTypeId: {},
      },
      lastSync: undefined,
      hasUnsyncedItems: false,
      setActiveEmployeeId: (employeeId: Uuid) => {
        set({
          activeEmployeeId: employeeId,
        });
      },
      __dev_setTodaysItems: (items) => {
        set({
          trackedTimePerDate: {
            [formatDate(dayjs())]: items,
          },
        });
      },
      checkAndUpdateUnsyncedItems: () => {
        set((state) => {
          const hasUnsyncedItems = Object.keys(state.trackedTimePerDate).some(
            (key) => {
              const items = state.trackedTimePerDate[key];
              return items.some(
                (item) => item.employeeId === state.activeEmployeeId,
              );
            },
          );
          return {
            hasUnsyncedItems,
          };
        });
      },
      changeLastSync: (
        date: Date,
        success: boolean,
        employeeId: Uuid,
        errors?: string[],
      ) => {
        set({
          lastSync: {
            date,
            success,
            errors,
          },
        });

        if (success) {
          get().resetEmployee(employeeId);
        }

        get().checkAndUpdateUnsyncedItems();
      },
      createTrackedTime: (employeeId, item) => {
        set((state) => {
          const date = formatDate(item.start);
          const trackedTime = state.trackedTimePerDate[date] || [];
          const newTrackedTime = [...trackedTime, item];
          return {
            trackedTimePerDate: {
              ...state.trackedTimePerDate,
              [date]: newTrackedTime,
            },
          };
        });
        get().checkAndUpdateUnsyncedItems();
      },
      updateTrackedTime: (employeeId, timeEntryId, item) => {
        set((state) => {
          const newTrackedTimePerDate = { ...state.trackedTimePerDate };
          Object.keys(state.trackedTimePerDate).forEach((key) => {
            const trackedTime = state.trackedTimePerDate[key];
            const index = trackedTime.findIndex(
              (i) => i.timeEntryId === timeEntryId,
            );

            if (index === -1) {
              return;
            }

            newTrackedTimePerDate[key][index] = {
              ...trackedTime[index],
              ...item,
            };
          });

          return {
            trackedTimePerDate: newTrackedTimePerDate,
          };
        });
        get().checkAndUpdateUnsyncedItems();
      },
      removeTrackedTime: (employeeId, timeEntryId) => {
        set((state) => {
          const newTrackedTimePerDate = { ...state.trackedTimePerDate };
          Object.keys(state.trackedTimePerDate).forEach((key) => {
            if (!newTrackedTimePerDate[key]) {
              return;
            }

            const trackedTime = state.trackedTimePerDate[key];
            newTrackedTimePerDate[key] = trackedTime.filter(
              (i) => i.timeEntryId !== timeEntryId,
            );
          });

          return {
            trackedTimePerDate: newTrackedTimePerDate,
          };
        });
      },
      resetDay: (day: Date) => {
        set((state) => {
          const date = formatDate(day);
          const trackedTime = state.trackedTimePerDate[date] || [];
          const filteredTrackedTime = trackedTime.filter(
            (item) => item.start < day,
          );
          return {
            trackedTimePerDate: {
              ...state.trackedTimePerDate,
              [date]: filteredTrackedTime,
            },
          };
        });
        get().checkAndUpdateUnsyncedItems();
      },
      calculateToday: (employeeId) => {
        const date = formatDate(dayjs());
        const trackedTime = get().trackedTimePerDate[date] || [];

        const workedHoursByTimeTypeId: {
          [key: string]: number;
        } = {};

        let workedHours = 0;
        trackedTime.forEach((item) => {
          if (item.employeeId === employeeId) {
            const diff = dayjs(item.end).diff(dayjs(item.start), 'hours', true);
            workedHoursByTimeTypeId[item.timeTypeId || 'unknown'] =
              (workedHoursByTimeTypeId[item.timeTypeId || 'unknown'] || 0) +
              diff;

            workedHours += diff;
          }
        });
        if (
          Math.round(get().today.workedHours * 60) ===
            Math.round(workedHours * 60) &&
          Object.values(workedHoursByTimeTypeId).length ===
            Object.values(get().today.workedHoursByTimeTypeId).length
        ) {
          return;
        }

        set({
          today: {
            date: new Date(),
            workedHours,
            workedHoursByTimeTypeId,
          },
        });
        get().checkAndUpdateUnsyncedItems();
      },
      reset: () => set({ trackedTimePerDate: {} }),
      resetEmployee: (employeeId: Uuid) => {
        set((value) => {
          const newTrackedTime: { [key: string]: TimeTrackingItem[] } = {};
          Object.keys(value.trackedTimePerDate).forEach((key) => {
            const trackedTime = value.trackedTimePerDate[key];
            const filteredTrackedTime = trackedTime.filter(
              (item) => item.employeeId !== employeeId,
            );

            if (filteredTrackedTime.length > 0) {
              newTrackedTime[key] = filteredTrackedTime;
            }
          });

          return {
            trackedTimePerDate: newTrackedTime,
          };
        });
      },
      trackedTimePerDate: {},
      getWorkedHoursToday: (employeeId: Uuid) => {
        const date = formatDate(dayjs());
        const trackedTime = get().trackedTimePerDate[date] || [];
        return trackedTime
          .map((item) => {
            if (item.employeeId === employeeId) {
              return dayjs(item.end).diff(dayjs(item.start), 'hours', true);
            }
            return 0;
          })
          .reduce((acc, hours) => acc + hours, 0);
      },
      getWorkedHoursTodayByTimeTypeId: (employeeId: Uuid, timeTypeId: Uuid) => {
        const date = formatDate(dayjs());
        const trackedTime = get().trackedTimePerDate[date] || [];
        return trackedTime
          .map((item) => {
            if (
              item.employeeId === employeeId &&
              item.timeTypeId === timeTypeId
            ) {
              return dayjs(item.end).diff(dayjs(item.start), 'hours', true);
            }
            return 0;
          })
          .reduce((acc, hours) => acc + hours, 0);
      },
      startTrackingTime: (employeeId, timeTypeId, start, extraProps, force) => {
        set((state) => {
          const date = formatDate(start);
          const activeItem = state.getActiveItem(employeeId);
          if (activeItem?.timeTypeId !== timeTypeId || force) {
            state.finishActiveItem(employeeId, start);
          }

          const trackedTime = state.trackedTimePerDate[date] || [];
          const timeEntryId = v7();
          trackedTime.push({
            timeEntryId,
            employeeId,
            timeTypeId,
            start,
            ...(extraProps || {}),
          });

          return {
            hasActiveItem: true,
            trackedTimePerDate: {
              ...state.trackedTimePerDate,
              [date]: trackedTime,
            },
          };
        });
        get().checkAndUpdateUnsyncedItems();
      },
      changeCurrentProjectIdAndOrEventId: (projectId, eventId) => {
        set((state) => {
          const activeItem = state.getActiveItem(state.activeEmployeeId || '');
          const activeStart = activeItem?.start;

          if (
            !activeItem ||
            !state.activeEmployeeId ||
            !state.hasActiveItem ||
            !activeStart ||
            !dayjs(activeStart).isSame(dayjs(), 'day')
          ) {
            return {
              currentProjectId: projectId,
              currentEventId: eventId,
            };
          }

          state.startTrackingTime(
            state.activeEmployeeId,
            activeItem.timeTypeId,
            new Date(),
            {
              projectId,
              eventId,
            },
            true,
          );

          return {
            currentProjectId: projectId,
            currentEventId: eventId,
          };
        });
      },
      getActiveItem: (employeeId: Uuid) => {
        const date = formatDate(dayjs());
        const trackedTime = get().trackedTimePerDate[date] || [];
        return trackedTime.find(
          (item) => item.employeeId === employeeId && !item.end,
        );
      },
      getUnfinishedItems: (employeeId: Uuid) =>
        Object.values(get().trackedTimePerDate).reduce((acc, items) => {
          const unfinishedItems = items.filter(
            (item) => item.employeeId === employeeId && !item.end,
          );

          return [...acc, ...unfinishedItems];
        }, [] as TimeTrackingItem[]),
      finishActiveItem: (employeeId, end) => {
        set((state) => {
          const date = formatDate(end);
          const trackedTime = state.trackedTimePerDate[date] || [];
          const activeItem = trackedTime.find(
            (item) => !item.end && item.employeeId === employeeId,
          );
          if (activeItem) {
            activeItem.end = end;
          }

          return {
            hasActiveItem: false,
            trackedTimePerDate: {
              ...state.trackedTimePerDate,
              [date]: trackedTime,
            },
          };
        });
        get().checkAndUpdateUnsyncedItems();
      },
    }),
    {
      name: 'time-tracking-store',
      storage: zustandStorage(),
      version: 2,
      migrate: (persistedState, version) => {
        if (!!persistedState && typeof persistedState !== 'object') {
          return persistedState as TimeTrackingStore;
        }

        if (version === 0 || version === 1) {
          const currentState = persistedState as TimeTrackingStore;

          const newTrackedTimePerDate = currentState.trackedTimePerDate || {};
          Object.keys(newTrackedTimePerDate).forEach((key) => {
            newTrackedTimePerDate[key] = newTrackedTimePerDate[key].map(
              (item) => {
                if (!item.timeEntryId) {
                  return {
                    ...item,
                    timeEntryId: v7(),
                  };
                }

                return item;
              },
            );
          });

          return { ...currentState, trackedTimePerDate: newTrackedTimePerDate };
        }

        return persistedState as TimeTrackingStore;
      },
    },
  ),
);
