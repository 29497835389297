import { Event } from '@bas/planning-domain/models';
import { colors } from '@bas/theme';
import { RenderPersonName, Tooltip, TooltipProps } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { EventType, showEventName } from '@bas/value-objects';
import { faRepeat, faRepeat1 } from '@fortawesome/pro-light-svg-icons';
import { Chip, Grid2, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';
import { EventIcon } from '../EventIcon';

export type AllDayEventProps = {
  event: Event;
  className?: string;
  onDoubleClick?: () => void;
};

const getClassNameByEventType = (eventType: EventType) => {
  switch (eventType) {
    case EventType.SICK_EVENT:
      return 'Bas-AllDayEvent-Sick';
    case EventType.MATERIAL_OTHER_ABSENCE_EVENT:
    case EventType.HRM_OTHER_ABSENCE_EVENT:
    case EventType.DAY_OFF_EVENT:
    case EventType.VACATION_EVENT:
    case EventType.INSPECTION_EVENT:
    case EventType.MAINTENANCE_EVENT:
      return 'Bas-AllDayEvent-Absence';
    default:
      return 'Bas-AllDayEvent-Default';
  }
};

const AllDayEvent = ({
  event,
  className,
  onDoubleClick,
}: AllDayEventProps): ReactElement => {
  const content = (
    <Chip
      onDoubleClick={onDoubleClick}
      label={
        <Grid2 container columnSpacing={2}>
          <Grid2>
            {showEventName(event.eventType) ? (
              event.name
            ) : (
              <FormattedMessage
                id={`planning.events.eventType.${event.eventType}`}
              />
            )}
          </Grid2>
          <Grid2>
            {event.recurringInformation?.recurring && (
              <Icon icon={faRepeat} size="xs" />
            )}
            {!event.recurringInformation?.recurring && event.overridesDay && (
              <Icon icon={faRepeat1} size="xs" />
            )}
          </Grid2>
        </Grid2>
      }
      className={clsx(className, getClassNameByEventType(event.eventType))}
      icon={<EventIcon eventType={event.eventType} />}
    />
  );

  let tooltip: Omit<TooltipProps, 'children'> | undefined;

  if (
    [
      EventType.SICK_EVENT,
      EventType.HRM_OTHER_ABSENCE_EVENT,
      EventType.DAY_OFF_EVENT,
      EventType.VACATION_EVENT,
    ].includes(event.eventType) &&
    event.employees.length > 0
  ) {
    tooltip = {
      arrow: true,
      title: (
        <FormattedList
          value={event.employees.map(
            ({ employee: { employeeId, personName } }) => (
              <RenderPersonName key={employeeId} {...personName} />
            ),
          )}
        />
      ),
    };
  }

  if (
    [
      EventType.MATERIAL_OTHER_ABSENCE_EVENT,
      EventType.INSPECTION_EVENT,
      EventType.MAINTENANCE_EVENT,
    ].includes(event.eventType) &&
    event.materials.length > 0
  ) {
    tooltip = {
      arrow: true,
      title: <FormattedList value={event.materialNames.map((name) => name)} />,
    };
  }

  if (event.recurringInformation?.recurring || event.overridesDay) {
    tooltip = {
      arrow: true,
      title: event.recurringInformation?.recurring ? (
        <FormattedMessage id="label.recurringEvent" />
      ) : (
        <FormattedMessage id="label.overridesDay" />
      ),
    };
  }

  if (!tooltip) {
    return content;
  }

  return <Tooltip {...tooltip}>{content}</Tooltip>;
};

const StyledAllDayEvent = styled(AllDayEvent)(
  ({ theme }) => `
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold !important;
  border-radius: 5px;
  cursor: pointer;

  .MuiChip-label {
    padding: 0;
    padding-left: 6px;
  }

  .MuiChip-icon {
    margin: 0;
  }

  &.Bas-AllDayEvent-Default {
    background: ${colors.blue[100]};
    &, svg {
      color: ${colors.blue[500]};
    }
  }

  &.Bas-AllDayEvent-Sick {
    background: ${colors.pink['200']};
    &, svg {
      color: ${colors.red['500']};
    }
  }

  &.Bas-AllDayEvent-Absence {
    background: #FFF4EB;
    &, svg {
      color: #F99132;
    }
  }
`,
);
export default StyledAllDayEvent;
