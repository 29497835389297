import { Pagination, TablePagination } from '@bas/ui/web/molecules';
import { Grid2 } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, MouseEvent } from 'react';

export type FullPaginationProps = {
  page: number;
  itemCount: number;
  rowsPerPage: number;
  onPageChange: (
    event: MouseEvent<HTMLButtonElement> | ChangeEvent<unknown> | null,
    page: number,
  ) => void;
  onRowsPerPageChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  rowsPerPageOptions?: Array<number>;
  hideNextButton?: boolean;
  hideLastButton?: boolean;
  siblingCount?: number;
  boundaryCount?: number;
};

const FullPagination = ({
  page,
  itemCount,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 25, 50],
  onPageChange,
  onRowsPerPageChange,
  hideNextButton = true,
  hideLastButton = true,
  siblingCount = 1,
  boundaryCount = 0,
}: FullPaginationProps) => (
  <Grid2 container alignItems="center" justifyContent="space-between">
    <Grid2>
      <Pagination
        page={page}
        count={Math.ceil(itemCount / rowsPerPage)}
        onChange={(e, pageIndex) => onPageChange(e, pageIndex)}
        shape="rounded"
        siblingCount={siblingCount}
        boundaryCount={boundaryCount}
        showFirstButton
        showLastButton
      />
    </Grid2>
    <Grid2>
      <TablePagination
        page={page - 1}
        count={itemCount}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={(e, pageIndex) => onPageChange(e, pageIndex + 1)}
        onRowsPerPageChange={onRowsPerPageChange}
        hideNextButton={hideNextButton}
        hideLastButton={hideLastButton}
      />
    </Grid2>
  </Grid2>
);

export default FullPagination;
