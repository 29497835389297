import {
  ClaResultsPerWeek,
  isClaAllowanceRule,
  isClaCompensationRule,
  isTimeEntry,
  isTimeOffRegistration,
} from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ClaResultsPerWeekByPeriodRequestProps = {
  startDate: Date;
  endDate: Date;
};

type Response = AxiosResponse<Collection<ClaResultsPerWeek>>;

export const ClaResultsPerWeekByPeriodRequest = async ({
  startDate,
  endDate,
  ...params
}: ClaResultsPerWeekByPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/employees/cla-results-per-week/${formatDate(startDate)}/${formatDate(
      endDate,
    )}`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useClaResultsPerWeekByPeriodRequest = (
  { startDate, endDate, ...request }: ClaResultsPerWeekByPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ClaResultsPerWeekByPeriodRequest({ ...request, startDate, endDate }),
    queryKey: [
      'cla-results-per-week',
      'list',
      formatDate(startDate),
      formatDate(endDate),
      request,
    ],
  });

export const ClaResultsPerWeekByPeriodRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (
    isTimeOffRegistration(data) ||
    isTimeEntry(data) ||
    isClaAllowanceRule(data) ||
    isClaCompensationRule(data)
  ) {
    queryClient.invalidateQueries({
      queryKey: ['cla-results-per-week', 'list', data],
    });
  }
};
