import { TranslatedName } from '@bas/value-objects';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useGetTranslatedName = () => {
  const intl = useIntl();

  return useCallback(
    (translations: TranslatedName[], fallback: string) => {
      const translation = translations.find(
        ({ locale }) => locale.toLowerCase() === intl.locale.toLowerCase(),
      );

      if (translation) {
        return translation.name;
      }

      return fallback;
    },
    [intl],
  );
};
