import { WhatsAppSettings } from '@bas/integration-domain/models';
import { ContentSection } from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { WhatsAppAccountReviewStatusChip } from '../WhatsAppAccountReviewStatusChip';

export type ConnectedWhatsAppBusinessesBlockProps = {
  providerSettings: WhatsAppSettings;
};

const ConnectedWhatsAppBusinessesBlock = ({
  providerSettings,
}: ConnectedWhatsAppBusinessesBlockProps): ReactElement => (
  <ContentSection actions={[]}>
    <Grid2 container columnSpacing={3}>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.connectedWhatsAppBusinesses" />
        </Typography>
      </Grid2>
      <Grid2 container spacing={1} size={12}>
        {' '}
        {providerSettings.businesses.map((business) => (
          <Grid2 container key={business.id} size={12}>
            <Grid2 size={6}>{business.name}</Grid2>
            <Grid2 size={3}>
              <WhatsAppAccountReviewStatusChip
                businessStatus={business.account_review_status}
              />
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default ConnectedWhatsAppBusinessesBlock;
