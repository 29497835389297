import {
  DeclarationCategory,
  isDeclarationCategory,
} from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DeclarationCategoryByCategoryIdProps = {
  categoryId: Uuid;
};

export const DeclarationCategoryByCategoryId = async ({
  categoryId,
  ...params
}: DeclarationCategoryByCategoryIdProps): Promise<
  AxiosResponse<DeclarationCategory>
> =>
  axios.get(`api/{tenantId}/hrm/declaration-categories/${categoryId}`, {
    params,
  });

export const useDeclarationCategoryByCategoryId = (
  request: DeclarationCategoryByCategoryIdProps,
  options: QueryOptionsWithKey<
    AxiosResponse<DeclarationCategory>,
    AxiosError,
    AxiosResponse<DeclarationCategory>
  > = {},
): UseQueryResult<AxiosResponse<DeclarationCategory>, AxiosError> =>
  useQuery<
    AxiosResponse<DeclarationCategory>,
    AxiosError,
    AxiosResponse<DeclarationCategory>
  >({
    ...options,
    queryFn: async () => DeclarationCategoryByCategoryId(request),
    queryKey: ['declaration-categories', 'detail', request.categoryId],
  });

export const DeclarationCategoryByCategoryIdInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isDeclarationCategory(data)) {
    queryClient.invalidateQueries({
      queryKey: ['declaration-categories', 'detail', data.categoryId],
    });
  }
};
