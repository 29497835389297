import { colors } from '@bas/theme';
import { Slider, SliderProps, styled } from '@mui/material';
import { ReactElement } from 'react';

export type WorkingDaySliderProps = SliderProps;

const WorkingDaySlider = ({
  orientation = 'vertical',
  max = 140,
  step = 5,
  marks = [
    {
      value: 0,
    },
    {
      value: 10,
    },
    {
      value: 20,
    },
    {
      value: 30,
    },
    {
      value: 40,
    },
    {
      value: 50,
    },
    {
      value: 60,
    },
    {
      value: 70,
    },
    {
      value: 80,
    },
    {
      value: 90,
    },
    {
      value: 100,
    },
    {
      value: 110,
    },
    {
      value: 120,
    },
    {
      value: 130,
    },
    {
      value: 140,
    },
  ],
  ...args
}: WorkingDaySliderProps): ReactElement => (
  <Slider
    {...args}
    orientation={orientation}
    max={max}
    step={step}
    marks={marks}
  />
);

const StyledWorkingDaySlider = styled(WorkingDaySlider)(
  ({ theme }) => `
    color: ${colors.blue[500]};
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: 0;

    .MuiSlider-thumb ~ .MuiSlider-thumb {
      margin-bottom: -11px;
    }

    .MuiSlider-thumb {
      z-index: 2;
      transition: none;
      width: 20px;
      border-radius: 0;
      margin-bottom: 10px;
      height: 4px;
      border-top: 1px solid ${colors.blue[200]};
      border-bottom: 1px solid ${colors.blue[200]};

      &:before {
        top: -4px;
        background: ${colors.blue[200]};
        height: 1px;
      }

      opacity: 0.5;
      &:focus, &:hover, &.Mui-active, &:before {
        box-shadow: none;
      }
    }

    .MuiSlider-track {
      border-radius: 3px;
      border-right: 0;
      border-left: 0;
      z-index: 1;
    }

     &.Mui-disabled {
       .MuiSlider-mark {
          color: ${colors.lila[200]};
       }

       .MuiSlider-track {
          display: none;
       }

       .MuiSlider-thumb {
          background: none;
          opacity: 0;
       }

       .MuiSlider-markActive {
         background-color: ${colors.lila[200]};
       }
     }

    .MuiSlider-mark {
      width: 100%;
      height: 1px;
      color: ${colors.lila[400]};
    }

    .MuiSlider-rail {
      color: ${theme.palette.common.white};
      opacity: 1;
      width: 100%;
    }
`,
);
export default StyledWorkingDaySlider;
