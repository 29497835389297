import { ReactHookFormTextField } from '@bas/ui/web/molecules';
import { Grid2 } from '@mui/material';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const ConnectReeleezeeForm = (): ReactElement => (
  <Grid2 container>
    <Grid2 size={12}>
      <Controller
        name="username"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.username" />}
          />
        )}
      />
    </Grid2>
    <Grid2 size={12}>
      <Controller
        name="password"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.password" />}
            type="password"
          />
        )}
      />
    </Grid2>
  </Grid2>
);

export default ConnectReeleezeeForm;
