import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { isNews, News } from '@bas/tenant-domain/models';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type NewsByNewsIdRequestProps = {
  newsId: Uuid;
};

export const NewsByNewsIdRequest = async ({
  newsId,
  ...params
}: NewsByNewsIdRequestProps): Promise<AxiosResponse<News>> =>
  axios.get(`api/{tenantId}/news/${newsId}`, {
    params: {
      ...params,
    },
  });

export const useNewsByNewsIdRequest = (
  request: NewsByNewsIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<News>,
    AxiosError<ErrorResponse>,
    AxiosResponse<News>
  > = {},
): UseQueryResult<AxiosResponse<News>, AxiosError<ErrorResponse>> =>
  useQuery<AxiosResponse<News>, AxiosError<ErrorResponse>, AxiosResponse<News>>(
    {
      ...options,
      queryFn: async () => NewsByNewsIdRequest(request),
      queryKey: ['news', 'details', request.newsId],
    },
  );

export const NewsByNewsIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isNews(data)) {
    queryClient.invalidateQueries({
      queryKey: ['news', 'details', data.newsId],
    });
  }
};
