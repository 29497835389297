import {
  ReeleezeeSettingsInputType,
  ReeleezeeSettingsInputTypeDefaultValues,
  ReeleezeeSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import {
  Integration,
  isReeleezeeSettings,
} from '@bas/integration-domain/models';
import { ReeleezeeSettingsContentSection } from '@bas/integration-domain/web/molecules';
import {
  ContentSection,
  ContentSectionEditAction,
  ReactHookForm,
} from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditingReeleezeeSettingsContentSection } from '../EditingReeleezeeSettingsContentSection';

export type EditableReeleezeeSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: ReeleezeeSettingsInputType) => Promise<void>;
};

const EditableReeleezeeSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableReeleezeeSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<ReeleezeeSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          ...ReeleezeeSettingsInputTypeDefaultValues(),
          ...integration.providerSettings,
        }}
        validationSchema={ReeleezeeSettingsInputTypeValidationBuilder}
      >
        <EditingReeleezeeSettingsContentSection
          integrationId={integration.integrationId}
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  if (!isReeleezeeSettings(integration.providerSettings)) {
    return (
      <ContentSection
        actions={[
          <ContentSectionEditAction onClick={() => setIsEditing(true)} />,
        ]}
      >
        <Grid2 container columnSpacing={3}>
          <Grid2 size={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.settings" />
            </Typography>
          </Grid2>
          <Grid2 size={6}>
            <Typography>
              <FormattedMessage id="label.integrationIsNotSetUpYet" />
            </Typography>
          </Grid2>
        </Grid2>
      </ContentSection>
    );
  }

  return (
    <ReeleezeeSettingsContentSection
      onEdit={() => setIsEditing(true)}
      integrationId={integration.integrationId}
      providerSettings={integration.providerSettings}
    />
  );
};

export default EditableReeleezeeSettingsBlock;
