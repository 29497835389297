import { HoursOverview } from '@bas/planning-domain/models';
import { calculatePercentagesFromHours, formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Avatar, AvatarSizeType, Tooltip } from '@bas/ui/web/atoms';
import { Box, BoxProps, Grid2, Skeleton, styled } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StackedCircularProgress } from '../StackedCircularProgress';

export type AvailabilityResourceProps = BoxProps &
  HoursOverview & {
    className?: string;
    name: string;
    shortName?: string;
    fullName?: string;
    src?: string;
    loading?: boolean;
    isActive?: boolean;
  };

const AvailabilityResource = forwardRef<unknown, AvailabilityResourceProps>(
  (
    {
      loading,
      src,
      name,
      fullName,
      shortName,
      isActive,
      className,
      totalHoursOfDay,
      totalAvailableHours,
      totalReservedHours,
      totalConfirmedHours,
      totalShortageHours,
      realConfirmedHours,
      realReservedHours,
      ...props
    }: AvailabilityResourceProps,
    ref,
  ): ReactElement => {
    const { availablePercentage, reservedPercentage, confirmedPercentage } =
      useMemo(
        () =>
          calculatePercentagesFromHours(
            totalHoursOfDay,
            totalConfirmedHours,
            totalReservedHours,
          ),
        [totalHoursOfDay, totalReservedHours, totalConfirmedHours],
      );

    let content = (
      <span>
        <StackedCircularProgress
          className="Bas-StackedCircularProgress"
          variant="determinate"
          values={[
            {
              value: reservedPercentage,
              color: colors.yellow[700],
            },
            {
              value:
                confirmedPercentage > 0
                  ? confirmedPercentage + reservedPercentage
                  : 0,
              color: colors.blue[500],
            },
            {
              value: 100 - availablePercentage,
              color: colors.red[200],
            },
          ]}
          size={52}
          sx={{
            position: 'absolute',
            top: '-4px',
            left: '-4px',
          }}
        >
          <Avatar
            src={src}
            name={name}
            shortName={shortName}
            size={AvatarSizeType.SMALL}
          />
        </StackedCircularProgress>
      </span>
    );

    if (!isActive) {
      content = (
        <Tooltip
          disabled={isActive}
          title={
            <Grid2 container>
              <Grid2 fontWeight="bold" pb={2} size={12}>
                {fullName || name}
              </Grid2>
              <Grid2 size={8}>
                <FormattedMessage id="label.availableHours" />
              </Grid2>
              <Grid2 size={4}>+ {formatHours(totalHoursOfDay)}</Grid2>
              {realReservedHours > 0 && (
                <>
                  <Grid2 size={8}>
                    <FormattedMessage id="label.reservedHours" />
                  </Grid2>
                  <Grid2 size={4}>- {formatHours(realReservedHours)}</Grid2>
                </>
              )}
              {realConfirmedHours > 0 && (
                <>
                  <Grid2 size={8}>
                    <FormattedMessage id="label.confirmedHours" />
                  </Grid2>
                  <Grid2 size={4}>- {formatHours(realConfirmedHours)}</Grid2>
                </>
              )}
              {totalShortageHours < 0 ? (
                <>
                  <Grid2 fontWeight="bold" size={8}>
                    <FormattedMessage id="label.overtime" />
                  </Grid2>
                  <Grid2 fontWeight="bold" size={4}>
                    = {formatHours(Math.abs(totalShortageHours))}
                  </Grid2>
                </>
              ) : (
                <>
                  <Grid2 fontWeight="bold" size={8}>
                    <FormattedMessage id="label.plannableHours" />
                  </Grid2>
                  <Grid2 fontWeight="bold" size={4}>
                    = {formatHours(totalAvailableHours)}
                  </Grid2>
                </>
              )}
            </Grid2>
          }
        >
          {content}
        </Tooltip>
      );
    }

    return (
      <Box
        ref={ref}
        className={clsx(className, {
          'Bas-AvailabilityResource--IsActive': isActive,
        })}
        {...props}
      >
        {!loading && content}
        {loading && <Skeleton variant="circular" height={44} width={44} />}
      </Box>
    );
  },
);

const StyledAvailabilityResource = styled(AvailabilityResource)(
  ({ theme }) => `
    padding: ${theme.spacing(0, 1)};
    height: 52px;
`,
);
export default StyledAvailabilityResource;
