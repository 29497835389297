import {
  EmployeeContractBlock,
  WorkingHoursContentSection,
} from '@bas/hrm-domain/web/molecules';
import { ExternalEmployee } from '@bas/integration-domain/models';
import { useExternalContractByEmployeeIdRequest } from '@bas/integration-domain/requests';
import { AddressBlock } from '@bas/ui/web/atoms';
import { Grid2, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

type ExtraExternalEmployeeInformationProps = {
  externalEmployee: ExternalEmployee;
};

const ExtraExternalEmployeeInformation = ({
  externalEmployee,
}: ExtraExternalEmployeeInformationProps): ReactElement => {
  const { data: contractsData } = useExternalContractByEmployeeIdRequest({
    integrationId: externalEmployee.integrationId,
    externalEmployeeId: externalEmployee.externalId,
  });

  const contracts = useMemo(
    () => contractsData?.data?.member || [],
    [contractsData],
  );

  return (
    <Grid2 container spacing={2}>
      <Grid2 container columnSpacing={1} size={12}>
        <Grid2 size={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.externalEmployeeInformation" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.employeeNumber" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.employeeNumber || (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.name" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>{externalEmployee.personName.fullName}</Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.gender" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage
              id={`genders.${externalEmployee.gender || 'unknown'}`}
            />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.dateOfBirth" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.dateOfBirth ? (
              <FormattedDate
                value={externalEmployee.dateOfBirth}
                dateStyle="short"
              />
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.phoneNumber" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.contactInformation.phoneNumber ||
              externalEmployee.contactInformation.mobileNumber || (
                <FormattedMessage id="label.unknown" />
              )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.emailAddress" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.contactInformation.emailAddress || (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            <FormattedMessage id="label.address" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.address?.streetName ? (
              <AddressBlock
                address={externalEmployee.address}
                variant="single_line"
              />
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.employmentStartDate" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.employmentStartDate ? (
              <FormattedDate
                value={externalEmployee.employmentStartDate}
                dateStyle="short"
              />
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.employmentEndDate" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.employmentEndDate ? (
              <FormattedDate
                value={externalEmployee.employmentEndDate}
                dateStyle="short"
              />
            ) : (
              <FormattedMessage id="label.indefinite" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.probationDate" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {externalEmployee.probationDate ? (
              <FormattedDate
                value={externalEmployee.probationDate}
                dateStyle="short"
              />
            ) : (
              <FormattedMessage id="label.none" />
            )}
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <Typography>
          <WorkingHoursContentSection
            workingHours={externalEmployee.workingHours}
          />
        </Typography>
      </Grid2>
      <Grid2 container size={12} spacing={0}>
        <Grid2 size={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.contracts" />
          </Typography>
        </Grid2>
        <Grid2 container spacing={3} size={12}>
          {contracts.length > 0 &&
            contracts
              .sort((contractA, contractB) =>
                dayjs(contractB.startDate).diff(contractA.startDate),
              )
              .map((contract) => (
                <Grid2 key={contract.externalId}>
                  <EmployeeContractBlock contract={contract} />
                </Grid2>
              ))}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ExtraExternalEmployeeInformation;
