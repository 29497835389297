import {
  ExactGlobeSettingsInputType,
  ExactGlobeSettingsInputTypeDefaultValues,
  ExactGlobeSettingsInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import {
  Integration,
  isExactGlobeSettings,
} from '@bas/integration-domain/models';
import { ExactGlobeSettingsContentSection } from '@bas/integration-domain/web/molecules';
import {
  ContentSection,
  ContentSectionEditAction,
  ReactHookForm,
} from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditingExactGlobeSettingsContentSection } from '../EditingExactGlobeSettingsContentSection';

export type EditableExactGlobeSettingsBlockProps = {
  integration: Integration;
  onUpdateSettings: (values: ExactGlobeSettingsInputType) => Promise<void>;
};

const EditableExactGlobeSettingsBlock = ({
  integration,
  onUpdateSettings,
}: EditableExactGlobeSettingsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<ExactGlobeSettingsInputType>
        name="edit-settings"
        onSubmit={async (values) => {
          await onUpdateSettings(values);
          setIsEditing(false);
        }}
        useProvider
        defaultValues={{
          ...ExactGlobeSettingsInputTypeDefaultValues(),
          ...integration.providerSettings,
        }}
        validationSchema={ExactGlobeSettingsInputTypeValidationBuilder}
      >
        <EditingExactGlobeSettingsContentSection
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  if (!isExactGlobeSettings(integration.providerSettings)) {
    return (
      <ContentSection
        actions={[
          <ContentSectionEditAction onClick={() => setIsEditing(true)} />,
        ]}
      >
        <Grid2 container columnSpacing={3}>
          <Grid2 size={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.settings" />
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography>
              <FormattedMessage id="label.integrationIsNotSetUpYet" />
            </Typography>
          </Grid2>
        </Grid2>
      </ContentSection>
    );
  }

  return (
    <ExactGlobeSettingsContentSection
      onEdit={() => setIsEditing(true)}
      providerSettings={integration.providerSettings}
    />
  );
};

export default EditableExactGlobeSettingsBlock;
