import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type VerifyAllExternalInvoicesMutationProps = {
  integrationId: Uuid;
};

export const VerifyAllExternalInvoicesMutation = async ({
  integrationId,
}: VerifyAllExternalInvoicesMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/integrations/${integrationId}/verify-all-invoices`,
    {},
  );

export const useVerifyAllExternalInvoicesMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    VerifyAllExternalInvoicesMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  VerifyAllExternalInvoicesMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    VerifyAllExternalInvoicesMutationProps
  >({
    mutationFn: VerifyAllExternalInvoicesMutation,
    throwOnError: false,
    ...options,
  });
