import { Icon } from '@bas/ui/web/base';
import { faFilterCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, ReactNode } from 'react';
import { FormattedDate, FormattedList, FormattedMessage } from 'react-intl';

export type BasedUponFiltersType = {
  [key: string]:
    | string
    | number
    | Date
    | string[]
    | boolean
    | { [key: string]: string | ReactNode };
};

export type BasedUponFiltersProps = {
  filters: BasedUponFiltersType;
  onResetFilters?: () => void;
  skipQuotes?: boolean;
  isOr?: boolean;
};

const BasedUponFilters = ({
  filters,
  onResetFilters,
  skipQuotes,
  isOr,
}: BasedUponFiltersProps): ReactElement => {
  const result: ReactNode[] = [];

  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      const value = filters[key];
      let resultingValue: ReactNode | undefined;
      if (value instanceof Date || dayjs.isDayjs(value)) {
        resultingValue = (
          <FormattedDate
            value={dayjs.isDayjs(value) ? value.toDate() : value}
            dateStyle="short"
          />
        );
      } else if (Array.isArray(value)) {
        resultingValue = <FormattedList value={value} />;
      } else if (typeof value === 'object') {
        resultingValue = (
          <FormattedList
            value={Object.values(value).map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={{ textTransform: 'lowercase' }}>
                {item}
              </span>
            ))}
          />
        );
      } else {
        resultingValue = filters[key] as ReactNode;
      }

      result.push(
        <span key={key}>
          <span style={{ textTransform: 'lowercase' }}>
            &ldquo;
            <FormattedMessage id={`label.${key}`} />
            &rdquo;
          </span>
          {typeof value !== 'boolean' && (
            <span>
              &nbsp;{!skipQuotes && <>&ldquo;</>}
              {resultingValue}
              {!skipQuotes && <>&rdquo;</>}
            </span>
          )}
        </span>,
      );
    }
  });

  return (
    <span>
      <FormattedList
        value={result}
        type={isOr ? 'disjunction' : 'conjunction'}
      />
      &nbsp;
      {!!onResetFilters && (
        <IconButton onClick={onResetFilters}>
          <Icon icon={faFilterCircleXmark} />
        </IconButton>
      )}
    </span>
  );
};

export default BasedUponFilters;
