import { colors, fontSizesWeb } from '@bas/theme';
import {
  Avatar,
  AvatarProps,
  AvatarSizeType,
  Draggable,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
  ListItemText,
} from '@bas/ui/web/atoms';
import { UseDraggableArguments } from '@dnd-kit/core';
import { ListItemAvatar, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { EmployeeListItemVariant } from './enum';

export type EmployeeListItemProps = Omit<
  ListItemProps,
  'selected' | 'draggable'
> & {
  selected?: boolean;
  primary: string | ReactNode;
  secondary?: string | ReactNode;
  button?: ListItemButtonProps;
  avatar: AvatarProps;
  onClick?: () => void | Promise<void>;
  variant?: EmployeeListItemVariant;
  draggable?: UseDraggableArguments;
};

const EmployeeListItem = ({
  primary,
  secondary,
  avatar,
  onClick,
  button,
  selected,
  className,
  draggable,
  disableGutters = true,
  ...args
}: EmployeeListItemProps): ReactElement => {
  const content = (
    <>
      <ListItemAvatar>
        <Avatar size={AvatarSizeType.SMALL} {...avatar} />
      </ListItemAvatar>
      <ListItemText primary={primary} secondary={secondary} />
    </>
  );

  let result: JSX.Element | null = null;
  if (onClick) {
    result = (
      <ListItemButton onClick={onClick} selected={selected} {...button}>
        {content}
      </ListItemButton>
    );
  } else {
    result = content;
  }

  if (draggable) {
    return (
      <Draggable draggableProps={draggable}>
        {({ setNodeRef, listeners, attributes }) => (
          <ListItem
            {...args}
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            className={clsx(className, 'Bas-EmployeeListItem')}
            disableGutters={disableGutters}
          >
            {result}
          </ListItem>
        )}
      </Draggable>
    );
  }

  return (
    <ListItem
      {...args}
      disableGutters={disableGutters}
      className={clsx(className, 'Bas-EmployeeListItem')}
    >
      {result}
    </ListItem>
  );
};

const StyledEmployeeListItem = styled(EmployeeListItem)(({
  theme,
  variant = EmployeeListItemVariant.DEFAULT,
}) => {
  let variantStyle = ``;

  if (variant === EmployeeListItemVariant.DEFAULT) {
    variantStyle = `
      box-sizing: border-box;
      padding: ${theme.spacing(2, 3)};
      border-color: ${colors.lila[300]};
      border-style: solid;
      border-width: 0;
      border-bottom-width: 1px;

      &:first-of-type {
        border-top-width: 1px;
      }

      .MuiListItemAvatar-root {
         padding-right: 4px;
      }

      .MuiTypography-root {
        color: ${colors.lila[800]};
        font-size: ${fontSizesWeb.base};
      }

      .MuiListItemText-primary {
        font-weight: bold;
        line-height: 21px;
        .Bas-EmployeeListItem-Match {
          color: ${theme.palette.common.black};
        }
      }

      .MuiListItemText-secondary {
        line-height: 22px;
      }
    `;
  } else if (variant === EmployeeListItemVariant.MOBILE) {
    variantStyle = `
      box-sizing: border-box;
      border-color: ${colors.lila[300]};
      border-style: solid;
      padding: 14px 0;
      border-width: 0;
      border-bottom-width: 1px;

      &:first-of-type {
        border-top-width: 1px;
      }

      .MuiListItemText-multiline {
        display: flex;
        flex-direction: row;
      }

      .MuiTypography-root {
        color: ${colors.lila[800]};
        font-size: ${fontSizesWeb.lg};
        line-height: 26px;
      }

      .MuiListItemText-primary {
        font-weight: bold;
        order: 2;
        margin-left: ${theme.spacing(1)};
      }

      .MuiListItemText-secondary {
        order: 1;
        margin-left: ${theme.spacing(1)};
      }
    `;
  }

  return `
    ${variantStyle}
    `;
});
export default StyledEmployeeListItem;
