import { TurnoverGroup } from '@bas/financial-domain/models';
import { colors } from '@bas/theme';
import {
  TableCell,
  TableRow,
  TableRowProps,
  TextFieldNumberFormat,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ReactHookFormTextField } from '@bas/ui/web/molecules';
import {
  faArrowDown,
  faArrowUp,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { Grid2, IconButton, MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type PercentageBillingMomentLineFormProps = TableRowProps & {
  index: number;
  prefix: string;
  turnoverGroups: TurnoverGroup[];
  remove: (index: number) => void;
  allowMoveUp: boolean;
  allowMoveDown: boolean;
  moveUp: () => void;
  moveDown: () => void;
};

const PercentageBillingMomentLineForm = ({
  index,
  prefix,
  turnoverGroups,
  remove,
  allowMoveDown,
  allowMoveUp,
  moveDown,
  moveUp,
  ...args
}: PercentageBillingMomentLineFormProps): ReactElement => (
  <>
    <TableRow {...args}>
      <TableCell>
        <Grid2 container spacing={1}>
          <Grid2
            sx={{
              color: allowMoveUp ? colors.lila[400] : 'inherit',
            }}
          >
            <Icon
              icon={faArrowUp}
              onClick={allowMoveUp ? undefined : moveUp}
              fontSize={20}
            />
          </Grid2>
          <Grid2
            sx={{
              color: allowMoveDown ? colors.lila[400] : 'inherit',
            }}
          >
            <Icon
              icon={faArrowDown}
              onClick={allowMoveDown ? undefined : moveDown}
              fontSize={20}
            />
          </Grid2>
        </Grid2>
      </TableCell>
      <TableCell>
        <Controller
          name={`${prefix}.invoiceLines.${index}.description`}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              debounce
              multiline
              variant="filled"
            />
          )}
        />
      </TableCell>
      <TableCell width="61px">
        <Controller
          name={`${prefix}.invoiceLines.${index}.percentage`}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              debounce
              variant="filled"
              InputProps={{
                inputComponent: TextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 2,
                  suffix: '%',
                },
              }}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => remove(index)}>
          <Icon icon={faTrashCan} />
        </IconButton>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell />
      <TableCell>
        <Controller
          name={`${prefix}.invoiceLines.${index}.turnoverGroupId`}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              debounce
              select
              label={<FormattedMessage id="label.turnoverGroup" />}
              variant="filled"
              InputLabelProps={{ shrink: true }}
              className="Bas-Select-WithLabel"
            >
              <MenuItem value="">
                <FormattedMessage id="label.none" />
              </MenuItem>
              {turnoverGroups.map((turnoverGroup) => (
                <MenuItem
                  key={turnoverGroup.turnoverGroupId}
                  value={turnoverGroup.turnoverGroupId}
                >
                  {turnoverGroup.name}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </TableCell>
    </TableRow>
  </>
);

export default PercentageBillingMomentLineForm;
