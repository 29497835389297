import {
  eventsWithOptionalLocation,
  eventsWithoutDepartureLocation,
  eventsWithoutLocation,
  eventsWithoutPage,
  eventsWithoutShowingAddresses,
  eventTypesThatAreLessImportant,
  eventTypesThatUnavailable,
  eventTypesWithoutName,
  movingEventTypes,
  transportEventTypes,
} from '../constants';
import { EmployeeProjectRole, EventType, ProjectType } from '../types';

export const hasEventPage = (eventType: EventType): boolean =>
  !eventsWithoutPage.includes(eventType);

export const showEventLocation = (eventType: EventType): boolean =>
  !eventsWithoutLocation.includes(eventType);

export const optionalDepartureLocation = (eventType: EventType): boolean =>
  eventsWithoutDepartureLocation.includes(eventType);

export const isLocationOptional = (eventType: EventType): boolean =>
  eventsWithOptionalLocation.includes(eventType);

export const showEventName = (eventType: EventType): boolean =>
  !eventTypesWithoutName.includes(eventType);

export const isMovingEvent = (eventType: EventType): boolean =>
  movingEventTypes.includes(eventType);

export const isTransportEvent = (eventType: EventType): boolean =>
  transportEventTypes.includes(eventType);

export const isMovingLiftEvent = (eventType: EventType): boolean =>
  eventType === EventType.MOVING_LIFT_EVENT;

export const isLessImportantEvent = (eventType: EventType): boolean =>
  eventTypesThatAreLessImportant.includes(eventType);

export const isUnavailableEvent = (eventType: EventType): boolean =>
  eventTypesThatUnavailable.includes(eventType);

export const isEventWithoutShowingAddresses = (eventType: EventType): boolean =>
  eventsWithoutShowingAddresses.includes(eventType);

export const getPlannableRoles = (
  eventType: EventType,
): EmployeeProjectRole[] => {
  switch (eventType) {
    case EventType.MOVING_EVENT:
    case EventType.LOAD_STORAGE_EVENT:
    case EventType.UNLOAD_STORAGE_EVENT:
    case EventType.MOVING_LOADING_EVENT:
    case EventType.MOVING_UNLOADING_EVENT:
      return [
        EmployeeProjectRole.FOREMAN,
        EmployeeProjectRole.CO_DRIVER,
        EmployeeProjectRole.HANDYMAN,
        EmployeeProjectRole.PACKER,
      ];

    case EventType.DELIVER_BOXES_EVENT:
    case EventType.PICKUP_BOXES_EVENT:
    case EventType.POST_ROAD_SIGNS_EVENT:
      return [EmployeeProjectRole.CO_DRIVER];

    case EventType.ASSEMBLE_EVENT:
    case EventType.DISASSEMBLE_EVENT:
      return [EmployeeProjectRole.FOREMAN, EmployeeProjectRole.HANDYMAN];

    case EventType.UNPACKING_EVENT:
    case EventType.PACKING_EVENT:
      return [EmployeeProjectRole.FOREMAN, EmployeeProjectRole.PACKER];

    case EventType.PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT:
      return [
        EmployeeProjectRole.FOREMAN,
        EmployeeProjectRole.CO_DRIVER,
        EmployeeProjectRole.HANDYMAN,
      ];

    case EventType.MOVING_LIFT_EVENT:
    case EventType.LIFT_WORK_EVENT:
      return [
        EmployeeProjectRole.FOREMAN,
        EmployeeProjectRole.LIFT_TRUCK,
        EmployeeProjectRole.CO_DRIVER,
      ];

    case EventType.TRANSPORT_JOB_DELIVER:
    case EventType.TRANSPORT_JOB_PICKUP:
    case EventType.DRIVING_DAY:
      return [
        EmployeeProjectRole.FOREMAN,
        EmployeeProjectRole.CO_DRIVER,
        EmployeeProjectRole.ATTENDEE,
      ];

    case EventType.DISPOSING_OF_WASTE_EVENT:
      return [EmployeeProjectRole.FOREMAN, EmployeeProjectRole.CO_DRIVER];

    case EventType.INTAKE_EVENT:
      return [EmployeeProjectRole.APPRAISER];

    case EventType.LENDING_STAFF_EVENT:
      return Object.values(EmployeeProjectRole);

    default:
      return [EmployeeProjectRole.ATTENDEE];
  }
};

export const getMainRoleForEventType = (
  eventType: EventType,
): EmployeeProjectRole => {
  switch (eventType) {
    case EventType.INTAKE_EVENT:
      return EmployeeProjectRole.APPRAISER;
    case EventType.ASSEMBLE_EVENT:
    case EventType.DISASSEMBLE_EVENT:
      return EmployeeProjectRole.HANDYMAN;
    case EventType.PACKING_EVENT:
    case EventType.UNPACKING_EVENT:
      return EmployeeProjectRole.PACKER;
    case EventType.MOVING_LIFT_EVENT:
    case EventType.LIFT_WORK_EVENT:
      return EmployeeProjectRole.LIFT_TRUCK;
    default:
      return getPlannableRoles(eventType).includes(
        EmployeeProjectRole.CO_DRIVER,
      )
        ? EmployeeProjectRole.CO_DRIVER
        : EmployeeProjectRole.ATTENDEE;
  }
};

export const getEventTypesThatAreAllowToBeChangedToEachOther = (
  eventType: EventType,
): EventType[] => {
  switch (eventType) {
    case EventType.INTAKE_EVENT:
    case EventType.PICKUP_BOXES_EVENT:
    case EventType.MOVING_LIFT_EVENT:
    case EventType.MOVING_EVENT:
    case EventType.DELIVER_BOXES_EVENT:
    case EventType.ASSEMBLE_EVENT:
    case EventType.UNPACKING_EVENT:
    case EventType.POST_ROAD_SIGNS_EVENT:
    case EventType.DISPOSING_OF_WASTE_EVENT:
    case EventType.PACKING_EVENT:
    case EventType.REMOVE_ROAD_SIGNS_EVENT:
    case EventType.UNLOAD_STORAGE_EVENT:
    case EventType.DISASSEMBLE_EVENT:
    case EventType.LOAD_STORAGE_EVENT:
    case EventType.PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT:
      return [
        EventType.INTAKE_EVENT,
        EventType.PICKUP_BOXES_EVENT,
        EventType.MOVING_LIFT_EVENT,
        EventType.MOVING_EVENT,
        EventType.DELIVER_BOXES_EVENT,
        EventType.ASSEMBLE_EVENT,
        EventType.UNPACKING_EVENT,
        EventType.POST_ROAD_SIGNS_EVENT,
        EventType.DISPOSING_OF_WASTE_EVENT,
        EventType.PACKING_EVENT,
        EventType.REMOVE_ROAD_SIGNS_EVENT,
        EventType.UNLOAD_STORAGE_EVENT,
        EventType.DISASSEMBLE_EVENT,
        EventType.LOAD_STORAGE_EVENT,
        EventType.PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT,
        EventType.MOVING_LOADING_EVENT,
        EventType.MOVING_UNLOADING_EVENT,
      ];

    case EventType.DAY_OFF_EVENT:
    case EventType.SICK_EVENT:
    case EventType.VACATION_EVENT:
    case EventType.HRM_OTHER_ABSENCE_EVENT:
      return [
        EventType.DAY_OFF_EVENT,
        EventType.SICK_EVENT,
        EventType.VACATION_EVENT,
        EventType.HRM_OTHER_ABSENCE_EVENT,
      ];

    case EventType.MAINTENANCE_EVENT:
    case EventType.INSPECTION_EVENT:
    case EventType.MATERIAL_OTHER_ABSENCE_EVENT:
      return [
        EventType.MAINTENANCE_EVENT,
        EventType.INSPECTION_EVENT,
        EventType.MATERIAL_OTHER_ABSENCE_EVENT,
      ];

    case EventType.TRANSPORT_JOB_DELIVER:
    case EventType.TRANSPORT_JOB_PICKUP:
    case EventType.DRIVING_DAY:
      return [
        EventType.TRANSPORT_JOB_DELIVER,
        EventType.TRANSPORT_JOB_PICKUP,
        EventType.DRIVING_DAY,
      ];

    case EventType.CUSTOM_EVENT:
    case EventType.LENDING_STAFF_EVENT:
    case EventType.LIFT_WORK_EVENT:
    case EventType.OFFICE_WORK_EVENT:
    case EventType.TRANSPORT_EVENT:
    case EventType.WAREHOUSE_WORK_EVENT:
      return [
        EventType.CUSTOM_EVENT,
        EventType.LENDING_STAFF_EVENT,
        EventType.LIFT_WORK_EVENT,
        EventType.OFFICE_WORK_EVENT,
        EventType.TRANSPORT_EVENT,
        EventType.WAREHOUSE_WORK_EVENT,
      ];

    default:
      return [];
  }
};

export const getAvailableEventTypesPerProjectType = (
  projectType: ProjectType,
): EventType[] => {
  switch (projectType) {
    case ProjectType.MOVING_JOB:
      return [
        EventType.INTAKE_EVENT,
        EventType.MOVING_EVENT,
        EventType.POST_ROAD_SIGNS_EVENT,
        EventType.REMOVE_ROAD_SIGNS_EVENT,
        EventType.LOAD_STORAGE_EVENT,
        EventType.UNLOAD_STORAGE_EVENT,
        EventType.DELIVER_BOXES_EVENT,
        EventType.PICKUP_BOXES_EVENT,
        EventType.DISASSEMBLE_EVENT,
        EventType.ASSEMBLE_EVENT,
        EventType.PACKING_EVENT,
        EventType.UNPACKING_EVENT,
        EventType.PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT,
        EventType.DISPOSING_OF_WASTE_EVENT,
        EventType.MOVING_LIFT_EVENT,
      ];

    case ProjectType.MOVING_LIFT_JOB:
      return [EventType.LIFT_WORK_EVENT];

    case ProjectType.TRANSPORT_JOB:
      return [
        EventType.TRANSPORT_JOB_PICKUP,
        EventType.TRANSPORT_JOB_DELIVER,
        EventType.DRIVING_DAY,
      ];

    default:
      return [];
  }
};
