import { Box, CircularProgress, styled } from '@mui/material';
import { ReactElement } from 'react';

export type OverlayFetchingIndicatorProps = {
  className?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'inherit';
};

const OverlayFetchingIndicator = ({
  className,
  color,
}: OverlayFetchingIndicatorProps): ReactElement => (
  <Box className={className}>
    <CircularProgress color={color} />
  </Box>
);

const StyledOverlayFetchingIndicator = styled(OverlayFetchingIndicator)(
  () => `
    position: absolute;
    right: 50%;
    top: 50%;
    z-index: 99;
`,
);
export default StyledOverlayFetchingIndicator;
