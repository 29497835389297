import { isServiceType, PublicServiceType } from '@bas/shared/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type PublicServiceTypesRequestProps = Pagination;
type Response = AxiosResponse<Collection<PublicServiceType>>;

export const PublicServiceTypesRequest = async ({
  ...params
}: PublicServiceTypesRequestProps): Promise<Response> =>
  axios.get('api/public/{tenantId}/settings/service-types', {
    params: { ...params },
  });

export const usePublicServiceTypesRequest = (
  request: PublicServiceTypesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => PublicServiceTypesRequest(request),
    queryKey: ['service-types', 'public-list'],
  });

export const usePrefetchPublicServiceTypesRequest = (
  request: PublicServiceTypesRequestProps = {},
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['service-types', 'public-list'],
      queryFn: async () =>
        PublicServiceTypesRequest({ perPage: 9999, ...request }),
    });
  }, [request, queryClient]);
};

export const PublicServiceTypeRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isServiceType(data)) {
    queryClient.invalidateQueries({
      queryKey: ['service-types', 'public-list'],
    });
  }
};
