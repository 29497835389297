import { isObjectResponse } from '@bas/value-objects';
import type { EmployeeOccasionalAvailability } from '../types';

export function isEmployeeOccasionalAvailability(
  obj: unknown,
): obj is EmployeeOccasionalAvailability {
  return (
    isObjectResponse(obj) &&
    (obj['@type'] === 'EmployeeOccasionalAvailability' ||
      obj['@type'] === 'remove_occasional_availability' ||
      obj['@type'] === 'occasional-availability')
  );
}
