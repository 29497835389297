import { QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { TenantClosureDate } from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TenantClosureDatesByDateRequestProps = {
  start: Date;
  end: Date;
};

type Response = AxiosResponse<Collection<TenantClosureDate>>;
type QueryKeyType = QueryKey & {
  [0]: 'tenant-closure-dates';
  [1]: 'list';
  [2]: string;
  [2]: string;
};

export const TenantClosureDatesByDateRequest = async ({
  start,
  end,
  ...params
}: TenantClosureDatesByDateRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/planning/tenant-closure-dates`, {
    params: {
      'date[after]': formatDate(start),
      'date[before]': formatDate(end),
      ...params,
    },
  });

export const useTenantClosureDatesByDateRequest = (
  request: TenantClosureDatesByDateRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => TenantClosureDatesByDateRequest({ ...request }),
    queryKey: [
      'tenant-closure-dates',
      'list',
      formatDate(request.start),
      formatDate(request.end),
    ],
  });
