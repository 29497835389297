import { useVatCodesRequest } from '@bas/financial-domain/requests';
import {
  CalculatedQuoteMomentLineInputTypeDefaultValues,
  FixedAmountQuoteMomentLineInputTypeDefaultValues,
  QuoteMomentLineInputType,
} from '@bas/tenant-domain/input-types';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@bas/ui/web/atoms';
import { ProjectType } from '@bas/value-objects';
import { Grid2, styled, TableContainer } from '@mui/material';
import { ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { QuoteMomentLineForm } from '../QuoteMomentLineForm';

export type QuoteMomentLinesFormProps = TableProps & {
  projectType?: ProjectType;
};

const QuoteMomentLinesForm = ({
  projectType,
  ...args
}: QuoteMomentLinesFormProps): ReactElement => {
  const {
    fields: lines,
    append,
    remove,
    swap,
  } = useFieldArray<
    { quote: { quoteLines: QuoteMomentLineInputType[] } },
    'quote.quoteLines'
  >({ name: `quote.quoteLines` as const });

  const { data: vatCodesData } = useVatCodesRequest(
    {
      perPage: 99999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <TableContainer>
      <Table {...args} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell width="62px" />
            <TableCell>
              <FormattedMessage id="label.description" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="label.quantity" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="label.pricePerUnit" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="label.vat" />
            </TableCell>
            <TableCell width="40px" />
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map(({ id }, index) => (
            <QuoteMomentLineForm
              key={id}
              index={index}
              vatCodes={vatCodesData?.data?.member || []}
              remove={remove}
              allowMoveUp={index === 0}
              allowMoveDown={lines.length - 1 === index}
              moveUp={() => swap(index - 1, index)}
              moveDown={() => swap(index + 1, index)}
              projectType={projectType}
            />
          ))}
          <TableRow>
            <TableCell colSpan={6}>
              <Grid2 container spacing={1}>
                {projectType && (
                  <Grid2 size={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() =>
                        append(
                          CalculatedQuoteMomentLineInputTypeDefaultValues(),
                        )
                      }
                    >
                      <FormattedMessage id="button.addCalculatedLine" />
                    </Button>
                  </Grid2>
                )}
                <Grid2 size={projectType ? 6 : 12}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() =>
                      append(FixedAmountQuoteMomentLineInputTypeDefaultValues())
                    }
                  >
                    <FormattedMessage id="button.addFixedLine" />
                  </Button>
                </Grid2>
              </Grid2>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledQuoteMomentLinesForm = styled(QuoteMomentLinesForm)(
  () => `
  .MuiTableBody-root {
    .MuiTableRow-root {
      &.Bas-QuoteMomentLineForm-FirstRow {
        .MuiTableCell-root {
          padding-top: 16px;
        }
      }
      &.Bas-QuoteMomentLineForm-LastRow {
        .MuiTableCell-root {
          padding-bottom: 16px;
        }
      }
    }
  }
  .MuiTableCell-root {
    padding: 4px 3px;
    border: 0;
    height: auto;
  }

  .MuiFilledInput-root {
    padding-left: 13px;
    padding-right: 13px;
    &, &:hover, &.Mui-focused {
      background: #F4F4F4 !important;
      border-radius: 3px;
      border: 0;

      .MuiFilledInput-input {
        text-align: left;
      }

      .MuiFilledInput-input[inputmode="numeric"] {
        text-align: center;
      }
    }
  }
`,
);
export default StyledQuoteMomentLinesForm;
