import {
  BatchData,
  Integration,
  InvoiceData,
  isInvoiceData,
  SyncStatus,
} from '@bas/integration-domain/models';
import {
  useMarkBatchDataAsCompletedMutation,
  useRetryBatchDataMutation,
} from '@bas/integration-domain/mutations';
import { useBatchDataByIntegrationIdRequest } from '@bas/integration-domain/requests';
import {
  AskExternalInvoicesExternalIdsFormDialog,
  InvoiceTableRow,
} from '@bas/integration-domain/web/organisms';
import { useDatatable } from '@bas/shared/hooks';
import { useBatchDataBulkStore } from '@bas/shared/state';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { Checkbox, LoadingTableRow } from '@bas/ui/web/molecules';
import { DataTableTemplate } from '@bas/ui/web/templates';
import { Uuid } from '@bas/value-objects';
import {
  faArrowsRotate,
  faCheckToSlot,
} from '@fortawesome/pro-light-svg-icons';
import { Grid2, TableContainer } from '@mui/material';
import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

export type InvoiceDataTabProps = {
  integration: Integration;
  failed?: boolean;
  allowBulk?: boolean;
};

const InvoiceDataTab = ({
  integration,
  failed,
  allowBulk,
}: InvoiceDataTabProps): ReactElement => {
  const { mutateAsync: retryBatchDataMutation } = useRetryBatchDataMutation();
  const { mutateAsync: markBatchDataAsCompletedMutation } =
    useMarkBatchDataAsCompletedMutation();

  const [askExternalIdsForBatchIds, setAskExternalIdsForBatchIds] = useState<
    Uuid[]
  >([]);

  const checkedBatchDataIds = useBatchDataBulkStore(
    (state) => state.checkedBatchDataIds,
  );
  const toggleBatchData = useBatchDataBulkStore(
    (state) => state.toggleBatchData,
  );
  const setCheckedBasedUponFilter = useBatchDataBulkStore(
    (state) => state.setCheckedBasedUponFilter,
  );
  const setCheckedBatchDataIds = useBatchDataBulkStore(
    (state) => state.setCheckedBatchDataIds,
  );
  const setRequestFilters = useBatchDataBulkStore(
    (state) => state.setRequestFilters,
  );

  const filters = useMemo(
    () =>
      failed
        ? {
            'syncStatus.value': [
              SyncStatus.PENDING,
              SyncStatus.AWAITING_APPROVAL,
              SyncStatus.FAILED,
            ],
          }
        : {},
    [failed],
  );

  const {
    basedUponFilters,
    searchGlobally: handleSearch,
    currentPage,
    rowsPerPage,
    resetFilters,
    changePage,
    changeRowsPerPage,
    requestFilters,
  } = useDatatable();

  const {
    data: batchDataItemsData,
    isLoading,
    isFetching,
    isFetched,
  } = useBatchDataByIntegrationIdRequest({
    integrationId: integration.integrationId,
    perPage: rowsPerPage,
    page: currentPage,
    ...requestFilters,
    ...filters,
  });

  const batchDataItems = useMemo(
    () => batchDataItemsData?.data.member || [],
    [batchDataItemsData?.data],
  );

  const numberOfBatchDataItems = batchDataItemsData?.data.totalItems || 0;

  const handleRetry = useCallback(
    async (data: BatchData) => {
      await retryBatchDataMutation({
        integrationId: data.integrationId,
        batchId: data.batchId,
        datas: [
          {
            dataId: data.dataId,
          },
        ],
      });
    },
    [retryBatchDataMutation],
  );

  const handleMarkAsCompleted = useCallback(async (data: BatchData) => {
    setAskExternalIdsForBatchIds([data.dataId]);
  }, []);

  const handleAskExternalIdsForBatchIds = useCallback(
    async (values: { datas: { dataId: Uuid; externalId?: string }[] }) => {
      await markBatchDataAsCompletedMutation({
        integrationId: integration.integrationId,
        datas: values.datas,
      });
      setAskExternalIdsForBatchIds([]);
    },
    [markBatchDataAsCompletedMutation, integration.integrationId],
  );

  const handleBulkMarkAsCompleted = useCallback(async () => {
    setAskExternalIdsForBatchIds(checkedBatchDataIds);
  }, [checkedBatchDataIds]);

  const handleBulkRetry = useCallback(async () => {
    await retryBatchDataMutation({
      integrationId: integration.integrationId,
      datas: checkedBatchDataIds.map((dataId) => ({ dataId })),
    });
  }, [retryBatchDataMutation, integration.integrationId, checkedBatchDataIds]);

  const handleCheckData = useCallback(
    (dataIdToCheck: Uuid) => {
      toggleBatchData(dataIdToCheck);
      setCheckedBasedUponFilter(false);
    },
    [toggleBatchData, setCheckedBasedUponFilter],
  );

  const handleCheckAllOfPage = useCallback(() => {
    const pageData = batchDataItems;
    if (checkedBatchDataIds.length === pageData.length) {
      setCheckedBatchDataIds([]);
      setCheckedBasedUponFilter(false);
      return;
    }

    setCheckedBatchDataIds(pageData.map((data) => data.dataId));
    setCheckedBasedUponFilter(false);
  }, [
    batchDataItems,
    checkedBatchDataIds,
    setCheckedBatchDataIds,
    setCheckedBasedUponFilter,
  ]);

  useEffect(() => {
    setRequestFilters(requestFilters);
  }, [requestFilters, setRequestFilters]);

  const loadingRows = useMemo(
    () =>
      new Array(10).fill(null).map((v, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <LoadingTableRow numberOfColumns={allowBulk ? 6 : 5} key={index} />
      )),
    [allowBulk],
  );

  const askForExternalIdsForData = useMemo(
    () =>
      batchDataItems.filter(
        (data) =>
          isInvoiceData(data) &&
          askExternalIdsForBatchIds.includes(data.dataId),
      ) as InvoiceData[],
    [askExternalIdsForBatchIds, batchDataItems],
  );

  return (
    <DataTableTemplate
      isFetching={isFetching && !isFetched}
      onSearch={handleSearch}
      appliedFilters={basedUponFilters}
      onResetFilters={resetFilters}
      pagination={{
        page: currentPage,
        rowsPerPage,
        itemCount: numberOfBatchDataItems,
        onPageChange: (e, page) => changePage(page),
        onRowsPerPageChange: (e) =>
          changeRowsPerPage(Number.parseInt(e.target.value, 10)),
      }}
      toolbarActions={
        allowBulk &&
        checkedBatchDataIds.length > 0 && (
          <Grid2 container spacing={1}>
            <Grid2>
              <Tooltip title={<FormattedMessage id="button.markAsCompleted" />}>
                <Button
                  onClick={handleBulkMarkAsCompleted}
                  size="small"
                  variant="outlined"
                >
                  <Icon icon={faCheckToSlot} />
                </Button>
              </Tooltip>
            </Grid2>
            <Grid2>
              <Tooltip title={<FormattedMessage id="button.retry" />}>
                <Button
                  onClick={handleBulkRetry}
                  size="small"
                  variant="outlined"
                >
                  <Icon icon={faArrowsRotate} />
                </Button>
              </Tooltip>
            </Grid2>
          </Grid2>
        )
      }
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {allowBulk && (
                <TableCell>
                  <Checkbox
                    onChange={handleCheckAllOfPage}
                    removePadding
                    size="small"
                    lightIcon
                    checked={
                      batchDataItems.length === checkedBatchDataIds.length
                    }
                  />
                </TableCell>
              )}
              <TableCell paddingRight>
                <FormattedMessage id="label.invoiceNumber" />
              </TableCell>
              {!failed && (
                <TableCell paddingRight>
                  <FormattedMessage id="label.externalId" />
                </TableCell>
              )}
              <TableCell paddingRight>
                <FormattedMessage id="label.status" />
              </TableCell>
              <TableCell paddingRight>
                <FormattedMessage id="label.processedAt" />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && loadingRows}
            {!isLoading &&
              batchDataItems.map((data) => {
                if (isInvoiceData(data)) {
                  return (
                    <InvoiceTableRow
                      key={data.dataId}
                      data={data}
                      showExternalId={!failed}
                      onRetry={async () => handleRetry(data)}
                      onMarkAsCompleted={async () =>
                        handleMarkAsCompleted(data)
                      }
                      showCheck={allowBulk}
                      checked={checkedBatchDataIds.includes(data.dataId)}
                      onCheck={() => handleCheckData(data.dataId)}
                    />
                  );
                }

                return <Fragment key={data.dataId} />;
              })}
            {!isLoading && batchDataItems.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <FormattedMessage id="label.noInvoicesFound" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AskExternalInvoicesExternalIdsFormDialog
        open={askExternalIdsForBatchIds.length > 0}
        data={askForExternalIdsForData}
        onSubmit={handleAskExternalIdsForBatchIds}
        onClose={() => setAskExternalIdsForBatchIds([])}
      />
    </DataTableTemplate>
  );
};

export default InvoiceDataTab;
