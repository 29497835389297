import { HoursEntry, OtherPlannedEmployee } from '@bas/hrm-domain/models';
import { OtherPlannedEmployeesTooltipContent } from '@bas/planning-domain/web/molecules';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { TableCell, TableRow, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { PersonName, Uuid } from '@bas/value-objects';
import {
  faExclamationCircle,
  faPencil,
  faTable,
  faThumbsUp,
  faTrashCan,
  faXmarkToSlot,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { Grid2, IconButton, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, ReactNode, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

export type ProjectHoursEntriesRow = {
  eventId: Uuid;
  employeeId: Uuid;
  employeeName: PersonName;
  title: string | ReactNode;
  reason?: string;
  start: Date;
  end: Date;
  workStart?: Date | null;
  workEnd?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  plannedStart: Date;
  plannedEnd: Date;
  totalHours: number;
  planned: number;
  worked: number;
  workedAccordingToForeman: number;
  approved: number;
  approvalReasons?: string[];
  breakTime: number;
  hoursAlwaysIncludingBreakTime: number;
  travelTime: number;
  approvedEntries: HoursEntry[];
  entryId?: Uuid;
  realEntryId?: Uuid;
  otherPlannedEmployees: OtherPlannedEmployee[];
};

export type ProjectHourEntriesTableRowProps = {
  className?: string;
  employee: {
    employeeId: Uuid;
    personName: PersonName;
  };
  totalHours: number;
  planned: number;
  worked: number;
  workedAccordingToForeman: number;
  approved: number;
  breakTime: number;
  travelTime: number;
  onStartShowingHourEntries: (entryId: Uuid) => void;
  onStartApprovingHours: (entry: ProjectHoursEntriesRow) => void;
  onStartRemovingHours: (entryId: Uuid) => void;
  onStartEditingHours: (entry: ProjectHoursEntriesRow) => void;
  hoursEntryRows: ProjectHoursEntriesRow[];
};

const ProjectHourEntriesTableRow = ({
  className,
  employee,
  totalHours,
  planned,
  worked,
  workedAccordingToForeman,
  approved,
  breakTime,
  travelTime,
  hoursEntryRows,
  onStartShowingHourEntries,
  onStartApprovingHours,
  onStartEditingHours,
  onStartRemovingHours,
}: ProjectHourEntriesTableRowProps): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const onToggleExpand = () => setExpanded((value) => !value);

  return (
    <>
      <TableRow
        className={clsx(className, {
          'Bas-ProjectHourEntriesTableRow-Expanded': expanded,
        })}
      >
        <TableCell>{employee.personName.fullName}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
          {formatHours(totalHours)}
        </TableCell>
        <TableCell align="center">{formatHours(planned)}</TableCell>
        <TableCell align="center">
          {formatHours(workedAccordingToForeman)}
        </TableCell>
        <TableCell align="center">{formatHours(worked)}</TableCell>
        <TableCell align="center">{formatHours(approved)}</TableCell>
        <TableCell align="center">{formatHours(travelTime)}</TableCell>
        <TableCell align="center">{formatHours(breakTime)}</TableCell>
        <TableCell>
          <IconButton
            onClick={onToggleExpand}
            size="small"
            disabled={hoursEntryRows.length === 0}
          >
            <Icon
              icon={faChevronDown}
              flip={expanded ? 'vertical' : undefined}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      {hoursEntryRows.map((entryRow) => {
        const mismatchingWithColleagues =
          entryRow.otherPlannedEmployees.findIndex(
            ({ workedHours: otherWorkedHours, plannedHours }) => {
              let hours: number = plannedHours;
              if (otherWorkedHours !== null) {
                hours = otherWorkedHours;
              }

              return hours === entryRow.worked;
            },
          ) !== -1;

        const mismatchingWithColleaguesAccordingToForeman =
          entryRow.otherPlannedEmployees.findIndex(
            ({
              workedHoursAccordingToForeman: otherWorkedHours,
              plannedHours,
            }) =>
              (otherWorkedHours === null ? plannedHours : otherWorkedHours) !==
              entryRow.workedAccordingToForeman,
          ) !== -1;

        return (
          <TableRow
            key={entryRow.entryId}
            className={clsx(
              className,
              'Bas-ProjectHourEntriesTableRow-Expandable',
              {
                'Bas-ProjectHourEntriesTableRow-ShowExpandable': expanded,
              },
            )}
          >
            <TableCell>
              <Tooltip
                arrow
                title={entryRow.reason || ''}
                disabled={!entryRow.reason}
              >
                <span>
                  {entryRow.title}
                  {entryRow.reason && (
                    <>
                      &nbsp;
                      <Icon icon={faExclamationCircle} />
                    </>
                  )}
                </span>
              </Tooltip>
            </TableCell>
            <TableCell>
              <FormattedDate
                value={entryRow.start}
                day="numeric"
                month="long"
              />
            </TableCell>
            <TableCell align="center">
              <Tooltip
                arrow
                disabled={!entryRow.travelStart && !entryRow.workStart}
                title={
                  <Grid2 container>
                    {entryRow.travelStart && (
                      <Grid2 size={12}>
                        <FormattedMessage id="label.departureTime" />
                        &nbsp;
                        <FormattedTime
                          value={entryRow.travelStart}
                          timeStyle="short"
                        />
                      </Grid2>
                    )}
                    {entryRow.workStart && (
                      <Grid2 size={12}>
                        <FormattedMessage id="label.workStartedAt" />
                        &nbsp;
                        <FormattedTime
                          value={entryRow.workStart}
                          timeStyle="short"
                        />
                      </Grid2>
                    )}
                  </Grid2>
                }
              >
                <span>
                  <FormattedTime value={entryRow.start} timeStyle="short" />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip
                arrow
                disabled={!entryRow.travelEnd && !entryRow.workEnd}
                title={
                  <Grid2 container>
                    {entryRow.travelEnd && (
                      <Grid2 size={12}>
                        <FormattedMessage id="label.arrivalTimeWarehouse" />
                        &nbsp;
                        <FormattedTime
                          value={entryRow.travelEnd}
                          timeStyle="short"
                        />
                      </Grid2>
                    )}
                    {entryRow.workEnd && (
                      <Grid2 size={12}>
                        <FormattedMessage id="label.workEndedAt" />
                        &nbsp;
                        <FormattedTime
                          value={entryRow.workEnd}
                          timeStyle="short"
                        />
                      </Grid2>
                    )}
                  </Grid2>
                }
              >
                <span>
                  <FormattedTime value={entryRow.end} timeStyle="short" />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              <Tooltip
                arrow
                title={
                  <Grid2 container>
                    <Grid2 size={8}>
                      <FormattedMessage id="label.workedHoursIncludingBreakTime" />
                    </Grid2>
                    <Grid2 size={4}>
                      + {formatHours(entryRow.hoursAlwaysIncludingBreakTime)}
                    </Grid2>
                    {entryRow.hoursAlwaysIncludingBreakTime !==
                      entryRow.totalHours - entryRow.travelTime && (
                      <>
                        <Grid2 size={8}>
                          <FormattedMessage id="label.breakTime" />
                        </Grid2>
                        <Grid2 size={4}>
                          - {formatHours(entryRow.breakTime)}
                        </Grid2>
                      </>
                    )}
                    {entryRow.travelTime > 0 && (
                      <>
                        <Grid2 size={8}>
                          <FormattedMessage id="label.travelTime" />
                        </Grid2>
                        <Grid2 size={4}>
                          + {formatHours(entryRow.travelTime)}
                        </Grid2>
                      </>
                    )}
                    <Grid2 fontWeight="bold" size={8}>
                      <FormattedMessage id="label.totalHours" />
                    </Grid2>
                    <Grid2 fontWeight="bold" size={4}>
                      = {formatHours(entryRow.totalHours)}
                    </Grid2>
                  </Grid2>
                }
              >
                <span>{formatHours(entryRow.totalHours)}</span>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              {formatHours(entryRow.planned)}
            </TableCell>
            <TableCell
              align="center"
              className={clsx(
                {
                  'Bas-ProjectHourEntriesTableRow-Overtime':
                    !approved &&
                    entryRow.workedAccordingToForeman > entryRow.planned,
                },
                {
                  'Bas-ProjectHourEntriesTableRow-MismatchingWithColleagues':
                    !approved && mismatchingWithColleaguesAccordingToForeman,
                },
              )}
            >
              <Tooltip
                arrow
                disabled={entryRow.otherPlannedEmployees.length === 0}
                title={
                  <OtherPlannedEmployeesTooltipContent
                    plannedStart={entryRow.plannedStart}
                    plannedEnd={entryRow.plannedEnd}
                    otherPlannedEmployees={entryRow.otherPlannedEmployees}
                  />
                }
              >
                <span
                  className={clsx({
                    'Bas-ProjectHourEntriesTableRow-Underline':
                      entryRow.otherPlannedEmployees.length > 0,
                  })}
                >
                  {formatHours(entryRow.workedAccordingToForeman)}
                </span>
              </Tooltip>
            </TableCell>
            <TableCell
              align="center"
              className={clsx(
                {
                  'Bas-ProjectHourEntriesTableRow-Overtime':
                    !approved && entryRow.worked > entryRow.planned,
                },
                {
                  'Bas-ProjectHourEntriesTableRow-MismatchingWithColleagues':
                    !approved && mismatchingWithColleagues,
                },
              )}
            >
              <Tooltip
                disabled={entryRow.otherPlannedEmployees.length === 0}
                title={
                  <OtherPlannedEmployeesTooltipContent
                    plannedStart={entryRow.plannedStart}
                    plannedEnd={entryRow.plannedEnd}
                    otherPlannedEmployees={entryRow.otherPlannedEmployees}
                  />
                }
              >
                <span
                  className={clsx({
                    'Bas-ProjectHourEntriesTableRow-Underline':
                      entryRow.otherPlannedEmployees.length > 0,
                  })}
                >
                  {formatHours(entryRow.worked)}
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip
                arrow
                disabled={
                  !entryRow.approvalReasons ||
                  entryRow.approvalReasons.length === 0
                }
                title={entryRow.approvalReasons || ''}
              >
                <span
                  className={clsx({
                    'Bas-ProjectHourEntriesTableRow-Underline':
                      entryRow.approvalReasons &&
                      entryRow.approvalReasons.length > 0,
                  })}
                >
                  {formatHours(entryRow.approved)}
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              {formatHours(entryRow.travelTime)}
            </TableCell>
            <TableCell align="center">
              {formatHours(entryRow.breakTime)}
            </TableCell>
            <TableCell className="Bas-ProjectHourEntriesTableRow-OnHover">
              {entryRow.approvedEntries.length > 1 && entryRow.realEntryId && (
                <Tooltip
                  title={<FormattedMessage id="button.showHourEntries" />}
                >
                  <IconButton
                    onClick={() =>
                      onStartShowingHourEntries(entryRow.realEntryId || '')
                    }
                    size="small"
                  >
                    <Icon icon={faTable} />
                  </IconButton>
                </Tooltip>
              )}
              {entryRow.approvedEntries.length <= 1 && (
                <>
                  <Tooltip
                    arrow
                    title={
                      <FormattedMessage
                        id={
                          entryRow.entryId ? 'button.edit' : 'button.enterHours'
                        }
                      />
                    }
                  >
                    <IconButton
                      onClick={() => onStartEditingHours(entryRow)}
                      size="small"
                      disabled={hoursEntryRows.length === 0}
                    >
                      <Icon
                        icon={entryRow.entryId ? faPencil : faXmarkToSlot}
                      />
                    </IconButton>
                  </Tooltip>
                  {!entryRow.approved && (
                    <Tooltip
                      arrow
                      title={<FormattedMessage id="button.approveHours" />}
                    >
                      <IconButton
                        onClick={() => onStartApprovingHours(entryRow)}
                        size="small"
                        disabled={hoursEntryRows.length === 0}
                      >
                        <Icon icon={faThumbsUp} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!entryRow.approved && entryRow.entryId && (
                    <Tooltip
                      arrow
                      title={<FormattedMessage id="button.removeHours" />}
                    >
                      <IconButton
                        onClick={() =>
                          onStartRemovingHours(entryRow.entryId || '')
                        }
                        size="small"
                      >
                        <Icon icon={faTrashCan} />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

const StyledProjectHourEntriesTableRow = styled(ProjectHourEntriesTableRow)(
  ({ theme }) => `
  .Bas-ProjectHourEntriesTableRow-Overtime {
    color: ${colors.yellow[700]};
    font-weight: bold;
  }

  .Bas-ProjectHourEntriesTableRow-MismatchingWithColleagues {
    color: ${colors.red[500]};
    font-weight: bold;
  }

  .Bas-ProjectHourEntriesTableRow-Underline {
    text-decoration: underline;
  }

  &.Bas-ProjectHourEntriesTableRow-Expandable {
    display: none;
  }

  &.Bas-ProjectHourEntriesTableRow-ShowExpandable {
    display: table-row;
    .MuiTableCell-root:first-of-type {
      text-indent: 8px;
    }
  }

  .Bas-ProjectHourEntriesTableRow-OnHover {
    opacity: 0;
  }

  &.Bas-ProjectHourEntriesTableRow-Expanded .MuiTableCell-root {
    &, .MuiButtonBase-root {
      color: ${colors.blue[500]};
      font-weight: bold;
    }
  }

  &:hover {
    .Bas-ProjectHourEntriesTableRow-OnHover {
      opacity: 1;
    }
  }
`,
);
export default StyledProjectHourEntriesTableRow;
