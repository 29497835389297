import {
  findIconDefinition,
  IconDefinition,
} from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { isIconDefinition } from './isIconDefinition';

export type IconProps = FontAwesomeIconProps;

const Icon = ({ className, icon, ...args }: IconProps): ReactElement => {
  let fillRule = false;
  let clipRule = false;
  let iconDefinition: IconDefinition | undefined;

  if (icon && isIconDefinition(icon)) {
    iconDefinition = icon;
  }

  if (!iconDefinition && typeof icon === 'object' && !Array.isArray(icon)) {
    iconDefinition = findIconDefinition(icon);
  }

  if (iconDefinition && iconDefinition.icon[2].includes('fillRule:evenodd')) {
    fillRule = true;
  }

  if (iconDefinition && iconDefinition.icon[2].includes('clipRule:evenodd')) {
    clipRule = true;
  }

  const classNames = [className];
  if (fillRule) {
    classNames.push(`fill-evenodd`);
  }

  if (clipRule) {
    classNames.push(`clip-evenodd`);
  }

  return <FontAwesomeIcon {...args} className={clsx(classNames)} icon={icon} />;
};

const StyledIcon = styled(Icon)(
  () => `
&.fill-evenodd {
  fill-rule: evenodd;
}

&.clip-evenodd {
  clip-rule: evenodd;
}
`,
);

export default StyledIcon;
