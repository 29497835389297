import { Employee, isEmployee } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmployeeByEmployeeIdRequestProps = {
  employeeId: Uuid;
};

export const EmployeeByEmployeeIdRequest = async ({
  employeeId,
  ...params
}: EmployeeByEmployeeIdRequestProps): Promise<AxiosResponse<Employee>> =>
  axios.get(`api/{tenantId}/hrm/employees/${employeeId}`, {
    params,
  });

export const useEmployeeByEmployeeIdRequestQuery = (
  request: EmployeeByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Employee>,
    AxiosError,
    AxiosResponse<Employee>
  > = {},
): UseQueryResult<AxiosResponse<Employee>, AxiosError> =>
  useQuery<AxiosResponse<Employee>, AxiosError, AxiosResponse<Employee>>({
    ...options,
    queryFn: async () => EmployeeByEmployeeIdRequest(request),
    queryKey: ['employees', 'detail', request.employeeId],
  });

export const EmployeeByEmployeeIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEmployee(data)) {
    queryClient.invalidateQueries({
      queryKey: ['employees', 'detail', data.employeeId],
    });
  }
};
