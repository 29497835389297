import { FunctionComponent } from 'react';
import { create } from 'zustand';

export enum SidebarType {
  PROJECT_DETAILS = 'project-details',
  STORAGE_DETAILS = 'storage-details',
  STORAGE_LOCATION_DETAILS = 'storage-location-details',
  DAMAGE_DETAILS = 'damage-details',
  EVENT_DETAILS = 'event-details',
  QUOTE_DETAILS = 'quote-details',
  INVOICE_DETAILS = 'invoice-details',
  CUSTOMER_DETAILS = 'customer-details',
  INTEGRATION_DETAILS = 'integration-details',
  TASK_DETAILS = 'task-details',
  DECLARATION_DETAILS = 'declaration-details',
  TIME_OFF_REGISTRATION_DETAILS = 'time-off-registration-details',
}

type BackofficeSidebarStore = {
  openSidebars: {
    sidebarType: SidebarType;
    args: Record<string, unknown>;
  }[];
  open: (type: SidebarType, args?: Record<string, unknown>) => void;
  close: () => void;
  hasContent: boolean;
  sidebarContent: FunctionComponent | null;
  setSidebarContent: (content: FunctionComponent) => void;
  closeAll: () => void;
  openKey: string | null;
  setOpenKey: (key: string | null) => void;
};

export const useBackofficeSidebarStore = create<BackofficeSidebarStore>()(
  (set) => ({
    openSidebars: [],
    open: (type, args) => {
      set((state) => ({
        openSidebars: [
          {
            sidebarType: type,
            args: args || {},
          },
          ...state.openSidebars,
        ],
      }));
    },
    close: () => {
      set((state) => ({
        openSidebars: state.openSidebars.slice(1),
      }));
    },
    closeAll: () => {
      set({ openSidebars: [] });
    },
    openKey: null,
    setOpenKey: (key) => {
      set({ openKey: key });
    },
    hasContent: false,
    sidebarContent: null,
    setSidebarContent: (content) => {
      set({ sidebarContent: content, hasContent: !!content });
    },
  }),
);
