import { isQuote } from '@bas/financial-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type QuoteAcceptedPdfByQuoteIdRequestProps = {
  quoteId: Uuid;
};

export const QuoteAcceptedPdfByQuoteIdRequest = async ({
  quoteId,
  ...params
}: QuoteAcceptedPdfByQuoteIdRequestProps): Promise<AxiosResponse<Blob>> =>
  axios.get(`api/{tenantId}/quotes/${quoteId}/download-accepted-pdf`, {
    responseType: 'blob',
    params,
  });

export const useQuoteAcceptedPdfByQuoteIdRequestQuery = (
  request: QuoteAcceptedPdfByQuoteIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => QuoteAcceptedPdfByQuoteIdRequest(request),
    queryKey: ['quotes', 'accepted-pdf', request.quoteId],
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    gcTime: 0,
    staleTime: 0,
  });

export const QuoteAcceptedPdfByQuoteIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isQuote(data)) {
    queryClient.invalidateQueries({
      queryKey: ['quotes', 'accepted-pdf', data.quoteId],
    });
  }
};
