import { CustomerNotificationsFeatureInputType } from '@bas/tenant-domain/input-types';
import { CustomerNotificationsFeature } from '@bas/tenant-domain/models';
import { TextField, TextFieldNumberFormat } from '@bas/ui/web/atoms';
import { FeatureHelpTooltip, Switch } from '@bas/ui/web/molecules';
import { Grid2, styled, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type EditableCustomerNotificationsFeatureBlockProps = {
  feature: CustomerNotificationsFeature;
  disabled?: boolean;
  onUpdateCustomerNotificationsFeature: (
    values: CustomerNotificationsFeatureInputType,
  ) => Promise<void>;
};

const EditableCustomerNotificationsFeatureBlock = ({
  feature,
  disabled,
  onUpdateCustomerNotificationsFeature,
  ...args
}: EditableCustomerNotificationsFeatureBlockProps): ReactElement => {
  const [daysInput, setDaysInput] = useState<number>(
    feature.howManyBusinessDaysBeforeMovingJobItShouldSendAReminder,
  );

  useDebounce(
    () => {
      if (
        daysInput !==
        feature.howManyBusinessDaysBeforeMovingJobItShouldSendAReminder
      ) {
        onUpdateCustomerNotificationsFeature({
          ...feature,
          howManyBusinessDaysBeforeMovingJobItShouldSendAReminder: daysInput,
          disableIcsAttachment: feature.disableIcsAttachment,
        });
      }
    },
    300,
    [
      daysInput,
      feature.howManyBusinessDaysBeforeMovingJobItShouldSendAReminder,
    ],
  );

  return (
    <Grid2 container spacing="12px" {...args}>
      <Grid2 pt={2} size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="featureTypes.customer-notifications" />
        </Typography>
      </Grid2>
      <Grid2 container spacing={1} alignItems="center" size={12}>
        <Grid2>
          <TextField
            value={daysInput}
            disabled={disabled}
            sx={{ width: '36px' }}
            onChange={(e) => setDaysInput(parseFloat(e.target.value))}
            InputProps={{
              inputComponent: TextFieldNumberFormat,
              inputProps: {
                pattern: '[0-9]*',
                inputMode: 'numeric',
                decimalScale: 0,
              },
            }}
          />
        </Grid2>
        <Grid2>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.customer-notifications.howManyBusinessDaysBeforeMovingJobItShouldSendAReminder"
              values={{
                numberOfDays: daysInput,
              }}
            />
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 container spacing={1} alignItems="center" size={12}>
        <Grid2>
          <Switch
            label={
              <FormattedMessage id="features.customer-notifications.disableIcsAttachment" />
            }
            checked={feature.disableIcsAttachment}
            disabled={disabled}
            onChange={(e, val) =>
              onUpdateCustomerNotificationsFeature({
                ...feature,
                disableIcsAttachment: val,
              })
            }
          />
        </Grid2>
        <Grid2>
          <FeatureHelpTooltip
            title={
              <FormattedMessage id="features.customer-notifications.disableIcsAttachment.help" />
            }
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const StyledEditableCustomerNotificationsFeatureBlock = styled(
  EditableCustomerNotificationsFeatureBlock,
)(
  () => `
  .MuiFormControl-root {
    width: 36px;
  }
  .MuiInput-input {
    text-align: center;
  }
`,
);
export default StyledEditableCustomerNotificationsFeatureBlock;
