import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EnableEmployeeAttributeMutationProps = {
  employeeAttributeId: Uuid;
};

export const EnableEmployeeAttributeMutation = async ({
  employeeAttributeId,
  ...values
}: EnableEmployeeAttributeMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/employee-attributes/${employeeAttributeId}/enable`,
    {
      employeeAttributeId,
      ...values,
    },
  );

export const useEnableEmployeeAttributeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableEmployeeAttributeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  EnableEmployeeAttributeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    EnableEmployeeAttributeMutationProps
  >({
    mutationFn: EnableEmployeeAttributeMutation,
    throwOnError: false,
    ...options,
  });
