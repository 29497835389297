import { HoursEntry, OtherPlannedEmployee } from '@bas/hrm-domain/models';
import { colors } from '@bas/theme';
import { Grid2, IconButton, styled } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Icon } from '@bas/ui/web/base';
import { TableCell, TableRow, Tooltip } from '@bas/ui/web/atoms';
import { formatHours } from '@bas/shared/utils';
import {
  faExclamationCircle,
  faPencil,
  faTable,
  faThumbsUp,
  faTrashCan,
  faXmarkToSlot,
} from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { EmployeeProjectRole, PersonName, Uuid } from '@bas/value-objects';
import { OtherPlannedEmployeesTooltipContent } from '../OtherPlannedEmployeesTooltipContent';

export type EventHoursEntriesRow = {
  eventId: Uuid;
  employeeId: Uuid;
  employeeName: PersonName;
  title: string | ReactNode;
  reason?: string;
  start: Date;
  end: Date;
  workStart?: Date | null;
  workEnd?: Date | null;
  travelStart?: Date | null;
  travelEnd?: Date | null;
  plannedStart: Date;
  plannedEnd: Date;
  totalHours: number;
  planned: number;
  worked: number;
  workedAccordingToForeman: number;
  approved: number;
  approvalReasons?: string[];
  breakTime: number;
  hoursAlwaysIncludingBreakTime: number;
  travelTime: number;
  approvedEntries: HoursEntry[];
  entryId?: Uuid;
  realEntryId?: Uuid;

  projectRole?: EmployeeProjectRole;
  otherPlannedEmployees: OtherPlannedEmployee[];
};

export type EventHourEntriesTableRowProps = EventHoursEntriesRow & {
  className?: string;
  onStartApprovingHours: (entry: EventHoursEntriesRow) => void;
  onStartRemovingHours: (entryId: Uuid) => void;
  onStartShowingHourEntries: (entryId: Uuid) => void;
  onStartEditingHours: (entry: EventHoursEntriesRow) => void;
};

const EventHourEntriesTableRow = ({
  className,
  onStartApprovingHours,
  onStartEditingHours,
  onStartRemovingHours,
  onStartShowingHourEntries,
  ...entryRow
}: EventHourEntriesTableRowProps): ReactElement => {
  const mismatchingWithColleagues =
    entryRow.otherPlannedEmployees.findIndex(
      ({ workedHours: otherWorkedHours, plannedHours }) => {
        let hours: number = plannedHours;
        if (otherWorkedHours !== null) {
          hours = otherWorkedHours;
        }

        return hours === entryRow.worked;
      },
    ) !== -1;

  const mismatchingWithColleaguesAccordingToForeman =
    entryRow.otherPlannedEmployees.findIndex(
      ({ workedHoursAccordingToForeman: otherWorkedHours, plannedHours }) =>
        (otherWorkedHours === null ? plannedHours : otherWorkedHours) !==
        entryRow.workedAccordingToForeman,
    ) !== -1;

  return (
    <TableRow key={entryRow.entryId} className={clsx(className)}>
      <TableCell>
        <Tooltip
          arrow
          title={entryRow.reason || ''}
          disabled={!entryRow.reason}
        >
          <span>
            {entryRow.employeeName.fullName}
            {entryRow.reason && (
              <>
                &nbsp;
                <Icon icon={faExclamationCircle} />
              </>
            )}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <FormattedDate value={entryRow.start} day="numeric" month="long" />
      </TableCell>
      <TableCell align="center">
        <Tooltip
          arrow
          disabled={!entryRow.travelStart && !entryRow.workStart}
          title={
            <Grid2 container>
              {entryRow.travelStart && (
                <Grid2 size={12}>
                  <FormattedMessage id="label.departureTime" />
                  &nbsp;
                  <FormattedTime
                    value={entryRow.travelStart}
                    timeStyle="short"
                  />
                </Grid2>
              )}
              {entryRow.workStart && (
                <Grid2 size={12}>
                  <FormattedMessage id="label.workStartedAt" />
                  &nbsp;
                  <FormattedTime value={entryRow.workStart} timeStyle="short" />
                </Grid2>
              )}
            </Grid2>
          }
        >
          <span>
            <FormattedTime value={entryRow.start} timeStyle="short" />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip
          arrow
          disabled={!entryRow.travelEnd && !entryRow.workEnd}
          title={
            <Grid2 container>
              {entryRow.travelEnd && (
                <Grid2 size={12}>
                  <FormattedMessage id="label.arrivalTimeWarehouse" />
                  &nbsp;
                  <FormattedTime value={entryRow.travelEnd} timeStyle="short" />
                </Grid2>
              )}
              {entryRow.workEnd && (
                <Grid2 size={12}>
                  <FormattedMessage id="label.workEndedAt" />
                  &nbsp;
                  <FormattedTime value={entryRow.workEnd} timeStyle="short" />
                </Grid2>
              )}
            </Grid2>
          }
        >
          <span>
            <FormattedTime value={entryRow.end} timeStyle="short" />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center" sx={{ fontWeight: 'bold' }}>
        <Tooltip
          arrow
          title={
            <Grid2 container>
              <Grid2 size={8}>
                <FormattedMessage id="label.workedHoursIncludingBreakTime" />
              </Grid2>
              <Grid2 size={4}>
                + {formatHours(entryRow.hoursAlwaysIncludingBreakTime)}
              </Grid2>
              {entryRow.hoursAlwaysIncludingBreakTime !==
                entryRow.totalHours - entryRow.travelTime && (
                <>
                  <Grid2 size={8}>
                    <FormattedMessage id="label.breakTime" />
                  </Grid2>
                  <Grid2 size={4}>- {formatHours(entryRow.breakTime)}</Grid2>
                </>
              )}
              {entryRow.travelTime > 0 && (
                <>
                  <Grid2 size={8}>
                    <FormattedMessage id="label.travelTime" />
                  </Grid2>
                  <Grid2 size={4}>+ {formatHours(entryRow.travelTime)}</Grid2>
                </>
              )}
              <Grid2 fontWeight="bold" size={8}>
                <FormattedMessage id="label.totalHours" />
              </Grid2>
              <Grid2 fontWeight="bold" size={4}>
                = {formatHours(entryRow.totalHours)}
              </Grid2>
            </Grid2>
          }
        >
          <span>{formatHours(entryRow.totalHours)}</span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">{formatHours(entryRow.planned)}</TableCell>
      <TableCell
        align="center"
        className={clsx(
          {
            'Bas-EventHourEntriesTableRow-Overtime':
              !entryRow.approved &&
              entryRow.workedAccordingToForeman > entryRow.planned,
          },
          {
            'Bas-EventHourEntriesTableRow-MismatchingWithColleagues':
              !entryRow.approved && mismatchingWithColleaguesAccordingToForeman,
          },
        )}
      >
        <Tooltip
          arrow
          disabled={entryRow.otherPlannedEmployees.length === 0}
          title={
            <OtherPlannedEmployeesTooltipContent
              plannedStart={entryRow.plannedStart}
              plannedEnd={entryRow.plannedEnd}
              otherPlannedEmployees={entryRow.otherPlannedEmployees}
            />
          }
        >
          <span
            className={clsx({
              'Bas-EventHourEntriesTableRow-Underline':
                entryRow.otherPlannedEmployees.length > 0,
            })}
          >
            {formatHours(entryRow.workedAccordingToForeman)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell
        align="center"
        className={clsx(
          {
            'Bas-EventHourEntriesTableRow-Overtime':
              !entryRow.approved && entryRow.worked > entryRow.planned,
          },
          {
            'Bas-EventHourEntriesTableRow-MismatchingWithColleagues':
              !entryRow.approved && mismatchingWithColleagues,
          },
        )}
      >
        <Tooltip
          disabled={entryRow.otherPlannedEmployees.length === 0}
          title={
            <OtherPlannedEmployeesTooltipContent
              plannedStart={entryRow.plannedStart}
              plannedEnd={entryRow.plannedEnd}
              otherPlannedEmployees={entryRow.otherPlannedEmployees}
            />
          }
        >
          <span
            className={clsx({
              'Bas-EventHourEntriesTableRow-Underline':
                entryRow.otherPlannedEmployees.length > 0,
            })}
          >
            {formatHours(entryRow.worked)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip
          arrow
          disabled={
            !entryRow.approvalReasons || entryRow.approvalReasons.length === 0
          }
          title={entryRow.approvalReasons || ''}
        >
          <span
            className={clsx({
              'Bas-EventHourEntriesTableRow-Underline':
                entryRow.approvalReasons && entryRow.approvalReasons.length > 0,
            })}
          >
            {formatHours(entryRow.approved)}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell align="center">{formatHours(entryRow.travelTime)}</TableCell>
      <TableCell align="center">{formatHours(entryRow.breakTime)}</TableCell>
      <TableCell className="Bas-EventHourEntriesTableRow-OnHover">
        {entryRow.approvedEntries.length > 1 && entryRow.realEntryId && (
          <Tooltip title={<FormattedMessage id="button.showHourEntries" />}>
            <IconButton
              onClick={() =>
                onStartShowingHourEntries(entryRow.realEntryId || '')
              }
              size="small"
            >
              <Icon icon={faTable} />
            </IconButton>
          </Tooltip>
        )}
        {entryRow.approvedEntries.length <= 1 && (
          <>
            <Tooltip
              arrow
              title={
                <FormattedMessage
                  id={entryRow.entryId ? 'button.edit' : 'button.enterHours'}
                />
              }
            >
              <IconButton
                onClick={() => onStartEditingHours(entryRow)}
                size="small"
              >
                <Icon icon={entryRow.entryId ? faPencil : faXmarkToSlot} />
              </IconButton>
            </Tooltip>
            {!entryRow.approved && (
              <Tooltip
                arrow
                title={<FormattedMessage id="button.approveHours" />}
              >
                <IconButton
                  onClick={() => onStartApprovingHours(entryRow)}
                  size="small"
                >
                  <Icon icon={faThumbsUp} />
                </IconButton>
              </Tooltip>
            )}
            {!entryRow.approved && entryRow.entryId && (
              <Tooltip
                arrow
                title={<FormattedMessage id="button.removeHours" />}
              >
                <IconButton
                  onClick={() => onStartRemovingHours(entryRow.entryId || '')}
                  size="small"
                >
                  <Icon icon={faTrashCan} />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const StyledEventHourEntriesTableRow = styled(EventHourEntriesTableRow)`
  .Bas-EventHourEntriesTableRow-Overtime {
    color: ${colors.yellow[700]};
    font-weight: bold;
  }

  .Bas-EventHourEntriesTableRow-MismatchingWithColleagues {
    color: ${colors.red[500]};
    font-weight: bold;
  }

  .Bas-EventHourEntriesTableRow-Underline {
    text-decoration: underline;
  }

  .Bas-EventHourEntriesTableRow-OnHover {
    opacity: 0;
  }

  &.Bas-EventHourEntriesTableRow-Expanded .MuiTableCell-root {
    &,
    .MuiButtonBase-root {
      color: ${colors.blue[500]};
      font-weight: bold;
    }
  }

  &:hover {
    .Bas-EventHourEntriesTableRow-OnHover {
      opacity: 1;
    }
  }
`;

export default StyledEventHourEntriesTableRow;
