import { colors as themeColors, fontSizesWeb } from '@bas/theme';
import { SizeType } from '@bas/value-objects';
import { Box, styled } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, memo, MouseEventHandler, ReactElement } from 'react';
import isEqual from 'react-fast-compare';
import { StackedCircularProgress } from '../StackedCircularProgress';

export type CalendarDayProps = {
  className?: string;
  label: string | ReactElement;
  availablePercentage: number;
  reservedPercentage: number;
  confirmedPercentage: number;
  selected?: boolean;
  isPast?: boolean;
  isToday?: boolean;
  other?: boolean;
  size?: SizeType | 'small' | 'medium' | 'large';
  onClick?: MouseEventHandler;
  colors?: {
    available?: string;
    reserved?: string;
    confirmed?: string;
  };
};

const CalendarDay = forwardRef<HTMLDivElement, CalendarDayProps>(
  (
    {
      className,
      label,
      selected = false,
      isToday = false,
      isPast = false,
      other = false,
      availablePercentage,
      reservedPercentage,
      confirmedPercentage,
      size = SizeType.MEDIUM,
      onClick,
      colors,
      ...args
    }: CalendarDayProps,
    ref,
  ): ReactElement => {
    let sizeNumber = 45;
    if (size === SizeType.MEDIUM) {
      sizeNumber = 60;
    } else if (size === SizeType.LARGE) {
      sizeNumber = 75;
    }

    return (
      <Box
        {...args}
        ref={ref}
        className={clsx(
          className,
          { 'Bas-CalendarDay-Today': isToday },
          { 'Bas-CalendarDay-Unavailable': availablePercentage === 0 },
          { 'Bas-CalendarDay-Selected': selected },
          { 'Bas-CalendarDay-Past': isPast },
          { 'Bas-CalendarDay-Other': other },
          { 'Bas-CalendarDay-Small': size === SizeType.SMALL },
          { 'Bas-CalendarDay-Medium': size === SizeType.MEDIUM },
          { 'Bas-CalendarDay-Large': size === SizeType.LARGE },
        )}
        onClick={onClick}
      >
        <div className="Bas-CalendarDay-Label">{label}</div>
        <div className="Bas-CalendarDay-Circular">
          <StackedCircularProgress
            size={sizeNumber}
            values={[
              {
                value: reservedPercentage,
                color: colors?.reserved || themeColors.yellow[700],
              },
              {
                value:
                  confirmedPercentage > 0
                    ? confirmedPercentage + reservedPercentage
                    : 0,
                color: colors?.confirmed || themeColors.blue[500],
              },
              {
                value: 100 - availablePercentage,
                color: colors?.available || themeColors.red[200],
              },
            ]}
          />
        </div>
        <div className="Bas-CalendarDay-Line" />
      </Box>
    );
  },
);

const MemoComponent = memo(
  styled(CalendarDay)(
    ({ theme }) => `
  position: relative;
  cursor: pointer;
  height: 45px;
  width: 45px;

  .Bas-CalendarDay-Line {
    margin: 8px auto 0 auto;
    border-radius: 6px;
    height: 0px;
    width: 23.5px;
    background: transparent;
    border: 1.5px solid transparent;
    transition: background 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  }

  .Bas-CalendarDay-Label {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${themeColors.lila[800]};
    font-family: ${theme.typography.fontFamily};
    font-size: ${fontSizesWeb.lg};
    font-weight: 500;
  }

  &.Bas-CalendarDay-Unavailable {
    .Bas-CalendarDay-Label {
      color: ${themeColors.red[700]};
    }
  }

  &.Bas-CalendarDay-Today {
    .Bas-CalendarDay-Line {
      background: ${themeColors.blue[200]};
      border-color: ${themeColors.blue[200]};
    }
  }

  &.Bas-CalendarDay-Selected {
    .Bas-CalendarDay-Label {
      color: ${themeColors.blue[500]};
    }
    .Bas-CalendarDay-Line {
      background: ${themeColors.blue[500]};
      border-color: ${themeColors.blue[500]};
    }
  }

  .Bas-CalendarDay-Circular {
    position: absolute;
    left: 0;
    top: 0;
  }

  &.Bas-CalendarDay-Past, &.Bas-CalendarDay-Other {
    .Bas-CalendarDay-Label, .Bas-CalendarDay-Circular {
      opacity: 0.3;
    }
  }

  &.Bas-CalendarDay-Medium {
    height: 60px;
    width: 60px;

    .Bas-CalendarDay-Line {
      margin: 9px auto 0 auto;
      width: 48.5px;
    }
  }

  &.Bas-CalendarDay-Large {
    height: 75px;
    width: 75px;

    .Bas-CalendarDay-Label {
      font-size: ${fontSizesWeb.xl};
    }

    .Bas-CalendarDay-Line {
      margin: 9px auto 0 auto;
      width: 48.5px;
    }
  }

  &:hover .Bas-CalendarDay-Label {
    color: ${themeColors.blue[500]};
  }

  &:hover .Bas-CalendarDay-Line {
    background: ${themeColors.lila[400]};
    border-color:${themeColors.lila[400]};
  }

  &.Bas-CalendarDay-Selected:hover {
    .Bas-CalendarDay-Line {
      background: ${themeColors.blue[500]};
      border-color: ${themeColors.blue[500]};
    }
  }
`,
  ),
  isEqual,
);
export default MemoComponent;
