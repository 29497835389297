import { Damage, isDamage } from '@bas/project-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DamageByDamageIdRequestProps = Pagination & {
  damageId: string;
};

type Response = AxiosResponse<Damage>;
type QueryKeyType = QueryKey & {
  [0]: 'damages';
  [1]: 'detail';
  [2]: Uuid;
};

export const DamageByDamageIdRequest = async ({
  damageId,
}: DamageByDamageIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/damages/${damageId}`);

export const useDamageByDamageIdRequest = (
  request: DamageByDamageIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    queryFn: async () => DamageByDamageIdRequest(request),
    queryKey: ['damages', 'detail', request.damageId],
    ...options,
  });

export const DamageByDamageIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isDamage(data)) {
    queryClient.invalidateQueries({
      queryKey: ['damages', 'detail', data.damageId],
    });
  }
};
