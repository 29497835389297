import { isObjectResponse, type Uuid } from '@bas/value-objects';
import { Message, Participant } from '../../types';
import { isEmployeeParticipant } from './EmployeeParticipant';
import { isUserParticipant } from './UserParticipant';

export function isMessage(object: unknown): object is Message {
  return isObjectResponse(object) && object['@type'] === 'Message';
}

export const checkIfIsSender = (
  participant: Participant,
  currentUserId: Uuid,
  currentEmployeeId: Uuid,
): boolean =>
  (isUserParticipant(participant) && participant.userId === currentUserId) ||
  (isEmployeeParticipant(participant) &&
    participant.employeeId === currentEmployeeId);

export const userHasReadMessage = (
  message: Message,
  currentUserId: Uuid,
  currentEmployeeId: Uuid,
): boolean =>
  !!message.participantMessageMetadata.find((metadata) =>
    checkIfIsSender(metadata.participant, currentUserId, currentEmployeeId),
  )?.readAt;
