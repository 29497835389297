import { colors } from '@bas/theme';
import {
  FormGroup,
  FormHelperText,
  FormHelperTextProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement, ReactNode } from 'react';
import { FormControlLabel } from '../FormControlLabel';

export type SwitchProps = MuiSwitchProps & {
  label?: ReactElement | string;
  error?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: ReactNode | string;
};

const thumbSize = '18px';

const CustomizedSwitch = styled(MuiSwitch)(
  ({ theme }) => `
  color: ${colors.lila[800]};
  width: 42px;
  padding: 0;
  border-radius: 12px;
  margin-left: 11px;

  &:hover {
    color: ${colors.lila[800]};
  }

  & .MuiSwitch-switchBase {
    padding: 3px;
  }

  & .MuiSwitch-switchBase.Mui-disabled, .MuiSwitch-switchBase.Mui-checked.Mui-disabled {
    color: ${colors.white};
  }

  & .MuiSwitch-switchBase.Mui-checked {
    color: ${colors.white};
    -webkit-transform: translateX(${thumbSize});
    -moz-transform: translateX(${thumbSize});
    -ms-transform: translateX(${thumbSize});
    transform: translateX(${thumbSize});
  }

  & .MuiSwitch-thumb {
    box-shadow: none;
    width: ${thumbSize};
    height: ${thumbSize};
  }

  & .MuiSwitch-track {
    opacity: 1;
    background: ${colors.lila[800]};
  }

  & .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
    opacity: 1;
    background: ${colors.lila[400]};
  }

  & .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track {
    opacity: 1;
    background: ${colors.green[300]};
  }

  &.Mui-checked {
    color: #1F1F1F;
  }
`,
);

const Switch = ({
  label,
  disabled,
  error,
  FormHelperTextProps: helperProps,
  helperText,
  className,
  size = 'small',
  ...props
}: SwitchProps): ReactElement => {
  const checkboxContent = (
    <CustomizedSwitch disabled={disabled} {...props} size={size} />
  );

  if (!label) {
    return checkboxContent;
  }

  return (
    <FormGroup className={className}>
      <FormControlLabel
        label={label}
        disabled={disabled}
        control={checkboxContent}
        sx={{ marginRight: 0 }}
      />
      {helperText && (
        <FormHelperText {...helperProps} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormGroup>
  );
};

const StyledSwitch = styled(Switch)(
  ({ theme }) => `
  .MuiFormControlLabel-label {
    padding-left: ${theme.spacing(1)} !important;
  }
`,
);
export default StyledSwitch;
