import { isObjectResponse } from '@bas/value-objects';
import type { ClaCompensationRule } from '../types';

export function isClaCompensationRule(
  object: unknown,
): object is ClaCompensationRule {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'ClaCompensationRule'
  );
}
