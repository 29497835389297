import { colors } from '@bas/theme';
import { Grid2, GridProps, styled } from '@mui/material';
import { ReactElement } from 'react';

const timeValues = [
  '06:00',
  '08:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
];

export type WorkingScheduleTimelineProps = GridProps & {
  overrideTimeValues?: string[];
};

const WorkingScheduleTimeline = ({
  overrideTimeValues,
  ...args
}: WorkingScheduleTimelineProps): ReactElement => (
  <Grid2
    {...args}
    container
    justifyContent="flex-end"
    alignItems="center"
    alignContent="space-between"
  >
    {(overrideTimeValues || timeValues).map((time) => (
      <Grid2 className="Bas-WorkingScheduleTimeline-Time" key={time}>
        {time}
      </Grid2>
    ))}
  </Grid2>
);

const StyledWorkingScheduleTimeline = styled(WorkingScheduleTimeline)(
  ({ theme }) => `
  height: 100%;
  font-size: 12px;
  color: ${colors.lila[800]};
  text-align: right;
  .Bas-WorkingScheduleTimeline-Time {
    width: 100%;
  }
`,
);
export default StyledWorkingScheduleTimeline;
