import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDayJs = (date: dayjs.Dayjs): string =>
  date.format('YYYY-MM-DD');

export const formatDate = (date: string | Date | Dayjs): string =>
  formatDayJs(dayjs(date));

export const formatDateTime = (
  date: string | Date | Dayjs,
  toUtc = true,
): string =>
  (toUtc ? dayjs(date).utc() : dayjs(date)).format('YYYY-MM-DD HH:mm:ss');
