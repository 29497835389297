import { useRemoveNationalHolidayMutation } from '@bas/hrm-domain/mutations';
import { useNationalHolidayByNationalHolidayIdRequestQuery } from '@bas/hrm-domain/requests';
import { WorkflowButtons, WorkflowItem } from '@bas/ui/web/molecules';
import { ConfirmDialog } from '@bas/ui/web/organisms';
import { Uuid } from '@bas/value-objects';
import { Typography } from '@mui/material';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

export type NationalHolidayWorkflowProps = {
  nationalHolidayId: Uuid;
};
const NationalHolidayWorkflow = ({
  nationalHolidayId,
}: NationalHolidayWorkflowProps): ReactElement => {
  const navigate = useNavigate();
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>();

  const { mutateAsync: removeNationalHoliday } =
    useRemoveNationalHolidayMutation();
  const { data: nationalHolidayData } =
    useNationalHolidayByNationalHolidayIdRequestQuery({
      nationalHolidayId: nationalHolidayId || '',
    });

  const nationalHoliday = useMemo(
    () => nationalHolidayData?.data,
    [nationalHolidayData],
  );

  const handleRemoveNationalHoliday = useCallback(async () => {
    await removeNationalHoliday({
      nationalHolidayId,
    });

    navigate('/settings/hrm/national-holidays');
    setActiveButtonIndex(undefined);
  }, [nationalHolidayId, navigate, removeNationalHoliday]);

  const removeAction: WorkflowItem = useMemo(
    () => ({
      label: <FormattedMessage id="label.remove" />,
      color: 'error',
      dialog: (
        <ConfirmDialog
          open
          onCancel={() => setActiveButtonIndex(undefined)}
          onClose={() => setActiveButtonIndex(undefined)}
          dangerous
          onConfirm={handleRemoveNationalHoliday}
          title={
            <FormattedMessage id="label.areYouSureYouWantToRemoveNationalHoliday.title" />
          }
          content={
            <Typography
              whiteSpace="pre-wrap"
              sx={{ overflowWrap: 'break-word' }}
            >
              <FormattedMessage
                id="label.areYouSureYouWantToRemoveNationalHoliday.content"
                values={{ name: nationalHoliday?.name }}
              />
            </Typography>
          }
        />
      ),
    }),
    [nationalHoliday?.name, handleRemoveNationalHoliday],
  );

  const workflow = useMemo(() => [removeAction], [removeAction]);

  return (
    <WorkflowButtons
      workflow={workflow}
      activeButtonIndex={activeButtonIndex}
      setActiveButtonIndex={setActiveButtonIndex}
    />
  );
};

export default NationalHolidayWorkflow;
