import { temporaryPermissions, useCheckPermissions } from '@bas/shared/hooks';
import { useBackofficeSidebarStore } from '@bas/shared/state';
import { Logo } from '@bas/ui/web/atoms';
import { Sidebar } from '@bas/ui/web/organisms';
import { ModuleLoadingPage, NotFoundPageTemplate } from '@bas/ui/web/templates';
import {
  faCalendar,
  faChartLine,
  faCog,
  faCoins,
  faCommentAlt,
  faPeopleCarry,
  faTachometerAlt,
  faTruckCouch,
  faUsersCrown,
  faWarehouseFull,
} from '@fortawesome/pro-light-svg-icons';
import { Box, LinearProgress, styled } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import { useFlag } from '@unleash/proxy-client-react';
import { lazy, ReactElement, Suspense, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SidebarContent } from '../SidebarContent';

const DashboardPage = lazy(() => import('@bas/dashboard-domain/web/pages'));
const DataPage = lazy(() => import('@bas/dashboard-domain/web/pages/DataPage'));
const PlanningPage = lazy(() => import('@bas/planning-domain/web/pages'));
const FinancialPage = lazy(() => import('@bas/financial-domain/web/pages'));
const ProfilePage = lazy(() => import('@bas/authentication-domain/web/pages'));
const CrmPage = lazy(() => import('@bas/crm-domain/web/pages'));
const HrmPage = lazy(() => import('@bas/hrm-domain/web/pages'));
const WmsPage = lazy(() => import('@bas/wms-domain/web/pages'));
const ProjectsPage = lazy(() => import('@bas/project-domain/web/pages'));
const SettingsPage = lazy(() => import('@bas/settings-domain/web/pages'));
const CommunicationPage = lazy(
  () => import('@bas/communication-domain/web/pages'),
);

export type BackofficeProps = {
  className?: string;
};

const Backoffice = ({ className }: BackofficeProps): ReactElement => {
  const isFetching = useIsFetching();
  const checkPermissions = useCheckPermissions();
  const { setSidebarContent, hasContent } = useBackofficeSidebarStore();

  useEffect(() => {
    if (!hasContent) {
      setSidebarContent(SidebarContent);
    }
  }, [hasContent, setSidebarContent]);

  const dataPage = useFlag('data');

  const mainMenuItems = useMemo(
    () =>
      [
        {
          icon: faTachometerAlt,
          label: <FormattedMessage id="menu.dashboard" />,
          to: '/',
          exact: true,
        },
        {
          icon: faTruckCouch,
          label: <FormattedMessage id="menu.projects" />,
          to: '/projects',
          neededPermissions: 'projects',
        },
        {
          icon: faUsersCrown,
          label: <FormattedMessage id="menu.customers" />,
          to: '/customers',
          neededPermissions: 'customers',
        },
        {
          icon: faCalendar,
          label: <FormattedMessage id="menu.planning" />,
          to: '/planning',
          neededPermissions: 'planning',
        },
        {
          icon: faCoins,
          label: <FormattedMessage id="menu.financial" />,
          to: '/financial',
          neededPermissions: 'financial',
        },
        {
          icon: faWarehouseFull,
          label: <FormattedMessage id="menu.wms" />,
          to: '/wms',
          neededPermissions: 'wms',
        },
        {
          icon: faPeopleCarry,
          label: <FormattedMessage id="menu.hrm" />,
          to: '/hrm',
          neededPermissions: 'hrm',
        },
        {
          icon: faCommentAlt,
          label: <FormattedMessage id="menu.communication" />,
          to: '/communication',
          neededPermissions: 'communication',
        },
        {
          icon: faChartLine,
          label: <FormattedMessage id="menu.data" />,
          to: '/data',
          neededPermissions: 'data',
          featureFlag: 'data',
          enabled: dataPage,
        },
      ]
        .filter(
          (item) =>
            item.enabled !== false &&
            (!item.neededPermissions ||
              checkPermissions(item.neededPermissions as temporaryPermissions)),
        )
        .map(({ neededPermissions, ...item }) => item),
    [checkPermissions, dataPage],
  );

  const bottomMenuItems = useMemo(
    () =>
      checkPermissions('settings')
        ? [
            {
              icon: faCog,
              label: <FormattedMessage id="menu.settings" />,
              to: '/settings',
            },
          ]
        : [],
    [checkPermissions],
  );

  const allowDataPage = useMemo(
    () => dataPage && checkPermissions('data'),
    [checkPermissions, dataPage],
  );

  return (
    <Box className={className}>
      {!!isFetching && (
        <Box className="Bas-Backoffice-Fetching">
          <LinearProgress variant="query" sx={{ height: '2px' }} />
        </Box>
      )}
      <Box className="Bas-Backoffice-Sidebar">
        <Sidebar
          logo={<Logo />}
          mainMenuList={{
            menuItems: mainMenuItems,
          }}
          bottomMenuList={{
            menuItems: bottomMenuItems,
          }}
        />
      </Box>
      <Box className="Bas-Backoffice-Content">
        <Suspense fallback={<ModuleLoadingPage />}>
          <Routes>
            <Route path="/projects/*" element={<ProjectsPage />} />
            <Route path="/customers/*" element={<CrmPage />} />
            <Route path="/wms/*" element={<WmsPage />} />
            <Route path="/hrm/*" element={<HrmPage />} />
            <Route path="/planning/*" element={<PlanningPage />} />
            <Route path="/financial/*" element={<FinancialPage />} />
            <Route path="/communication/*" element={<CommunicationPage />} />
            <Route path="/settings/*" element={<SettingsPage />} />
            <Route path="/profile/*" element={<ProfilePage />} />
            {allowDataPage && <Route path="/data/*" element={<DataPage />} />}
            <Route path="/dashboard">
              <Route path=":taskId" element={<DashboardPage />} />
              <Route index element={<DashboardPage />} />
            </Route>
            <Route index element={<Navigate replace to="dashboard" />} />
            <Route path="*" element={<NotFoundPageTemplate />} />
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
};

const StyledBackoffice = styled(Backoffice)(`
  display: flex;
  overflow: hidden;
  height: 100%;

  .Bas-Backoffice-Fetching {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
  }


  .Bas-Backoffice-Content {
    height: 100vh;
    width: calc(100% - 88px);
  }
`);

export default StyledBackoffice;
