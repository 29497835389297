import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { isTenantInvoice, TenantInvoice } from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TenantInvoicesRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<TenantInvoice>>;
type QueryKeyType = QueryKey & {
  [0]: 'tenant-invoices';
  [1]: 'list';
};

export const TenantInvoicesRequest = async ({
  ...params
}: TenantInvoicesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/tenant-invoices`, { params });

export const useTenantInvoicesRequest = (
  request: TenantInvoicesRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => TenantInvoicesRequest({ ...request }),
    queryKey: ['tenant-invoices', 'list', request],
  });

export const TenantInvoicesByTenantInvoiceIdsRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isTenantInvoice(data)) {
      queryClient.invalidateQueries({ queryKey: ['tenant-invoices', 'list'] });
    }
  };
