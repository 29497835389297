import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { IconPack } from '@fortawesome/pro-light-svg-icons';
import { HTMLAttributes, useMemo } from 'react';
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { FormattedMessage } from 'react-intl';
import { BackendIcon } from '../BackendIcon';
import { ReactHookFormAutocomplete } from '../ReactHookFormAutocomplete';

const { definitions } = library as unknown as {
  definitions: (IconDefinition | IconPack)[];
};

type Option = {
  category: string;
  label: string | null;
  value: string;
};

type ReactHookFormIconSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  onChange: (e: unknown, value: Option | null) => void;
  registered: {
    field: ControllerRenderProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  };
};

const ReactHookFormIconSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  onChange,
  registered,
}: ReactHookFormIconSelectProps<TFieldValues, TName>) => {
  const icons = useMemo(() => {
    const result: Option[] = [];

    Object.keys(definitions).forEach((key) => {
      const iconsWithinKey = definitions[key as keyof typeof definitions];

      Object.keys(iconsWithinKey).forEach((iconKey) => {
        result.push({
          category: key,
          label: iconKey,
          value: `${iconKey}${key === 'bas' ? '.svg' : ''}`,
        });
      });
    });

    return result;
  }, []);

  return (
    <ReactHookFormAutocomplete
      {...registered}
      fullWidth
      options={icons}
      textField={{
        label: <FormattedMessage id="label.icon" />,
      }}
      onChange={onChange}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: Option) => (
        <li {...props}>
          <BackendIcon icon={option.value} />
          &nbsp;
          {' - '}
          {option.label}
        </li>
      )}
      getOptionLabel={(option: Option) => {
        if (!option) {
          return '';
        }

        if (!option.label) {
          return icons.find((item) => item.value === option.value)?.label || '';
        }

        return option.label || '';
      }}
      groupBy={(option: Option) => option.category}
      disableCloseOnSelect
      isOptionEqualToValue={(option: Option, currentValue: Option) =>
        option.value === currentValue?.value
      }
    />
  );
};

export default ReactHookFormIconSelect;
