import {
  HoursEntry,
  isHoursEntry,
  isProjectHoursEntry,
} from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type HourEntriesByEventIdRequestProps = Pagination & {
  eventId: Uuid;
};

type Response = AxiosResponse<Collection<HoursEntry>>;

export const HourEntriesByEventIdRequest = async ({
  eventId,
  ...params
}: HourEntriesByEventIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events/${eventId}/hours-entries`, {
    params: { ...params },
  });

export const useHourEntriesByEventIdRequest = (
  { eventId, ...request }: HourEntriesByEventIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => HourEntriesByEventIdRequest({ ...request, eventId }),
    queryKey: ['hour-entries', 'list', eventId, request],
  });

export const usePrefetchHourEntriesByEventIdRequest = ({
  eventId,
  ...request
}: HourEntriesByEventIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'hour-entries',
        'list',
        eventId,
        ...Object.values({ eventId, ...request }),
      ],
      queryFn: async () => HourEntriesByEventIdRequest({ eventId, ...request }),
    });
  }, [eventId, queryClient, request]);
};

export const HourEntriesByEventIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isHoursEntry(data) && isProjectHoursEntry(data)) {
    queryClient.invalidateQueries({
      queryKey: ['hour-entries', 'list', data.eventId],
    });
  }
};
