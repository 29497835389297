import { useTurnoverGroupsRequest } from '@bas/financial-domain/requests';
import { CostCenterMappingType } from '@bas/integration-domain/models';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useCostCenterBasOptions = (
  costCenterMappingType: CostCenterMappingType,
) => {
  const { formatMessage } = useIntl();
  const { data: identitiesData } = useIdentitiesRequest(
    {},
    {
      enabled: costCenterMappingType === CostCenterMappingType.PER_IDENTITY,
    },
  );

  const { data: turnoverGroupsData } = useTurnoverGroupsRequest(
    {
      perPage: 9999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        costCenterMappingType === CostCenterMappingType.PER_TURNOVER_GROUP,
    },
  );

  return useMemo((): {
    basId: Uuid;
    label: string;
  }[] => {
    if (costCenterMappingType === CostCenterMappingType.PER_IDENTITY) {
      return (identitiesData?.data?.member || []).map(
        ({ identityId, companyInformation: { companyName } }) => ({
          basId: identityId,
          label: companyName,
        }),
      );
    }

    if (costCenterMappingType === CostCenterMappingType.PER_TURNOVER_GROUP) {
      return [
        {
          basId: 'none',
          label: formatMessage({ id: 'label.none' }),
        },
        ...(turnoverGroupsData?.data?.member || []).map(
          ({ turnoverGroupId, name }) => ({
            basId: turnoverGroupId,
            label: name,
          }),
        ),
      ];
    }

    return [];
  }, [
    costCenterMappingType,
    formatMessage,
    identitiesData?.data,
    turnoverGroupsData?.data,
  ]);
};
