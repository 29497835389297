import { isQuoteEvent } from './QuoteEvent';
import { QuoteEventType, QuoteFinalizedEvent } from '../types';

export function isQuoteFinalizedEvent(
  object: unknown,
): object is QuoteFinalizedEvent {
  return (
    isQuoteEvent(object) &&
    object.eventType === QuoteEventType.QuoteFinalizedEvent
  );
}
