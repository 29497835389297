import { StorageEventType, StorageWasInvoicedEvent } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageWasInvoicedEvent(
  object: unknown,
): object is StorageWasInvoicedEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageWasInvoicedEvent
  );
}
