import { colors } from '@bas/theme';
import { FormattedCurrency, TableCell, TableRow } from '@bas/ui/web/atoms';
import { Money } from '@bas/value-objects';
import { Skeleton, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';

export type FinancialDocumentTotalTableRowProps = {
  className?: string;
  description: string | ReactNode;
  value: Money;
  bold?: boolean;
  hasBackground?: boolean;
  isLoadingValue?: boolean;
};

const FinancialDocumentTotalTableRow = ({
  className,
  description,
  value,
  bold,
  hasBackground,
  isLoadingValue,
}: FinancialDocumentTotalTableRowProps): ReactElement => (
  <TableRow
    className={clsx(className, {
      'Bas-FinancialDocumentTotalTableRow-Bold': bold,
      'Bas-FinancialDocumentTotalTableRow-HasBackground': hasBackground,
    })}
  >
    <TableCell paddingLeft sx={{ whiteSpace: 'pre-wrap' }}>
      {description}
    </TableCell>
    <TableCell colSpan={3} />
    <TableCell sx={{ textAlign: 'right', width: '100px' }} paddingRight>
      {isLoadingValue ? <Skeleton /> : <FormattedCurrency {...value} />}
    </TableCell>
  </TableRow>
);

const StyledFinancialDocumentTotalTableRow = styled(
  FinancialDocumentTotalTableRow,
)(
  ({ theme }) => `
    &.Bas-FinancialDocumentTotalTableRow-HasBackground {
      background: ${colors.lila[100]};
    }

    &.Bas-FinancialDocumentTotalTableRow-Bold {
      .MuiTableCell-root {
        font-weight: bold;
      }
    }
`,
);
export default StyledFinancialDocumentTotalTableRow;
