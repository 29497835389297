import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ChangeEmployeeAttributeMutationProps = {
  employeeAttributeId: Uuid;
  name: string;
  description: string;
};

export const ChangeEmployeeAttributeMutation = async ({
  employeeAttributeId,
  ...values
}: ChangeEmployeeAttributeMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/{tenantId}/hrm/employee-attributes/${employeeAttributeId}`, {
    employeeAttributeId,
    ...values,
  });

export const useChangeEmployeeAttributeMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeAttributeMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  ChangeEmployeeAttributeMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    ChangeEmployeeAttributeMutationProps
  >({
    mutationFn: ChangeEmployeeAttributeMutation,
    throwOnError: false,
    ...options,
  });
