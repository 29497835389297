import { HoursOverview } from '@bas/planning-domain/models';
import { useAvailableHoursByRoleAndPeriodRequest } from '@bas/planning-domain/requests';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Tooltip, TooltipProps } from '@bas/ui/web/atoms';
import { EmployeeProjectRole } from '@bas/value-objects';
import { Grid2, Skeleton, styled } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type DailyCalendarStatisticsTooltipProps = Omit<
  TooltipProps,
  'title'
> & {
  currentDate: Date;
};

const defaultHours = {
  totalPlannableHours: 0,
  totalConfirmedHours: 0,
  totalAvailableHours: 0,
  totalHoursOfDay: 0,
  totalPlannedHours: 0,
  totalReservedHours: 0,
  totalShortageHours: 0,
  realReservedHours: 0,
  realConfirmedHours: 0,
  totalReservedHoursToFullfilled: 0,
  totalConfirmedHoursToFullfilled: 0,
} as HoursOverview;

const DailyCalendarStatisticsTooltip = ({
  open,
  onClose,
  currentDate,
  ...props
}: DailyCalendarStatisticsTooltipProps): ReactElement => {
  const [isHovering, setIsHovering] = useState(false);
  const { data: hourStatisticsData, isLoading } =
    useAvailableHoursByRoleAndPeriodRequest(
      {
        projectRole: EmployeeProjectRole.CO_DRIVER,
        startDate: currentDate,
        endDate: currentDate,
      },
      {
        enabled: open || isHovering,
      },
    );

  const hourStatistics = useMemo(
    () =>
      (hourStatisticsData?.data.member || []).find((h) =>
        dayjs(h.date).isSame(currentDate, 'day'),
      ),
    [currentDate, hourStatisticsData?.data],
  );

  const totalEmployeeHours = useMemo(
    () => hourStatistics?.employeeHours || defaultHours,
    [hourStatistics?.employeeHours],
  );

  const totalMovingTruckHours = useMemo(
    () => hourStatistics?.movingTruckHours || defaultHours,
    [hourStatistics?.movingTruckHours],
  );

  const totalMovingLiftHours = useMemo(
    () => hourStatistics?.movingLiftHours || defaultHours,
    [hourStatistics?.movingLiftHours],
  );

  const hourTypes = useMemo(
    () => ({
      employees: totalEmployeeHours,
      movingTrucks: totalMovingTruckHours,
      movingLifts: totalMovingLiftHours,
    }),
    [totalEmployeeHours, totalMovingTruckHours, totalMovingLiftHours],
  );

  const { formatMessage } = useIntl();

  return (
    <Tooltip
      {...props}
      placement="bottom"
      arrow
      allowWider
      open={open}
      onOpen={() => setIsHovering(true)}
      onClose={() => setIsHovering(false)}
      title={
        <Grid2 container columnSpacing={1}>
          {Object.keys(hourTypes).map((type) => {
            const totalHours = hourTypes[type as keyof typeof hourTypes];
            return (
              <Grid2 key={type} size={4}>
                <Grid2 container columnSpacing={1}>
                  <Grid2 fontWeight="bold" pb={2} size={12}>
                    <FormattedMessage id={`label.${type}`} />
                  </Grid2>
                  <Grid2 size={9}>
                    <FormattedMessage id="label.availableHours" />
                  </Grid2>
                  <Grid2 size={3}>
                    {isLoading ? (
                      <Skeleton
                        sx={{
                          backgroundColor: colors.lila[200],
                        }}
                        variant="text"
                        width="100%"
                        height={20}
                      />
                    ) : (
                      `+ ${formatHours(totalHours.totalPlannableHours)}`
                    )}
                  </Grid2>
                  {totalHours.totalReservedHours > 0 && (
                    <>
                      <Grid2 whiteSpace="nowrap" size={9}>
                        {`${formatMessage({
                          id: 'label.reservedHours',
                        })} ${formatMessage({
                          id: 'label.isPlanned',
                        })}`}
                      </Grid2>
                      <Grid2 size={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            Math.max(
                              totalHours.totalReservedHours -
                                totalHours.totalReservedHoursToFullfilled,
                              0,
                            ),
                          )}`
                        )}
                      </Grid2>
                    </>
                  )}
                  {totalHours.totalConfirmedHours > 0 && (
                    <>
                      <Grid2 whiteSpace="nowrap" size={9}>
                        {`${formatMessage({
                          id: 'label.confirmedHours',
                        })} ${formatMessage({
                          id: 'label.isPlanned',
                        })}`}
                      </Grid2>
                      <Grid2 size={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            Math.max(
                              totalHours.totalConfirmedHours -
                                totalHours.totalConfirmedHoursToFullfilled,
                              0,
                            ),
                          )}`
                        )}
                      </Grid2>
                    </>
                  )}
                  {totalHours.totalReservedHoursToFullfilled > 0 && (
                    <>
                      <Grid2 whiteSpace="nowrap" size={9}>
                        {`${formatMessage({
                          id: 'label.reservedHours',
                        })} ${formatMessage({
                          id: 'label.notPlanned',
                        })}`}
                      </Grid2>
                      <Grid2 size={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            totalHours.totalReservedHoursToFullfilled,
                          )}`
                        )}
                      </Grid2>
                    </>
                  )}
                  {totalHours.totalConfirmedHoursToFullfilled > 0 && (
                    <>
                      <Grid2 whiteSpace="nowrap" size={9}>
                        {`${formatMessage({
                          id: 'label.confirmedHours',
                        })} ${formatMessage({
                          id: 'label.notPlanned',
                        })}`}
                      </Grid2>
                      <Grid2 size={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            totalHours.totalConfirmedHoursToFullfilled,
                          )}`
                        )}
                      </Grid2>
                    </>
                  )}
                  {totalHours.totalShortageHours < 0 ? (
                    <>
                      <Grid2 fontWeight="bold" size={9}>
                        <FormattedMessage id="label.shortageHours" />
                      </Grid2>
                      <Grid2 fontWeight="bold" size={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `= ${formatHours(
                            Math.abs(totalHours.totalShortageHours),
                          )}`
                        )}
                      </Grid2>
                    </>
                  ) : (
                    <>
                      <Grid2 fontWeight="bold" size={9}>
                        <FormattedMessage id="label.plannableHours" />
                      </Grid2>
                      <Grid2 fontWeight="bold" size={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `= ${formatHours(totalHours.totalAvailableHours)}`
                        )}
                      </Grid2>
                    </>
                  )}
                </Grid2>
              </Grid2>
            );
          })}
        </Grid2>
      }
    />
  );
};

const StyledDailyCalendarStatisticsTooltip = styled(
  DailyCalendarStatisticsTooltip,
)`
  width: 950px;
  height: 175px;
  border-radius: 10px;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  box-sizing: border-box;
`;

export default StyledDailyCalendarStatisticsTooltip;
