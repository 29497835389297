import dayjs, { Dayjs } from 'dayjs';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

type ApproveTimeEntriesStore = {
  startDate: Dayjs | Date;
  endDate: Dayjs | Date;
  setDates: ({
    startDate,
    endDate,
  }: {
    startDate: Dayjs | Date;
    endDate: Dayjs | Date;
  }) => void;
};

export const useApproveTimeEntriesStore = create<ApproveTimeEntriesStore>()(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  persist(
    (set) => ({
      startDate: dayjs().startOf('week'),
      endDate: dayjs().endOf('week'),
      setDates: (dates) => {
        set(dates);
      },
    }),
    {
      name: 'time-entries-store',
      storage: zustandStorage(),
      version: 0,
    },
  )!,
);
