import { OctopusSettingsInputType } from '@bas/integration-domain/input-types';
import {
  CostCenterMappingType,
  GeneralLedgerMappingType,
} from '@bas/integration-domain/models';
import { useOctopusMappingOptions } from '@bas/integration-domain/web/hooks';
import { useTenantStore } from '@bas/shared/state';
import { IdentityType } from '@bas/tenant-domain/models';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import { Alert, TextFieldNumberFormat } from '@bas/ui/web/atoms';
import {
  IdentityIndicator,
  ReactHookFormAutocomplete,
  ReactHookFormCheckbox,
  ReactHookFormDatePickerField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { MappingTableExternalOption, Uuid } from '@bas/value-objects';
import { Grid2, MenuItem, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CostCenterMappingForm } from '../CostCenterMappingForm';
import { GeneralLedgerMappingForm } from '../GeneralLedgerMappingForm';
import { MappingTableForm } from '../MappingTableForm';
import { VatMappingForm } from '../VatMappingForm';

export type OctopusSettingsFormProps = {
  integrationId: Uuid;
};

const OctopusSettingsForm = ({
  integrationId,
}: OctopusSettingsFormProps): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);
  const [dossierId, generalLedgerMappingType, costCenterMappingType] = useWatch<
    OctopusSettingsInputType,
    ['dossierId', 'generalLedgerMappingType', 'costCenterMappingType']
  >({
    name: ['dossierId', 'generalLedgerMappingType', 'costCenterMappingType'],
  });

  const {
    dossiersData,
    dossiersError,
    generalLedgersError,
    generalLedgerExternalOptions,
    costCentersError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsExternalOptions,
    journalsError,
  } = useOctopusMappingOptions(integrationId, dossierId);

  const useIdentities = useTenantStore(
    (state) => state.tenant?.useIdentities || false,
  );

  const { data: identitiesData } = useIdentitiesRequest(
    {},
    { enabled: useIdentities || false },
  );

  const identitiesToShow = useMemo(
    () =>
      (identitiesData?.data.member || []).filter(
        ({ identityType }) => identityType !== IdentityType.TRADE_NAME,
      ),
    [identitiesData],
  );

  const identityOptions = useMemo(
    () =>
      identitiesToShow.map(({ identityId, companyInformation }) => ({
        basId: identityId,
        label: <IdentityIndicator identityId={identityId} full />,
      })),
    [identitiesToShow],
  );

  const dossiers = useMemo(
    () => dossiersData?.data?.member ?? [],
    [dossiersData],
  );
  const { formatMessage } = useIntl();

  const dossiersOptions = useMemo(
    () => [
      {
        externalId: 'ignore',
        label: formatMessage({ id: 'label.ignoreIdentity' }),
      },
      ...dossiers.map(({ dossierKey, dossierDescription }) => ({
        externalId: dossierKey,
        label: dossierDescription,
      })),
    ],
    [dossiers, formatMessage],
  );

  return (
    <Grid2 container columnSpacing={3} rowSpacing={3} pt={1}>
      {(dossiersError ||
        generalLedgersError ||
        costCentersError ||
        journalsError ||
        vatCodesError) && (
        <Grid2 mt={2} size={12}>
          <Alert severity="error">
            <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
          </Alert>
        </Grid2>
      )}
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="startDate"
          render={(registered) => (
            <ReactHookFormDatePickerField
              {...registered}
              textFieldProps={{
                fullWidth: true,
              }}
              label={<FormattedMessage id="label.startDate" />}
            />
          )}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="dossierId"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.defaultAdministration" />}
              select
            >
              {dossiers.map((dossier) => (
                <MenuItem key={dossier.dossierKey} value={dossier.dossierKey}>
                  {dossier.dossierDescription}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="leadingNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.leadingNumber" />}
              InputProps={{
                inputComponent: TextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 0,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 sx={{ display: { md: 'none' } }} size={6} />
      <Grid2 size={6}>
        <Controller
          name="generalLedgerMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.generalLedgerMappingType" />}
              select
            >
              {Object.values(GeneralLedgerMappingType)
                .filter(
                  (option) =>
                    tenantState?.useIdentities ||
                    option !== GeneralLedgerMappingType.PER_IDENTITY,
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage
                      id={`generalLedgerMappingTypes.${option}`}
                    />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      <Grid2 size={6}>
        <Controller
          name="costCenterMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.costCenterMappingType" />}
              select
            >
              {Object.values(CostCenterMappingType)
                .filter(
                  (option) =>
                    tenantState?.useIdentities ||
                    option !== CostCenterMappingType.PER_IDENTITY,
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage id={`costCenterMappingTypes.${option}`} />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      {dossierId && (
        <>
          <Grid2 size={6}>
            <Controller
              name="defaultGeneralLedger"
              render={(registered) => (
                <ReactHookFormAutocomplete
                  {...registered}
                  fullWidth
                  textField={{
                    fullWidth: true,
                    label: <FormattedMessage id="label.defaultGeneralLedger" />,
                  }}
                  identifier="externalId"
                  options={generalLedgerExternalOptions}
                  getOptionLabel={(
                    option: string | MappingTableExternalOption,
                  ) => {
                    if (typeof option === 'string') {
                      const found = generalLedgerExternalOptions.find(
                        ({ externalId }) => externalId === option,
                      );

                      return found?.label || option;
                    }

                    return option.label;
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="discountGeneralLedger"
              render={(registered) => (
                <ReactHookFormAutocomplete
                  {...registered}
                  fullWidth
                  textField={{
                    fullWidth: true,
                    label: (
                      <FormattedMessage id="label.discountGeneralLedger" />
                    ),
                  }}
                  identifier="externalId"
                  options={generalLedgerExternalOptions}
                  getOptionLabel={(
                    option: string | MappingTableExternalOption,
                  ) => {
                    if (typeof option === 'string') {
                      const found = generalLedgerExternalOptions.find(
                        ({ externalId }) => externalId === option,
                      );

                      return found?.label || option;
                    }

                    return option.label;
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="salesJournalCode"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  label={<FormattedMessage id="label.salesJournal" />}
                  select
                >
                  {journalsExternalOptions.map((journal) => (
                    <MenuItem
                      key={journal.externalId}
                      value={journal.externalId}
                    >
                      {journal.label}
                    </MenuItem>
                  ))}
                </ReactHookFormTextField>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="sendEntryNumbers"
              render={(registered) => (
                <ReactHookFormCheckbox
                  {...registered}
                  label={
                    <FormattedMessage id="label.useInvoiceNumberAsTransactionNumber" />
                  }
                />
              )}
            />
          </Grid2>
          <Grid2 container spacing={4} mt={4} size={12}>
            {useIdentities && (
              <Grid2 size={12}>
                <Typography
                  variant="subtitle1"
                  className="Bas-ContentSection-DontChangeColor"
                  pb={2}
                >
                  <FormattedMessage id="settings.integrationDetails.administrationMapping" />
                </Typography>
                <MappingTableForm
                  basOptions={identityOptions}
                  externalOptions={dossiersOptions}
                  prefix="administrationMapping"
                  basIdLabel={<FormattedMessage id="label.identity" />}
                  externalIdLabel={
                    <FormattedMessage id="label.administration" />
                  }
                />
              </Grid2>
            )}
            <Grid2 size={12}>
              <Typography
                variant="subtitle1"
                className="Bas-ContentSection-DontChangeColor"
                pb={2}
              >
                <FormattedMessage id="settings.integrationDetails.generalLedgerMapping" />
              </Typography>
              <GeneralLedgerMappingForm
                generalLedgerMappingType={generalLedgerMappingType}
                externalOptions={generalLedgerExternalOptions}
              />
            </Grid2>
            <Grid2
              hidden={costCenterMappingType === CostCenterMappingType.NONE}
              size={12}
            >
              <Typography
                variant="subtitle1"
                className="Bas-ContentSection-DontChangeColor"
                pb={2}
              >
                <FormattedMessage id="settings.integrationDetails.costCenterMapping" />
              </Typography>
              <CostCenterMappingForm
                costCenterMappingType={costCenterMappingType}
                externalOptions={costCenterExternalOptions}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="subtitle1"
                className="Bas-ContentSection-DontChangeColor"
                pb={2}
              >
                <FormattedMessage id="settings.integrationDetails.vatMapping" />
              </Typography>
              <VatMappingForm externalOptions={vatExternalOptions} />
            </Grid2>
          </Grid2>
        </>
      )}
    </Grid2>
  );
};

export default OctopusSettingsForm;
