import { colors, fontSizesWeb } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import {
  faCircleExclamation,
  faExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type AlertProps = MuiAlertProps & { hideIcon?: boolean };

const mapIcons = {
  success: undefined,
  info: undefined,
  warning: faExclamation,
  error: faCircleExclamation,
};

const Alert = ({ severity, icon, hideIcon, ...props }: AlertProps) => {
  let severityIcon;
  if (!icon && severity && mapIcons[severity]) {
    severityIcon = mapIcons[severity];
  }

  return (
    <MuiAlert
      severity={severity}
      icon={
        hideIcon
          ? false
          : icon || (severityIcon && <Icon icon={severityIcon} />)
      }
      {...props}
    />
  );
};

const StyledAlert = styled(Alert)(
  ({ theme }) => `
  padding: ${theme.spacing(1, 3)};
  &.MuiAlert-standardWarning {
    background: ${colors.orange[600]};
    .MuiAlert-icon, .MuiAlert-Message {
      color: ${colors.orange[900]};
    }
  }

  .MuiAlert-icon {
    align-items: center;
    margin-right: ${theme.spacing(2)};
  }

  .MuiAlert-action {
    align-items: center;
  }

  .MuiAlert-message {
    font-size: ${fontSizesWeb.base};
    line-height: 22px;
  }
`,
);
export default StyledAlert;
