import { EmployeeAttributeInputType } from '@bas/hrm-domain/input-types';
import { ReactHookFormTextField } from '@bas/ui/web/molecules';
import { Grid2 } from '@mui/material';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type EmployeeAttributeFormProps = {
  className?: string;
  children?: ReactNode;
};

const EmployeeAttributeForm = ({
  className,
  children,
}: EmployeeAttributeFormProps): ReactElement => (
  <Grid2 container rowSpacing={3} columnSpacing={2} className={className}>
    <Grid2 size={12}>
      <Controller<EmployeeAttributeInputType>
        name="name"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.name" />}
          />
        )}
      />
    </Grid2>
    <Grid2 size={12}>
      <Controller<EmployeeAttributeInputType>
        name="description"
        render={(registered) => (
          <ReactHookFormTextField
            {...registered}
            fullWidth
            label={<FormattedMessage id="label.description" />}
          />
        )}
      />
    </Grid2>
    {children}
  </Grid2>
);

export default EmployeeAttributeForm;
