import {
  DayWithHoursToBeApprovedOrWithoutHours,
  isHoursEntry,
} from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DayWithHoursToBeApprovedOrWithoutHoursRequestProps = Pagination;

export const DayWithHoursToBeApprovedOrWithoutHoursRequest = async ({
  ...params
}: DayWithHoursToBeApprovedOrWithoutHoursRequestProps): Promise<
  AxiosResponse<Collection<DayWithHoursToBeApprovedOrWithoutHours>>
> =>
  axios.get(
    `api/{tenantId}/hrm/employees/hour-entries/day-with-hours-to-be-approved-or-without-hours`,
    { params },
  );

export const useDayWithHoursToBeApprovedOrWithoutHoursRequest = (
  request: DayWithHoursToBeApprovedOrWithoutHoursRequestProps = {},
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<DayWithHoursToBeApprovedOrWithoutHours>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<DayWithHoursToBeApprovedOrWithoutHours>>
  > = {},
): UseQueryResult<
  AxiosResponse<Collection<DayWithHoursToBeApprovedOrWithoutHours>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<DayWithHoursToBeApprovedOrWithoutHours>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<DayWithHoursToBeApprovedOrWithoutHours>>
  >({
    ...options,
    queryFn: async () =>
      DayWithHoursToBeApprovedOrWithoutHoursRequest({ ...request }),
    queryKey: ['dayWithHoursToBeApprovedOrWithoutHours', 'list'],
  });

export const DayWithHoursToBeApprovedOrWithoutHoursRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isHoursEntry(data)) {
      queryClient.invalidateQueries({
        queryKey: ['dayWithHoursToBeApprovedOrWithoutHours', 'list'],
      });
    }
  };
