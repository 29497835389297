import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import {
  ImportExternalEmployeeInputType,
  ImportExternalEmployeeInputTypeDefaultValues,
  ImportExternalEmployeeInputTypeValidationBuilder,
} from '@bas/integration-domain/input-types';
import { ExternalEmployee } from '@bas/integration-domain/models';
import { Button, DriversLicenceBlock } from '@bas/ui/web/atoms';
import { DialogProps, ReactHookFormAutocomplete } from '@bas/ui/web/molecules';
import { FormDialog } from '@bas/ui/web/organisms';
import { DriversLicenceCharacteristics } from '@bas/value-objects';
import { Grid2, styled } from '@mui/material';
import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import {
  Controller,
  SubmitHandler,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

type InputType = {
  externalEmployees: ImportExternalEmployeeInputType[];
};

export type ImportExternalEmployeesFormDialogProps = Omit<
  DialogProps,
  'onSubmit'
> & {
  open: boolean;
  externalEmployees: ExternalEmployee[];
  onSubmit: SubmitHandler<{
    externalEmployees: ImportExternalEmployeeInputType[];
  }>;
};

const ExternalEmployeeFormRowContent = ({
  externalEmployee: {
    personName: { fullName },
  },
  index,
}: {
  externalEmployee: ExternalEmployee;
  index: number;
}) => {
  const { formatMessage } = useIntl();
  const selectedSkills = useWatch<
    InputType,
    'externalEmployees.0.employeeSkills'
  >({
    name: `externalEmployees.${index}.employeeSkills` as 'externalEmployees.0.employeeSkills',
  });

  const getOptionLabel = useCallback(
    (option: string): string =>
      formatMessage({ id: `employeeSkills.${option}` }),
    [formatMessage],
  );

  const availableSkills = useMemo(
    () =>
      [...Object.values(OfficeSkill), ...Object.values(MovingJobSkill)].filter(
        (skill) => !selectedSkills.includes(skill),
      ),
    [selectedSkills],
  );

  const keys: (keyof DriversLicenceCharacteristics)[] = [
    'bLicense',
    'beLicense',
    'bPlusLicense',
    'cLicense',
    'c1License',
    'c1eLicense',
    'ceLicense',
    'code95License',
  ];

  const driversLicense = useWatch<
    InputType,
    'externalEmployees.0.driversLicense'
  >({
    name: `externalEmployees.${index}.driversLicense` as 'externalEmployees.0.driversLicense',
  });

  const { setValue } = useFormContext<InputType>();

  return (
    <Grid2 container spacing={2} alignItems="center" pb={2} size={12}>
      <Grid2 size={2}>{fullName}</Grid2>
      <Grid2 size={5}>
        <Controller
          name={`externalEmployees.${index}.employeeSkills`}
          render={(registered) => (
            <ReactHookFormAutocomplete
              {...registered}
              fullWidth
              multiple
              textField={{
                label: <FormattedMessage id="label.skills" />,
              }}
              options={availableSkills}
              getOptionLabel={getOptionLabel}
              disableCloseOnSelect
            />
          )}
        />
      </Grid2>
      <Grid2 container spacing={1} overflow="scroll" flexWrap="nowrap" size={5}>
        {keys.map((key) => (
          <Grid2 key={key}>
            <DriversLicenceBlock
              disabled={!driversLicense[key]}
              onClick={() =>
                setValue(
                  `externalEmployees.${index}.driversLicense.${key}`,
                  !driversLicense[key],
                )
              }
            >
              <FormattedMessage id={`driversLicenses.${key}`} />
            </DriversLicenceBlock>
          </Grid2>
        ))}
      </Grid2>
    </Grid2>
  );
};

const FormContent = ({
  externalEmployees,
}: {
  externalEmployees: ExternalEmployee[];
}): ReactElement => (
  <Grid2 container alignItems="stretch" spacing={4}>
    <Grid2 size={12}>
      <FormattedMessage id="label.selectTheSkillsAndDriversLicensesForTheNewEmployees" />
    </Grid2>
    <Grid2 container size={12} spacing={0}>
      {externalEmployees.map((externalEmployee, index) => (
        <ExternalEmployeeFormRowContent
          externalEmployee={externalEmployee}
          index={index}
          key={externalEmployee.id}
        />
      ))}
    </Grid2>
  </Grid2>
);

const ImportExternalEmployeesFormDialog = ({
  onClose,
  externalEmployees,
  ...args
}: ImportExternalEmployeesFormDialogProps): ReactElement | null => {
  if (!externalEmployees.length) {
    return null;
  }

  return (
    <FormDialog<InputType>
      name="import-external-employees"
      onClose={onClose}
      title={<FormattedMessage id="label.importEmployees" />}
      validationSchema={Yup.object({
        externalEmployees: Yup.array().of(
          ImportExternalEmployeeInputTypeValidationBuilder(),
        ),
      })}
      dialogActions={[
        <Button type="submit" key="save">
          <FormattedMessage id="button.import" />
        </Button>,
      ]}
      defaultValues={{
        externalEmployees: externalEmployees.map((externalEmployee) => ({
          ...ImportExternalEmployeeInputTypeDefaultValues(),
          ...externalEmployee,
        })),
      }}
      maxWidth="xl"
      fullWidth
      useProvider
      {...args}
    >
      <FormContent externalEmployees={externalEmployees} />
    </FormDialog>
  );
};

const StyledImportExternalEmployeesFormDialog = styled(
  ImportExternalEmployeesFormDialog,
)``;

export default StyledImportExternalEmployeesFormDialog;
