import { ExactOnlineGLAccount } from '@bas/integration-domain/models';
import { Pagination, QueryOptionsWithKey } from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequestProps =
  Pagination & {
    integrationId: Uuid;
    divisionCode: string;
  };

type Response = AxiosResponse<Collection<ExactOnlineGLAccount>>;

export const ExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequest =
  async ({
    integrationId,
    divisionCode,
    ...params
  }: ExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequestProps): Promise<Response> =>
    axios.get(
      `api/{tenantId}/integrations/${integrationId}/exact-online/${divisionCode}/exact-online-general-ledger-accounts`,
      {
        params: { ...params },
        paramsSerializer(param) {
          return Qs.stringify(param, { arrayFormat: 'brackets' });
        },
      },
    );

export const useExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequest = (
  request: ExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequest(request),
    queryKey: [
      'integrations',
      'exact-online-general-ledger-accounts',
      request.integrationId,
      request,
    ],
  });
