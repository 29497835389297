import { colors, fontSizesWeb } from '@bas/theme';
import { createTheme } from '@mui/material';
import * as locales from '@mui/material/locale';

export const backofficeTheme = createTheme(
  {
    spacing: (factor: number | string) => {
      const floatFactor = parseFloat(factor as string);
      if (
        (typeof factor === 'string' &&
          (factor.includes('rem') ||
            factor.includes('px') ||
            factor.includes('%') ||
            factor.includes('em'))) ||
        (typeof factor === 'string' && Number.isNaN(floatFactor))
      ) {
        return factor;
      }

      const values = [0, 8, 16, 24, 32, 48, 56, 64, 72, 96, 120];
      const index = Math.floor(floatFactor);
      const currentSpace = values[index];
      const nextSpace = values[index + 1] || currentSpace * 2;
      const space =
        currentSpace + (nextSpace - currentSpace) * (floatFactor - index);
      return `${space}px`;
    },
    palette: {
      text: {
        primary: colors.lila[800],
        disabled: colors.lila[400],
      },
      primary: {
        main: colors.blue[500],
        light: colors.blue[200],
        dark: colors.blue[700],
      },
      secondary: {
        main: colors.blue[500],
        light: '#E6EEFF',
      },
      success: {
        contrastText: colors.lila[800],
        main: colors.green[300],
      },
      error: {
        main: colors.red[700],
      },
      warning: {
        contrastText: colors.lila[800],
        main: colors.orange[700],
      },
      background: {
        default: colors.white,
      },
      action: {
        disabled: colors.lila[400],
        disabledOpacity: 1,
      },
    },
    typography: {
      fontFamily: ['DM Sans, sans-serif'].join(','),
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '1px',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          menuItem: {
            justifyContent: 'center',
            fontSize: fontSizesWeb.sm,
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: 'inherit !important',
              color: colors.blue[500],
            },
            '&:hover': {
              backgroundColor: 'inherit',
              color: colors.blue[500],
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body2: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: fontSizesWeb.sm,
            lineHeight: '18px',
          },
          body1: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: fontSizesWeb.base,
            lineHeight: '24px',
          },
          caption: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '13px',
          },
          button: {},
          h1: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '64px',
            lineHeight: '83px',
          },
          h2: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '48px',
            lineHeight: '62px',
          },
          h3: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: fontSizesWeb['2xl'],
            lineHeight: '47px',
          },
          h4: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: fontSizesWeb.xl,
            lineHeight: '31px',
          },
          h5: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: fontSizesWeb.lg,
            lineHeight: '26px',
            color: colors.lila[800],
          },
          h6: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: fontSizesWeb.lg,
            lineHeight: '26px',
          },
          subtitle1: {
            fontFamily: 'Work Sans',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '1.2px',
            textTransform: 'uppercase',
          },
          subtitle2: {
            fontFamily: 'DM Sans',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: fontSizesWeb.base,
            lineHeight: '22px',
            color: colors.lila[600],
          },
        },
      },
    },
  },
  locales.nlNL,
);
