import { NmbrsSettingsInputType } from '@bas/integration-domain/input-types';
import { Integration } from '@bas/integration-domain/models';
import { useUpdateIntegrationSettingsMutation } from '@bas/integration-domain/mutations';
import {
  EditableNmbrsSettingsBlock,
  IntegrationActionButtons,
} from '@bas/integration-domain/web/organisms';
import { formatDate } from '@bas/shared/utils';
import { Grid2 } from '@mui/material';
import { ReactElement, useCallback } from 'react';

export type NmbrsSettingsTabProps = {
  integration: Integration;
};

const NmbrsSettingsTab = ({
  integration,
}: NmbrsSettingsTabProps): ReactElement => {
  const { mutateAsync: updateIntegrationSettingsMutation } =
    useUpdateIntegrationSettingsMutation();

  const handleUpdateSettings = useCallback(
    async (settings: NmbrsSettingsInputType) => {
      await updateIntegrationSettingsMutation({
        integrationId: integration.integrationId,
        provider: integration.provider,
        settings: {
          provider: integration.provider,
          ...settings,
          startDate: formatDate(settings.startDate as Date),
        },
      });
    },
    [
      integration.integrationId,
      integration.provider,
      updateIntegrationSettingsMutation,
    ],
  );

  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <IntegrationActionButtons integrationId={integration.integrationId} />
      </Grid2>
      <Grid2 size={12}>
        <EditableNmbrsSettingsBlock
          integration={integration}
          onUpdateSettings={handleUpdateSettings}
        />
      </Grid2>
    </Grid2>
  );
};

export default NmbrsSettingsTab;
