import {
  InvoiceLine,
  isQuoteLine,
  QuoteLine,
  TurnoverGroup,
} from '@bas/financial-domain/models';
import {
  FormattedCurrency,
  OptionalLabel,
  TableCell,
  TableRow,
  Tooltip,
} from '@bas/ui/web/atoms';
import { styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';

export type FinancialDocumentLineTableRowProps = {
  className?: string;
  line: QuoteLine | InvoiceLine;
  documentFinalized?: boolean;
  turnoverGroups: TurnoverGroup[];
};

const FinancialDocumentLineTableRow = ({
  className,
  line,
  documentFinalized,
  turnoverGroups,
}: FinancialDocumentLineTableRowProps): ReactElement => (
  <TableRow
    className={clsx(className, {
      'Bas-FinancialDocumentLineTableRow-Declined':
        documentFinalized &&
        isQuoteLine(line) &&
        line.optional &&
        !line.accepted,
    })}
  >
    <TableCell paddingLeft sx={{ whiteSpace: 'pre-wrap' }}>
      {line.description}
      {isQuoteLine(line) && line.optional && <OptionalLabel />}
    </TableCell>
    <TableCell sx={{ textAlign: 'right', width: '55px' }}>
      {`${line.quantity}x`}
    </TableCell>
    <TableCell sx={{ textAlign: 'right', width: '100px' }}>
      <FormattedCurrency {...line.pricePerUnit} />
    </TableCell>
    <TableCell sx={{ textAlign: 'right', width: '76px' }}>
      {`${line.vatPercentage.percentage}%`}
    </TableCell>
    <TableCell sx={{ textAlign: 'right', width: '100px' }} paddingRight>
      <Tooltip
        title={
          <Typography>
            {
              turnoverGroups.find(
                ({ turnoverGroupId }) =>
                  line.turnoverGroupId === turnoverGroupId,
              )?.name
            }
          </Typography>
        }
        disabled={!line.turnoverGroupId}
      >
        <span>
          <FormattedCurrency {...line.totalPrice} />
        </span>
      </Tooltip>
    </TableCell>
  </TableRow>
);

const StyledFinancialDocumentLineTableRow = styled(
  FinancialDocumentLineTableRow,
)(
  () => `
  &.Bas-FinancialDocumentLineTableRow-Declined {
    .MuiTableCell-root {
      opacity: 0.3;
    }
  }
`,
);
export default StyledFinancialDocumentLineTableRow;
