import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { isSupplier, Supplier } from '@bas/wms-domain/models';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type SuppliersRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<Supplier>>;

export const SuppliersRequest = async ({
  ...params
}: SuppliersRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/inventory/suppliers`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useSuppliersRequest = (
  request: SuppliersRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => SuppliersRequest(request),
    queryKey: ['suppliers', 'list', request],
  });

export const usePrefetchSuppliersRequest = (
  request: SuppliersRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['suppliers', 'list', request],
      queryFn: async () => SuppliersRequest(request),
    });
  }, [queryClient, request]);
};

export const SuppliersRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isSupplier(data)) {
    queryClient.invalidateQueries({ queryKey: ['suppliers', 'list'] });
  }
};
