import { useMemo } from 'react';
import { RRule } from 'rrule';
import { Weekday } from 'rrule/dist/esm/weekday';

const dutchTranslations: { [key: string]: string } = {
  every: 'elke',
  until: 'tot',
  day: 'dag',
  days: 'dagen',
  week: 'week',
  weeks: 'weken',
  on: 'op',
  at: 'om',
  and: 'en',
  the: 'de',
  first: 'eerste',
  second: 'tweede',
  third: 'derde',
  fourth: 'vierde',
  fifth: 'vijfde',
  last: 'laatste',
  January: 'januari',
  February: 'februari',
  March: 'maart',
  April: 'april',
  May: 'mei',
  June: 'juni',
  July: 'juli',
  August: 'augustus',
  September: 'september',
  October: 'oktober',
  November: 'november',
  December: 'december',
};

const dutchDayNames = [
  'zondag',
  'maandag',
  'dinsdag',
  'woensdag',
  'donderdag',
  'vrijdag',
  'zaterdag',
];
const dutchMonthNames = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
];

const gettext = (id: string | number | Weekday): string =>
  dutchTranslations[id as keyof typeof dutchTranslations] || id.toString();

export const convertRRuleToText = (rrule: string): string => {
  if (!rrule) {
    return '';
  }

  const rruleObject = RRule.fromString(rrule);

  return rruleObject?.isFullyConvertibleToText()
    ? rruleObject?.toText(gettext, {
        dayNames: dutchDayNames,
        monthNames: dutchMonthNames,
        tokens: {},
      })
    : rruleObject?.toString();
};

export const useConvertRRuleToText = (
  rrule: string | undefined | null,
): string => useMemo(() => convertRRuleToText(rrule || ''), [rrule]);
