import { TabProps } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { TabsWithinRightSide } from '@bas/ui/web/organisms';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { TabContext } from '@mui/lab';
import { Box, Grid2, IconButton, Slide, styled } from '@mui/material';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { SwitchTransition } from 'react-transition-group';

export type RightSideWithTabsTemplateProps = {
  header: ReactNode | ReactNode[];
  setActiveTab?: (newTab: string) => void;
  activeTab: string;
  tabs: (Omit<TabProps, 'content'> & { content: ReactNode; tabKey: string })[];
  className?: string;
  onClose?: () => void;
};

const RightSideWithTabsTemplate = ({
  header,
  setActiveTab,
  activeTab,
  tabs,
  className,
  onClose,
}: RightSideWithTabsTemplateProps): ReactElement => {
  // const swiperRef = useRef<SwiperRef>(null);
  const [direction, setDirection] = useState<'left' | 'right'>('left');

  const activeTabIndex = useMemo(
    () => tabs.findIndex(({ tabKey }) => tabKey === activeTab),
    [activeTab, tabs],
  );

  const [tabIndex, setTabIndex] = useState(activeTabIndex);
  useEffect(() => {
    (async () => {
      if (activeTabIndex === tabIndex) {
        return;
      }

      if (activeTabIndex > tabIndex) {
        setDirection('right');
      } else {
        setDirection('left');
      }

      await new Promise((r) => {
        setTimeout(r, 25);
      });

      setTabIndex(activeTabIndex);
    })();
  }, [activeTabIndex, tabIndex]);

  return (
    <Box className={className}>
      {onClose && (
        <IconButton
          className="Bas-RightSideWithTabsTemplate-Close"
          onClick={onClose}
        >
          <Icon icon={faTimes} />
        </IconButton>
      )}
      <TabContext value={activeTab}>
        <Box className="Bas-RightSideWithTabsTemplate-Header">
          <Grid2
            container
            alignItems="center"
            rowSpacing={1}
            columnSpacing={2}
            flexWrap="wrap-reverse"
          >
            {Array.isArray(header) ? (
              header.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid2 key={index}>{item}</Grid2>
              ))
            ) : (
              <Grid2 size={12}>{header}</Grid2>
            )}
          </Grid2>
          <TabsWithinRightSide
            activeTab={activeTab}
            onChange={setActiveTab}
            tabs={tabs.map(({ label, tabKey, ...tab }) => ({
              label,
              ...tab,
              tabKey,
              value: tabKey,
            }))}
          />
        </Box>
        <Box className="Bas-RightSideWithTabsTemplate-Content">
          <Box className="Bas-RightSideWithTabsTemplate-Tab">
            <SwitchTransition mode="out-in">
              <Slide
                timeout={175}
                direction={direction}
                key={tabIndex}
                onExiting={() =>
                  setDirection((val) => (val === 'left' ? 'right' : 'left'))
                }
              >
                <Box className="step">{tabs[tabIndex]?.content}</Box>
              </Slide>
            </SwitchTransition>
          </Box>
        </Box>
      </TabContext>
    </Box>
  );
};

const StyledRightSideWithTabsTemplate = styled(RightSideWithTabsTemplate)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  .Bas-RightSideWithTabsTemplate-Close {
    position: absolute;
    font-size: 24px;
    padding: 0;
    z-index: 15;
    top: -${theme.spacing(3)};
    left: -${theme.spacing(3)};
    ${theme.breakpoints.down('lg')} {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
    @media (max-width: 1024px) {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
  }

  .Bas-RightSideWithTabsTemplate-Content {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    *[aria-hidden="true"] {
      max-height: 150px;
    }

    & .Bas-RightSideWithTabsTemplate-Tab {
      padding: 0;
      padding-top: ${theme.spacing(3)};
      padding-bottom: ${theme.spacing(3)};
      height: 100%;
      box-sizing: border-box;
      position: relative;
      .step {
        height: 100%;
      }
    }
  }
`,
);
export default StyledRightSideWithTabsTemplate;
