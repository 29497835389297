import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { isDocumentTemplate } from '@bas/tenant-domain/models';
import { Language, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type PreviewDocumentTemplateByLanguageRequestProps = {
  documentTemplateId: Uuid;
  language: Language;
};

export const PreviewDocumentTemplateByLanguageRequest = async ({
  documentTemplateId,
  language,
  ...params
}: PreviewDocumentTemplateByLanguageRequestProps): Promise<
  AxiosResponse<Blob>
> =>
  axios.get(
    `api/{tenantId}/document-templates/${documentTemplateId}/preview-pdf/${language}`,
    {
      responseType: 'blob',
      params,
    },
  );

export const usePreviewDocumentTemplateByLanguageRequestQuery = (
  request: PreviewDocumentTemplateByLanguageRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => PreviewDocumentTemplateByLanguageRequest(request),
    queryKey: [
      'document-templates',
      'pdf',
      request.documentTemplateId,
      request.language,
    ],
  });

export const PreviewDocumentTemplateByLanguageRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isDocumentTemplate(data)) {
      queryClient.invalidateQueries({
        queryKey: ['document-templates', 'pdf', data.documentTemplateId],
      });
    }
  };
