import { ConnectSchoutenZekerheidIntegrationInputType } from '@bas/integration-domain/input-types';
import { useTenantStore } from '@bas/shared/state';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import {
  IdentityIndicator,
  ReactHookFormTextField,
  Switch,
} from '@bas/ui/web/molecules';
import { Grid2, Skeleton } from '@mui/material';
import { ReactElement, useEffect, useMemo } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const ConnectSchoutenZekerheidForm = (): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);

  const { data: identitiesData, isInitialLoading: isLoadingIdentities } =
    useIdentitiesRequest({}, { enabled: tenantState?.useIdentities || false });

  const identities = useMemo(
    () => identitiesData?.data?.member || [],
    [identitiesData],
  );

  const apiKeyPerIdentity = useWatch<
    ConnectSchoutenZekerheidIntegrationInputType,
    'apiKeyPerIdentity'
  >({
    name: 'apiKeyPerIdentity',
  });
  const { fields, replace } =
    useFieldArray<ConnectSchoutenZekerheidIntegrationInputType>({
      name: 'apiKeys',
    });

  const { setValue } =
    useFormContext<ConnectSchoutenZekerheidIntegrationInputType>();

  useEffect(() => {
    if (fields.length === 0) {
      replace(
        identities.map((identity) => ({
          apiKey: '',
          identityId: identity.identityId,
        })),
      );
    }
  }, [identities, replace, fields.length]);

  return (
    <Grid2 container rowSpacing={3} columnSpacing={2}>
      {isLoadingIdentities && <Skeleton variant="rectangular" height={40} />}
      {!isLoadingIdentities &&
        (!tenantState?.useIdentities || !apiKeyPerIdentity) && (
          <Grid2 size={12}>
            <Controller
              name="apiKey"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  label={<FormattedMessage id="label.apiKey" />}
                />
              )}
            />
          </Grid2>
        )}
      {tenantState?.useIdentities && (
        <Grid2 size={12}>
          <Switch
            label={<FormattedMessage id="label.apiKeyPerIdentity" />}
            value={apiKeyPerIdentity}
            checked={apiKeyPerIdentity}
            onChange={(_, checked) => setValue('apiKeyPerIdentity', checked)}
          />
        </Grid2>
      )}
      {apiKeyPerIdentity &&
        tenantState?.useIdentities &&
        fields.map(({ id, identityId }, index) => (
          <Grid2 key={id} size={12}>
            <Controller
              name={`apiKeys.${index}.apiKey`}
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  label={<IdentityIndicator identityId={identityId} full />}
                />
              )}
            />
          </Grid2>
        ))}
    </Grid2>
  );
};

export default ConnectSchoutenZekerheidForm;
