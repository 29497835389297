import { Event, isProjectEvent } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type EventsByProjectIdRequestProps = Pagination & {
  projectId: Uuid;
  fromDate?: string;
  untilDate?: string;
};

type Response = AxiosResponse<Collection<Event>>;

export const EventsByProjectIdRequest = async ({
  projectId,
  fromDate,
  untilDate,
  ...params
}: EventsByProjectIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events/by-project/${projectId}`, {
    params: { ...params, fromDate, untilDate },
  });

export const useEventsByProjectIdRequest = (
  request: EventsByProjectIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      EventsByProjectIdRequest({ perPage: 1000, ...request }),
    queryKey: [
      'events',
      'list',
      request.projectId,
      request.fromDate ? formatDate(request.fromDate) : undefined,
      request.untilDate ? formatDate(request.untilDate) : undefined,
    ],
  });

export const EventsByProjectIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isProjectEvent(data)) {
    queryClient.invalidateQueries({
      queryKey: ['events', 'list', data.projectId],
    });
  }
};

export const usePrefetchEventsByProjectIdRequest = (
  request: EventsByProjectIdRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'events',
        'list',
        request.projectId,
        request.fromDate ? formatDate(request.fromDate) : undefined,
        request.untilDate ? formatDate(request.untilDate) : undefined,
      ],
      queryFn: async () =>
        EventsByProjectIdRequest({ perPage: 100, ...request }),
    });
  }, [queryClient, request]);
};
