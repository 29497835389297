import { isVehicle, Vehicle } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type VehicleByMaterialIdRequestProps = Pagination & {
  materialId: Uuid;
};

type Response = AxiosResponse<Vehicle>;
type QueryKeyType = QueryKey & {
  [0]: 'vehicles';
  [1]: 'details';
  [2]: Uuid;
};

export const VehicleByMaterialIdRequest = async ({
  materialId,
  ...params
}: VehicleByMaterialIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/vehicles/${materialId}`, {
    params: { ...params },
  });

export const useVehicleByMaterialIdRequest = (
  request: VehicleByMaterialIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => VehicleByMaterialIdRequest({ ...request }),
    queryKey: ['vehicles', 'details', request.materialId],
  });

export const VehicleByMaterialIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isVehicle(data)) {
    queryClient.invalidateQueries({
      queryKey: ['vehicles', 'details', data.materialId],
    });
  }
};
