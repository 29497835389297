import { UiFeatureInputType } from '@bas/tenant-domain/input-types';
import { UiFeature } from '@bas/tenant-domain/models';
import { TextField } from '@bas/ui/web/atoms';
import { FeatureHelpTooltip, Switch } from '@bas/ui/web/molecules';
import { Grid2, MenuItem, styled, Typography } from '@mui/material';
import hash from 'object-hash';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useDebounce from 'react-use/lib/useDebounce';

export type EditableUiFeatureBlockProps = {
  feature: UiFeature;
  disabled?: boolean;
  onUpdateUiFeature: (values: UiFeatureInputType) => Promise<void>;
};
const options: ('employeeHours' | 'movingLiftHours' | 'movingTruckHours')[] = [
  'employeeHours',
  'movingLiftHours',
  'movingTruckHours',
];

const EditableUiFeatureBlock = ({
  feature,
  disabled,
  onUpdateUiFeature,
  ...args
}: EditableUiFeatureBlockProps): ReactElement => {
  const [numberOfItemsPerPageInput, setNumberOfItemsPerPageInput] =
    useState<number>(feature.numberOfItemsPerPage);
  const [
    hideBackofficeOnlyEmployeesInput,
    setHideBackofficeOnlyEmployeesInput,
  ] = useState<boolean>(feature.hideBackofficeOnlyEmployees);
  const [
    defaultDisableAutomaticTravelTimeInput,
    setDefaultDisableAutomaticTravelTimeInput,
  ] = useState<boolean>(feature.defaultDisableAutomaticTravelTime);
  const [
    defaultHideFilesForNonBackofficeEmployeesInput,
    setDefaultHideFilesForNonBackofficeEmployeesInput,
  ] = useState<boolean>(feature.defaultHideFilesForNonBackofficeEmployees);
  const [availabilityBasedUponInput, setAvailabilityBasedUponInput] = useState<
    ('employeeHours' | 'movingLiftHours' | 'movingTruckHours')[]
  >(feature.availabilityBasedUpon);

  useDebounce(
    () => {
      if (
        numberOfItemsPerPageInput !== feature.numberOfItemsPerPage ||
        hideBackofficeOnlyEmployeesInput !==
          feature.hideBackofficeOnlyEmployees ||
        defaultDisableAutomaticTravelTimeInput !==
          feature.defaultDisableAutomaticTravelTime ||
        defaultHideFilesForNonBackofficeEmployeesInput !==
          feature.defaultHideFilesForNonBackofficeEmployees ||
        hash(availabilityBasedUponInput) !== hash(feature.availabilityBasedUpon)
      ) {
        onUpdateUiFeature({
          ...feature,
          numberOfItemsPerPage: numberOfItemsPerPageInput,
          availabilityBasedUpon: availabilityBasedUponInput,
          hideBackofficeOnlyEmployees: hideBackofficeOnlyEmployeesInput,
          defaultDisableAutomaticTravelTime:
            defaultDisableAutomaticTravelTimeInput,
          defaultHideFilesForNonBackofficeEmployees:
            defaultHideFilesForNonBackofficeEmployeesInput,
        });
      }
    },
    300,
    [
      numberOfItemsPerPageInput,
      feature.numberOfItemsPerPage,
      availabilityBasedUponInput,
      hideBackofficeOnlyEmployeesInput,
      feature.availabilityBasedUpon,
      defaultDisableAutomaticTravelTimeInput,
      defaultHideFilesForNonBackofficeEmployeesInput,
      feature.defaultDisableAutomaticTravelTime,
      feature.defaultHideFilesForNonBackofficeEmployees,
    ],
  );

  return (
    <Grid2 container spacing="12px" {...args}>
      <Grid2 pt={2} size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="featureTypes.ui" />
        </Typography>
      </Grid2>
      <Grid2 container spacing={1} alignItems="center" size={12}>
        <Grid2>
          <TextField
            value={numberOfItemsPerPageInput}
            disabled={disabled}
            sx={{ width: '50px' }}
            onChange={(e) =>
              setNumberOfItemsPerPageInput(parseFloat(e.target.value))
            }
            select
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </TextField>
        </Grid2>
        <Grid2>
          <Typography color="textPrimary">
            <FormattedMessage
              id="features.ui.numberOfItemsPerPage"
              values={{
                numberOfItemsPerPage: numberOfItemsPerPageInput,
              }}
            />
          </Typography>
        </Grid2>
      </Grid2>
      {options.map((option) => (
        <Grid2 container spacing={1} size={12}>
          <Grid2>
            <Switch
              label={
                <FormattedMessage
                  id={`features.ui.availabilityBasedUpon.${option}`}
                />
              }
              checked={availabilityBasedUponInput.includes(option)}
              disabled={disabled}
              onChange={(e, val) => {
                setAvailabilityBasedUponInput((current) => {
                  const newValue = [...current.filter((o) => o !== option)];
                  if (!val) {
                    return newValue;
                  }

                  return [...newValue, option];
                });
              }}
            />
          </Grid2>
          <Grid2>
            <FeatureHelpTooltip
              title={
                <FormattedMessage id="features.ui.availabilityBasedUpon.help" />
              }
            />
          </Grid2>
        </Grid2>
      ))}
      <Grid2 container spacing={1} size={12}>
        <Grid2>
          <Switch
            label={
              <FormattedMessage id="features.ui.hideBackofficeOnlyEmployees" />
            }
            checked={hideBackofficeOnlyEmployeesInput}
            disabled={disabled}
            onChange={(e, val) => {
              setHideBackofficeOnlyEmployeesInput(val);
            }}
          />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={1} size={12}>
        <Grid2>
          <Switch
            label={
              <FormattedMessage id="features.ui.defaultDisableAutomaticTravelTime" />
            }
            checked={defaultDisableAutomaticTravelTimeInput}
            disabled={disabled}
            onChange={(e, val) => {
              setDefaultDisableAutomaticTravelTimeInput(val);
            }}
          />
        </Grid2>
      </Grid2>
      <Grid2 container spacing={1} size={12}>
        <Grid2>
          <Switch
            label={
              <FormattedMessage id="features.ui.defaultHideFilesForNonBackofficeEmployees" />
            }
            checked={defaultHideFilesForNonBackofficeEmployeesInput}
            disabled={disabled}
            onChange={(e, val) => {
              setDefaultHideFilesForNonBackofficeEmployeesInput(val);
            }}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

const StyledEditableUiFeatureBlock = styled(EditableUiFeatureBlock)(
  () => `
  .MuiFormControl-root {
    width: 50px;
  }
  .MuiInput-input {
    text-align: center;
  }
`,
);
export default StyledEditableUiFeatureBlock;
