import { useRefreshTokenMutation } from '@bas/authentication-domain/mutations';
import { useAuthStore } from '@bas/shared/state';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

export const useMonitorAndRefreshAccessToken = (logout: () => void) => {
  const refreshingToken = useRef(false);
  const { mutateAsync: refreshTokenRequest } = useRefreshTokenMutation();
  const getExpiresAt = useAuthStore((state) => state.getExpiresAt);
  const getRefreshToken = useAuthStore((state) => state.getRefreshToken);
  const setAuth = useAuthStore((state) => state.setAuth);
  const setIsRefreshing = useAuthStore((state) => state.setIsRefreshing);
  const hasRefreshToken = useAuthStore((state) => !!state.refresh_token);
  const expiresAt = useAuthStore((state) => state.expiresAt);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('check', hasRefreshToken, expiresAt);
    if (!hasRefreshToken) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let refreshingId: any | null = null;
    const checkAndRefreshToken = async () => {
      // eslint-disable-next-line no-console
      console.log('checkAndRefreshToken');
      if (refreshingToken.current) {
        // eslint-disable-next-line no-console
        console.log('already refreshing token');
        return;
      }

      const currentExpiresAt = getExpiresAt();
      if (!getExpiresAt().subtract(1, 'minute').isBefore(dayjs())) {
        let timeOut = currentExpiresAt.diff(dayjs(), 'second') * 1000;
        if (timeOut < 0) {
          timeOut = 1;
        }
        // eslint-disable-next-line no-console
        console.log('refreshing token in', timeOut);
        refreshingId = setTimeout(checkAndRefreshToken, timeOut);

        return;
      }

      // eslint-disable-next-line no-console
      console.log('refreshing token');
      refreshingToken.current = true;
      setIsRefreshing(true);
      try {
        const refreshToken = getRefreshToken();
        const refreshTokenResponse = await refreshTokenRequest({
          refresh_token: refreshToken || '',
        });

        if (refreshTokenResponse.data) {
          setAuth(refreshTokenResponse.data);
          const newExpiresAt = getExpiresAt();

          refreshingId = setTimeout(
            checkAndRefreshToken,
            newExpiresAt.diff(dayjs(), 'second') * 1000,
          );
        }
        refreshingToken.current = false;
        setIsRefreshing(false);

        // eslint-disable-next-line no-console
        console.log('refreshed token');
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 0) {
            refreshingToken.current = false;
            setIsRefreshing(false);

            await new Promise((r) => {
              setTimeout(r, 1000);
            });
            checkAndRefreshToken();

            return;
          }
        }

        // eslint-disable-next-line no-console
        console.log('error refreshing token', error);
        refreshingToken.current = false;
        setIsRefreshing(false);
        logout();
      }
    };

    checkAndRefreshToken();

    return () => {
      if (refreshingId !== null) {
        clearTimeout(refreshingId);
      }
    };
  }, [
    expiresAt,
    getExpiresAt,
    logout,
    getRefreshToken,
    refreshTokenRequest,
    setAuth,
    setIsRefreshing,
    hasRefreshToken,
  ]);
};
