import {
  isEvent,
  MaterialAvailabilityByDate,
} from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MaterialsAvailabilityByDateRequestProps = {
  date: Date;
};

type Response = AxiosResponse<MaterialAvailabilityByDate>;
type QueryKeyType = QueryKey & {
  [0]: 'material-availability';
  [1]: 'list';
  [2]: string;
};

export const MaterialsAvailabilityByDateRequest = async ({
  date,
  ...params
}: MaterialsAvailabilityByDateRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/availability/materials/${formatDate(date)}`,
    {
      params: { ...params },
    },
  );

export const useMaterialsAvailabilityByDateRequest = (
  request: MaterialsAvailabilityByDateRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => MaterialsAvailabilityByDateRequest({ ...request }),
    queryKey: ['material-availability', 'list', formatDate(request.date)],
  });

export const MaterialsAvailabilityByDateRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEvent(data)) {
    queryClient.invalidateQueries({
      queryKey: ['material-availability', 'list', formatDate(data.start)],
    });
  }
};
