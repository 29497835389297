import * as Yup from 'yup';

export type DisableSettingInputType = {
  disableAfter?: Date | null;
};

export const disableSettingInputTypeDefaultValues =
  (): DisableSettingInputType => ({
    disableAfter: null,
  });

export const disableSettingInputTypeValidationBuilder =
  (): Yup.ObjectSchema<DisableSettingInputType> =>
    Yup.object({
      disableAfter: Yup.date().required().label('label.disableAfter'),
    });
