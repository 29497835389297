import { Declaration, isDeclaration } from '@bas/hrm-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import Qs from 'qs';
import { useEffect } from 'react';

export type DeclarationsByEmployeeIdRequestProps = Pagination & {
  employeeId: Uuid;
  start?: Date | Dayjs | null;
  end?: Date | Dayjs | null;
};

type Response = AxiosResponse<Collection<Declaration>>;

export const DeclarationsByEmployeeIdRequest = async ({
  employeeId,
  start,
  end,
  ...params
}: DeclarationsByEmployeeIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/employees/${employeeId}/declarations`, {
    params: {
      ...params,
      'start[before]': start ? formatDate(start) : undefined,
      'end[after]': end ? formatDate(end) : undefined,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useDeclarationsByEmployeeIdRequest = (
  { employeeId, ...request }: DeclarationsByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      DeclarationsByEmployeeIdRequest({ ...request, employeeId }),
    queryKey: ['declarations', 'list', employeeId, request],
  });

export const usePrefetchDeclarationsByEmployeeIdRequest = ({
  employeeId,
  ...request
}: DeclarationsByEmployeeIdRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'declarations',
        'list',
        employeeId,
        ...Object.values({ employeeId, ...request }),
      ],
      queryFn: async () =>
        DeclarationsByEmployeeIdRequest({ employeeId, ...request }),
    });
  }, [employeeId, queryClient, request]);
};

export const DeclarationsByEmployeeIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isDeclaration(data)) {
    queryClient.invalidateQueries({
      queryKey: ['declarations', 'list', data.employeeId],
    });
  }
};
