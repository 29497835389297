import { isQuote, QuoteStatistics } from '@bas/financial-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';

export type QuoteStatisticsRequestProps = {
  startPeriod: Dayjs;
  endPeriod: Dayjs;
};
type Response = AxiosResponse<QuoteStatistics>;

export const QuoteStatisticsRequest = async ({
  startPeriod,
  endPeriod,
}: QuoteStatisticsRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/quotes/quote-statistics/${formatDate(
      startPeriod,
    )}/${formatDate(endPeriod)}`,
    {},
  );

export const useQuoteStatisticsRequest = (
  request: QuoteStatisticsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => QuoteStatisticsRequest(request),
    queryKey: ['quotes', 'statistics', request.startPeriod, request.endPeriod],
  });

export const QuoteStatisticsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isQuote(data)) {
    queryClient.invalidateQueries({ queryKey: ['quotes', 'statistics'] });
  }
};
