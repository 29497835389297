import { TranslatedName } from '@bas/value-objects';
import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

export type RenderTranslatedNameProps = {
  translations: TranslatedName[];
  fallback: string | ReactNode;
};

const RenderTranslatedName = ({
  translations,
  fallback,
}: RenderTranslatedNameProps): ReactElement => {
  const intl = useIntl();

  const translation = translations.find(
    ({ locale }) => locale.toLowerCase() === intl.locale.toLowerCase(),
  );
  if (translation) {
    return <span>{translation.name}</span>;
  }

  return <span>{fallback}</span>;
};

export default RenderTranslatedName;
