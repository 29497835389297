import { BatchData, isBatchData } from '@bas/integration-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type BatchDataByIntegrationIdRequestProps = Pagination &
  Filterable & {
    integrationId: Uuid;
  };

type Response = AxiosResponse<Collection<BatchData>>;

export const BatchDataByIntegrationIdRequest = async ({
  integrationId,
  ...params
}: BatchDataByIntegrationIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/integrations/${integrationId}/batch-datas`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useBatchDataByIntegrationIdRequest = (
  request: BatchDataByIntegrationIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => BatchDataByIntegrationIdRequest(request),
    queryKey: ['integrations', 'batch-data', request.integrationId, request],
  });

export const BatchDataByIntegrationIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isBatchData(data)) {
    queryClient.invalidateQueries({
      queryKey: ['integrations', 'batch-data', data.integrationId],
    });
  }
};
