import { isYukiSettings, YukiSettings } from '@bas/integration-domain/models';
import { useYukiMappingOptions } from '@bas/integration-domain/web/hooks';
import { Alert } from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid2, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountingIntegrationSettingsBlock } from '../AccountingIntegrationSettingsBlock';

export type YukiSettingsContentSectionProps = {
  integrationId: Uuid;
  providerSettings: YukiSettings;
  onEdit: () => void;
};

const YukiSettingsContentSection = ({
  integrationId,
  providerSettings,
  onEdit,
}: YukiSettingsContentSectionProps): ReactElement => {
  const {
    generalLedgersError,
    generalLedgerExternalOptions,
    vatExternalOptions,
    administrationsError,
    administrationsExternalOptions,
    domainsError,
  } = useYukiMappingOptions(integrationId);

  const administration = useMemo(
    () =>
      administrationsExternalOptions.find(
        ({ externalId }) => externalId === providerSettings.administrationId,
      ),
    [providerSettings.administrationId, administrationsExternalOptions],
  );

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid2 container columnSpacing={3}>
        <Grid2 size={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid2>
        {(generalLedgersError || administrationsError || domainsError) && (
          <Grid2 mt={2} size={12}>
            <Alert severity="error">
              <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
            </Alert>
          </Grid2>
        )}
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.administration" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {isYukiSettings(providerSettings) && administration ? (
              administration.label
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          {isYukiSettings(providerSettings) && (
            <AccountingIntegrationSettingsBlock
              providerSettings={providerSettings}
              externalGeneralLedgers={generalLedgerExternalOptions}
              externalCostCenters={administrationsExternalOptions}
              externalVatCodes={vatExternalOptions}
            />
          )}
        </Grid2>
      </Grid2>
    </ContentSection>
  );
};

export default YukiSettingsContentSection;
