import { useVatCodesRequest } from '@bas/financial-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';

export const useVatCodesBasOptions = () => {
  const { data: vatCodesData } = useVatCodesRequest(
    {
      perPage: 99999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  return useMemo(
    (): {
      basId: Uuid;
      label: string;
    }[] =>
      (vatCodesData?.data?.member || []).map(
        ({ vatCodeId, country, percentage: { percentage } }) => ({
          basId: vatCodeId,
          label: `${percentage}% - ${country}`,
        }),
      ),
    [vatCodesData?.data],
  );
};
