import { QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmailAttachmentByEmailIdAndContentIdRequestProps = {
  emailId: Uuid;
  attachmentId: Uuid;
};

export const EmailAttachmentByEmailIdAndContentIdRequest = async ({
  emailId,
  attachmentId,
  ...params
}: EmailAttachmentByEmailIdAndContentIdRequestProps): Promise<
  AxiosResponse<Blob>
> =>
  axios.get(
    `api/{tenantId}/communication/emails/${emailId}/attachments/${attachmentId}/download`,
    {
      responseType: 'blob',
      params,
    },
  );

export const useEmailAttachmentByEmailIdAndContentIdRequestQuery = (
  request: EmailAttachmentByEmailIdAndContentIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Blob>,
    AxiosError,
    AxiosResponse<Blob>
  > = {},
): UseQueryResult<AxiosResponse<Blob>, AxiosError> =>
  useQuery<AxiosResponse<Blob>, AxiosError, AxiosResponse<Blob>>({
    ...options,
    queryFn: async () => EmailAttachmentByEmailIdAndContentIdRequest(request),
    queryKey: ['emails', 'attachments', request.emailId, request.attachmentId],
  });
