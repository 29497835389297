import { colors, fontSizesWeb } from '@bas/theme';
import {
  Box,
  FormGroup,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  styled,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';

export type SliderProps = Omit<MuiSliderProps, 'content'> & {
  label?: ReactElement | string;
  error?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: ReactNode | string;
};

const Slider = ({
  label,
  className,
  disabled,
  error,
  FormHelperTextProps: helperProps,
  helperText,
  ...props
}: SliderProps) => {
  const sliderContent = <MuiSlider {...props} />;

  if (!label) {
    return (
      <Box
        paddingLeft={3}
        paddingRight={3}
        height={64}
        display="flex"
        alignItems="flex-end"
        className={className}
      >
        {sliderContent}
      </Box>
    );
  }

  return (
    <FormGroup className={className}>
      <FormLabel>{label}</FormLabel>
      <Box
        paddingLeft={3}
        paddingRight={3}
        height={64}
        display="flex"
        alignItems="flex-end"
      >
        {sliderContent}
      </Box>
      {helperText && (
        <FormHelperText {...helperProps} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormGroup>
  );
};

const BasSlider = styled(Slider)`
  .MuiFormLabel-root {
    color: ${colors.lila[800]};
  }
  .MuiSlider-valueLabelOpen {
    background-color: ${colors.blue[900]};
    color: ${colors.white};
    border-radius: 2px;
    font-size: ${fontSizesWeb.sm};
    line-height: 18px;
    padding-left: 11px;
    padding-right: 11px;
    font-weight: normal;
  }
`;

const StyledSlider = ({ ...props }: SliderProps) => <BasSlider {...props} />;

export default StyledSlider;
