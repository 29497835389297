import {
  useDisableEmployeeAttributeMutation,
  useEnableEmployeeAttributeMutation,
} from '@bas/hrm-domain/mutations';
import { useEmployeeAttributeByEmployeeAttributeIdRequestQuery } from '@bas/hrm-domain/requests';
import { DisableSettingInputType } from '@bas/shared/input-types';
import { WorkflowButtons, WorkflowItem } from '@bas/ui/web/molecules';
import { ConfirmDialog, DisableSettingFormDialog } from '@bas/ui/web/organisms';
import { Uuid } from '@bas/value-objects';
import { Typography } from '@mui/material';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export type EmployeeAttributeWorkflowProps = {
  employeeAttributeId: Uuid;
};
const EmployeeAttributeWorkflow = ({
  employeeAttributeId,
}: EmployeeAttributeWorkflowProps): ReactElement => {
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>();

  const { mutateAsync: disableSettingMutation } =
    useDisableEmployeeAttributeMutation();
  const { mutateAsync: enableSettingMutation } =
    useEnableEmployeeAttributeMutation();
  const { data: employeeAttributeData } =
    useEmployeeAttributeByEmployeeAttributeIdRequestQuery({
      employeeAttributeId: employeeAttributeId || '',
    });

  const employeeAttribute = useMemo(
    () => employeeAttributeData?.data,
    [employeeAttributeData],
  );

  const handleDisableSetting = useCallback(
    async (values: DisableSettingInputType) => {
      await disableSettingMutation({
        employeeAttributeId,
        disableAfter: values.disableAfter as Date,
      });

      setActiveButtonIndex(undefined);
    },
    [employeeAttributeId, disableSettingMutation],
  );

  const handleEnableSetting = useCallback(async () => {
    await enableSettingMutation({
      employeeAttributeId,
    });

    setActiveButtonIndex(undefined);
  }, [employeeAttributeId, enableSettingMutation]);

  const disableAction: WorkflowItem = useMemo(
    () => ({
      label: <FormattedMessage id="label.disable" />,
      dialog: (
        <DisableSettingFormDialog
          open
          onClose={() => setActiveButtonIndex(undefined)}
          onSubmit={handleDisableSetting}
        />
      ),
    }),
    [handleDisableSetting],
  );

  const enableAction: WorkflowItem = useMemo(
    () => ({
      label: <FormattedMessage id="label.enable" />,
      dialog: (
        <ConfirmDialog
          open
          onCancel={() => setActiveButtonIndex(undefined)}
          onClose={() => setActiveButtonIndex(undefined)}
          onConfirm={handleEnableSetting}
          title={
            <FormattedMessage id="label.areYouSureYouWantToEnableEmployeeAttribute.title" />
          }
          content={
            <Typography
              whiteSpace="pre-wrap"
              sx={{ overflowWrap: 'break-word' }}
            >
              <FormattedMessage
                id="label.areYouSureYouWantToEnableEmployeeAttribute.content"
                values={{ name: employeeAttribute?.name }}
              />
            </Typography>
          }
        />
      ),
    }),
    [employeeAttribute?.name, handleEnableSetting],
  );

  const workflow = useMemo(
    () => (employeeAttribute?.disabledAfter ? [enableAction] : [disableAction]),
    [employeeAttribute?.disabledAfter, disableAction, enableAction],
  );

  return (
    <WorkflowButtons
      workflow={workflow}
      activeButtonIndex={activeButtonIndex}
      setActiveButtonIndex={setActiveButtonIndex}
    />
  );
};

export default EmployeeAttributeWorkflow;
