import { DayOfInterest, isTimeEntry } from '@bas/hrm-domain/models';
import {
  Filterable,
  getNextPageParamFromHydra,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import type { InfiniteData } from '@tanstack/query-core';
import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DayOfInterestsByEmployeeIdRequestProps = Pagination &
  Filterable & {
    employeeId: Uuid;
    startDate: Date;
    endDate: Date;
  };

export const DayOfInterestsByEmployeeIdRequest = async ({
  employeeId,
  startDate,
  endDate,
  ...params
}: DayOfInterestsByEmployeeIdRequestProps): Promise<
  AxiosResponse<Collection<DayOfInterest>>
> =>
  axios.get(
    `api/{tenantId}/hrm/employees/${employeeId}/day-of-interests/${formatDate(
      startDate,
    )}/${formatDate(endDate)}`,
    {
      params: {
        ...params,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      },
    },
  );

export const useDayOfInterestsByEmployeeIdRequest = (
  request: DayOfInterestsByEmployeeIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<DayOfInterest>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<DayOfInterest>>
  > = {},
): UseQueryResult<
  AxiosResponse<Collection<DayOfInterest>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<DayOfInterest>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<DayOfInterest>>
  >({
    ...options,
    queryFn: async () => DayOfInterestsByEmployeeIdRequest(request),
    queryKey: ['day-of-interests', 'list', request.employeeId, request],
  });

export const useInfiniteDayOfInterestsByEmployeeIdRequest = (
  request: DayOfInterestsByEmployeeIdRequestProps,
  options: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<Collection<DayOfInterest>>,
      AxiosError<ErrorResponse>,
      InfiniteData<AxiosResponse<Collection<DayOfInterest>>>,
      AxiosResponse<Collection<DayOfInterest>>,
      QueryKey,
      {
        startDate: Date | undefined;
        endDate: Date | undefined;
      }
    >,
    'queryKey' | 'getNextPageParam' | 'initialPageParam'
  > = {},
): UseInfiniteQueryResult<
  InfiniteData<AxiosResponse<Collection<DayOfInterest>>>,
  AxiosError<ErrorResponse>
> =>
  useInfiniteQuery<
    AxiosResponse<Collection<DayOfInterest>>,
    AxiosError<ErrorResponse>,
    InfiniteData<AxiosResponse<Collection<DayOfInterest>>>,
    QueryKey,
    {
      startDate: Date | undefined;
      endDate: Date | undefined;
    }
  >({
    ...options,
    initialPageParam: {
      startDate: request.startDate,
      endDate: request.endDate,
    },
    queryFn: async ({ pageParam }) =>
      DayOfInterestsByEmployeeIdRequest({
        ...request,
        startDate:
          pageParam &&
          typeof pageParam.startDate !== 'undefined' &&
          !!pageParam.startDate
            ? pageParam.startDate
            : request.startDate,
        endDate:
          pageParam &&
          typeof pageParam.endDate !== 'undefined' &&
          !!pageParam.endDate
            ? pageParam.endDate
            : request.endDate,
      }),
    getNextPageParam: getNextPageParamFromHydra,
    queryKey: ['day-of-interests', 'list', request.employeeId, 'infinite'],
  });

export const DayOfInterestsByEmployeeIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTimeEntry(data)) {
    queryClient.invalidateQueries({ queryKey: ['day-of-interests', 'list'] });
  }
};
