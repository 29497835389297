import {
  EmployeeAvailability,
  StandardWorkingHours,
  WeekHours,
} from '@bas/hrm-domain/models';
import { WorkingDay, WorkingSchedule } from '@bas/value-objects';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { getWeekDayString } from './getWeekDayString';

dayjs.extend(isoWeek);

const checkIfWeekScheduleIsInUse = (
  weekSchedule: WeekHours | undefined,
): boolean =>
  !!weekSchedule && Object.values(weekSchedule).some((value) => value > 0);

export const calculateStartAndEndTimeForWorkingDay = ({
  date,
  workingHours,
  availability,
  tenantWorkingSchedule,
}: {
  date: Date | Dayjs;
  workingHours?: StandardWorkingHours;
  availability: EmployeeAvailability;
  tenantWorkingSchedule?: WorkingSchedule;
}): {
  start: Date | Dayjs;
  end: Date | Dayjs;
  hoursToWork: number;
} => {
  let weekToUse = workingHours?.week1Hours;
  const weekDayString = getWeekDayString(date);
  let employeeWorkingDay: WorkingDay | undefined = availability[weekDayString];
  let tenantWorkingDay = tenantWorkingSchedule?.[weekDayString];

  if (!employeeWorkingDay?.startTime || !employeeWorkingDay?.endTime) {
    employeeWorkingDay = undefined;
  }

  if (!tenantWorkingDay?.startTime || !tenantWorkingDay?.endTime) {
    tenantWorkingDay = undefined;
  }

  if (
    dayjs(date).isoWeek() % 2 &&
    workingHours?.week2Hours &&
    checkIfWeekScheduleIsInUse(workingHours?.week2Hours)
  ) {
    weekToUse = workingHours.week2Hours;
  }

  const [startHour, startMinute] = (
    employeeWorkingDay || tenantWorkingDay
  )?.startTime?.split(':') || [undefined, undefined];

  const [endHour, endMinute] = (
    employeeWorkingDay || tenantWorkingDay
  )?.endTime?.split(':') || [undefined, undefined];

  if (!startHour || !startMinute || !endHour || !endMinute) {
    return {
      start: date,
      end: date,
      hoursToWork: 0,
    };
  }

  const result = {
    start: dayjs(date).hour(Number(startHour)).minute(Number(startMinute)),
    end: dayjs(date).hour(Number(endHour)).minute(Number(endMinute)),
    hoursToWork: weekToUse?.[weekDayString] || 0,
  };

  if (!weekToUse) {
    result.hoursToWork = result.end.diff(result.start, 'hours', true);
  }

  if (result.end.diff(result.start, 'hours', true) > result.hoursToWork) {
    result.end = result.start
      .clone()
      .add(weekToUse?.[weekDayString] || 0, 'hour');
  }

  if (result.end.diff(result.start, 'hours', true) < result.hoursToWork) {
    result.end = result.start.clone().add(result.hoursToWork, 'hour');
  }

  return result;
};
