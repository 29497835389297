import { ConnectIntegrationInputType } from '@bas/integration-domain/input-types';
import { providersThatNeedRequestingAccess } from '@bas/integration-domain/models';
import {
  getFormComponent,
  getFormInputType,
  getProviderName,
} from '@bas/settings-domain/web/hooks';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { DialogProps } from '@bas/ui/web/molecules';
import { FormDialog } from '@bas/ui/web/organisms';
import { ProviderType } from '@bas/value-objects';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { Box, Grid2, styled, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type ConnectIntegrationFormDialogProps = Omit<
  DialogProps,
  'onSubmit'
> & {
  provider: ProviderType;
  open: boolean;
  onSubmit: SubmitHandler<ConnectIntegrationInputType>;
};

const providerTypesWithoutCostWarning = [
  ProviderType.SCHOUTEN_ZEKERHEID,
  ProviderType.MOLLIE,
  ProviderType.WEBSITE,
];

const ConnectIntegrationFormDialog = ({
  onClose,
  provider,
  ...args
}: ConnectIntegrationFormDialogProps): ReactElement => {
  const formInputType = useMemo(() => getFormInputType(provider), [provider]);
  const formComponent = useMemo(() => getFormComponent(provider), [provider]);

  return (
    <FormDialog<ConnectIntegrationInputType>
      name="editing-template-translation"
      onClose={onClose}
      title={
        <FormattedMessage
          id="settings.integrations.connectWithIntegration"
          values={{
            provider: getProviderName(provider),
          }}
        />
      }
      validationSchema={formInputType.validationSchema}
      defaultValues={{ ...formInputType.defaultValues }}
      maxWidth="md"
      fullWidth
      dialogActions={[
        <Button type="submit" key="connect">
          {provider === ProviderType.WHATSAPP && (
            <span>
              <Icon icon={faFacebook} />
              &nbsp;
            </span>
          )}
          {providersThatNeedRequestingAccess.includes(provider) ? (
            <FormattedMessage id="button.requestAccess" />
          ) : (
            <FormattedMessage id="button.connect" />
          )}
        </Button>,
      ]}
      useProvider
      {...args}
    >
      <Grid2 container alignItems="stretch">
        <Grid2 pr={4} size={6}>
          <Typography whiteSpace="pre-wrap" sx={{ overflowWrap: 'break-word' }}>
            <FormattedMessage
              id={`providerTypes.${provider}.connectingDescription`}
            />
          </Typography>
          <Box pt={2}>{formComponent}</Box>
        </Grid2>
        <Grid2
          pl={6}
          pr={4}
          className="Bas-ConnectIntegrationFormDialog-WithBorder"
          size={6}
        >
          <Typography whiteSpace="pre-wrap" sx={{ overflowWrap: 'break-word' }}>
            <FormattedMessage
              id={`providerTypes.${provider}.workingDescription`}
            />
          </Typography>

          <Typography
            hidden={providerTypesWithoutCostWarning.includes(provider)}
            whiteSpace="pre-wrap"
            sx={{ overflowWrap: 'break-word' }}
            pt={2}
          >
            <FormattedMessage id="settings.integration.warningThatIntegrationIsNotFree" />
          </Typography>
        </Grid2>
      </Grid2>
    </FormDialog>
  );
};

const StyledConnectIntegrationFormDialog = styled(ConnectIntegrationFormDialog)(
  ({ theme }) => `
  .MuiPaper-root {
    min-height: 649px;
  }

  .Bas-ConnectIntegrationFormDialog-WithBorder {
    border-left: 1px solid ${colors.lila[400]};
    min-height: 501px;
  }
`,
);
export default StyledConnectIntegrationFormDialog;
