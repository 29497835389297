import { colors } from '@bas/theme';
import { PaginationItem, PaginationItemProps } from '@bas/ui/web/atoms';
import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  styled,
} from '@mui/material';

export type PaginationProps = MuiPaginationProps;

const Pagination = ({
  renderItem = (item) => (
    <PaginationItem {...(item as unknown as PaginationItemProps)} />
  ),
  ...args
}: PaginationProps) => <MuiPagination {...args} renderItem={renderItem} />;

const StyledPagination = styled(Pagination)(
  () => `
    color: ${colors.lila[700]};
`,
);
export default StyledPagination;
