import { EmployeeAttributeInputType } from '@bas/hrm-domain/input-types';
import { EmployeeAttribute } from '@bas/hrm-domain/models';
import { useChangeEmployeeAttributeMutation } from '@bas/hrm-domain/mutations';
import {
  EditableEmployeeAttributeDetailsBlock,
  EmployeeAttributeWorkflow,
} from '@bas/settings-domain/web/organisms';
import { Grid2 } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { ReactElement, useCallback } from 'react';

dayjs.extend(timezone);

export type EmployeeAttributeInformationTabProps = {
  employeeAttribute: EmployeeAttribute;
};

const EmployeeAttributeInformationTab = ({
  employeeAttribute,
}: EmployeeAttributeInformationTabProps): ReactElement => {
  const { mutateAsync: changeEmployeeAttribute } =
    useChangeEmployeeAttributeMutation();

  const handleChangeDetails = useCallback(
    async (values: EmployeeAttributeInputType) => {
      await changeEmployeeAttribute({
        employeeAttributeId: employeeAttribute.employeeAttributeId,
        ...values,
      });

      return true;
    },
    [employeeAttribute.employeeAttributeId, changeEmployeeAttribute],
  );

  return (
    <Grid2 container spacing={4}>
      <Grid2 size={12}>
        <EmployeeAttributeWorkflow
          employeeAttributeId={employeeAttribute.employeeAttributeId}
        />
      </Grid2>
      <Grid2 size={12}>
        <EditableEmployeeAttributeDetailsBlock
          employeeAttribute={employeeAttribute}
          onChangeDetails={handleChangeDetails}
        />
      </Grid2>
    </Grid2>
  );
};

export default EmployeeAttributeInformationTab;
