import { colors, fontSizesWeb } from '@bas/theme';
import { styled, Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';

export type TabProps = Omit<MuiTabProps, 'content'>;

const BasTab = styled(MuiTab)(
  ({ theme }) => `
  text-transform: none;
  font-weight: normal;
  font-size: ${fontSizesWeb.base};
  opacity: 1;
  padding: 12px 0px;
  margin: 0px ${theme.spacing(2)};
  min-width: 0;
  color: ${colors.lila[800]};

  &:hover {
    color: ${colors.blue[500]};
  }

  &.Mui-disabled {
    color: ${colors.lila[400]};
  }

  &.Mui-selected {
    font-weight: bold;
    border-bottom: none;
  }
`,
);

const StyledTab = ({ disableRipple = true, ...props }: TabProps) => (
  <BasTab disableRipple={disableRipple} {...props} />
);

export default StyledTab;
