import { isMaterial, Material } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MaterialsByMaterialIdsRequestProps = Pagination & {
  materialIds: Array<Uuid>;
};

type Response = AxiosResponse<Collection<Material>>;
type QueryKeyType = QueryKey & {
  [0]: 'materials';
  [1]: 'list';
  [2]: Uuid[];
};

function isCurrentQueryKey(object: unknown): object is QueryKeyType {
  return (
    Array.isArray(object) &&
    object[0] === 'materials' &&
    object[1] === 'list' &&
    object[2] &&
    Array.isArray(object[2])
  );
}

export const MaterialsByMaterialIdsRequest = async ({
  materialIds,
  ...params
}: MaterialsByMaterialIdsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/materials`, {
    params: { materialId: materialIds, ...params },
  });

export const useMaterialsByMaterialIdsRequest = (
  request: MaterialsByMaterialIdsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => MaterialsByMaterialIdsRequest({ ...request }),
    queryKey: ['materials', 'list', request.materialIds],
  });

export const MaterialsByMaterialIdsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isMaterial(data)) {
    queryClient.setQueriesData<Response | undefined>(
      {
        predicate: ({ queryKey }) => {
          if (!isCurrentQueryKey(queryKey)) {
            return false;
          }

          return (
            queryKey[0] === 'materials' &&
            queryKey[1] === 'list' &&
            queryKey[2].includes(data.materialId)
          );
        },
      },
      (previous) => {
        if (!previous) {
          return previous;
        }

        return {
          ...previous,
          data: {
            ...previous.data,
            member: previous.data.member?.map((material) =>
              material.materialId === data.materialId ? data : material,
            ),
          },
        };
      },
    );
  }
};
