import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { isTenant, Tenant } from '@bas/tenant-domain/models';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TenantByTenantIdRequestProps = {
  tenantId: Uuid;
};

export const TenantByTenantIdRequest = async ({
  tenantId,
  ...params
}: TenantByTenantIdRequestProps): Promise<AxiosResponse<Tenant>> =>
  axios.get(`api/tenants/${tenantId}`, {
    params,
  });

export const useTenantByTenantIdRequestQuery = (
  request: TenantByTenantIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Tenant>,
    AxiosError,
    AxiosResponse<Tenant>
  > = {},
): UseQueryResult<AxiosResponse<Tenant>, AxiosError> =>
  useQuery<AxiosResponse<Tenant>, AxiosError, AxiosResponse<Tenant>>({
    ...options,
    queryFn: async () => TenantByTenantIdRequest(request),
    queryKey: ['tenants', 'detail', request.tenantId],
  });

export const TenantByTenantIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTenant(data)) {
    queryClient.invalidateQueries({
      queryKey: ['tenants', 'detail', data.tenantId],
    });
  }
};
