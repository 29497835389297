import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { isTaskComment, TaskComment } from '@bas/task-domain/models';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type TaskCommentsByTaskIdRequestProps = Pagination &
  Filterable & {
    taskId: Uuid;
  };

type Response = AxiosResponse<Collection<TaskComment>>;

export const TaskCommentsByTaskIdRequest = async ({
  taskId,
  ...params
}: TaskCommentsByTaskIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/tasks/${taskId}/task-comments`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useTaskCommentsByTaskIdRequest = (
  request: TaskCommentsByTaskIdRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TaskCommentsByTaskIdRequest(request),
    queryKey: ['tasks', 'comments', request.taskId, request],
  });

export const usePrefetchTaskCommentsByTaskIdRequest = (
  request: TaskCommentsByTaskIdRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['tasks', 'comments', request.taskId, request],
      queryFn: async () => TaskCommentsByTaskIdRequest(request),
    });
  }, [queryClient, request]);
};

export const TaskCommentsByTaskIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTaskComment(data)) {
    queryClient.invalidateQueries({
      queryKey: ['tasks', 'comments', data.taskId],
    });
  }
};
