import { colors } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/pro-light-svg-icons';
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Box,
  Skeleton,
  SxProps,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { AvatarSizeType } from './enum';

export type AvatarProps = Omit<MuiAvatarProps, 'size'> & {
  loading?: boolean;
  uploadable?: boolean;
  name?: string | null;
  icon?: IconProp;
  shortName?: string | null;
  size?: AvatarSizeType;
};

const stringAvatar = (name: string) => {
  const splittedName = name.split(' ');
  if (splittedName.length === 1) {
    return `${splittedName[0][0]}${splittedName[0][1]}`;
  }

  return `${splittedName[0][0]}${splittedName[1][0]}`;
};

const Avatar = ({
  name = null,
  loading = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shortName = null,
  icon,
  className,
  onClick,
  size,
  uploadable = true,
  avatarSx,
  ...props
}: AvatarProps & { avatarSx?: SxProps<Theme> }) =>
  loading ? (
    <Skeleton className={className} variant="circular" />
  ) : (
    <Box
      className={clsx(className, {
        'Bas-Avatar-Uploadable': !!onClick && uploadable,
      })}
    >
      {!!onClick && (
        <MuiAvatar
          onClick={onClick}
          sx={avatarSx}
          className={clsx(
            'Bas-UploadingAvatar-root',
            { 'Bas-Avatar-Small': size === AvatarSizeType.SMALL },
            { 'Bas-Avatar-Xs': size === AvatarSizeType.XS },
            { 'Bas-Avatar-Large': size === AvatarSizeType.BIG },
          )}
        >
          <Icon className="Bas-UploadingAvatar-UploadingIcon" icon={faUpload} />
        </MuiAvatar>
      )}
      <MuiAvatar
        sx={avatarSx}
        className={clsx(
          'Bas-Avatar-root',
          { 'Bas-Avatar-Small': size === AvatarSizeType.SMALL },
          { 'Bas-Avatar-Xs': size === AvatarSizeType.XS },
          { 'Bas-Avatar-Large': size === AvatarSizeType.BIG },
          { 'Bas-Avatar-HasIcon': !!icon },
          { 'Bas-Avatar-Loading': loading },
        )}
        {...props}
      >
        {icon && <Icon icon={icon} />}
        {name && stringAvatar(name)}
      </MuiAvatar>
    </Box>
  );

const StyledAvatar = styled(Avatar)`
  position: relative;

  .Bas-UploadingAvatar-root {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: opacity 0.3s ease;
    cursor: pointer;
    background: ${colors.lila[200]};
    color: ${colors.lila[700]};

    &.Bas-Avatar-Xs {
      height: 40px;
      width: 40px;
    }

    &.Bas-Avatar-Small {
      height: 44px;
      width: 44px;
    }

    &.Bas-Avatar-Large {
      height: 88px;
      width: 88px;
    }
  }

  &.Bas-Avatar-Uploadable:hover {
    .Bas-Avatar-root {
      opacity: 0;
    }

    .Bas-UploadingAvatar-root {
      opacity: 1;
    }
  }

  .Bas-Avatar-root {
    background: ${colors.lila[700]};
    color: ${colors.white};
    transition:
      background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      opacity 0.3s ease;

    &.Bas-Avatar-Loading,
    &.Bas-Avatar-HasIcon {
      background: ${colors.lila[200]};
      color: ${colors.lila[700]};
    }

    &.Bas-Avatar-Xs {
      height: 40px;
      width: 40px;
    }

    &.Bas-Avatar-Small {
      height: 44px;
      width: 44px;
    }

    &.Bas-Avatar-Large {
      height: 88px;
      width: 88px;
    }
  }
`;

const RenderStyledAvatar = ({ sx, ...props }: AvatarProps) => (
  <StyledAvatar avatarSx={sx} {...props} />
);

export default RenderStyledAvatar;
