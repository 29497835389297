import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { TableCell, TableRow, TableRowProps, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { WorkedHoursSpecification } from '@bas/value-objects';
import { faXmarkToSlot } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { IconButton, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import {
  EmployeeHourEntryTableRow,
  EmployeeHourEntryTableRowProps,
} from '../EmployeeHourEntryTableRow';
import { WorkedHoursSpecificationSummary } from '../WorkedHoursSpecificationSummary';

export type EmployeeHourEntriesDayTableRowProps = TableRowProps & {
  date: Date;
  startTime?: Date;
  endTime?: Date;
  totalHours: WorkedHoursSpecification;
  projectHours: WorkedHoursSpecification;
  internalHours: WorkedHoursSpecification;
  approvedHours: WorkedHoursSpecification;
  nonWorkingHours: WorkedHoursSpecification;
  waitingHours: WorkedHoursSpecification;
  hourEntryRows: EmployeeHourEntryTableRowProps[];
  onEnterHours: (date: Date) => void;
};

const EmployeeHourEntriesDayTableRow = ({
  date,
  startTime,
  endTime,
  totalHours,
  projectHours,
  internalHours,
  approvedHours,
  nonWorkingHours,
  waitingHours,
  hourEntryRows,
  onEnterHours,
  className,
  ...args
}: EmployeeHourEntriesDayTableRowProps): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const onToggleExpand = () => setExpanded((value) => !value);

  return (
    <>
      <TableRow
        className={clsx(className, {
          'Bas-EmployeeHourEntriesDayTableRow-Expanded': expanded,
        })}
        {...args}
      >
        <TableCell paddingRight>
          <FormattedDate value={date} weekday="long" />
        </TableCell>
        <TableCell paddingRight>
          <FormattedDate value={date} month="long" day="numeric" />
        </TableCell>
        <TableCell align="center">
          {startTime && <FormattedTime value={startTime} timeStyle="short" />}
        </TableCell>
        <TableCell align="center">
          {endTime && <FormattedTime value={endTime} timeStyle="short" />}
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
          <Tooltip
            title={
              <WorkedHoursSpecificationSummary
                workedHoursSpecification={totalHours}
              />
            }
            disabled={totalHours.workedHoursIncludingBreakTime === 0}
          >
            <span>
              {formatHours(totalHours.workedHours + totalHours.travelTime)}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              <WorkedHoursSpecificationSummary
                workedHoursSpecification={approvedHours}
              />
            }
            disabled={approvedHours.workedHoursIncludingBreakTime === 0}
          >
            <span>
              {formatHours(
                approvedHours.workedHours + approvedHours.travelTime,
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              <WorkedHoursSpecificationSummary
                workedHoursSpecification={projectHours}
              />
            }
            disabled={projectHours.workedHoursIncludingBreakTime === 0}
          >
            <span>
              {formatHours(projectHours.workedHours + projectHours.travelTime)}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              <WorkedHoursSpecificationSummary
                workedHoursSpecification={internalHours}
              />
            }
            disabled={internalHours.workedHoursIncludingBreakTime === 0}
          >
            <span>
              {formatHours(
                internalHours.workedHours + internalHours.travelTime,
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              <WorkedHoursSpecificationSummary
                workedHoursSpecification={nonWorkingHours}
              />
            }
            disabled={nonWorkingHours.workedHoursIncludingBreakTime === 0}
          >
            <span>
              {formatHours(
                nonWorkingHours.workedHours + nonWorkingHours.travelTime,
              )}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              <WorkedHoursSpecificationSummary
                workedHoursSpecification={waitingHours}
              />
            }
            disabled={waitingHours.workedHoursIncludingBreakTime === 0}
          >
            <span>
              {formatHours(waitingHours.workedHours + waitingHours.travelTime)}
            </span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={<FormattedMessage id="button.enterHours" />}>
            <IconButton onClick={() => onEnterHours(date)} size="small">
              <Icon icon={faXmarkToSlot} />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={onToggleExpand}
            size="small"
            disabled={hourEntryRows.length === 0}
          >
            <Icon
              icon={faChevronDown}
              flip={expanded ? 'vertical' : undefined}
            />
          </IconButton>
        </TableCell>
      </TableRow>
      {hourEntryRows.map((hourEntryRow, index) => (
        <EmployeeHourEntryTableRow
          className={clsx(
            className,
            'Bas-EmployeeHourEntriesDayTableRow-Expandable',
            {
              'Bas-EmployeeHourEntriesDayTableRow-ShowExpandable': expanded,
            },
          )}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...hourEntryRow}
        />
      ))}
    </>
  );
};

const StyledEmployeeHourEntriesDayTableRow = styled(
  EmployeeHourEntriesDayTableRow,
)(
  ({ theme }) => `
  &.Bas-EmployeeHourEntriesDayTableRow-Expandable {
    display: none;
  }

  &.Bas-EmployeeHourEntriesDayTableRow-ShowExpandable {
    display: table-row;
    .MuiTableCell-root:first-of-type {
      text-indent: 8px;
    }
  }

  &.Bas-EmployeeHourEntriesDayTableRow-Expanded .MuiTableCell-root {
    &, .MuiButtonBase-root {
      font-weight: bold;
      color: ${colors.blue[500]};
    }
  }
`,
);
export default StyledEmployeeHourEntriesDayTableRow;
