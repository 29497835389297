import { colors } from '@bas/theme';
import { ListItem, ListItemButton, ListItemProps, styled } from '@mui/material';
import clsx from 'clsx';
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import { SidebarSizeType } from './enum';

export type SidebarListItemProps = Omit<ListItemProps, 'onClick'> & {
  size?: SidebarSizeType;
  active?: boolean;
  className?: string;
  to: string;
  exact?: boolean;
};

const SidebarListItem = ({
  children,
  active = false,
  to,
  className,
  exact = false,
  size = SidebarSizeType.MEDIUM,
  ...args
}: SidebarListItemProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: exact });

  return (
    <ListItem
      disableGutters
      aria-current={!!match || active}
      className={clsx(className, {
        'Bas-SidebarListItem-Active': !!match || active,
      })}
      {...args}
    >
      <ListItemButton component={RouterLink} to={to} disableRipple>
        {children}
      </ListItemButton>
    </ListItem>
  );
};

const StyledSidebarListItem = styled(SidebarListItem)(
  ({ theme, size = SidebarSizeType.MEDIUM }) => `
  padding: 0;
  height: ${size === SidebarSizeType.MEDIUM ? '88px' : '71px'};
  width: 88px;
  text-align: center;
  background: ${colors.blue[900]};
  color: ${colors.white};
  &.Bas-SidebarListItem-Active {
    color: ${colors.blue[500]};
  }
  cursor: pointer;
  display: flex;
  justify-content: center;

  .MuiListItemButton-root {
    justify-content: center;
  }
  &:not(.Bas-SidebarListItem-Active):hover {
    color: ${colors.blue[200]};
  }
`,
);
export default StyledSidebarListItem;
