import { Declaration, isDeclaration } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type DeclarationsRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<Declaration>>;

export const DeclarationsRequest = async ({
  ...params
}: DeclarationsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/declarations`, {
    params: {
      ...params,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useDeclarationsRequest = (
  { ...request }: DeclarationsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => DeclarationsRequest({ ...request }),
    queryKey: ['declarations', 'list', request],
  });

export const DeclarationsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isDeclaration(data)) {
    queryClient.invalidateQueries({ queryKey: ['declarations', 'list'] });
  }
};
