import {
  Conversation,
  isConversation,
  isMessage,
} from '@bas/communication-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ConversationsRequestProps = Pagination &
  Filterable & {
    employeeId?: Uuid;
    userId?: Uuid;
  };

type Response = AxiosResponse<Collection<Conversation>>;

export const ConversationsRequest = async ({
  ...params
}: ConversationsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/chat/conversations`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useConversationsRequest = (
  request: ConversationsRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => ConversationsRequest(request),
    queryKey: ['conversations', 'list', request],
  });

export const ConversationsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isConversation(data) || isMessage(data)) {
    queryClient.invalidateQueries({ queryKey: ['conversations', 'list'] });
  }
};
