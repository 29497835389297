import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { isMovingBox, MovingBox } from '@bas/wms-domain/models';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type MovingBoxesRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<MovingBox>>;

export const MovingBoxesRequest = async ({
  ...params
}: MovingBoxesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/inventory/moving-boxes`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useMovingBoxesRequest = (
  request: MovingBoxesRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => MovingBoxesRequest(request),
    queryKey: ['moving-boxes', 'list', request],
  });

export const usePrefetchMovingBoxesRequest = (
  request: MovingBoxesRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['moving-boxes', 'list', request],
      queryFn: async () => MovingBoxesRequest(request),
    });
  }, [queryClient, request]);
};

export const MovingBoxesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isMovingBox(data)) {
    queryClient.invalidateQueries({ queryKey: ['moving-boxes', 'list'] });
  }
};
