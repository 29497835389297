import { Money } from '@bas/value-objects';
import { FormattedNumber } from 'react-intl';

const FormattedCurrency = ({
  amount,
  currency,
  disablePaddingZero,
}: Money & { disablePaddingZero?: boolean }) => (
  <FormattedNumber
    {...{
      value: amount / 100,
      style: 'currency',
      currency: currency || 'EUR',
      minimumFractionDigits: disablePaddingZero ? 0 : 2,
    }}
  />
);

export default FormattedCurrency;
