import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MarkSessionAsActiveMutationProps = {
  sessionId: Uuid;
};

export const MarkSessionAsActiveMutation = async ({
  sessionId,
}: MarkSessionAsActiveMutationProps): Promise<AxiosResponse> =>
  axios.put(`api/sessions/${sessionId}/mark-as-active`, { sessionId });

export const useMarkSessionAsActiveMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkSessionAsActiveMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  MarkSessionAsActiveMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    MarkSessionAsActiveMutationProps
  >({
    mutationFn: MarkSessionAsActiveMutation,
    ...options,
    throwOnError: (error) => (error?.response?.status || 500) >= 500,
  });
