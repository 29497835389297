import {
  Conversation,
  isConversation,
  isMessage,
} from '@bas/communication-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ConversationByConversationIdRequestProps = {
  conversationId: Uuid;
};

export const ConversationByConversationIdRequest = async ({
  conversationId,
  ...params
}: ConversationByConversationIdRequestProps): Promise<
  AxiosResponse<Conversation>
> =>
  axios.get(`api/{tenantId}/chat/conversations/${conversationId}`, {
    params,
  });

export const useConversationByConversationIdRequestQuery = (
  request: ConversationByConversationIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Conversation>,
    AxiosError,
    AxiosResponse<Conversation>
  > = {},
): UseQueryResult<AxiosResponse<Conversation>, AxiosError> =>
  useQuery<
    AxiosResponse<Conversation>,
    AxiosError,
    AxiosResponse<Conversation>
  >({
    ...options,
    queryFn: async () => ConversationByConversationIdRequest(request),
    queryKey: ['conversations', 'detail', request.conversationId],
  });

export const ConversationByConversationIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isConversation(data) || isMessage(data)) {
      queryClient.invalidateQueries({
        queryKey: ['conversations', 'detail', data.conversationId],
      });
    }
  };
