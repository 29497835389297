import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

type CurrencyTextFieldNumberFormatProps = {
  currency: string;
  name: string;
  value: number | string | undefined | null;
  defaultValue?: number | string | undefined | null;
  onBlur: () => void;
  onChange: (event: {
    target: { name: string; value: number | string | undefined | null };
  }) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CurrencyTextFieldNumberFormat: any = forwardRef<
  typeof NumericFormat,
  CurrencyTextFieldNumberFormatProps
>(
  (
    {
      name,
      onChange,
      onBlur,
      defaultValue,
      value,
      ...rest
    }: CurrencyTextFieldNumberFormatProps,
    ref,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any => {
    const valueToUse = value ?? defaultValue;
    let fieldValue: number | string = '';
    if (valueToUse !== undefined) {
      if (typeof valueToUse === 'string' && valueToUse !== '') {
        fieldValue = (parseFloat(valueToUse) / 100).toFixed(2);
      } else if (typeof valueToUse === 'number') {
        fieldValue = (valueToUse / 100).toFixed(2);
      }
    }

    return (
      <NumericFormat
        {...rest}
        onBlur={() => onBlur()}
        value={fieldValue}
        getInputRef={ref}
        onValueChange={(values) => {
          let newValue = null;
          if (values.floatValue === 0) {
            newValue = 0;
          } else if (values.floatValue) {
            newValue = Math.round(values.floatValue * 100);
          }

          if (
            newValue !==
            (typeof valueToUse === 'string'
              ? parseFloat(valueToUse)
              : valueToUse)
          ) {
            onChange({
              target: {
                name,
                value: newValue === 0 ? '0' : newValue,
              },
            });
          }
        }}
        decimalScale={2}
        thousandSeparator="."
        decimalSeparator=","
        valueIsNumericString
        prefix="&euro; "
      />
    );
  },
);

export default CurrencyTextFieldNumberFormat;
