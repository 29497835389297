import { useTenantStore } from '@bas/shared/state';
import { isUiFeature, UiFeature } from '@bas/tenant-domain/models';
import { useState } from 'react';

export type UsePaginationResult = {
  currentPage: number;
  rowsPerPage: number;
  changePage: (newPage: number) => void | Promise<void>;
  changeRowsPerPage: (newRowsPerPage: number) => void | Promise<void>;
};

export type UsePaginationProps = {
  defaultRowsPerPage?: number;
};

export const usePagination = ({
  defaultRowsPerPage,
}: UsePaginationProps = {}): UsePaginationResult => {
  const tenant = useTenantStore((state) => state.internalTenant);
  const uiFeature = tenant?.features.find<UiFeature>(isUiFeature);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    defaultRowsPerPage || uiFeature?.numberOfItemsPerPage || 10,
  );

  return {
    currentPage,
    rowsPerPage,
    changePage: (newPage) => setCurrentPage(newPage),
    changeRowsPerPage: (newRowsPerPage) => setRowsPerPage(newRowsPerPage),
  };
};
