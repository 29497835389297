import {
  ContentSection,
  ContentSectionCancelAction,
  ContentSectionSaveAction,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { YukiSettingsForm } from '../YukiSettingsForm';

export type EditingYukiSettingsContentSectionProps = {
  integrationId: Uuid;
  onCancel: () => void;
};

const EditingYukiSettingsContentSection = ({
  integrationId,
  onCancel,
}: EditingYukiSettingsContentSectionProps): ReactElement => (
  <ContentSection
    editing
    actions={[
      <ContentSectionCancelAction onClick={onCancel} />,
      <ContentSectionSaveAction />,
    ]}
  >
    <Grid2 container spacing={3} alignItems="flex-start">
      <Grid2 pt={4} size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.settings" />
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <YukiSettingsForm integrationId={integrationId} />
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default EditingYukiSettingsContentSection;
