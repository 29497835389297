import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { isReusableMaterial, ReusableMaterial } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ReusableMaterialByReusableMaterialIdRequestQueryProps = {
  inventoryItemId: Uuid;
};

export const ReusableMaterialByReusableMaterialIdRequestQuery = async ({
  inventoryItemId,
  ...params
}: ReusableMaterialByReusableMaterialIdRequestQueryProps): Promise<
  AxiosResponse<ReusableMaterial>
> =>
  axios.get(`api/{tenantId}/inventory/reusable-materials/${inventoryItemId}`, {
    params,
  });

export const useReusableMaterialByReusableMaterialIdRequestQuery = (
  request: ReusableMaterialByReusableMaterialIdRequestQueryProps,
  options: QueryOptionsWithKey<
    AxiosResponse<ReusableMaterial>,
    AxiosError,
    AxiosResponse<ReusableMaterial>
  > = {},
): UseQueryResult<AxiosResponse<ReusableMaterial>, AxiosError> =>
  useQuery<
    AxiosResponse<ReusableMaterial>,
    AxiosError,
    AxiosResponse<ReusableMaterial>
  >({
    ...options,
    queryFn: async () =>
      ReusableMaterialByReusableMaterialIdRequestQuery(request),
    queryKey: ['reusable-materials', 'detail', request.inventoryItemId],
  });

export const ReusableMaterialByReusableMaterialIdRequestQueryInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isReusableMaterial(data)) {
      queryClient.invalidateQueries({
        queryKey: ['reusable-materials', 'detail', data.inventoryItemId],
      });
    }
  };
