import { StandardWorkingHours } from '@bas/hrm-domain/models';
import { formatHours } from '@bas/shared/utils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Grid2, TableContainer, Typography } from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type WorkingHoursContentSectionProps = {
  workingHours: StandardWorkingHours;
  onEdit?: () => void;
};

const WorkingHoursContentSection = ({
  workingHours,
  onEdit,
}: WorkingHoursContentSectionProps): ReactElement => (
  <ContentSection
    actions={onEdit ? [<ContentSectionEditAction onClick={onEdit} />] : []}
  >
    <Grid2 container columnSpacing={3}>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.workingSchedule" />
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell paddingRight>
                  <FormattedMessage id="label.week" />
                </TableCell>
                <TableCell
                  width="12.5%"
                  sx={{ textAlign: 'center' }}
                  paddingRight
                >
                  <FormattedDate
                    value={dayjs().weekday(0).toDate()}
                    weekday="short"
                  />
                </TableCell>
                <TableCell
                  width="12.5%"
                  sx={{ textAlign: 'center' }}
                  paddingRight
                >
                  <FormattedDate
                    value={dayjs().weekday(1).toDate()}
                    weekday="short"
                  />
                </TableCell>
                <TableCell
                  width="12.5%"
                  sx={{ textAlign: 'center' }}
                  paddingRight
                >
                  <FormattedDate
                    value={dayjs().weekday(2).toDate()}
                    weekday="short"
                  />
                </TableCell>
                <TableCell
                  width="12.5%"
                  sx={{ textAlign: 'center' }}
                  paddingRight
                >
                  <FormattedDate
                    value={dayjs().weekday(3).toDate()}
                    weekday="short"
                  />
                </TableCell>
                <TableCell
                  width="12.5%"
                  sx={{ textAlign: 'center' }}
                  paddingRight
                >
                  <FormattedDate
                    value={dayjs().weekday(4).toDate()}
                    weekday="short"
                  />
                </TableCell>
                <TableCell
                  width="12.5%"
                  sx={{ textAlign: 'center' }}
                  paddingRight
                >
                  <FormattedDate
                    value={dayjs().weekday(5).toDate()}
                    weekday="short"
                  />
                </TableCell>
                <TableCell
                  width="12.5%"
                  sx={{ textAlign: 'center' }}
                  paddingRight
                >
                  <FormattedDate
                    value={dayjs().weekday(6).toDate()}
                    weekday="short"
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell paddingRight>
                  <FormattedMessage id="label.even" />
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week1Hours?.monday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week1Hours?.tuesday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week1Hours?.wednesday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week1Hours?.thursday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week1Hours?.friday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week1Hours?.saturday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week1Hours?.sunday)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell paddingRight>
                  <FormattedMessage id="label.odd" />
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week2Hours?.monday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week2Hours?.tuesday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week2Hours?.wednesday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week2Hours?.thursday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week2Hours?.friday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week2Hours?.saturday)}
                </TableCell>
                <TableCell paddingRight sx={{ textAlign: 'center' }}>
                  {formatHours(workingHours.week2Hours?.sunday)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default WorkingHoursContentSection;
