import { ReeleezeeSettingsInputType } from '@bas/integration-domain/input-types';
import {
  CostCenterMappingType,
  GeneralLedgerMappingType,
} from '@bas/integration-domain/models';
import { useReeleezeeMappingOptions } from '@bas/integration-domain/web/hooks';
import { useTenantStore } from '@bas/shared/state';
import { Alert, TextFieldNumberFormat } from '@bas/ui/web/atoms';
import {
  ReactHookFormDatePickerField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid2, MenuItem, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CostCenterMappingForm } from '../CostCenterMappingForm';
import { GeneralLedgerMappingForm } from '../GeneralLedgerMappingForm';
import { VatMappingForm } from '../VatMappingForm';

export type ReeleezeeSettingsFormProps = {
  integrationId: Uuid;
};

const ReeleezeeSettingsForm = ({
  integrationId,
}: ReeleezeeSettingsFormProps): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);
  const [administrationId, generalLedgerMappingType, costCenterMappingType] =
    useWatch<
      ReeleezeeSettingsInputType,
      ['administrationId', 'generalLedgerMappingType', 'costCenterMappingType']
    >({
      name: [
        'administrationId',
        'generalLedgerMappingType',
        'costCenterMappingType',
      ],
    });

  const {
    administrationsData,
    administrationsError,
    ledgerAccountsError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    taxRatesError,
    vatExternalOptions,
  } = useReeleezeeMappingOptions(integrationId, administrationId);

  return (
    <Grid2 container columnSpacing={3} rowSpacing={3} pt={1}>
      {(administrationsError ||
        ledgerAccountsError ||
        departmentsError ||
        taxRatesError) && (
        <Grid2 mt={2} size={12}>
          <Alert severity="error">
            <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
          </Alert>
        </Grid2>
      )}
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="startDate"
          render={(registered) => (
            <ReactHookFormDatePickerField
              {...registered}
              textFieldProps={{
                fullWidth: true,
              }}
              label={<FormattedMessage id="label.startDate" />}
            />
          )}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="administrationId"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.administration" />}
              select
            >
              {(administrationsData?.data?.member || []).map((profile) => (
                <MenuItem key={profile.id} value={profile.id}>
                  {profile.Name}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="leadingNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.leadingNumber" />}
              InputProps={{
                inputComponent: TextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 0,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 sx={{ display: { md: 'none' } }} size={6} />
      <Grid2 size={6}>
        <Controller
          name="generalLedgerMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.generalLedgerMappingType" />}
              select
            >
              {Object.values(GeneralLedgerMappingType)
                .filter(
                  (option) =>
                    tenantState?.useIdentities ||
                    option !== GeneralLedgerMappingType.PER_IDENTITY,
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage
                      id={`generalLedgerMappingTypes.${option}`}
                    />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      <Grid2 size={6}>
        <Controller
          name="costCenterMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.costCenterMappingType" />}
              select
            >
              {Object.values(CostCenterMappingType)
                .filter(
                  (option) =>
                    tenantState?.useIdentities ||
                    option !== CostCenterMappingType.PER_IDENTITY,
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage id={`costCenterMappingTypes.${option}`} />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      <Grid2 size={6}>
        <Controller
          name="defaultGeneralLedger"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.defaultGeneralLedger" />}
              select
            >
              <MenuItem value="">
                <FormattedMessage id="label.none" />
              </MenuItem>
              {generalLedgerExternalOptions.map(({ externalId, label }) => (
                <MenuItem key={externalId} value={externalId}>
                  {label}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      {administrationId && (
        <Grid2 container spacing={4} mt={4} size={12}>
          <Grid2 size={12}>
            <Typography
              variant="subtitle1"
              className="Bas-ContentSection-DontChangeColor"
              pb={2}
            >
              <FormattedMessage id="settings.integrationDetails.generalLedgerMapping" />
            </Typography>
            <GeneralLedgerMappingForm
              generalLedgerMappingType={generalLedgerMappingType}
              externalOptions={generalLedgerExternalOptions}
            />
          </Grid2>
          <Grid2
            hidden={costCenterMappingType === CostCenterMappingType.NONE}
            size={12}
          >
            <Typography
              variant="subtitle1"
              className="Bas-ContentSection-DontChangeColor"
              pb={2}
            >
              <FormattedMessage id="settings.integrationDetails.costCenterMapping" />
            </Typography>
            <CostCenterMappingForm
              costCenterMappingType={costCenterMappingType}
              externalOptions={costCenterExternalOptions}
            />
          </Grid2>
          <Grid2 size={12}>
            <Typography
              variant="subtitle1"
              className="Bas-ContentSection-DontChangeColor"
              pb={2}
            >
              <FormattedMessage id="settings.integrationDetails.vatMapping" />
            </Typography>
            <VatMappingForm externalOptions={vatExternalOptions} />
          </Grid2>
        </Grid2>
      )}
    </Grid2>
  );
};

export default ReeleezeeSettingsForm;
