import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { isNews, News } from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type NewsRequestProps = Pagination & Filterable;

export const NewsRequest = async ({
  ...params
}: NewsRequestProps): Promise<AxiosResponse<Collection<News>>> =>
  axios.get(`api/{tenantId}/news`, {
    params: {
      ...params,
    },
  });

export const useNewsRequest = (
  request: NewsRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<News>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<News>>
  > = {},
): UseQueryResult<AxiosResponse<Collection<News>>, AxiosError<ErrorResponse>> =>
  useQuery<
    AxiosResponse<Collection<News>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<News>>
  >({
    ...options,
    queryFn: async () => NewsRequest(request),
    queryKey: ['news', 'list', request],
  });

export const NewsRequestInvalidator: QueryInvalidator = (data, queryClient) => {
  if (isNews(data)) {
    queryClient.invalidateQueries({ queryKey: ['news', 'list'] });
  }
};
