import { isObjectResponse } from '@bas/value-objects';
import type { ClaAllowanceRule } from '../types';

export function isClaAllowanceRule(
  object: unknown,
): object is ClaAllowanceRule {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'ClaAllowanceRule'
  );
}
