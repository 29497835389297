import { isObjectResponse } from '@bas/value-objects';
import type { TimeOffRegistration } from '../types';

export function isTimeOffRegistration(
  object: unknown,
): object is TimeOffRegistration {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'TimeOffRegistration'
  );
}
