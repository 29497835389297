import { isEmployeeOccasionalAvailability } from '@bas/hrm-domain/models';
import { AvailableHours, isEvent } from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import {
  Collection,
  EmployeeProjectRole,
  ErrorResponse,
} from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type AvailableHoursByRoleAndPeriodRequestProps = {
  projectRole: EmployeeProjectRole;
  startDate: Date;
  endDate: Date;
};

type Response = AxiosResponse<Collection<AvailableHours>>;
type QueryKeyType = QueryKey & {
  [0]: 'available-hours';
  [1]: 'list';
  [2]: string;
  [3]: string;
  [4]: string;
};

export const AvailableHoursByRoleAndPeriodRequest = async ({
  projectRole,
  startDate,
  endDate,
  ...params
}: AvailableHoursByRoleAndPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/available-hours/${projectRole}/${formatDate(
      startDate,
    )}/${formatDate(endDate)}`,
    {
      params: { ...params },
    },
  );

export const useAvailableHoursByRoleAndPeriodRequest = (
  request: AvailableHoursByRoleAndPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => AvailableHoursByRoleAndPeriodRequest({ ...request }),
    queryKey: [
      'available-hours',
      'list',
      formatDate(request.startDate),
      formatDate(request.endDate),
      request.projectRole,
    ],
  });

export const AvailableHoursByRoleAndPeriodRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEvent(data) || isEmployeeOccasionalAvailability(data)) {
      queryClient.invalidateQueries({ queryKey: ['available-hours', 'list'] });
    }
  };
