import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RemoveNationalHolidayMutationProps = {
  nationalHolidayId: Uuid;
};

export const RemoveNationalHolidayMutation = async ({
  nationalHolidayId,
  ...values
}: RemoveNationalHolidayMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/hrm/national-holidays/${nationalHolidayId}/remove`,
    {
      nationalHolidayId,
      ...values,
    },
  );

export const useRemoveNationalHolidayMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveNationalHolidayMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  RemoveNationalHolidayMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RemoveNationalHolidayMutationProps
  >({
    mutationFn: RemoveNationalHolidayMutation,
    throwOnError: false,
    ...options,
  });
