import { colors, fontSizesWeb } from '@bas/theme';
import { FormattedCurrency, Tooltip } from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Money } from '@bas/value-objects';
import { Grid2, styled, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type RevenueCostsSummaryProps = {
  grossCosts?: Money;
  grossRevenue?: Money;
  expectedCosts?: Money;
  expectedRevenue?: Money;
  onEdit: () => void;
  className?: string;
};

const defaultMoney = {
  currency: 'EUR',
  amount: 0,
};

const RevenueCostsSummary = ({
  grossRevenue,
  grossCosts,
  expectedCosts,
  expectedRevenue,
  onEdit,
  className,
}: RevenueCostsSummaryProps): ReactElement => {
  const grossProfit = useMemo(
    () =>
      grossCosts &&
      grossCosts.amount > 0 &&
      grossRevenue &&
      grossRevenue.amount > 0
        ? {
            currency: grossCosts.currency,
            amount: grossRevenue.amount - grossCosts.amount,
          }
        : undefined,
    [grossCosts, grossRevenue],
  );

  const grossMargin = useMemo(
    () =>
      grossProfit &&
      grossProfit.amount > 0 &&
      grossRevenue &&
      grossRevenue.amount > 0
        ? grossProfit.amount / grossRevenue.amount
        : undefined,
    [grossProfit, grossRevenue],
  );

  const expectedProfit = useMemo(
    () =>
      expectedCosts &&
      expectedCosts.amount > 0 &&
      expectedRevenue &&
      expectedRevenue.amount > 0
        ? {
            currency: expectedCosts.currency,
            amount: expectedRevenue.amount - expectedCosts.amount,
          }
        : undefined,
    [expectedCosts, expectedRevenue],
  );

  const expectedMargin = useMemo(
    () =>
      expectedProfit &&
      expectedProfit.amount > 0 &&
      expectedRevenue &&
      expectedRevenue.amount > 0
        ? expectedProfit.amount / expectedRevenue.amount
        : undefined,
    [expectedProfit, expectedRevenue],
  );

  const hasGrossProfit = useMemo(
    () =>
      grossProfit && parseInt((grossProfit.amount || 0).toString(), 10) !== 0,
    [grossProfit],
  );

  const hasExpectedProfit = useMemo(
    () =>
      expectedProfit &&
      parseInt((expectedProfit.amount || 0).toString(), 10) !== 0,
    [expectedProfit],
  );

  const hasGrossRevenue = useMemo(
    () =>
      grossRevenue && parseInt((grossRevenue.amount || 0).toString(), 10) !== 0,
    [grossRevenue],
  );

  const hasExpectedRevenue = useMemo(
    () =>
      expectedRevenue &&
      parseInt((expectedRevenue.amount || 0).toString(), 10) !== 0,
    [expectedRevenue],
  );

  const hasGrossCosts = useMemo(
    () => grossCosts && parseInt((grossCosts.amount || 0).toString(), 10) !== 0,
    [grossCosts],
  );

  const hasExpectedCosts = useMemo(
    () =>
      expectedCosts &&
      parseInt((expectedCosts.amount || 0).toString(), 10) !== 0,
    [expectedCosts],
  );

  const profitToShow = useMemo(() => {
    if (hasGrossProfit && grossProfit) {
      return grossProfit;
    }

    if (hasExpectedProfit && expectedProfit) {
      return expectedProfit;
    }

    return defaultMoney;
  }, [expectedProfit, grossProfit, hasExpectedProfit, hasGrossProfit]);

  const revenueToShow = useMemo(() => {
    if (hasGrossRevenue && grossRevenue) {
      return grossRevenue;
    }

    if (hasExpectedRevenue && expectedRevenue) {
      return expectedRevenue;
    }

    return defaultMoney;
  }, [expectedRevenue, grossRevenue, hasExpectedRevenue, hasGrossRevenue]);

  const costsToShow = useMemo(() => {
    if (hasGrossCosts && grossCosts) {
      return grossCosts;
    }

    if (hasExpectedCosts && expectedCosts) {
      return expectedCosts;
    }

    return defaultMoney;
  }, [expectedCosts, grossCosts, hasExpectedCosts, hasGrossCosts]);

  return (
    <ContentSection
      actions={[<ContentSectionEditAction onClick={onEdit} />]}
      className={className}
    >
      <Grid2 container columnSpacing={3}>
        <Grid2
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Typography variant="subtitle2">
            <FormattedMessage
              id={`label.${hasGrossProfit ? 'grossProfit' : 'expectedProfit'}`}
            />
          </Typography>
          <Tooltip
            title={
              <Grid2 container>
                {grossMargin && grossMargin !== 0 && (
                  <Grid2 size={12}>
                    <Typography>
                      {(grossMargin * 100).toFixed(2)}
                      {'% '}
                      <FormattedMessage id="label.grossMargin" />
                    </Typography>
                  </Grid2>
                )}
                {expectedMargin && expectedMargin !== 0 && (
                  <Grid2 size={12}>
                    <Typography>
                      {(expectedMargin * 100).toFixed(2)}
                      {'% '}
                      <FormattedMessage id="label.expectedMargin" />
                    </Typography>
                  </Grid2>
                )}
                {expectedProfit && hasGrossProfit && hasExpectedProfit && (
                  <Grid2 size={12}>
                    <Typography>
                      <FormattedCurrency {...expectedProfit} />
                      &nbsp;
                      <FormattedMessage id="label.expectedProfit" />
                    </Typography>
                  </Grid2>
                )}
              </Grid2>
            }
            disabled={!grossMargin && !expectedMargin}
          >
            <Typography
              variant="h3"
              lineHeight={fontSizesWeb['2xl']}
              className={
                hasGrossProfit && (grossProfit?.amount || 0) < 0
                  ? 'Bas-RevenueCostsSummary-Loss'
                  : undefined
              }
            >
              <FormattedCurrency {...profitToShow} />
            </Typography>
          </Tooltip>
        </Grid2>
        <Grid2
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Tooltip
            title={
              expectedRevenue ? (
                <Typography>
                  <FormattedCurrency {...expectedRevenue} />
                  &nbsp;
                  <FormattedMessage id="label.expectedRevenue" />
                </Typography>
              ) : (
                <span />
              )
            }
            disabled={
              !grossRevenue ||
              !expectedRevenue ||
              !hasGrossRevenue ||
              !hasExpectedRevenue
            }
          >
            <span>
              <Typography variant="subtitle2">
                <FormattedMessage
                  id={`label.${
                    hasGrossRevenue ? 'grossRevenue' : 'expectedRevenue'
                  }`}
                />
              </Typography>
              <Typography variant="h4" sx={{ paddingTop: '11px' }}>
                <FormattedCurrency {...revenueToShow} />
              </Typography>
            </span>
          </Tooltip>
        </Grid2>
        <Grid2
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Tooltip
            title={
              expectedCosts ? (
                <Typography>
                  <FormattedCurrency {...expectedCosts} />
                  &nbsp;
                  <FormattedMessage id="label.expectedCosts" />
                </Typography>
              ) : (
                <span />
              )
            }
            disabled={
              !grossCosts ||
              !expectedCosts ||
              !hasGrossCosts ||
              !hasExpectedCosts
            }
          >
            <span>
              <Typography variant="subtitle2">
                <FormattedMessage
                  id={`label.${hasGrossCosts ? 'grossCosts' : 'expectedCosts'}`}
                />
              </Typography>
              <Typography variant="h4" sx={{ paddingTop: '11px' }}>
                <FormattedCurrency {...costsToShow} />
              </Typography>
            </span>
          </Tooltip>
        </Grid2>
      </Grid2>
    </ContentSection>
  );
};

const StyledRevenueCostsSummary = styled(RevenueCostsSummary)`
  .Bas-RevenueCostsSummary-Loss {
    color: ${colors.red[500]};
  }
`;
export default StyledRevenueCostsSummary;
