import { ExactOnlineSettingsInputType } from '@bas/integration-domain/input-types';
import {
  CostCenterMappingType,
  GeneralLedgerMappingType,
} from '@bas/integration-domain/models';
import { useExactOnlineMappingOptions } from '@bas/integration-domain/web/hooks';
import { useCheckPermissions, useGetTranslatedName } from '@bas/shared/hooks';
import { useTenantStore } from '@bas/shared/state';
import { IdentityType } from '@bas/tenant-domain/models';
import {
  useCostTypesRequest,
  useIdentitiesRequest,
} from '@bas/tenant-domain/requests';
import { Alert, TextFieldNumberFormat } from '@bas/ui/web/atoms';
import {
  IdentityIndicator,
  ReactHookFormAutocomplete,
  ReactHookFormCheckbox,
  ReactHookFormDatePickerField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { MappingTableExternalOption, Uuid } from '@bas/value-objects';
import { Grid2, MenuItem, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CostCenterMappingForm } from '../CostCenterMappingForm';
import { GeneralLedgerMappingForm } from '../GeneralLedgerMappingForm';
import { MappingTableForm } from '../MappingTableForm';
import { VatMappingForm } from '../VatMappingForm';

export type ExactOnlineSettingsFormProps = {
  integrationId: Uuid;
};

const ExactOnlineSettingsForm = ({
  integrationId,
}: ExactOnlineSettingsFormProps): ReactElement => {
  const [defaultDivisionCode, generalLedgerMappingType, costCenterMappingType] =
    useWatch<
      ExactOnlineSettingsInputType,
      [
        'defaultDivisionCode',
        'generalLedgerMappingType',
        'costCenterMappingType',
      ]
    >({
      name: [
        'defaultDivisionCode',
        'generalLedgerMappingType',
        'costCenterMappingType',
      ],
    });

  const useIdentities = useTenantStore(
    (state) => state.tenant?.useIdentities || false,
  );

  const { data: identitiesData } = useIdentitiesRequest(
    {},
    { enabled: useIdentities || false },
  );

  const identitiesToShow = useMemo(
    () =>
      (identitiesData?.data.member || []).filter(
        ({ identityType }) => identityType !== IdentityType.TRADE_NAME,
      ),
    [identitiesData],
  );

  const identityOptions = useMemo(
    () =>
      identitiesToShow.map(({ identityId, companyInformation }) => ({
        basId: identityId,
        label: <IdentityIndicator identityId={identityId} full />,
      })),
    [identitiesToShow],
  );

  const {
    divisionsData,
    divisionsError,
    generalLedgersError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsExternalOptions,
    journalsError,
  } = useExactOnlineMappingOptions(integrationId, defaultDivisionCode);

  const divisions = useMemo(
    () => (divisionsData?.data.member || []).filter(({ code }) => code !== ''),
    [divisionsData],
  );
  const { formatMessage } = useIntl();

  const divisionOptions = useMemo(
    () => [
      {
        externalId: 'ignore',
        label: formatMessage({ id: 'label.ignoreIdentity' }),
      },
      ...divisions.map(({ code, customerName }) => ({
        externalId: code.toString(),
        label: customerName,
      })),
    ],
    [divisions, formatMessage],
  );

  const enablePurchaseOrdersPerProjectByCostCenter = useWatch<
    ExactOnlineSettingsInputType,
    'enablePurchaseOrdersPerProjectByCostCenter'
  >({
    name: 'enablePurchaseOrdersPerProjectByCostCenter',
  });

  const enableCreateCostCenterPerProjectInvoice = useWatch<
    ExactOnlineSettingsInputType,
    'enableCreateCostCenterPerProjectInvoice'
  >({
    name: 'enableCreateCostCenterPerProjectInvoice',
  });

  const { data: costTypesData } = useCostTypesRequest(
    {
      perPage: 99999,
    },
    {
      enabled: enablePurchaseOrdersPerProjectByCostCenter,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  const getTranslatedName = useGetTranslatedName();
  const costTypes = useMemo(
    () =>
      (costTypesData?.data?.member || []).map(
        ({ costTypeId, costTypeName, translatedNames }) => ({
          basId: costTypeId,
          label: getTranslatedName(translatedNames, costTypeName),
        }),
      ),
    [costTypesData?.data, getTranslatedName],
  );

  const checkPermissions = useCheckPermissions();
  const hasSuperAdminRight = useMemo(
    () => checkPermissions('super-admin'),
    [checkPermissions],
  );

  return (
    <Grid2 container columnSpacing={3} rowSpacing={3} pt={1}>
      {(divisionsError ||
        generalLedgersError ||
        departmentsError ||
        journalsError ||
        vatCodesError) && (
        <Grid2 mt={2} size={12}>
          <Alert severity="error">
            <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
          </Alert>
        </Grid2>
      )}
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="startDate"
          render={(registered) => (
            <ReactHookFormDatePickerField
              {...registered}
              textFieldProps={{
                fullWidth: true,
              }}
              label={<FormattedMessage id="label.startDate" />}
            />
          )}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="defaultDivisionCode"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.defaultAdministration" />}
              select
            >
              {divisions.map((division) => (
                <MenuItem key={division.code} value={division.code}>
                  {division.customerName}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      <Grid2
        size={{
          xs: 6,
          md: 4,
        }}
      >
        <Controller
          name="leadingNumber"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.leadingNumber" />}
              InputProps={{
                inputComponent: TextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 0,
                },
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 sx={{ display: { md: 'none' } }} size={6} />
      <Grid2 size={6}>
        <Controller
          name="generalLedgerMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.generalLedgerMappingType" />}
              select
            >
              {Object.values(GeneralLedgerMappingType)
                .filter(
                  (option) =>
                    useIdentities ||
                    option !== GeneralLedgerMappingType.PER_IDENTITY,
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage
                      id={`generalLedgerMappingTypes.${option}`}
                    />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      <Grid2 size={6}>
        <Controller
          name="costCenterMappingType"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.costCenterMappingType" />}
              select
            >
              {Object.values(CostCenterMappingType)
                .filter(
                  (option) =>
                    useIdentities ||
                    option !== CostCenterMappingType.PER_IDENTITY,
                )
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    <FormattedMessage id={`costCenterMappingTypes.${option}`} />
                  </MenuItem>
                ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid2>
      {defaultDivisionCode && (
        <>
          <Grid2 size={6}>
            <Controller
              name="defaultGeneralLedger"
              render={(registered) => (
                <ReactHookFormAutocomplete
                  {...registered}
                  fullWidth
                  textField={{
                    fullWidth: true,
                    label: <FormattedMessage id="label.defaultGeneralLedger" />,
                  }}
                  identifier="externalId"
                  options={generalLedgerExternalOptions}
                  getOptionLabel={(
                    option: string | MappingTableExternalOption,
                  ) => {
                    if (typeof option === 'string') {
                      const found = generalLedgerExternalOptions.find(
                        ({ externalId }) => externalId === option,
                      );

                      return found?.label || option;
                    }

                    return option.label;
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="discountGeneralLedger"
              render={(registered) => (
                <ReactHookFormAutocomplete
                  {...registered}
                  fullWidth
                  textField={{
                    fullWidth: true,
                    label: (
                      <FormattedMessage id="label.discountGeneralLedger" />
                    ),
                  }}
                  identifier="externalId"
                  options={generalLedgerExternalOptions}
                  getOptionLabel={(
                    option: string | MappingTableExternalOption,
                  ) => {
                    if (typeof option === 'string') {
                      const found = generalLedgerExternalOptions.find(
                        ({ externalId }) => externalId === option,
                      );

                      return found?.label || option;
                    }

                    return option.label;
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="salesJournalCode"
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  label={<FormattedMessage id="label.salesJournal" />}
                  select
                >
                  {journalsExternalOptions.map((journal) => (
                    <MenuItem
                      key={journal.externalId}
                      value={journal.externalId}
                    >
                      {journal.label}
                    </MenuItem>
                  ))}
                </ReactHookFormTextField>
              )}
            />
          </Grid2>
          <Grid2 size={6}>
            <Controller
              name="sendEntryNumbers"
              render={(registered) => (
                <ReactHookFormCheckbox
                  {...registered}
                  label={
                    <FormattedMessage id="label.useInvoiceNumberAsTransactionNumber" />
                  }
                />
              )}
            />
          </Grid2>
          <Grid2
            hidden={
              !hasSuperAdminRight && !enableCreateCostCenterPerProjectInvoice
            }
            size={6}
          >
            <Controller
              name="enableCreateCostCenterPerProjectInvoice"
              render={(registered) => (
                <ReactHookFormCheckbox
                  {...registered}
                  label={
                    <FormattedMessage id="label.createCostCenterPerProjectInvoice" />
                  }
                />
              )}
            />
          </Grid2>
          <Grid2
            hidden={
              !hasSuperAdminRight && !enablePurchaseOrdersPerProjectByCostCenter
            }
            size={6}
          >
            <Controller
              name="enablePurchaseOrdersPerProjectByCostCenter"
              render={(registered) => (
                <ReactHookFormCheckbox
                  {...registered}
                  label={
                    <FormattedMessage id="label.purchaseOrdersPerProjectByCostCenter" />
                  }
                />
              )}
            />
          </Grid2>
          <Grid2 container spacing={4} mt={4} size={12}>
            {useIdentities && (
              <Grid2 size={12}>
                <Typography
                  variant="subtitle1"
                  className="Bas-ContentSection-DontChangeColor"
                  pb={2}
                >
                  <FormattedMessage id="settings.integrationDetails.administrationMapping" />
                </Typography>
                <MappingTableForm
                  basOptions={identityOptions}
                  externalOptions={divisionOptions}
                  prefix="administrationMapping"
                  basIdLabel={<FormattedMessage id="label.identity" />}
                  externalIdLabel={
                    <FormattedMessage id="label.administration" />
                  }
                />
              </Grid2>
            )}
            <Grid2 size={12}>
              <Typography
                variant="subtitle1"
                className="Bas-ContentSection-DontChangeColor"
                pb={2}
              >
                <FormattedMessage id="settings.integrationDetails.generalLedgerMapping" />
              </Typography>
              <GeneralLedgerMappingForm
                generalLedgerMappingType={generalLedgerMappingType}
                externalOptions={generalLedgerExternalOptions}
              />
            </Grid2>
            <Grid2
              hidden={costCenterMappingType === CostCenterMappingType.NONE}
              size={12}
            >
              <Typography
                variant="subtitle1"
                className="Bas-ContentSection-DontChangeColor"
                pb={2}
              >
                <FormattedMessage id="settings.integrationDetails.costCenterMapping" />
              </Typography>
              <CostCenterMappingForm
                costCenterMappingType={costCenterMappingType}
                externalOptions={costCenterExternalOptions}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="subtitle1"
                className="Bas-ContentSection-DontChangeColor"
                pb={2}
              >
                <FormattedMessage id="settings.integrationDetails.vatMapping" />
              </Typography>
              <VatMappingForm externalOptions={vatExternalOptions} />
            </Grid2>
            {enablePurchaseOrdersPerProjectByCostCenter && (
              <Grid2 size={12}>
                <Typography
                  variant="subtitle1"
                  className="Bas-ContentSection-DontChangeColor"
                  pb={2}
                >
                  <FormattedMessage id="settings.integrationDetails.purchaseCostMapping" />
                </Typography>
                <MappingTableForm
                  basOptions={costTypes}
                  externalOptions={generalLedgerExternalOptions}
                  prefix="purchaseCostMapping"
                  basIdLabel={<FormattedMessage id="label.costType" />}
                  externalIdLabel={
                    <FormattedMessage id="label.generalLedger" />
                  }
                />
              </Grid2>
            )}
          </Grid2>
        </>
      )}
    </Grid2>
  );
};

export default ExactOnlineSettingsForm;
