import {
  DisableSettingInputType,
  disableSettingInputTypeDefaultValues,
  disableSettingInputTypeValidationBuilder,
} from '@bas/shared/input-types';
import { Button } from '@bas/ui/web/atoms';
import { DialogProps } from '@bas/ui/web/molecules';
import { ReactElement } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { DisableSettingForm } from '../DisableSettingForm';
import { FormDialog } from '../FormDialog';

export type DisableSettingFormDialogProps = Omit<DialogProps, 'onSubmit'> & {
  onSubmit: SubmitHandler<DisableSettingInputType>;
};

const DisableSettingFormDialog = ({
  onClose,
  onSubmit,
  ...args
}: DisableSettingFormDialogProps): ReactElement => (
  <FormDialog<DisableSettingInputType>
    {...args}
    maxWidth="sm"
    fullWidth
    onClose={onClose}
    defaultValues={{
      ...disableSettingInputTypeDefaultValues(),
    }}
    validationSchema={disableSettingInputTypeValidationBuilder}
    name="disable-settting"
    useProvider
    title={<FormattedMessage id="label.disable" />}
    onSubmit={onSubmit}
    disableSubmitOnEnter
    dialogActions={[
      <Button type="submit" key="send">
        <FormattedMessage id="label.disable" />
      </Button>,
    ]}
  >
    <DisableSettingForm />
  </FormDialog>
);

export default DisableSettingFormDialog;
