import dayjs from 'dayjs';
import { DocumentType } from '@bas/value-objects';
import { DocumentTemplateType } from '../types';

const address = (path: string) => ({
  streetName: {
    name: 'Straatnaam',
    value: `{{${path}.streetName}}`,
    sample: 'Postbus',
  },
  houseNumberIncludingAddition: {
    name: 'Huisnummer',
    value: `{{${path}.houseNumberIncludingAddition}}`,
    sample: '987',
  },
  zipCode: {
    name: 'Postcode',
    value: `{{${path}.zipCode}}`,
    sample: '5600 AZ',
  },
  city: {
    name: 'Stad',
    value: `{{${path}.city}}`,
    sample: 'Eindhoven',
  },
  country: {
    name: 'Land',
    value: `{{${path}.country}}`,
    sample: 'Nederland',
  },
});

const identity = {
  name: 'Identiteit',
  mergeTags: {
    logoUrl: {
      name: 'Logo',
      value: '{{identity.logoUrl}}',
      sample: 'https://site.bas.software/wp-content/uploads/2020/09/logo.png',
    },
    companyName: {
      name: 'Bedrijfsnaam',
      value: '{{identity.companyName}}',
      sample: 'Bas Software B.V.',
    },
    phoneNumber: {
      name: 'Telefoonnummer',
      value: '{{identity.phoneNumber}}',
      sample: '085-3033415',
    },
    mobileNumber: {
      name: 'Mobielenummer',
      value: '{{identity.mobileNumber}}',
      sample: '085-3033415',
    },
    emailAddress: {
      name: 'Email',
      value: '{{identity.emailAddress}}',
      sample: 'info@bas.software',
    },
    cocNumber: {
      name: 'KVK nummer',
      value: '{{identity.cocNumber}}',
      sample: '530560318',
    },
    vatNumber: {
      name: 'BTW nummer',
      value: '{{identity.vatNumber}}',
      sample: 'NL087370979B15',
    },
    ibanNumber: {
      name: 'IBAN',
      value: '{{identity.ibanNumber}}',
      sample: 'NL72INGB0956389430',
    },
    address: {
      name: 'Adres',
      mergeTags: address('identity.address'),
    },
  },
};

const freeText = (documentType: string) => ({
  name: 'Vrije tekstvelden',
  mergeTags: {
    freeText0: {
      name: '1ste vrijetekstveld',
      value: `{{${documentType}.freeText[0]|nl2br}}`,
      sample: 'Vrije tekst',
    },
    freeText1: {
      name: '2de vrijetekstveld',
      value: `{{${documentType}.freeText[1]|nl2br}}`,
      sample: 'Vrije tekst',
    },
    freeText2: {
      name: '3de vrijetekstveld',
      value: `{{${documentType}.freeText[2]|nl2br}}`,
      sample: 'Vrije tekst',
    },
    freeText3: {
      name: '4de vrijetekstveld',
      value: `{{${documentType}.freeText[3]|nl2br}}`,
      sample: 'Vrije tekst',
    },
    freeText4: {
      name: '5de vrijetekstveld',
      value: `{{${documentType}.freeText[4]|nl2br}}`,
      sample: 'Vrije tekst',
    },
    freeText5: {
      name: 'Vrijetekstveld met eigen naam',
      value: `{{${documentType}.freeText['Voer hier tussen de quotes je naam in']|nl2br}}`,
      sample: 'Vrije tekst',
    },
  },
});

const contactPerson = (contactType: string) => ({
  salutation: {
    name: 'Aanhef',
    value: `{{customer.${contactType}.salutation}}`,
    sample: 'heer',
  },
  firstName: {
    name: 'Voornaam',
    value: `{{customer.${contactType}.firstName}}`,
    sample: 'Bas',
  },
  lastName: {
    name: 'Achternaam',
    value: `{{customer.${contactType}.lastName}}`,
    sample: 'de Jong',
  },
  phoneNumber: {
    name: 'Telefoonnummer',
    value: `{{customer.${contactType}.phoneNumber}}`,
  },
  mobileNumber: {
    name: 'Mobielenummer',
    value: `{{customer.${contactType}.mobileNumber}}`,
  },
  emailAddress: {
    name: 'Email',
    value: `{{customer.${contactType}.emailAddress}}`,
  },
});

const customer = {
  name: 'Klant',
  mergeTags: {
    customerNumber: {
      name: 'Klantnummer',
      value: `{{customer.customerNumber}}`,
      sample: '12',
    },
    salutation: {
      name: 'Aanhef',
      value: `{{customer.salutation}}`,
      sample: 'heer',
    },
    name: {
      name: 'Naam',
      value: '{{customer.name}}',
      sample: 'Bas de Jong',
    },
    firstName: {
      name: 'Voornaam',
      value: '{{customer.firstName}}',
      sample: 'Bas',
    },
    lastName: {
      name: 'Achternaam',
      value: '{{customer.lastName}}',
      sample: 'de Jong',
    },
    companyName: {
      name: 'Bedrijfsnaam',
      value: '{{customer.companyName}}',
      sample: 'Bas Software B.V.',
    },
    vatNumber: {
      name: 'BTW nummer',
      value: '{{customer.vatNumber}}',
      sample: 'NL676770265B70',
    },
    cocNumber: {
      name: 'KVK nummer',
      value: '{{customer.cocNumber}}',
      sample: '12345678',
    },
    phoneNumber: {
      name: 'Telefoonnummer',
      value: '{{customer.phoneNumber}}',
    },
    mobileNumber: {
      name: 'Mobielenummer',
      value: '{{customer.mobileNumber}}',
    },
    emailAddress: {
      name: 'Email',
      value: '{{customer.emailAddress}}',
    },
    invoiceAddress: {
      name: 'Factuur adres',
      mergeTags: address('customer.invoiceAddress'),
    },
    primaryContactPerson: {
      name: 'Hoofd contactpersoon',
      mergeTags: contactPerson('primaryContactPerson'),
    },
    invoiceContactPerson: {
      name: 'Facturatie contactpersoon',
      mergeTags: contactPerson('invoiceContactPerson'),
    },
  },
};

const invoice = {
  name: 'Factuur',
  mergeTags: {
    invoiceNumber: {
      name: 'Factuurnummer',
      value: '{{invoice.invoiceNumber}}',
      sample: '2021-00001',
    },
    reference: {
      name: 'Referentie',
      value: '{{invoice.reference}}',
      sample: '2021-00001',
    },
    invoicedAt: {
      name: 'Factuurdatum',
      value: '{{invoice.invoicedAt}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    expiresAt: {
      name: 'Vervaldatum',
      value: '{{invoice.expiresAt}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    totalIncludingVat: {
      name: 'Totaal incl btw.',
      value: '{{invoice.totalIncludingVat}}',
      sample: '€ 100,50',
    },
    structuredMessage: {
      name: 'Gestructureerde mededeling',
      value: '{{invoice.structuredMessage}}',
      sample: '+++020/2200/00917+++',
    },
    freeText: freeText('invoice'),
  },
};

const quote = {
  name: 'Offerte',
  mergeTags: {
    invoiceNumber: {
      name: 'Offertenummer',
      value: '{{quote.quotationNumber}}',
      sample: '2021-00001',
    },
    reference: {
      name: 'Referentie',
      value: '{{quote.reference}}',
      sample: '2021-00001',
    },
    expiresAt: {
      name: 'Vervaldatum',
      value: '{{quote.expiresAt}}',
      sample: dayjs().format('DD-MM-YYYY'),
    },
    totalIncludingVat: {
      name: 'Totaal incl btw.',
      value: '{{quote.totalIncludingVat}}',
      sample: '€ 100,50',
    },
    freeText: freeText('quote'),
  },
};

const movingJob = {
  name: 'Verhuizing',
  mergeTags: {
    fromAddress: {
      name: 'Laad adres',
      mergeTags: address('project.primaryFromAddress'),
    },
    toAddress: {
      name: 'Los adres',
      mergeTags: address('project.primaryToAddress'),
    },
    firstEventDate: {
      name: 'Eerste verhuis afspraak datum',
      value: `{{firstEvent.date}}`,
      sample: dayjs().format('DD-MM-YYYY'),
    },
    firstEventTime: {
      name: 'Eerste verhuis afspraak tijd',
      value: `{{firstEvent.time}}`,
      sample: dayjs().format('HH:mm'),
    },
    lastEventDate: {
      name: 'Laatste verhuis afspraak datum',
      value: `{{lastEvent.date}}`,
      sample: dayjs().format('DD-MM-YYYY'),
    },
    lastEventTime: {
      name: 'Laatste verhuis afspraak tijd',
      value: `{{lastEvent.time}}`,
      sample: dayjs().format('HH:mm'),
    },
    customerNotes: {
      name: 'Notities voor de klant',
      value: `{{project.customerNotes|nl2br}}`,
      sample: 'Notities voor de klant',
    },
    projectCode: {
      name: 'Dossier nummer',
      value: `{{project.projectCode}}`,
      sample: '2022-000001',
    },
  },
};

const event = {
  name: 'Afspraak',
  mergeTags: {
    eventType: {
      name: 'Type werkzaamheden',
      value: `{{event.eventType}}`,
      sample: 'Verhuizing',
    },
    eventStartDate: {
      name: 'Start datum',
      value: `{{event.start.date}}`,
      sample: dayjs().format('DD-MM-YYYY'),
    },
    eventStartTime: {
      name: 'Start tijd',
      value: `{{event.start.time}}`,
      sample: dayjs().format('HH:mm'),
    },
    eventEndDate: {
      name: 'Eind datum',
      value: `{{event.end.date}}`,
      sample: dayjs().format('DD-MM-YYYY'),
    },
    eventEndTime: {
      name: 'Eind tijd',
      value: `{{event.end.time}}`,
      sample: dayjs().format('HH:mm'),
    },
    finishedAtCustomerAt: {
      name: 'Afgerond bij de klant om',
      value: `{{event.finishedAtCustomerAt.time}}`,
      sample: dayjs().format('HH:mm'),
    },
    realStart: {
      name: 'Daadwerkelijke start tijd',
      value: `{{event.realStart.time}}`,
      sample: dayjs().format('HH:mm'),
    },
    realTravelStart: {
      name: 'Vertrektijd loods',
      value: `{{event.realTravelStart.time}}`,
      sample: dayjs().format('HH:mm'),
    },
    breakTime: {
      name: 'Pauze',
      value: `{{event.breakTime}}`,
      sample: '30',
    },
    particulars: {
      name: 'Bijzonderheden',
      value: `{{event.particulars}}`,
      sample: 'Bijzonderheden',
    },
    remarks: {
      name: 'Opmerkingen klant',
      value: `{{event.remarks}}`,
      sample: 'Opmerkingen klant',
    },
    wasTheWorkPerformedSatisfactorily: {
      name: 'Klant tevreden',
      value: `{{event.wasTheWorkPerformedSatisfactorily}}`,
      sample: 'Wel/Niet',
    },
  },
};

const materials = {
  name: 'Materialen',
  rules: {
    materials: {
      name: 'Alle materiaal regels',
      before:
        "{% for material in materials|filter(material => material.materialType == 'reusable-material') %}",
      after: '{% endfor %}',
    },
    outstandingMaterials: {
      name: 'Materiaal in bezit van de klant',
      before:
        "{% for material in materials|filter(material => material.materialType == 'reusable-material' and material.outstandingStock > 0) %}",
      after: '{% endfor %}',
    },
    usedMaterials: {
      name: 'Materiaal die zijn verbruikt',
      before:
        "{% for material in materials|filter(material => material.materialType == 'reusable-material' and material.usedStock > 0) %}",
      after: '{% endfor %}',
    },
    soldMaterials: {
      name: 'Materiaal die zijn verkocht',
      before:
        "{% for material in materials|filter(material => material.materialType == 'reusable-material' and material.soldStock > 0) %}",
      after: '{% endfor %}',
    },
    withoutOutstandingMaterials: {
      name: 'Geen materiaal in bezit van de klant (let op variabelen niet beschikbaar)',
      before:
        "{% if material in materials|filter(material => material.materialType == 'reusable-material' and material.outstandingStock > 0)|length == 0 %}",
      after: '{% endif %}',
    },
    withoutUsedMaterials: {
      name: 'Geen materiaal die zijn verbruikt (let op variabelen niet beschikbaar)',
      before:
        "{% if material in materials|filter(material => material.materialType == 'reusable-material' and material.usedStock > 0)|length == 0 %}",
      after: '{% endif %}',
    },
    withoutSoldMaterials: {
      name: 'Geen materiaal die zijn verkocht (let op variabelen niet beschikbaar)',
      before:
        "{% if material in materials|filter(material => material.materialType == 'reusable-material' and material.soldStock > 0)|length == 0 %}",
      after: '{% endif %}',
    },
    withoutMaterials: {
      name: 'Geen materiaal regels (let op variabelen niet beschikbaar)',
      before:
        "{% if materials|filter(material => material.materialType == 'reusable-material')|length == 0 %}",
      after: '{% endif %}',
    },
    boxes: {
      name: 'Alle doos regels',
      before:
        "{% for material in materials|filter(material => material.materialType == 'moving-boxes') %}",
      after: '{% endfor %}',
    },
    outstandingBoxes: {
      name: 'Dozen in bezit van de klant',
      before:
        "{% for material in materials|filter(material => material.materialType == 'moving-boxes' and material.outstandingStock > 0) %}",
      after: '{% endfor %}',
    },
    usedBoxes: {
      name: 'Dozen die zijn verbruikt',
      before:
        "{% for material in materials|filter(material => material.materialType == 'moving-boxes' and material.usedStock > 0) %}",
      after: '{% endfor %}',
    },
    soldBoxes: {
      name: 'Dozen die zijn verkocht',
      before:
        "{% for material in materials|filter(material => material.materialType == 'moving-boxes' and material.soldStock > 0) %}",
      after: '{% endfor %}',
    },
    withoutOutstandingBoxes: {
      name: 'Geen dozen in bezit van de klant (let op variabelen niet beschikbaar)',
      before:
        "{% if material in materials|filter(material => material.materialType == 'moving-boxes' and material.outstandingStock > 0)|length == 0 %}",
      after: '{% endif %}',
    },
    withoutUsedBoxes: {
      name: 'Geen dozen die zijn verbruikt (let op variabelen niet beschikbaar)',
      before:
        "{% if material in materials|filter(material => material.materialType == 'moving-boxes' and material.usedStock > 0)|length == 0 %}",
      after: '{% endif %}',
    },
    withoutSoldBoxes: {
      name: 'Geen dozen die zijn verkocht (let op variabelen niet beschikbaar)',
      before:
        "{% if material in materials|filter(material => material.materialType == 'moving-boxes' and material.soldStock > 0)|length == 0 %}",
      after: '{% endif %}',
    },
    withoutBoxes: {
      name: 'Geen dozen regels (let op variabelen niet beschikbaar)',
      before:
        "{% if materials|filter(material => material.materialType == 'moving-boxes')|length == 0 %}",
      after: '{% endif %}',
    },
  },
  mergeTags: {
    name: {
      name: 'Naam',
      value: '{{material.inventoryItemName}}',
      sample: 'Meterbak',
    },
    outstanding: {
      name: 'Uitstaand',
      value: '{{material.outstandingStock}}',
      sample: '10',
    },
    usedStock: {
      name: 'Verbruikt',
      value: '{{material.usedStock}}',
      sample: '10',
    },
    sold: {
      name: 'Verkocht',
      value: '{{material.soldStock}}',
      sample: '5',
    },
  },
};

const damages = {
  name: 'Schades',
  rules: {
    duringCurrentEventNew: {
      name: 'Schades gemeld tijdens de klus gemaakt door het personeel',
      before: `{% for damage in damagesDuringEvent|filter(damage => damage.damageWasAlreadyThere == false) %}`,
      after: '{% endfor %}',
    },
    duringCurrentEventExisting: {
      name: 'Schades gemeld tijdens de klus die al aanwezig waren',
      before: `{% for damage in damagesDuringEvent|filter(damage => damage.damageWasAlreadyThere == true) %}`,
      after: '{% endfor %}',
    },
    WithoutDuringCurrentEventNew: {
      name: 'Zonder schades gemeld tijdens de klus gemaakt door het personeel (let op geen variabelen)',
      before: `{% if damagesDuringEvent|filter(damage => damage.damageWasAlreadyThere == false)|length == 0 %}`,
      after: '{% endif %}',
    },
    WithoutDuringCurrentEventExisting: {
      name: 'Zonder schades gemeld tijdens de klus die al aanwezig waren (let op geen variabelen)',
      before: `{% if damagesDuringEvent|filter(damage => damage.damageWasAlreadyThere == true)|length == 0 %}`,
      after: '{% endif %}',
    },
  },
  mergeTags: {
    summary: {
      name: 'Samenvatting',
      value: '{{damage.summary}}',
      sample: 'Schade aan kast',
    },
    description: {
      name: 'Omschrijving',
      value: '{{damage.description}}',
      sample: 'Er is een kast uit de vrachtwagen gevallen.',
    },
    damageWasAlreadyThere: {
      name: 'Was de schade al aanwezig',
      value: '{{damage.damageWasAlreadyThere}}',
      sample: 'wel/niet',
    },
  },
};

const mergeTags: {
  [key: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
} = {
  invoice: {
    default: {
      identity,
      customer,
      invoice,
      movingJob,
    },
    'moving-job': {
      identity,
      customer,
      invoice,
      movingJob,
    },
    'moving-job-with-storage': {
      identity,
      customer,
      invoice,
      movingJob,
    },
    storage: {
      identity,
      customer,
      invoice,
    },
    manual: {
      identity,
      customer,
      invoice,
      movingJob,
    },
  },
  quote: {
    default: {
      identity,
      customer,
      quote,
      movingJob,
    },
    'moving-job': {
      identity,
      customer,
      quote,
      movingJob,
    },
    'moving-job-with-storage': {
      identity,
      customer,
      quote,
      movingJob,
    },
    storage: {
      identity,
      customer,
      quote,
    },
    manual: {
      identity,
      customer,
      quote,
      movingJob,
    },
  },
  contract: {
    manual: {
      identity,
      customer,
      movingJob,
      event,
      materials,
      damages,
    },
  },
};

export const getMergeTagsByDocumentTypeAndDocumentTemplateType = (
  documentType: DocumentType,
  templateType: DocumentTemplateType,
): unknown => mergeTags[documentType][templateType] || {};
