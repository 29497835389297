import { colors } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import { ContentSection } from '@bas/ui/web/molecules';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid2, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type AddTeamMemberItemProps = {
  className?: string;
  active?: boolean;
  onClick: () => Promise<void> | void;
};

const AddTeamMemberItem = ({
  className,
  active,
  onClick,
}: AddTeamMemberItemProps): ReactElement => (
  <ContentSection
    className={clsx(className, { 'Bas-AddTeamMemberItem-Active': active })}
    actions={[]}
    onClick={async (e) => {
      if (
        e.target instanceof HTMLElement &&
        !e.target.classList.contains('Bas-AddTeamMemberItem-Delete')
      ) {
        return onClick();
      }

      return undefined;
    }}
  >
    <Box className="Bas-AddTeamMemberItem-root">
      <Box className="Bas-AddTeamMemberItem-Avatar">
        <Icon icon={faUserCircle} />
      </Box>
      <Grid2
        container
        className="Bas-AddTeamMemberItem-NameBlock"
        alignItems="center"
      >
        <Grid2 className="Bas-AddTeamMemberItem-Name" size={12}>
          <Typography>
            <FormattedMessage id="teamMemberRoles.addMember" />
          </Typography>
        </Grid2>
      </Grid2>
    </Box>
  </ContentSection>
);

const StyledAddTeamMemberItem = styled(AddTeamMemberItem)(
  ({ theme }) => `
  cursor: pointer;
  &:hover, &.Bas-AddTeamMemberItem-Active {
    .Bas-AddTeamMemberItem-root {
      .Bas-AddTeamMemberItem-Role .MuiTypography-root, .Bas-AddTeamMemberItem-Name .MuiTypography-root {
        color: ${colors.blue[500]};
      }
    }
  }

  .Bas-ContentSection-Actions {
    top: -${theme.spacing(0)};
    right: ${theme.spacing(0)};
  }

  &.Bas-ContentSection-HasActions {
    &:hover, &.Bas-ContentSection-Editing {
      &:before {
        left: -13px;
        right: -13px;
        top: -${theme.spacing(1)};
        bottom: -${theme.spacing(1)};
      }
    }
  }

  .Bas-AddTeamMemberItem-root {
    display: flex;
    flex-direction: row;

    .Bas-AddTeamMemberItem-Avatar {
      color: ${colors.lila[400]};
      padding-right: 12px;
      font-size: 44px;
    }

    .Bas-AddTeamMemberItem-Role .MuiTypography-root {
      color: ${theme.palette.common.black};
    }

    .Bas-AddTeamMemberItem-Name .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
    }
  }
`,
);
export default StyledAddTeamMemberItem;
