import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { isTask, Task } from '@bas/task-domain/models';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TaskByTaskIdRequestProps = {
  taskId: Uuid;
};

export const TaskByTaskIdRequest = async ({
  taskId,
  ...params
}: TaskByTaskIdRequestProps): Promise<AxiosResponse<Task>> =>
  axios.get(`api/{tenantId}/tasks/${taskId}`, {
    params,
  });

export const useTaskByTaskIdRequestQuery = (
  request: TaskByTaskIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Task>,
    AxiosError,
    AxiosResponse<Task>
  > = {},
): UseQueryResult<AxiosResponse<Task>, AxiosError> =>
  useQuery<AxiosResponse<Task>, AxiosError, AxiosResponse<Task>>({
    ...options,
    queryFn: async () => TaskByTaskIdRequest(request),
    queryKey: ['tasks', 'detail', request.taskId],
  });

export const TaskByTaskIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTask(data)) {
    queryClient.invalidateQueries({
      queryKey: ['tasks', 'detail', data.taskId],
    });
  }
};
