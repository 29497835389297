import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Identity, isIdentity } from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type IdentitiesRequestProps = Pagination;

type Response = AxiosResponse<Collection<Identity>>;
type QueryKeyType = QueryKey & {
  [0]: 'identities';
  [1]: 'list';
};

export const IdentitiesRequest = async ({
  ...params
}: IdentitiesRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/identities', { params });

export const useIdentitiesRequest = (
  request: IdentitiesRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => IdentitiesRequest({ ...request }),
    queryKey: ['identities', 'list'],
  });

export const IdentitiesByIdentityIdsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isIdentity(data)) {
    queryClient.invalidateQueries({ queryKey: ['identities', 'list'] });
  }
};
