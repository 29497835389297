import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { isStorage, Storage } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type StorageByStorageIdRequestProps = {
  storageId: Uuid;
};

export const StorageByStorageIdRequest = async ({
  storageId,
  ...params
}: StorageByStorageIdRequestProps): Promise<AxiosResponse<Storage>> =>
  axios.get(`api/{tenantId}/storages/${storageId}`, {
    params,
  });

export const useStorageByStorageIdRequestQuery = (
  request: StorageByStorageIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Storage>,
    AxiosError,
    AxiosResponse<Storage>
  > = {},
): UseQueryResult<AxiosResponse<Storage>, AxiosError> =>
  useQuery<AxiosResponse<Storage>, AxiosError, AxiosResponse<Storage>>({
    ...options,
    queryFn: async () => StorageByStorageIdRequest(request),
    queryKey: ['storages', 'detail', request.storageId],
  });

export const StorageByStorageIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isStorage(data)) {
    queryClient.invalidateQueries({
      queryKey: ['storages', 'detail', data.storageId],
    });
  }
};
