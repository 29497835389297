import { isObjectResponse } from '@bas/value-objects';
import { ExactOnlineSettings } from '../../types';

export function isExactOnlineSettings(
  object: unknown,
): object is ExactOnlineSettings {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'ExactOnlineSettings'
  );
}
