import { EstimationItemToUseForQuote } from '../types';

export function isEstimationItemToUseForQuote(
  object: unknown,
): object is EstimationItemToUseForQuote {
  return (
    !!object &&
    typeof object === 'object' &&
    Object.prototype.hasOwnProperty.call(object, 'groupType') &&
    Object.prototype.hasOwnProperty.call(object, 'itemType')
  );
}
