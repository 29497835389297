import { colors } from '@bas/theme';
import { TextField, TextFieldProps } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { styled, TextField as MuiTextField } from '@mui/material';
import {
  TimePicker as MuiTimePicker,
  TimePickerProps as MuiTimePickerProps,
} from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

export type TimePickerFieldProps = MuiTimePickerProps<Dayjs> & {
  textFieldProps?: TextFieldProps;
};

const OpenPickerIcon = () => (
  <Icon icon={faClock} sx={{ marginRight: '4px' }} fontSize={16} />
);

const TimePickerField = ({
  textFieldProps,
  className,
  value,
  onChange,
  format = 'HH:mm',
  slots = {
    openPickerIcon: OpenPickerIcon,
    textField: TextField as typeof MuiTextField,
  },
  slotProps = {
    openPickerButton: {
      disableRipple: true,
    },
  },
  ...props
}: TimePickerFieldProps) => (
  <MuiTimePicker
    format={format}
    slots={slots}
    value={value ? dayjs(value) : null}
    onChange={(date, context) => {
      onChange?.(date || null, context);
    }}
    slotProps={{
      ...slotProps,
      popper: {
        className,
        ...slotProps?.popper,
      },
      textField: {
        ...slotProps?.textField,
        ...textFieldProps,
      },
    }}
    {...props}
  />
);

const StyledTimePickerField = styled(TimePickerField)(
  ({ theme }) => `
  .MuiPaper-root {
    box-shadow: unset;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid ${colors.lila[400]};

    > div > div, .MuiCalendarPicker-root {
      width: 326px;
    }

    .MuiCalendarPicker-root {
      padding: ${theme.spacing(0, 2)};
      box-sizing: border-box;
    }

    .MuiCalendarPicker-viewTransitionContainer > div > div:not(.PrivatePickersSlideTransition-root) {
      display:none;
    }
  }
`,
);
export default StyledTimePickerField;
