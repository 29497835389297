import { isFurnitureType, PublicFurnitureType } from '@bas/shared/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';

export type PublicFurnitureTypesRequestProps = Pagination;
type Response = AxiosResponse<Collection<PublicFurnitureType>>;

export const PublicFurnitureTypesRequest = async ({
  ...params
}: PublicFurnitureTypesRequestProps): Promise<Response> =>
  axios.get('api/public/settings/furniture-types', {
    params: { ...params },
  });

export const usePublicFurnitureTypesRequest = (
  request: PublicFurnitureTypesRequestProps = {
    perPage: 9999,
  },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => PublicFurnitureTypesRequest(request),
    queryKey: ['furniture-types', 'public-list'],
  });

export const usePrefetchPublicFurnitureTypesRequest = (
  request: PublicFurnitureTypesRequestProps = {},
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['moving-boxes', 'public-list'],
      queryFn: async () =>
        PublicFurnitureTypesRequest({ perPage: 9999, ...request }),
    });
  }, [queryClient, request]);
};

export const publicFurnitureTypeRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isFurnitureType(data)) {
    queryClient.invalidateQueries({
      queryKey: ['furniture-types', 'public-list'],
    });
  }
};
