import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import {
  DocumentTemplate,
  DocumentTemplateType,
  isDocumentTemplate,
} from '@bas/tenant-domain/models';
import { Collection, DocumentType, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type DocumentTemplatesRequestProps = Pagination & {
  'documentType.value'?: DocumentType;
  'templateType.value'?: DocumentTemplateType;
};

type Response = AxiosResponse<Collection<DocumentTemplate>>;

export const DocumentTemplatesRequest = async ({
  ...params
}: DocumentTemplatesRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/document-templates', {
    params: { ...params },
  });

export const useDocumentTemplatesRequest = (
  request: DocumentTemplatesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => DocumentTemplatesRequest(request),
    queryKey: ['document-templates', 'list', Object.values(request)],
  });

export const DocumentTemplatesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isDocumentTemplate(data)) {
    queryClient.invalidateQueries({ queryKey: ['document-templates', 'list'] });
  }
};
