export const calculatePercentagesFromHours = (
  totalHoursOfDay: number,
  confirmedHours: number,
  reservedHours: number,
): {
  availablePercentage: number;
  reservedPercentage: number;
  confirmedPercentage: number;
} => ({
  availablePercentage: totalHoursOfDay === 0 ? 0 : 100,
  reservedPercentage: totalHoursOfDay
    ? (reservedHours / totalHoursOfDay) * 100
    : 0,
  confirmedPercentage: totalHoursOfDay
    ? (confirmedHours / totalHoursOfDay) * 100
    : 0,
});
