import { ClaAllowanceRule, isClaAllowanceRule } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ClaAllowanceRulesRequestProps = Pagination &
  Filterable & {
    withDisabled?: boolean;
  };

export const ClaAllowanceRulesRequest = async ({
  ...params
}: ClaAllowanceRulesRequestProps): Promise<
  AxiosResponse<Collection<ClaAllowanceRule>>
> =>
  axios.get(`api/{tenantId}/hrm/cla/allowance-rules`, {
    params: { ...params },
  });

export const useClaAllowanceRulesRequest = (
  request: ClaAllowanceRulesRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<ClaAllowanceRule>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<ClaAllowanceRule>>
  > = {},
): UseQueryResult<
  AxiosResponse<Collection<ClaAllowanceRule>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<ClaAllowanceRule>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<ClaAllowanceRule>>
  >({
    ...options,
    queryFn: async () => ClaAllowanceRulesRequest(request),
    queryKey: ['cla-allowance-rules', 'list', request],
  });

export const ClaAllowanceRulesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isClaAllowanceRule(data)) {
    queryClient.invalidateQueries({
      queryKey: ['cla-allowance-rules', 'list'],
    });
  }
};
