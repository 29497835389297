import { colors, fontSizesWeb } from '@bas/theme';
import { ListItem, ListItemButton, ListItemProps, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement } from 'react';
import { SidebarSizeType } from '../SidebarListItem';

export type SidebarIconListItemProps = Omit<ListItemProps, 'onClick'> & {
  size?: SidebarSizeType;
  className?: string;
};

const SidebarIconListItem = ({
  children,
  className,
  ...args
}: SidebarIconListItemProps): ReactElement => (
  <ListItem disableGutters className={clsx(className)} {...args}>
    <ListItemButton disableRipple>{children}</ListItemButton>
  </ListItem>
);

const StyledSidebarIconListItem = styled(SidebarIconListItem)(
  ({ theme, size = SidebarSizeType.MEDIUM }) => `
  padding: 0;
  height: ${size === SidebarSizeType.MEDIUM ? '88px' : '71px'};
  width: 88px;
  text-align: center;
  background: ${colors.blue[900]};
  color: ${colors.white};
  &.Bas-SidebarIconListItem-Active {
    color: ${colors.blue[500]};
  }
  cursor: pointer;
  display: flex;
  justify-content: center;

  .MuiListItemButton-root {
    justify-content: center;
  }
  &:not(.Bas-SidebarIconListItem-Active):hover {
    color: ${colors.blue[200]};
  }
  .MenuListItem-Icon {
    font-size: 1.65rem;
    margin-bottom: 4px;
  }

  .MenuListItem-Label {
    font-size: ${fontSizesWeb.xs};
    font-family: ${theme.typography.fontFamily};
  }
`,
);
export default StyledSidebarIconListItem;
