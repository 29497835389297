import { StorageEmptiedEvent, StorageEventType } from '../../types';
import { isStorageEvent } from './StorageEvent';

export function isStorageEmptiedEvent(
  object: unknown,
): object is StorageEmptiedEvent {
  return (
    isStorageEvent(object) &&
    object.eventType === StorageEventType.StorageEmptiedEvent
  );
}
