import { Email, isEmail } from '@bas/communication-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type EmailByEmailIdRequestProps = {
  emailId: Uuid;
};

export const EmailByEmailIdRequest = async ({
  emailId,
  ...params
}: EmailByEmailIdRequestProps): Promise<AxiosResponse<Email>> =>
  axios.get(`api/{tenantId}/communication/emails/${emailId}`, {
    params,
  });

export const useEmailByEmailIdRequestQuery = (
  request: EmailByEmailIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Email>,
    AxiosError,
    AxiosResponse<Email>
  > = {},
): UseQueryResult<AxiosResponse<Email>, AxiosError> =>
  useQuery<AxiosResponse<Email>, AxiosError, AxiosResponse<Email>>({
    ...options,
    queryFn: async () => EmailByEmailIdRequest(request),
    queryKey: ['emails', 'detail', request.emailId],
  });

export const EmailByEmailIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEmail(data)) {
    queryClient.invalidateQueries({
      queryKey: ['emails', 'detail', data.emailId],
    });
  }
};
