import {
  isEmployeeOccasionalAvailability,
  MovingJobSkill,
  OfficeSkill,
} from '@bas/hrm-domain/models';
import {
  EmployeeAvailableHoursOverview,
  isEvent,
} from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type EmployeeAvailableHoursOverviewByPeriodRequestProps = {
  startDate: Date;
  endDate: Date;
  hideBackofficeOnlyEmployees?: boolean;
  skills?: (OfficeSkill | MovingJobSkill)[];
};

type Response = AxiosResponse<Collection<EmployeeAvailableHoursOverview>>;
type QueryKeyType = QueryKey & {
  [0]: 'employee-available-hours-overview';
  [1]: 'list';
  [2]: string;
  [3]: string;
  [4]: boolean | undefined;
  [5]: string[] | undefined;
};

export const EmployeeAvailableHoursOverviewByPeriodRequest = async ({
  startDate,
  endDate,
  ...params
}: EmployeeAvailableHoursOverviewByPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/by-employee/${formatDate(startDate)}/${formatDate(
      endDate,
    )}`,
    {
      params: { ...params },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    },
  );

export const useEmployeeAvailableHoursOverviewByPeriodRequest = (
  request: EmployeeAvailableHoursOverviewByPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () =>
      EmployeeAvailableHoursOverviewByPeriodRequest({ ...request }),
    queryKey: [
      'employee-available-hours-overview',
      'list',
      formatDate(request.startDate),
      formatDate(request.endDate),
      request.hideBackofficeOnlyEmployees,
      request.skills,
    ],
  });

export const EmployeeAvailableHoursOverviewByPeriodRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEvent(data) || isEmployeeOccasionalAvailability(data)) {
      queryClient.invalidateQueries({
        queryKey: ['employee-available-hours-overview', 'list'],
      });
    }
  };
