import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type SchoutenZekerheidFinalizedBatchDataMutationProps = {
  integrationId: Uuid;
  dataId: Uuid;
  batchId: Uuid;
};

export const SchoutenZekerheidFinalizedBatchDataMutation = async ({
  integrationId,
  batchId,
  dataId,
  ...values
}: SchoutenZekerheidFinalizedBatchDataMutationProps): Promise<AxiosResponse> =>
  axios.put(
    `api/{tenantId}/integrations/${integrationId}/batches/${batchId}/batch-datas/${dataId}/schouten-zekerheid-finalized`,
    {
      integrationId,
      batchId,
      dataId,
      ...values,
    },
  );

export const useSchoutenZekerheidFinalizedBatchDataMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SchoutenZekerheidFinalizedBatchDataMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  SchoutenZekerheidFinalizedBatchDataMutationProps
> =>
  useMutation<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    SchoutenZekerheidFinalizedBatchDataMutationProps
  >({
    mutationFn: SchoutenZekerheidFinalizedBatchDataMutation,
    throwOnError: false,
    ...options,
  });
