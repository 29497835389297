import { EmployeeContract } from '@bas/hrm-domain/models';
import { formatHours } from '@bas/shared/utils';
import { basPaperclip } from '@bas/svg-icons';
import { colors } from '@bas/theme';
import {
  FormattedCurrency,
  Table,
  TableCell,
  TableRow,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { Box, BoxProps, styled, TableBody, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';

export type EmployeeContractBlockProps = BoxProps & {
  contract: Omit<EmployeeContract, 'contractId'>;
};

const EmployeeContractBlock = ({
  contract,
  ...args
}: EmployeeContractBlockProps): ReactElement => (
  <Box {...args}>
    <Box className="Bas-EmployeeContractBlock-Paperclip">
      <Icon icon={basPaperclip} />
    </Box>
    <Box className="Bas-EmployeeContractBlock-DateTitle">
      <Typography>
        <FormattedDate value={contract.startDate} dateStyle="short" />
      </Typography>
    </Box>
    <Box className="Bas-EmployeeContractBlock-ContractInfo">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography>
                <FormattedMessage id="label.end" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {contract.endDate ? (
                  <FormattedDate value={contract.endDate} dateStyle="short" />
                ) : (
                  <FormattedMessage id="label.indefinite" />
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>
                <FormattedMessage id="label.type" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <FormattedMessage
                  id={`contractTypes.${contract.contractType}`}
                />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>
                <FormattedMessage id="label.wage" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <FormattedCurrency {...contract.hourlyWage} />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>
                <FormattedMessage id="label.hours" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <Typography>
                  {formatHours(contract.hoursPerWeek, true)}
                </Typography>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>
                <FormattedMessage id="label.days" />
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <FormattedNumber value={contract.daysPerWeek} />
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  </Box>
);

const StyledEmployeeContractBlock = styled(EmployeeContractBlock)(
  ({ theme }) => `
  box-sizing: border-box;
  padding: ${theme.spacing(2)};
  width: 220px;
  min-height: 200px;
  height: 100%;
  border-radius: 3px;
  background: ${colors.lila[200]};
  position: relative;

  .Bas-EmployeeContractBlock-Paperclip {
    position: absolute;
    right: 18px;
    top: -12px;
    font-size: 28px;
    color: ${colors.lila[600]};
  }

  .Bas-EmployeeContractBlock-DateTitle {
    border-bottom: 1px solid ${colors.lila[400]};
    padding-bottom: 11px;
    padding-left: 5px;

    .MuiTypography-root {
      color: ${theme.palette.common.black};
      font-weight: bold;
      line-height: 21px;
    }
  }

  .Bas-EmployeeContractBlock-ContractInfo {
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(1)};
    .MuiTableRow-root {
      & > .MuiTableCell-root {
        border-bottom: none;
        &:first-of-type {
          padding-right: 20px;
        }
      }
    }
  }
`,
);
export default StyledEmployeeContractBlock;
