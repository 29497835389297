import { isMessage, Message } from '@bas/communication-domain/models';
import {
  getNextPageParamFromHydra,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import type { InfiniteData } from '@tanstack/query-core';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MessagesByConversationIdRequestProps = Pagination & {
  conversationId: Uuid;
};

export const MessagesByConversationIdRequest = async ({
  conversationId,
  ...params
}: MessagesByConversationIdRequestProps): Promise<
  AxiosResponse<Collection<Message>>
> =>
  axios.get(`api/{tenantId}/chat/conversations/${conversationId}/messages`, {
    params,
  });

export const useMessagesByConversationIdRequestQuery = (
  request: MessagesByConversationIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<Message>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<Message>>
  > = {},
): UseQueryResult<
  AxiosResponse<Collection<Message>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<Message>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<Message>>
  >({
    ...options,
    queryFn: async () => MessagesByConversationIdRequest({ ...request }),
    queryKey: ['messages', 'list', request.conversationId],
  });

export const useInfiniteMessagesByConversationIdRequestQuery = (
  request: MessagesByConversationIdRequestProps,
  options: Omit<
    UseInfiniteQueryOptions<
      AxiosResponse<Collection<Message>>,
      AxiosError<ErrorResponse>,
      InfiniteData<AxiosResponse<Collection<Message>>>
    >,
    'queryKey' | 'getNextPageParam' | 'initialPageParam'
  > = {},
): UseInfiniteQueryResult<
  InfiniteData<AxiosResponse<Collection<Message>>>,
  AxiosError<ErrorResponse>
> =>
  useInfiniteQuery<
    AxiosResponse<Collection<Message>>,
    AxiosError<ErrorResponse>,
    InfiniteData<AxiosResponse<Collection<Message>>>
  >({
    ...options,
    queryFn: async ({ pageParam }) =>
      MessagesByConversationIdRequest({
        ...request,
        perPage: request.perPage ?? 40,
        page: pageParam as number | undefined,
      }),
    initialPageParam: 1,
    getNextPageParam: getNextPageParamFromHydra,
    queryKey: ['messages', 'list', request.conversationId, 'infinite'],
  });

export const MessagesByConversationIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isMessage(data)) {
    queryClient.invalidateQueries({
      queryKey: ['messages', 'list', data.conversationId],
    });
  }
};
