import { EmployeeAttribute } from '@bas/hrm-domain/models';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type EmployeeAttributeInformationContentSectionProps = {
  employeeAttribute: EmployeeAttribute;
  onEdit: () => void;
};

const EmployeeAttributeInformationContentSection = ({
  employeeAttribute,
  onEdit,
}: EmployeeAttributeInformationContentSectionProps): ReactElement => (
  <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
    <Grid2 container columnSpacing={3}>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.details" />
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Typography>{employeeAttribute.name}</Typography>
        <Typography>{employeeAttribute.description}</Typography>
        <Typography>
          {employeeAttribute.disabledAfter ? (
            <FormattedMessage
              id="label.disabledAfterDate"
              values={{
                date: (
                  <FormattedDate
                    value={employeeAttribute.disabledAfter}
                    dateStyle="short"
                  />
                ),
              }}
            />
          ) : (
            <FormattedMessage id="label.enabled" />
          )}
        </Typography>
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default EmployeeAttributeInformationContentSection;
