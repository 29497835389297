import { useTenantStore } from '@bas/shared/state';
import { useTenantByTenantIdRequestQuery } from '@bas/tenant-domain/requests';
import { TextField, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { FeatureHelpTooltip } from '@bas/ui/web/molecules';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  Grid2,
  GridProps,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

const IncomingEmailFeatureContentSection = ({
  ...args
}: GridProps): ReactElement => {
  const tenantState = useTenantStore((state) => state.internalTenant);
  const { data: tenantData } = useTenantByTenantIdRequestQuery(
    {
      tenantId: tenantState?.tenantId || '',
    },
    { enabled: !!tenantState },
  );

  return (
    <Grid2 container columnSpacing={1} {...args}>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.incomingEmail" />
        </Typography>
      </Grid2>
      <Grid2 spacing={1} container size={12}>
        {(tenantData?.data?.incomingEmailAddresses || []).map(
          (emailAddress) => (
            <Grid2
              spacing={1}
              container
              alignItems="center"
              key={emailAddress}
              size={12}
            >
              <Grid2>
                <Typography>
                  {!emailAddress && <Skeleton variant="text" />}
                  {emailAddress && (
                    <TextField
                      id="emailAddress"
                      value={emailAddress}
                      variant="outlined"
                      sx={{ padding: 0 }}
                    />
                  )}
                </Typography>
              </Grid2>
              <Grid2>
                <Tooltip title={<FormattedMessage id="label.copy" />}>
                  <Box>
                    <Icon
                      sx={{ cursor: 'pointer' }}
                      icon={faCopy}
                      onClick={() => {
                        const copyText =
                          document.getElementById('emailAddress');
                        if (copyText instanceof HTMLInputElement) {
                          copyText.select();
                          copyText.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(copyText.value);
                        }
                      }}
                    />
                  </Box>
                </Tooltip>
              </Grid2>
              <Grid2>
                <FeatureHelpTooltip
                  onClick={() => {
                    window.open(
                      'https://help.bas.software/',
                      '_blank',
                      'noopener',
                    );
                  }}
                />
              </Grid2>
            </Grid2>
          ),
        )}
      </Grid2>
    </Grid2>
  );
};

const StyledIncomingEmailFeatureContentSection = styled(
  IncomingEmailFeatureContentSection,
)(
  () => `
  .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root {
    width: 260px;
    padding: 0;
  }
`,
);
export default StyledIncomingEmailFeatureContentSection;
