import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { isTaskPriority, TaskPriority } from '@bas/task-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TaskPrioritiesRequestProps = Pagination;

type Response = AxiosResponse<Collection<TaskPriority>>;
type QueryKeyType = QueryKey & {
  [0]: 'task-priorities';
  [1]: 'list';
};

export const TaskPrioritiesRequest = async ({
  ...params
}: TaskPrioritiesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/task-priorities`, { params });

export const useTaskPrioritiesRequest = (
  request: TaskPrioritiesRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => TaskPrioritiesRequest({ ...request }),
    queryKey: ['task-priorities', 'list'],
  });

export const TaskPrioritiesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTaskPriority(data)) {
    queryClient.invalidateQueries({ queryKey: ['task-priorities', 'list'] });
  }
};
