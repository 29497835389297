import { isQuote, Quote } from '@bas/financial-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type QuotesRequestProps = Pagination &
  Filterable & {
    projectId?: Uuid;
    relation?: Uuid;
  };
type Response = AxiosResponse<Collection<Quote>>;

export const QuotesRequest = async ({
  ...params
}: QuotesRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/quotes', {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useQuotesRequest = (
  request: QuotesRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => QuotesRequest(request),
    queryKey: ['quotes', 'list', ...Object.values(request)],
  });

export const usePrefetchQuotesRequest = (request: QuotesRequestProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['quotes', 'list', ...Object.values(request)],
      queryFn: async () => QuotesRequest(request),
    });
  }, [queryClient, request]);
};

export const QuotesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isQuote(data)) {
    queryClient.invalidateQueries({ queryKey: ['quotes', 'list'] });
  }
};
