import { UserByUserIdRequest } from '@bas/authentication-domain/requests';
import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import { EmployeeByEmployeeIdRequest } from '@bas/hrm-domain/requests';
import { useHasBackendRight, useLogout } from '@bas/shared/hooks';
import {
  useAuthStore,
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { TenantInvoiceStatus } from '@bas/tenant-domain/models';
import { useTenantInvoicesRequest } from '@bas/tenant-domain/requests';
import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { Uuid } from '@bas/value-objects';
import {
  faFileContract,
  faPortalExit,
  faSignature,
} from '@fortawesome/pro-light-svg-icons';
import { Grid2 } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import load from 'load-script';
import { useSnackbar } from 'notistack';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormattedDate,
  FormattedList,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { FullScreenPermissionErrorPage } from '../FullScreenPermissionErrorPage';

dayjs.extend(utc);

export type SecureRouteProps = {
  requiresSkill?: OfficeSkill | MovingJobSkill;
  children: ReactElement;
};

const SecureRoute = ({
  requiresSkill,
  children,
}: SecureRouteProps): ReactElement | null => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setAuth, ...authState } = useAuthStore();
  const userState = useUserStore((state) => state.user);
  const setUserState = useUserStore((state) => state.setUser);
  const tenantState = useTenantStore((state) => state.tenant);
  const internalTenant = useTenantStore((state) => state.internalTenant);
  const employeeState = useEmployeeStore((state) => state.employee);
  const setEmployeeState = useEmployeeStore((state) => state.setEmployee);

  // eslint-disable-next-line no-console
  console.log(requiresSkill, 'skill');

  const hasBackendRights = useHasBackendRight();

  useEffect(() => {
    if (
      !hasBackendRights &&
      userState &&
      employeeState &&
      tenantState &&
      internalTenant &&
      !pathname.includes('/app')
    ) {
      window.location.href = '/app';
    }
  }, [
    employeeState,
    hasBackendRights,
    navigate,
    pathname,
    internalTenant,
    tenantState,
    userState,
  ]);

  useEffect(() => {
    (async () => {
      if (
        authState.token &&
        authState.userId &&
        userState?.userId !== authState.userId
      ) {
        const { data } = await UserByUserIdRequest({
          userId: authState.userId,
        });

        setUserState(data);
      }
    })();
  }, [authState.token, authState.userId, userState, setUserState]);

  useEffect(() => {
    (async () => {
      if (
        authState.employeeId &&
        employeeState?.employeeId !== authState.employeeId
      ) {
        const { data } = await EmployeeByEmployeeIdRequest({
          employeeId: authState.employeeId,
        });

        setEmployeeState(data);
      }
    })();
  }, [authState.employeeId, employeeState, setEmployeeState]);

  useEffect(() => {
    if (!authState || !authState.token) {
      if (!authState.initialPath) {
        setAuth({ initialPath: pathname });
      }

      if (pathname !== '/login') {
        navigate('/login');
      }
    }

    if (!employeeState?.employeeId) {
      // eslint-disable-next-line no-console
      console.log('no employee');
    }
  }, [
    authState.token,
    authState.initialPath,
    pathname,
    navigate,
    setAuth,
    employeeState,
    authState,
  ]);

  const isSuperAdmin = useMemo(
    () => userState?.roles.includes('ROLE_SUPER_ADMIN'),
    [userState],
  );

  const isDisabled = useMemo(() => {
    if (isSuperAdmin) {
      return false;
    }
    return (
      internalTenant?.disableAfter !== undefined &&
      internalTenant?.disableAfter !== null &&
      dayjs(internalTenant?.disableAfter).isBefore(dayjs(), 'minute')
    );
  }, [internalTenant?.disableAfter, isSuperAdmin]);

  const { showNewMessage } = useIntercom();
  const handleRequestHelp = useCallback(
    (content?: string) => showNewMessage(content),
    [showNewMessage],
  );
  const { formatMessage, formatDate } = useIntl();

  const hasContractsToSign = useMemo(
    () =>
      (internalTenant?.contracts || []).some(
        (contract) =>
          !contract.contractSigned &&
          !contract.ignoreContract &&
          contract.contractFinalized,
      ),
    [internalTenant?.contracts],
  );

  const blockedDueToContractToSign = useMemo(
    () =>
      (internalTenant?.contracts || []).some(
        (contract) =>
          !contract.contractSigned &&
          !contract.ignoreContract &&
          contract.contractFinalized &&
          contract.contractFinalizedAt &&
          dayjs(contract.contractFinalizedAt).isBefore(
            dayjs().subtract(7, 'day'),
          ),
      ),
    [internalTenant?.contracts],
  );

  const employeesToSign = useMemo(() => {
    if (!internalTenant?.contracts) {
      return [];
    }

    return internalTenant?.contracts
      .filter(
        (contract) =>
          !contract.contractSigned &&
          !contract.ignoreContract &&
          contract.contractFinalized,
      )
      .reduce(
        (acc, contract) => {
          if (!contract.signers) {
            return acc;
          }

          const signers = contract.signers.filter(
            (signer) =>
              !acc.some((item) => item.employeeId === signer.employeeId),
          );

          return [...acc, ...signers];
        },
        [] as {
          email: string;
          embeddedSigningUrl: string | null;
          externalSignerId: string | null;
          name: string;
          passcode: string;
          signed: boolean;
          signerId: Uuid;
          employeeId: Uuid;
        }[],
      );
  }, [internalTenant?.contracts]);

  const { isFetching: isFetchingInvoices, data: invoicesData } =
    useTenantInvoicesRequest(
      {
        'invoiceStatus.value': [
          TenantInvoiceStatus.OVERDUE,
          TenantInvoiceStatus.OPEN,
        ],
      },
      {
        placeholderData: keepPreviousData,
      },
    );

  const tenantInvoices = useMemo(
    () => invoicesData?.data?.member || [],
    [invoicesData?.data],
  );

  const blockedDueToOpenInvoices = useMemo(() => {
    if (
      isFetchingInvoices ||
      isSuperAdmin ||
      internalTenant?.dontBlockWithOpenInvoices === true ||
      internalTenant?.dontBlockWithOpenInvoices === undefined
    ) {
      return false;
    }

    return (
      tenantInvoices.map((item) =>
        dayjs(item.dueAt).isBefore(dayjs().subtract(7, 'day')),
      ).length >= 3
    );
  }, [
    isFetchingInvoices,
    isSuperAdmin,
    internalTenant?.dontBlockWithOpenInvoices,
    tenantInvoices,
  ]);

  const logout = useLogout();
  const [loadedScript, setLoadedScript] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleSign = useCallback(
    (passcode: string, url: string, force?: boolean) => {
      if (!loadedScript && !force) {
        load('https://static.signwell.com/assets/embedded.js', () => {
          setLoadedScript(true);
          handleSign(passcode, url, true);
        });

        return;
      }

      enqueueSnackbar(
        formatMessage(
          { id: 'label.yourPasscodeIs' },
          {
            passcode,
          },
        ),
        {
          variant: 'info',
          persist: false,
        },
      );
      const signWellEmbed = new SignWellEmbed({
        url,
        allowDecline: false,
        allowRedirect: false,
        events: {
          completed: (e) => {
            enqueueSnackbar(
              formatMessage({
                id: 'label.contractIsSuccessfullySigned',
              }),
              {
                variant: 'success',
              },
            );
          },
        },
      });

      signWellEmbed.open();
    },
    [enqueueSnackbar, formatMessage, loadedScript],
  );

  return useMemo(() => {
    if (isDisabled) {
      return (
        <FullScreenPermissionErrorPage
          title={<FormattedMessage id="label.disabledTenant" />}
          description={
            <FormattedMessage
              id="label.disabledTenantDescription"
              values={{
                date: (
                  <FormattedDate
                    value={dayjs(internalTenant?.disableAfter).toDate()}
                    dateStyle="long"
                    timeStyle="short"
                  />
                ),
              }}
            />
          }
          requestHelp={() =>
            handleRequestHelp(
              formatMessage(
                { id: 'label.disabledTenantMessage' },
                {
                  date: formatDate(
                    dayjs(internalTenant?.disableAfter).toDate(),
                    {
                      dateStyle: 'long',
                      timeStyle: 'short',
                    },
                  ),
                  name: internalTenant?.hostname,
                },
              ),
            )
          }
        />
      );
    }

    if (blockedDueToOpenInvoices) {
      return (
        <FullScreenPermissionErrorPage
          title={<FormattedMessage id="label.blockedDueToOpenInvoices" />}
          description={
            <FormattedMessage
              id="label.blockedDueToOpenInvoicesDescription"
              values={{
                name: tenantState?.name,
              }}
            />
          }
          requestHelp={() =>
            handleRequestHelp(
              formatMessage(
                { id: 'label.blockedDueToOpenInvoicesMessage' },
                {
                  name: tenantState?.name,
                },
              ),
            )
          }
        />
      );
    }

    if (hasContractsToSign) {
      const employeeToSign = employeesToSign.find(
        (item) => item.employeeId === employeeState?.employeeId,
      );

      if (!!employeeToSign && employeeToSign.embeddedSigningUrl) {
        return (
          <FullScreenPermissionErrorPage
            customIcon={faFileContract}
            customActions={[
              <Grid2 key={1}>
                <Button
                  onClick={() => {
                    handleSign(
                      employeeToSign.passcode,
                      employeeToSign.embeddedSigningUrl || '',
                    );
                  }}
                  className="Bas-FullScreenPermissionErrorPage-Action"
                >
                  <Icon icon={faSignature} />
                  &nbsp;
                  <FormattedMessage id="button.sign" />
                </Button>
              </Grid2>,
            ]}
            title={<FormattedMessage id="label.youHaveToSignTheContract" />}
            description={
              <FormattedMessage id="label.pleaseSignTheContractBeforeYouCanContinue" />
            }
            requestHelp={() =>
              handleRequestHelp(
                formatMessage(
                  { id: 'label.youHaveToSignTheContractMessage' },
                  {
                    name: tenantState?.name,
                  },
                ),
              )
            }
          />
        );
      }

      if (blockedDueToContractToSign) {
        return (
          <FullScreenPermissionErrorPage
            title={<FormattedMessage id="label.tenantHasContractsToSign" />}
            description={
              <FormattedMessage
                id="label.tenantHasContractsToSignDescription"
                values={{
                  name: tenantState?.name,
                  employeeNames: (
                    <FormattedList
                      value={employeesToSign.map((item) => item.name)}
                    />
                  ),
                }}
              />
            }
          />
        );
      }
    }

    if (
      !authState.token ||
      (!employeeState?.employeeId &&
        !userState?.roles.includes('ROLE_SUPER_ADMIN')) ||
      (employeeState?.employmentEndDate &&
        dayjs(employeeState?.employmentEndDate).isBefore(dayjs(), 'day')) ||
      !userState?.userId
    ) {
      return (
        <FullScreenPermissionErrorPage
          title={<FormattedMessage id="label.noAccessForEmployee" />}
          description={
            <FormattedMessage
              id="label.noAccessForEmployeeDescription"
              values={{
                name: tenantState?.name,
              }}
            />
          }
          customActions={[
            <Grid2 key={1}>
              <Button
                onClick={() => {
                  logout();
                }}
                className="Bas-FullScreenPermissionErrorPage-Action"
              >
                <Icon icon={faPortalExit} />
                &nbsp;
                <FormattedMessage id="button.logout" />
              </Button>
            </Grid2>,
          ]}
        />
      );
    }

    return children;
  }, [
    isDisabled,
    blockedDueToOpenInvoices,
    hasContractsToSign,
    authState.token,
    employeeState?.employeeId,
    employeeState?.employmentEndDate,
    userState?.roles,
    userState?.userId,
    children,
    internalTenant?.disableAfter,
    internalTenant?.hostname,
    handleRequestHelp,
    formatMessage,
    formatDate,
    tenantState?.name,
    employeesToSign,
    blockedDueToContractToSign,
    handleSign,
    logout,
  ]);
};

export default SecureRoute;
