import {
  ExactGlobeSettings,
  isExactGlobeSettings,
} from '@bas/integration-domain/models';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountingIntegrationSettingsBlock } from '../AccountingIntegrationSettingsBlock';

export type ExactGlobeSettingsContentSectionProps = {
  providerSettings: ExactGlobeSettings;
  onEdit: () => void;
};

const ExactGlobeSettingsContentSection = ({
  providerSettings,
  onEdit,
}: ExactGlobeSettingsContentSectionProps): ReactElement => (
  <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
    <Grid2 container columnSpacing={3}>
      <Grid2 size={12}>
        <Typography variant="subtitle1">
          <FormattedMessage id="label.settings" />
        </Typography>
      </Grid2>
      <Grid2 size={6}>
        <Typography>
          <FormattedMessage id="label.salesJournal" />
        </Typography>
      </Grid2>
      <Grid2 size={6}>
        <Typography>
          {isExactGlobeSettings(providerSettings) &&
            providerSettings.salesJournalCode}
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        {isExactGlobeSettings(providerSettings) && (
          <AccountingIntegrationSettingsBlock
            providerSettings={providerSettings}
            externalGeneralLedgers={[]}
            externalCostCenters={[]}
            externalVatCodes={[]}
          />
        )}
      </Grid2>
    </Grid2>
  </ContentSection>
);

export default ExactGlobeSettingsContentSection;
