import { colors } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import {
  faCheckSquare,
  faSpinnerThird,
  faSquare,
} from '@fortawesome/pro-light-svg-icons';
import { faCheckSquare as faSolidCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormGroup,
  FormHelperText,
  FormHelperTextProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { FormControlLabel } from '../FormControlLabel';

export type CheckboxProps = MuiCheckboxProps & {
  label?: string | number | ReactElement;
  parentHover?: boolean;
  error?: boolean;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: ReactNode | string;
  light?: boolean;
  lightIcon?: boolean;
  enableLoading?: boolean;
  removePadding?: boolean;
};

export type CustomizedCheckboxComponentProps = MuiCheckboxProps & {
  light?: boolean;
  removePadding?: boolean;
};

const CustomizedCheckboxComponent =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ light, removePadding, ...props }: CustomizedCheckboxComponentProps) => (
    <MuiCheckbox {...props} />
  );

const CustomizedCheckbox = styled(CustomizedCheckboxComponent)(
  ({ theme, light, removePadding }) => `
  color: ${light ? theme.palette.common.white : colors.lila[800]};
  ${removePadding ? 'padding: 0;' : ''}

  .Bas-Checkbox-Icon {
    font-size: 24px;
  }

  .Bas-Checkbox-Icon.fa-stack {
    font-size: 18px;
  }

  &.Bas-Checkbox-Small {
    padding-bottom: 2px;
    .Bas-Checkbox-Icon {
      font-size: 14px;
    }

    .Bas-Checkbox-Icon.fa-stack {
      font-size: 10px;
    }
  }

  .Bas-Checkbox-HoverIcon {
    display: none;
  }

  .Bas-Checkbox-NormalIcon {
    display: block;
  }

  &:hover, &.Bas-Checkbox-Hover {
    @media (hover: hover) {
      .Bas-Checkbox-HoverIcon {
        display: block;
      }

      .Bas-Checkbox-NormalIcon {
        display: none;
      }
    }

    color: ${light ? theme.palette.common.white : colors.lila[800]};
  }

  &.Mui-checked {
    color: ${light ? theme.palette.common.white : '#1F1F1F;'};
  }

  &.Mui-disabled {
    color: ${light ? colors.lila['700'] : colors.lila['400']};
  }

`,
);

const Checkbox = ({
  label,
  disabled,
  enableLoading,
  parentHover,
  error,
  FormHelperTextProps: helperProps,
  helperText,
  light = false,
  disableRipple = true,
  lightIcon,
  onChange,
  checked,
  size,
  ...props
}: CheckboxProps): ReactElement => {
  const [hover, setHover] = useState<boolean>(parentHover || false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingValue, setLoadingValue] = useState<boolean | undefined>();

  useEffect(() => {
    if (checked === loadingValue) {
      setLoadingValue(undefined);
    }
  }, [checked, loadingValue]);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  let checkedIcon = (
    <Icon icon={faSolidCheckSquare} className="Bas-Checkbox-Icon" />
  );

  if (lightIcon) {
    checkedIcon = <Icon icon={faCheckSquare} className="Bas-Checkbox-Icon" />;
  }

  let icon = (
    <span>
      <Icon
        icon={faCheckSquare}
        className="Bas-Checkbox-Icon Bas-Checkbox-HoverIcon"
      />
      <Icon
        icon={faSquare}
        className="Bas-Checkbox-Icon Bas-Checkbox-NormalIcon"
      />
    </span>
  );

  if (lightIcon) {
    icon = <Icon icon={faSquare} className="Bas-Checkbox-Icon " />;
  }

  if (disabled) {
    icon = (
      <Icon
        icon={faSquare}
        className="Bas-Checkbox-Icon Bas-Checkbox-NormalIcon"
      />
    );
  }

  if (
    enableLoading &&
    (loading || (loadingValue !== undefined && loadingValue !== checked))
  ) {
    icon = (
      <span className="Bas-Checkbox-Icon fa-stack">
        <Icon icon={faSquare} className="fa-stack-2x" />
        <Icon icon={faSpinnerThird} className="fa-stack-1x" spin />
      </span>
    );
    checkedIcon = icon;
  }

  const checkboxContent = (
    <CustomizedCheckbox
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disabled={disabled}
      light={light}
      checked={checked}
      icon={icon}
      size={size}
      checkedIcon={checkedIcon}
      className={clsx(
        { 'Bas-Checkbox-Hover': hover },
        { 'Bas-Checkbox-Small': size === 'small' },
      )}
      onChange={async (e, newValue) => {
        if (onChange && !loading && loadingValue === undefined) {
          if (enableLoading) {
            setLoading(true);
            setLoadingValue(newValue);
          }
          await onChange(e, newValue);
          if (enableLoading) {
            setLoading(false);
          }
        }
      }}
      disableRipple={disableRipple}
      {...props}
    />
  );

  if (!label) {
    return checkboxContent;
  }

  return (
    <FormGroup>
      <FormControlLabel
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        label={label}
        disabled={disabled}
        control={checkboxContent}
        light={light}
      />
      {helperText && (
        <FormHelperText {...helperProps} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormGroup>
  );
};

export default Checkbox;
