import { useCallback, useMemo } from 'react';
import { colors } from '@bas/theme';
import { useIntl } from 'react-intl';
import {
  GroupedHours,
  TimeOffStatistics,
  timeOffTypeGroupsToCalculate,
} from '@bas/hrm-domain/models';

export const useGetStackValuesForTimeOff = (
  statistics: TimeOffStatistics | undefined,
  hoursToApprove?: number,
  requestingHours = 0,
) => {
  const { formatNumber } = useIntl();
  const calculateValue = useCallback(
    (groupedHours: GroupedHours[], key: keyof GroupedHours) =>
      groupedHours.reduce((total, item) => {
        if (timeOffTypeGroupsToCalculate.includes(item.group)) {
          return total + (item[key] as number);
        }

        return total;
      }, 0),
    [],
  );

  const totalHoursBuildUp = useMemo(() => {
    if (!statistics) {
      return 0;
    }

    return calculateValue(statistics.groupedHours, 'buildUpHours');
  }, [calculateValue, statistics]);

  const totalUsedHours = useMemo(() => {
    if (!statistics) {
      return 0;
    }

    return calculateValue(statistics.groupedHours, 'usedHours');
  }, [calculateValue, statistics]);

  const totalReservedHours = useMemo(() => {
    if (!statistics) {
      return 0;
    }

    return (
      calculateValue(statistics.groupedHours, 'reservedHours') + requestingHours
    );
  }, [calculateValue, statistics, requestingHours]);

  return useMemo(() => {
    const usedHoursPercentage = (totalUsedHours / totalHoursBuildUp) * 100;
    const reservedHoursPercentage =
      (totalReservedHours / totalHoursBuildUp) * 100;

    const result = [];
    if (usedHoursPercentage) {
      result.push({
        value: usedHoursPercentage,
        color: colors.green[300],
        showValue: true,
        label: formatNumber(totalUsedHours, {
          style: 'unit',
          unit: 'hour',
        }),
      });
    }

    if (reservedHoursPercentage) {
      result.push({
        value: reservedHoursPercentage,
        color: colors.blue[200],
        showValue: true,
        label: formatNumber(totalReservedHours, {
          style: 'unit',
          unit: 'hour',
        }),
      });
    }

    if (typeof hoursToApprove === 'number' && hoursToApprove > 0) {
      const hoursToApprovePercentage =
        (hoursToApprove / totalHoursBuildUp) * 100;
      if (hoursToApprovePercentage) {
        result.push({
          value: hoursToApprovePercentage,
          color: colors.orange[300],
          showValue: true,
          label: formatNumber(hoursToApprove, {
            style: 'unit',
            unit: 'hour',
          }),
        });
      }
    }

    return result;
  }, [
    totalUsedHours,
    totalHoursBuildUp,
    totalReservedHours,
    hoursToApprove,
    formatNumber,
  ]);
};
