import { colors } from '@bas/theme';
import {
  MaterialBoxCategory,
  MaterialBoxCategoryProps,
} from '@bas/ui/web/molecules';
import { Box, BoxProps, Grid2 } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

export type MaterialBoxProps = BoxProps & {
  categories: MaterialBoxCategoryProps[];
  disableLastOfRowCheck?: boolean;
  scrollInsteadOfWrap?: boolean;
};

const MaterialBoxes = ({
  categories,
  disableLastOfRowCheck,
  children,
  scrollInsteadOfWrap,
  className,
  ...props
}: MaterialBoxProps) => (
  <Box
    className={clsx(className, {
      'Bas-MaterialBoxes-Scroll': scrollInsteadOfWrap,
    })}
    {...props}
  >
    <Box>
      <Grid2
        container
        spacing={2}
        className="Bas-MaterialBoxes-Categories-Holder"
        justifyContent={scrollInsteadOfWrap ? 'flex-start' : 'center'}
        flexWrap={scrollInsteadOfWrap ? 'nowrap' : undefined}
      >
        {categories.map((category, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid2 key={index} className="Bas-MaterialBoxes-Category">
            <MaterialBoxCategory {...category} />
          </Grid2>
        ))}
        {children && (
          <Grid2 className="Bas-MaterialBoxes-Category">{children}</Grid2>
        )}
      </Grid2>
    </Box>
  </Box>
);

const StyledMaterialBox = styled(MaterialBoxes)(
  ({ theme }) => `
  &.Bas-MaterialBoxes-Scroll {
    white-space: nowrap;
    & .MuiGrid2-container {
      flex-wrap: nowrap;
    }

    .Bas-MaterialBoxes-Category:first-of-type {
      margin-left: auto;
    }

    .Bas-MaterialBoxes-Category:last-of-type {
      margin-right: auto;
    }
  }

  .Bas-MaterialBoxes-Category:not(:last-of-type) > .MuiBox-root {
    border-right: 1px solid ${colors.lila[300]};
    padding-right: ${theme.spacing(2)};
    padding-left: ${theme.spacing(2)};
  }
`,
);
export default StyledMaterialBox;
