export enum EventType {
  UNKNOWN = '',
  DAY_OFF_EVENT = 'hrm-day-off-event',
  HRM_OTHER_ABSENCE_EVENT = 'hrm-other-absence-event',
  SICK_EVENT = 'hrm-sick-event',
  VACATION_EVENT = 'hrm-vacation-event',
  INSPECTION_EVENT = 'material-event-inspection-event',
  MAINTENANCE_EVENT = 'material-event-maintenance-event',
  MATERIAL_OTHER_ABSENCE_EVENT = 'material-event-other-absence-event',
  ASSEMBLE_EVENT = 'moving-job-assemble',
  DELIVER_BOXES_EVENT = 'moving-job-deliver-boxes',
  DISASSEMBLE_EVENT = 'moving-job-disassemble',
  DISPOSING_OF_WASTE_EVENT = 'moving-job-disposing-of-waste',
  LOAD_STORAGE_EVENT = 'moving-job-load-storage',
  MOVING_EVENT = 'moving-job-moving',
  MOVING_LOADING_EVENT = 'moving-job-moving-loading',
  MOVING_UNLOADING_EVENT = 'moving-job-moving-unloading',
  MOVING_LIFT_EVENT = 'moving-job-moving-lift',
  PACKING_EVENT = 'moving-job-packing',
  PICKUP_BOXES_EVENT = 'moving-job-pickup-boxes',
  POST_ROAD_SIGNS_EVENT = 'moving-job-post-road-signs',
  PREPARE_HOUSE_ACCORDING_TO_QUOTE_EVENT = 'moving-job-prepare-house-according-to-quote',
  REMOVE_ROAD_SIGNS_EVENT = 'moving-job-remove-road-signs',
  UNLOAD_STORAGE_EVENT = 'moving-job-unload-storage',
  UNPACKING_EVENT = 'moving-job-unpacking',
  INTAKE_EVENT = 'project-intake',
  CUSTOM_EVENT = 'custom',
  LENDING_STAFF_EVENT = 'lending-staff',
  LIFT_WORK_EVENT = 'lift-work',
  OFFICE_WORK_EVENT = 'office-work',
  PROJECT_EVENT = 'project',
  TRANSPORT_EVENT = 'transport',
  TRANSPORT_JOB_PICKUP = 'transport-job-pickup',
  TRANSPORT_JOB_DELIVER = 'transport-job-deliver',
  DRIVING_DAY = 'project-driving-day',
  WAREHOUSE_WORK_EVENT = 'warehouse-work',
}
