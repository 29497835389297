import { OfficeSkill } from '@bas/hrm-domain/models';
import {
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { useMemo } from 'react';

export const useHasBackendRight = () => {
  const tenantId = useTenantStore((state) => state.tenant?.tenantId);
  const roles = useUserStore((state) => state.user?.roles);
  const employeeSkills = useEmployeeStore(
    (state) => state.employee?.employeeSkills,
  );

  return useMemo(() => {
    if (!tenantId) {
      return false;
    }

    if (
      employeeSkills &&
      !!employeeSkills.find((skill) =>
        [
          OfficeSkill.PLANNER,
          OfficeSkill.ADMINISTRATIVE_ASSISTANT,
          OfficeSkill.WAREHOUSE,
        ].includes(skill as OfficeSkill),
      )
    ) {
      return true;
    }

    return (roles || []).includes('ROLE_SUPER_ADMIN');
  }, [employeeSkills, roles, tenantId]);
};
