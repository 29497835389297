import { isTurnoverGroup, TurnoverGroup } from '@bas/financial-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type TurnoverGroupsRequestProps = Pagination & Filterable;
type Response = AxiosResponse<Collection<TurnoverGroup>>;

export const TurnoverGroupsRequest = async ({
  ...params
}: TurnoverGroupsRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/turnover-groups', {
    params: { ...params },
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useTurnoverGroupsRequest = (
  request: TurnoverGroupsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TurnoverGroupsRequest(request),
    queryKey: ['turnover-groups', 'list', ...Object.values(request)],
  });

export const usePrefetchTurnoverGroupsRequest = (
  request: TurnoverGroupsRequestProps,
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['turnover-groups', 'list', ...Object.values(request)],
      queryFn: async () => TurnoverGroupsRequest(request),
    });
  }, [queryClient, request]);
};

export const TurnoverGroupsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTurnoverGroup(data)) {
    queryClient.invalidateQueries({ queryKey: ['turnover-groups', 'list'] });
  }
};
