import { isObjectResponse } from '@bas/value-objects';
import { KingFinanceSettings } from '../../types';

export function isKingFinanceSettings(
  object: unknown,
): object is KingFinanceSettings {
  return (
    !!object && isObjectResponse(object) && object['@type'] === 'IMuisSettings'
  );
}
