import { ClaWorkedHours } from '@bas/hrm-domain/models';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Box, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { FormattedDateTimeRange, FormattedMessage } from 'react-intl';

const ClaHoursBox = ({
  workedHour,
  className,
}: {
  workedHour: ClaWorkedHours;
  className?: string;
}): ReactElement => (
  <Box className={className}>
    <Typography>
      <FormattedDateTimeRange
        from={dayjs(`${workedHour.date} ${workedHour.startTime}`).toDate()}
        to={dayjs(`${workedHour.date} ${workedHour.endTime}`).toDate()}
        timeStyle="short"
      />
    </Typography>
    <Typography variant="body2">
      <FormattedMessage id={`claHourTypes.${workedHour.hourType}`} />
    </Typography>
    <Typography variant="body2">
      {formatHours(workedHour.durationHours)}
    </Typography>
  </Box>
);

const StyledClaHoursBox = styled(ClaHoursBox)`
  padding: ${({ theme }) => theme.spacing(2)};
  min-width: 120px;
  box-sizing: border-box;
  border-radius: 4px;
  text-wrap: nowrap;
  background-color: #eaf8ea;
  color: ${colors.green[800]};
`;

export default StyledClaHoursBox;
