import { WebsiteSettingsInputType } from '@bas/integration-domain/input-types';
import {
  ContentSection,
  ContentSectionCancelAction,
  ContentSectionSaveAction,
} from '@bas/ui/web/molecules';
import { Grid2, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ConnectedWebsiteForm } from '../ConnectedWebsiteForm';

export type EditingWebsiteSettingsContentSectionProps = {
  onCancel: () => void;
};

const EditingWebsiteSettingsContentSection = ({
  onCancel,
}: EditingWebsiteSettingsContentSectionProps): ReactElement => {
  const { fields: websites } = useFieldArray<
    WebsiteSettingsInputType,
    'websites'
  >({
    name: 'websites',
  });

  return (
    <ContentSection
      editing
      actions={[
        <ContentSectionCancelAction onClick={onCancel} />,
        <ContentSectionSaveAction />,
      ]}
    >
      <Grid2 container spacing={3} alignItems="flex-start">
        <Grid2 pt={4} size={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          {websites.map((v, index) => (
            <ConnectedWebsiteForm prefix={`websites.${index}`} />
          ))}
        </Grid2>
      </Grid2>
    </ContentSection>
  );
};

export default EditingWebsiteSettingsContentSection;
