import { ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type RefreshTokenMutationProps = {
  refresh_token: string;
};

export type RefreshTokenMutationResponse = {
  employeeId: Uuid;
  userId: Uuid;
  refresh_token: string;
  refresh_token_expiration: number;
  intercomHash: string;
  mercureToken: string;
  token: string;
  twoFactorNeeded: boolean;
  expiresAt?: Date;
  sessionId: Uuid;
};

export const RefreshTokenMutation = async ({
  // eslint-disable-next-line camelcase
  refresh_token,
  ...params
}: RefreshTokenMutationProps): Promise<
  AxiosResponse<RefreshTokenMutationResponse>
  // eslint-disable-next-line camelcase
> => axios.post('api/auth/refresh', { refresh_token }, { params });

export const useRefreshTokenMutation = (
  options: UseMutationOptions<
    AxiosResponse,
    AxiosError<ErrorResponse>,
    RefreshTokenMutationProps
  > = {},
): UseMutationResult<
  AxiosResponse<RefreshTokenMutationResponse>,
  AxiosError<ErrorResponse>,
  RefreshTokenMutationProps
> =>
  useMutation<
    AxiosResponse<RefreshTokenMutationResponse>,
    AxiosError<ErrorResponse>,
    RefreshTokenMutationProps
  >({
    mutationFn: RefreshTokenMutation,
    throwOnError: false,
    ...options,
  });
