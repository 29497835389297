import {
  EmployeeAttributeInputType,
  employeeAttributeInputTypeDefaultValues,
  employeeAttributeInputTypeValidationBuilder,
} from '@bas/hrm-domain/input-types';
import { EmployeeAttribute } from '@bas/hrm-domain/models';
import { EmployeeAttributeInformationContentSection } from '@bas/settings-domain/web/molecules';
import { ReactHookForm } from '@bas/ui/web/molecules';
import { ReactElement, useState } from 'react';
import { EditingEmployeeAttributeInformationContentSection } from '../EditingEmployeeAttributeInformationContentSection';

export type EditableEmployeeAttributeDetailsBlockProps = {
  employeeAttribute: EmployeeAttribute;
  onChangeDetails: (values: EmployeeAttributeInputType) => Promise<boolean>;
};

const EditableEmployeeAttributeDetailsBlock = ({
  employeeAttribute,
  onChangeDetails,
}: EditableEmployeeAttributeDetailsBlockProps): ReactElement => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (isEditing) {
    return (
      <ReactHookForm<EmployeeAttributeInputType>
        name="change-employee-attribute"
        onSubmit={async (values) => {
          const result = await onChangeDetails(values);

          if (result) {
            setIsEditing(false);
          }
        }}
        useProvider
        defaultValues={{
          ...employeeAttributeInputTypeDefaultValues(),
          ...employeeAttribute,
        }}
        validationSchema={employeeAttributeInputTypeValidationBuilder}
      >
        <EditingEmployeeAttributeInformationContentSection
          onCancel={() => setIsEditing(false)}
        />
      </ReactHookForm>
    );
  }

  return (
    <EmployeeAttributeInformationContentSection
      employeeAttribute={employeeAttribute}
      onEdit={() => setIsEditing(true)}
    />
  );
};

export default EditableEmployeeAttributeDetailsBlock;
