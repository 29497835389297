import { Collection } from '@bas/value-objects';
import { AxiosResponse } from 'axios';
import { parse } from 'url';

export const getNextPageParamFromHydra: <TQueryFnData>(
  lastPage: AxiosResponse<Collection<TQueryFnData>>,
  pages: AxiosResponse<Collection<TQueryFnData>>[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any = (lastPage): any => {
  if (!lastPage.data.view || typeof lastPage.data.view.next === 'undefined') {
    return undefined;
  }

  try {
    const parsed = parse(lastPage.data.view.next, true);
    return parsed.query.page;
  } catch (e) {
    return undefined;
  }
};
