import {
  Filterable,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import {
  isTenantClosureDate,
  TenantClosureDate,
} from '@bas/tenant-domain/models';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

type Response = AxiosResponse<Collection<TenantClosureDate>>;
type QueryKeyType = QueryKey & {
  [0]: 'tenant-closure-dates';
  [1]: 'list';
};

export type TenantClosureDatesRequestProps = Filterable;

export const TenantClosureDatesRequest = async ({
  ...params
}: TenantClosureDatesRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/planning/tenant-closure-dates`, {
    params: {
      ...params,
    },
  });

export const useTenantClosureDatesRequest = (
  request: TenantClosureDatesRequestProps = {},
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => TenantClosureDatesRequest({ ...request }),
    queryKey: ['tenant-closure-dates', 'list'],
  });

export const TenantClosureDatesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isTenantClosureDate(data) || data['@type'] === 'remove_closure_date') {
    queryClient.invalidateQueries({
      queryKey: ['tenant-closure-dates', 'list'],
    });
  }
};
