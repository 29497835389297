import {
  ExactOnlineSettings,
  isExactOnlineSettings,
} from '@bas/integration-domain/models';
import { useExactOnlineMappingOptions } from '@bas/integration-domain/web/hooks';
import { Alert } from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid2, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccountingIntegrationSettingsBlock } from '../AccountingIntegrationSettingsBlock';

export type ExactOnlineSettingsContentSectionProps = {
  integrationId: Uuid;
  providerSettings: ExactOnlineSettings;
  onEdit: () => void;
};

const ExactOnlineSettingsContentSection = ({
  integrationId,
  providerSettings,
  onEdit,
}: ExactOnlineSettingsContentSectionProps): ReactElement => {
  const {
    divisionsData,
    divisionsError,
    generalLedgersError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsExternalOptions,
    journalsError,
  } = useExactOnlineMappingOptions(
    integrationId,
    providerSettings.defaultDivisionCode,
  );

  const division = useMemo(() => {
    if (!isExactOnlineSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.defaultDivisionCode) {
      return undefined;
    }

    return (divisionsData?.data?.member || []).find(
      ({ code }) => code === providerSettings?.defaultDivisionCode,
    );
  }, [providerSettings, divisionsData?.data]);

  const salesJournal = useMemo(() => {
    if (!isExactOnlineSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.salesJournalCode) {
      return undefined;
    }

    return journalsExternalOptions.find(
      ({ externalId }) => externalId === providerSettings?.salesJournalCode,
    );
  }, [providerSettings, journalsExternalOptions]);

  const divisions = useMemo(
    () => (divisionsData?.data.member || []).filter(({ code }) => code !== ''),
    [divisionsData],
  );
  const { formatMessage } = useIntl();

  const administrationExternalOptions = useMemo(
    () => [
      {
        externalId: 'ignore',
        label: formatMessage({ id: 'label.ignoreIdentity' }),
      },
      ...divisions.map(({ code, customerName }) => ({
        externalId: code.toString(),
        label: customerName,
      })),
    ],
    [divisions, formatMessage],
  );

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid2 container columnSpacing={3}>
        <Grid2 size={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid2>
        {(divisionsError ||
          generalLedgersError ||
          departmentsError ||
          vatCodesError ||
          journalsError) && (
          <Grid2 mt={2} size={12}>
            <Alert severity="error">
              <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
            </Alert>
          </Grid2>
        )}
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.defaultAdministration" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {isExactOnlineSettings(providerSettings) && division ? (
              division.customerName
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.salesJournal" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {isExactOnlineSettings(providerSettings) && salesJournal ? (
              salesJournal.label
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            <FormattedMessage id="label.useInvoiceNumberAsTransactionNumber" />
          </Typography>
        </Grid2>
        <Grid2 size={6}>
          <Typography>
            {isExactOnlineSettings(providerSettings) && (
              <FormattedMessage
                id={`label.${
                  providerSettings.sendEntryNumbers ? 'enabled' : 'disabled'
                }`}
              />
            )}
            {!isExactOnlineSettings(providerSettings) && (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          {isExactOnlineSettings(providerSettings) && (
            <AccountingIntegrationSettingsBlock
              providerSettings={providerSettings}
              externalGeneralLedgers={generalLedgerExternalOptions}
              externalCostCenters={costCenterExternalOptions}
              externalVatCodes={vatExternalOptions}
              externalAdministrations={administrationExternalOptions}
            />
          )}
        </Grid2>
      </Grid2>
    </ContentSection>
  );
};

export default ExactOnlineSettingsContentSection;
