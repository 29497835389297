import { Employee, MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

type EmployeeStoreType = {
  employee?: Employee;
  setEmployee: (employee: Employee) => void;
  logout: () => void;
  canAskHelp: () => boolean;
  isWarehouseEmployee: () => boolean;
};

export const useEmployeeStore = create<EmployeeStoreType>()(
  persist(
    (set, get) => ({
      employee: undefined,
      setEmployee: (employee: Employee) => set({ employee }),
      logout: () => set({ employee: undefined }),
      canAskHelp: () => {
        const employeeSkills = get().employee?.employeeSkills || [];

        return (
          employeeSkills?.includes(OfficeSkill.ADMINISTRATIVE_ASSISTANT) ||
          employeeSkills?.includes(OfficeSkill.PLANNER) ||
          employeeSkills?.includes(MovingJobSkill.APPRAISER)
        );
      },
      isWarehouseEmployee: () => {
        const employeeSkills = get().employee?.employeeSkills || [];

        return (
          employeeSkills?.includes(OfficeSkill.ADMINISTRATIVE_ASSISTANT) ||
          employeeSkills?.includes(OfficeSkill.PLANNER) ||
          employeeSkills?.includes(OfficeSkill.WAREHOUSE)
        );
      },
    }),
    {
      name: 'employee-store',
      storage: zustandStorage(),
    },
  ),
);
