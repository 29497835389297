import { Package } from '@bas/shared/models';
import { Icon } from '@bas/ui/web/base';
import { IdentityIndicator } from '@bas/ui/web/molecules';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Box, BoxProps, IconButton, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';

export type PackageDetailsPageTemplateProps = BoxProps & {
  packageItem: Package;
  className?: string;
  onClose?: () => void;
};

const PackageDetailsPageTemplate = ({
  packageItem,
  children,
  onClose,
  ...args
}: PackageDetailsPageTemplateProps): ReactElement => (
  <Box {...args}>
    {onClose && (
      <IconButton
        className="Bas-PackageDetailsPageTemplate-Close"
        onClick={onClose}
      >
        <Icon icon={faTimes} />
      </IconButton>
    )}
    <Box className="Bas-PackageDetailsPageTemplate-Header">
      <Typography color="textPrimary" variant="h3">
        <IdentityIndicator identityId={packageItem.identityId} size="medium">
          {packageItem.packageName}
        </IdentityIndicator>
      </Typography>
    </Box>
    <Box className="Bas-PackageDetailsPageTemplate-Content">{children}</Box>
  </Box>
);

const StyledPackageDetailsPageTemplate = styled(PackageDetailsPageTemplate)(
  ({ theme }) => `
  position: relative;
  
  .Bas-PackageDetailsPageTemplate-Close {
    position: absolute;
    font-size: 24px;
    padding: 0;
    z-index: 15;
    top: -${theme.spacing(3)};
    left: -${theme.spacing(3)};
    ${theme.breakpoints.down('lg')} {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
    @media (max-width: 1024px) {
      top: -${theme.spacing(3)};
      left: -${theme.spacing(2)};
    }
  }

  .Bas-PackageDetailsPageTemplate-Header {
    padding-bottom: ${theme.spacing(2)};
  }
`,
);

export default StyledPackageDetailsPageTemplate;
