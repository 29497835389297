import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { isMovingBox, MovingBox } from '@bas/wms-domain/models';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MovingBoxByMovingBoxIdRequestProps = {
  inventoryItemId: Uuid;
};

export const MovingBoxByMovingBoxIdRequest = async ({
  inventoryItemId,
  ...params
}: MovingBoxByMovingBoxIdRequestProps): Promise<AxiosResponse<MovingBox>> =>
  axios.get(`api/{tenantId}/inventory/moving-boxes/${inventoryItemId}`, {
    params,
  });

export const useMovingBoxByMovingBoxIdRequestQuery = (
  request: MovingBoxByMovingBoxIdRequestProps,
  options: QueryOptionsWithKey<
    AxiosResponse<MovingBox>,
    AxiosError,
    AxiosResponse<MovingBox>
  > = {},
): UseQueryResult<AxiosResponse<MovingBox>, AxiosError> =>
  useQuery<AxiosResponse<MovingBox>, AxiosError, AxiosResponse<MovingBox>>({
    ...options,
    queryFn: async () => MovingBoxByMovingBoxIdRequest(request),
    queryKey: ['moving-boxes', 'detail', request.inventoryItemId],
  });

export const MovingBoxByMovingBoxIdRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isMovingBox(data)) {
    queryClient.invalidateQueries({
      queryKey: ['moving-boxes', 'detail', data.inventoryItemId],
    });
  }
};
