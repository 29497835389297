import {
  isTimeOffRegistration,
  TimeOffRegistration,
} from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TimeOffRegistrationByTimeOffIdRequestQueryProps = {
  timeOffId: Uuid;
};

export const TimeOffRegistrationByTimeOffIdRequestQuery = async ({
  timeOffId,
  ...params
}: TimeOffRegistrationByTimeOffIdRequestQueryProps): Promise<
  AxiosResponse<TimeOffRegistration>
> =>
  axios.get(`api/{tenantId}/hrm/time-off-registrations/${timeOffId}`, {
    params,
  });

export const useTimeOffRegistrationByTimeOffIdRequestQuery = (
  request: TimeOffRegistrationByTimeOffIdRequestQueryProps,
  options: QueryOptionsWithKey<
    AxiosResponse<TimeOffRegistration>,
    AxiosError,
    AxiosResponse<TimeOffRegistration>
  > = {},
): UseQueryResult<AxiosResponse<TimeOffRegistration>, AxiosError> =>
  useQuery<
    AxiosResponse<TimeOffRegistration>,
    AxiosError,
    AxiosResponse<TimeOffRegistration>
  >({
    ...options,
    queryFn: async () => TimeOffRegistrationByTimeOffIdRequestQuery(request),
    queryKey: ['time-off-registrations', 'detail', request.timeOffId],
  });

export const TimeOffRegistrationByTimeOffIdRequestQueryInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isTimeOffRegistration(data)) {
      queryClient.invalidateQueries({
        queryKey: ['time-off-registrations', 'detail', data.timeOffId],
      });
    }
  };
