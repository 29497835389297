/* eslint-disable react/no-array-index-key */
import { EmailEvent, isEmailEvent } from '@bas/communication-domain/models';
import {
  InvoiceEvent,
  isInvoiceEvent,
  isQuoteEvent,
  QuoteEvent,
} from '@bas/financial-domain/models';
import { colors } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import {
  EventsTimelineEmailEventItem,
  EventsTimelineInvoiceEventItem,
  EventsTimelineQuoteEventItem,
  EventsTimelineStorageEventItem,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { isStorageEvent, StorageEvent } from '@bas/wms-domain/models';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Box, styled, Typography, useMediaQuery } from '@mui/material';
import { Fragment, ReactElement } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type EventsTimelineProps = {
  className?: string;
  onShowEmail?: (emailId: Uuid) => void;
  events: ((
    | (EmailEvent & {
        emailId: Uuid;
      })
    | (QuoteEvent & { quoteId: Uuid })
    | (InvoiceEvent & { invoiceId: Uuid })
    | (StorageEvent & { storageId: Uuid })
    | { description: string }
  ) & { eventDate: Date; title: string })[];
};

const EventsTimeline = ({
  events,
  className,
  onShowEmail,
}: EventsTimelineProps): ReactElement => {
  const alternatePositions = useMediaQuery('@media (min-width:1260px)');

  return (
    <Box className={className}>
      {events.length === 0 && (
        <Typography>
          <FormattedMessage id="label.noTimelineEvents" />
        </Typography>
      )}
      <Timeline position={alternatePositions ? 'alternate' : undefined}>
        {events.map((event, index) => {
          if (isEmailEvent(event)) {
            return (
              <EventsTimelineEmailEventItem
                isLast={index + 1 !== events.length}
                event={event}
                onShowEmail={onShowEmail}
                key={index}
              />
            );
          }

          if (isQuoteEvent(event)) {
            return (
              <EventsTimelineQuoteEventItem
                isLast={index + 1 !== events.length}
                event={event}
                key={index}
              />
            );
          }

          if (isInvoiceEvent(event)) {
            return (
              <EventsTimelineInvoiceEventItem
                isLast={index + 1 !== events.length}
                event={event}
                key={index}
              />
            );
          }

          if (isStorageEvent(event)) {
            return (
              <EventsTimelineStorageEventItem
                isLast={index + 1 !== events.length}
                event={event}
                key={index}
              />
            );
          }

          if (event.description) {
            return (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <Icon icon={faPlus} />
                  </TimelineDot>
                  {index + 1 !== events.length && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant="subtitle1">
                    {event.description}
                  </Typography>
                  <Typography variant="subtitle2">
                    <FormattedDate
                      value={event.eventDate}
                      dateStyle="medium"
                      timeStyle="short"
                    />
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            );
          }

          return <Fragment key={index} />;
        })}
      </Timeline>
    </Box>
  );
};

const StyledEventsTimeline = styled(EventsTimeline)(
  ({ theme }) => `
  .MuiTimeline-root {
    padding: 0;
    margin-top: 0;
  }

  .MuiTimelineItem-root {
    &:not(.MuiTimelineItem-positionAlternate) {
      &:not(:last-of-type) {
        > .MuiTypography-root {
          margin-bottom: ${theme.spacing(3)};
        }
      }

      &:before {
        display: none;
      }
    }

    .MuiTimelineDot-root {
      box-shadow: none;
      height: 44px;
      width: 44px;
      font-size: 24px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 0;
    }

    .MuiTimelineConnector-root {
      width: 1px;
      background-color: ${colors.lila[400]};
    }

    &:not(:last-of-type) {
      .MuiTimelineConnector-root {
        margin-bottom: -11.5px;
      }
    }
  }
`,
);
export default StyledEventsTimeline;
