import { Event, isEvent } from '@bas/planning-domain/models';
import {
  Filterable,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type EventsByEventIdsRequestProps = Filterable & {
  eventId: Uuid[];
};

type Response = AxiosResponse<Collection<Event>>;
type QueryKeyType = QueryKey & {
  [0]: 'events';
  [1]: 'list-by-event-ids';
  [2]: Uuid[];
};

export const EventsByEventIdsRequest = async ({
  ...params
}: EventsByEventIdsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/events`, {
    params: { ...params },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useEventsByEventIdsRequest = (
  request: EventsByEventIdsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {},
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    queryFn: async () => EventsByEventIdsRequest(request),
    queryKey: ['events', 'list-by-event-ids', request.eventId],
    ...options,
  });

export const EventsByEventIdsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient,
) => {
  if (isEvent(data)) {
    queryClient.invalidateQueries({
      queryKey: ['events', 'list-by-event-ids'],
    });
  }
};
